import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { CommonService } from '../../../services/common/common.service';

@Component({
  selector: 'app-modal-claves-genericas',
  templateUrl: './modal-claves-genericas.component.html',
  styleUrls: ['./modal-claves-genericas.component.scss']
})

export class ModalClavesGenericasComponent implements OnInit {
  public formGroup = this.formBuilder.group({
    NumeroParte: ['', [Validators.required]],
    Clave: ['', [Validators.required]],
    Descripcion: ['', [Validators.required]],
    CostoCompra: ['', [Validators.required]],
    MonedaCompra: ['', [Validators.required]]
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<ModalClavesGenericasComponent>,
    private formBuilder: FormBuilder,
    private commonService: CommonService
  ) { }

  ngOnInit() { }

  modalClose(): void {
    this.matDialogRef.close(false);
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      Swal.fire({
        type: 'warning',
        title: 'Faltan campos por completar',
        text: 'Por favor, escribe los datos que faltan',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    this.insert();
  }

  insert() {
    Swal.fire({
      title: 'Guardando...',
      text: 'Por favor espere',
      allowOutsideClick: false,
    });
    Swal.showLoading();
    this.commonService.createGenericKey(
      this.formGroup.value.Clave,
      this.formGroup.value.NumeroParte,
      this.formGroup.value.Descripcion,
      this.formGroup.value.MonedaCompra,
      0,
      this.formGroup.value.CostoCompra,
    ).then((res) => {
      if (res.message === 'true') {
        Swal.fire({
          type: 'success',
          title: '¡Correcto!',
          text: 'Se ha guardado correctamente',
          allowOutsideClick: false,
          confirmButtonText: 'Aceptar'
        }).then((res) => {
          if (res.value) {
            this.matDialogRef.close(true);
          }
        });
      } else {
        throw new Error('Hubo un error al insertar en la base de datos, contacte al administrador');
      }
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
        confirmButtonText: 'Aceptar'
      });
    });
  }

  validateKey(key) {
    if (key) {
      this.commonService.validateGenericKey('clave', key).then((res) => {
        if (res.message === 'false') {
          Swal.fire({
            type: 'error',
            title: 'Error',
            text: 'La clave ya existe',
            confirmButtonText: 'Aceptar'
          }).then((res) => {
            if (res.value) {
              this.formGroup.controls.Clave.setValue('');
            }
          });
        }
      }).catch((error) => {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: error.message
        });
      });
    }
  }

  validatePartNumber(part_number: string) {
    if (part_number) {
      this.commonService.validateNoParte('noParte', part_number).then((res) => {
        if (res.message === 'false') {
          Swal.fire({
            type: 'error',
            title: 'Error',
            text: 'El número de parte ya existe',
            confirmButtonText: 'Aceptar'
          }).then((res) => {
            if (res.value) {
              this.formGroup.controls.NumeroParte.setValue('');
            }
          });
        }
      }).catch((error) => {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: error.message
        });
      });
    }
  }
}