import { version } from 'process';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, retry, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import {DataCanjes,ReporteCanjesKabiCoins} from '../../../views/reporte-canjes-kabicoins/model/canjes-kabiCoins';
import * as FileSaver from 'file-saver';
import { EmpresaService } from '../../Empresa/Empresa.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CanjesKabicoinsService {
  empresaId =  String(environment.empresaId);
  private destroy$ = new Subject<void>();
  constructor(private http: HttpClient,  private empresaService: EmpresaService) {
    this.empresaService.empresaId$
    .pipe(takeUntil(this.destroy$))
    .subscribe(id => {
      this.empresaId = id;
    });

   }

  public getDataCanjesKabicoins =  (fechaInicio: Date, fechaFinal: Date, cliente: number) => {

    const data = {
      option : 'canjesKabiCoins',
      fechaInicio,
      fechaFinal,
      cliente ,
      empresa: this.empresaId,
      version: environment.version
    };
    return this.http
      .post<DataCanjes>(
        environment.apiUrl + 'reportes/reporte-canjes-kabicoins/reporte-canjes-kabicoins.php',
        data
      )
      .pipe(map((data) => data))
      .toPromise();
  }

  public  getExecelCanjes = (reporteCanjes: ReporteCanjesKabiCoins[],total:number) => {
    const data = {
      option: 'setExcelResumenCanjes',
      reporteCanjes,
      empresa: this.empresaId,
      total
    };
    let f = new Date();
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http
      .post<any>(
        environment.apiUrl +
        'reportes/reporte-canjes-kabicoins/reporte-canjes-kabicoins.php',
        data,
        { headers, responseType: 'blob' as 'json' }
      )
      .pipe(
        map((data1) => {
          var blob = new Blob([data1], { type: 'application/vnd.ms.excel' });
          const file = new File([blob], 'Reporte_Canjes_kabiCoins_' + f.getDate() + '_' + (f.getMonth() + 1) + '_' + f.getFullYear() + '.xlsx', {
            type: 'application/vnd.ms.excel',
          });
          FileSaver.saveAs(file);
        })
      )
      .toPromise();
  }


  public getDataPuntosKabiCoins =  (cliente: any, allClientes:boolean, sucursal:string =  '-1') => {

    const data = {
      option : 'getClientesKabiCoins',
      cliente,
      allClientes,
      sucursal,
      empresaId: this.empresaId,
    };
    return this.http.post<any>(environment.apiUrl +  'reportes/reporte-canjes-kabicoins/reporte-canjes-kabicoins.php', data).pipe(retry(3),
    catchError(this.handleError), map(response => {
      return response;
    }));;
  }

  // reporteCanjes: ReporteCanjesKabiCoins[],total:number
  public  getExecelPuntosKabiCoins = (filas: any, columnas: string[]) => {
    const data = {
      option: 'setExcelClientesKabiCoins',
      filas,
      empresaID: this.empresaId,
      columnas
    };
    let f = new Date();
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post<any>(
        environment.apiUrl +
        'reportes/reporte-canjes-kabicoins/reporte-canjes-kabicoins.php',
        data,
        { headers, responseType: 'blob' as 'json' }
      )
      .pipe(
        map((data1) => {
          var blob = new Blob([data1], { type: 'application/vnd.ms.excel' });
          const file = new File([blob], 'Reporte_puntos_kabiCoins_' + f.getDate() + '_' + (f.getMonth() + 1) + '_' + f.getFullYear() + '.xlsx', {
            type: 'application/vnd.ms.excel',
          });
          FileSaver.saveAs(file);
        })
      )
      .toPromise();
  }


  private handleError = (error: HttpErrorResponse | any) => {
    Swal.fire({
      type: 'error',
      title: 'Error',
      text:
        'Lo sentimos, Ocurrio un error de comunicación, Favor de contactarse con el administrador del sitio Incidencias@kabik.com.mx' + error.message
    });
    return throwError(error);
  }

}
