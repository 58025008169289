import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';
import Swal from 'sweetalert2';
import { PeriodoInventarioService } from '../../../../services/inventario/periodo/periodo-inventario.service';
import { ABCService } from '../../../ABC/utils/services/ABC.service';
import { DataSucursales, sucursal } from '../../../ABC/utils/models/ABC';

@Component({
  selector: 'app-modal-periodo-inventario',
  templateUrl: './modal-periodo-inventario.component.html',
  styleUrls: ['./modal-periodo-inventario.component.scss']
})

export class ModalPeriodoInventarioComponent implements OnInit {
  public isModalUpdate: boolean = false;
  public sucursales: sucursal[] = [];
  public almacenes = [];
  public departamentos = [];
  public progressSucursal: boolean = false;
  public progressAlmacen: boolean = false;
  public formGroup = this.formBuilder.group({
    PeriodoID: null,
    SucursalID: [null, [Validators.required]],
    AlmacenID: ['', [Validators.required]],
    DepartamentoID: ['', [Validators.required]],
    ClavePeriodo: ['', [Validators.required]],
    FechaInicio: [Validators.required, Validators.maxLength[0]],
    FechaFinal: [Validators.required, Validators.maxLength[0]],
    ActivoPeriodo: false,
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<ModalPeriodoInventarioComponent>,
    private formBuilder: FormBuilder,
    private _adapter: DateAdapter<any>,
    private periodoInventarioService: PeriodoInventarioService,
    private abcService: ABCService
  ) {
    this._adapter.setLocale('mx');
    this.getSucursales();
    this.getDepartamentos();
  }

  ngOnInit() {
    if (this.data != null) {
      this.getAlmacenes(this.data.SucursalID);
      this.isModalUpdate = true;
      this.patchValue(this.data);
    }
  }

  modalClose(): void {
    this.matDialogRef.close(false);
  }

  validate(event) {
    this.periodoInventarioService.validate(event).then((res) => {
      const response: [] = res.response;
      if (response.length > 0) {
        Swal.fire({
          type: 'info',
          title: 'Información',
          text: 'La clave de periodo ya existe, intente con otro'
        });
        this.formGroup.get('ClavePeriodo').setValue('');
      }
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
        footer: 'Comunícate para resolver el problema',
      });
    });
  }

  getSucursales() {
    this.progressSucursal = true;
    this.abcService.getSucursales().subscribe((res: DataSucursales) => {
       this.sucursales = res.sucursales;
       this.progressSucursal = false;
    });
  }

  getAlmacenes(sucursal: number) {
    this.formGroup.get('AlmacenID').setValue('');
    this.progressAlmacen = true;
    this.periodoInventarioService.almacen(sucursal).then((res) => {
      this.almacenes = res.response;
      this.progressAlmacen = false;
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
        footer: 'Comunícate para resolver el problema',
      });
      this.progressAlmacen = false;
    })
  }

  getDepartamentos() {
    this.periodoInventarioService.departamento().then((res) => {
      this.departamentos = res.response;
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
        footer: 'Comunícate para resolver el problema',
      });
    })
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      Swal.fire({
        type: 'warning',
        title: 'Faltan campos por completar',
        text: 'Por favor, escribe los datos que faltan',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    if (this.formGroup.value.FechaInicio > this.formGroup.value.FechaFinal) {
      Swal.fire({
        type: 'warning',
        title: 'Fechas incorrectas',
        text: 'La fecha inicio no puede ser mayor a la fecha final',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    if (this.isModalUpdate) {
      this.update();
    } else {
      this.insert();
    }
  }

  public patchValue(data: any) {
    this.formGroup.patchValue({
      PeriodoID: data.PeriodoID,
      SucursalID: data.SucursalID,
      AlmacenID: data.AlmacenID,
      DepartamentoID: data.DepartamentoID,
      ClavePeriodo: data.ClavePeriodo,
      FechaInicio: new Date(data.FechaInicio),
      FechaFinal: new Date(data.FechaFinal),
      ActivoPeriodo: data.ActivoPeriodo == 1 ? true : false
    });
  }

  insert() {
    Swal.fire({
      title: 'Guardando...',
      text: 'Por favor espere',
      allowOutsideClick: false,
    });
    Swal.showLoading();
    let ClavePeriodo: string = this.eliminarAcentos(this.formGroup.value.ClavePeriodo);
    this.periodoInventarioService.insert(
      this.formGroup.value.SucursalID,
      this.formGroup.value.AlmacenID,
      this.formGroup.value.DepartamentoID,
      ClavePeriodo.trim(),
      this.formGroup.value.FechaInicio,
      this.formGroup.value.FechaFinal,
      this.formGroup.value.ActivoPeriodo ? 1 : 0,
    ).then((res) => {
      if (res.message === 'true') {
        Swal.fire({
          type: 'success',
          title: 'Correcto',
          text: 'Se ha guardado correctamente',
          footer: 'Excelente',
          allowOutsideClick: false,
          confirmButtonText: 'Aceptar'
        }).then((res) => {
          if (res.value) {
            this.matDialogRef.close(true);
          }
        });
      } else {
        throw new Error(res.message);
      }
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
        footer: 'Comunícate para resolver el problema',
      });
    });
  }

  public update() {
    Swal.fire({
      title: 'Actualizando...',
      text: 'Por favor espere',
      allowOutsideClick: false,
    });
    Swal.showLoading();
    this.periodoInventarioService.update(
      this.formGroup.value.PeriodoID,
      this.formGroup.value.SucursalID,
      this.formGroup.value.AlmacenID,
      this.formGroup.value.DepartamentoID,
      this.formGroup.value.ClavePeriodo,
      this.formGroup.value.FechaInicio,
      this.formGroup.value.FechaFinal,
      this.formGroup.value.ActivoPeriodo ? 1 : 0,
    ).subscribe((res) => {
      if (res.message === 'true') {
        Swal.fire({
          type: 'success',
          title: 'Correcto',
          text: 'Se ha editado correctamente',
          footer: 'Excelente',
          allowOutsideClick: false,
          confirmButtonText: 'Aceptar'
        }).then((res) => {
          if (res.value) {
            this.matDialogRef.close(true);
          }
        });
      } else {
        throw new Error(res.message);
      }
    },
    (error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
        footer: 'Comunícate para resolver el problema',
      });
    });
  }

  public today = (): Date => {
    return new Date();
  };

  public eliminarAcentos = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
}