import { EventosService } from './../../../services/eventos/eventos.service';
import { HttpErrorResponse } from '@angular/common/http';
import { element } from 'protractor';
import {Component,OnInit,Input, Output, EventEmitter} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlmacenService } from '../../../services/Almacen/almacen.service';
import { Datum, DatumRemitentes, dataDireccion } from '../model/Almacen';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var require: any;
const FileSaver = require('file-saver');
import Swal from 'sweetalert2';
import { GenericService } from '../../../services/generic.service';
import { SqlGenericService } from '../../../services/sqlGenericService';
import {
  environment,
  paqueteriaEstafeta,
} from './../../../../environments/environment.prod';
import { retry } from 'rxjs/operators';
import { LoginService } from '../../../services/login/login.service';
import { EventService } from '../../../../app/services/event.service';
import { EmpresaService } from '../../../services/Empresa/Empresa.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
export interface User {
  name: string;
}
@Component({
  selector: 'app-generar-guia-detalle',
  templateUrl: './generar-guia-detalle.component.html',
  styleUrls: ['./generar-guia-detalle.component.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class GenerarGuiaDetalleComponent implements OnInit {
  public formGenGuia: FormGroup;
  public procesoInter: boolean; //Si la guia se asigna a un proceso interno o a un pedido
  public devolucionCliente;
  public colonia: any;
  public coloniaDestinatario: any;
  public pedidoId: number = 0;
  public folioFactura: string = '';

  public empresaId: number = environment.empresaId;
  public almacenId: number = 0;
  public clienteId: number = 0;
  public nombre: string;
  public sucursales: any;
  /********Falta optimiza duplicar variables  */
  public clienteBuscar: string;
  public pedidoBuscar: string;
  public almacenBuscar: string;
  public intento: number = 0;

  /**************************** */
  public listaRemitentes: DatumRemitentes[];
  public listaDestinatarios: DatumRemitentes[];
  public listDireccionDestinatario: dataDireccion[];
  public listRemitentes: any;
  public spinnerCpR: boolean;
  public spinnerCpD: boolean;
  public checkedDestinatario: boolean;
  public checkedRemitente: boolean;
  public GuardaDireccRemitentes: Datum;
  public GuardaDireccDestinatario: Datum;
  public entregaSucursal: boolean = false;
  public globalTokenEstafeta: any = null;

  public dataSucursalOcurre: any = {
    estado: {
      value: '',
      label: 'Estado',
      values: [],
      onChange: () => {
        this.cargarCiudades(this.dataSucursalOcurre.estado.value);
      },
    },

    ciudad: {
      value: '',
      label: 'Ciudad',
      values: [],
      onChange: () => {
        this.cargarAsentamientos(this.dataSucursalOcurre.ciudad.value);
      },
    },

    asentamiento: {
      value: '',
      label: 'Código Postal',
      values: [],
      onChange: () => {
        this.findLocations(this.dataSucursalOcurre.asentamiento.value);
      },
    },

    locaciones: {
      value: '',
      label: 'Locación',
      values: [],
    },
  };

  @Input() aditionalInfo = null;
  @Input() extraClass = '';
  @Output() closeModalGuia: EventEmitter<boolean> = new EventEmitter();
  private destroy$ = new Subject<void>();

  public dataForm: any = {
    remitente: [],
    destinatario: [],
  };

  constructor(
    private fb: FormBuilder,
    private almacenService: AlmacenService,
    private snackBar: MatSnackBar,
    private activeRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router,
    private genericService: GenericService,
    private sqlGenericService: SqlGenericService,
    private authService: LoginService,
    private eventService: EventService,
    private empresaService: EmpresaService
  ) {
    this.activeRoute.params.subscribe(
      (param) => {
        if (Object.entries(param).length != 0) {
          if (param.data === 'ProcesosInternos') {
            this.procesoInter = true;
            this.devolucionCliente = false;
          } else if (param.data === 'DevolucionClientes') {
            this.devolucionCliente = true;
            this.procesoInter = false;
          } else {
            console.log(this.aditionalInfo);

            const params = JSON.parse(this.aditionalInfo ?? param.data);
            console.log('params',params);

            this.pedidoId = params.pedido;
            this.almacenId = params.almacen;
            this.clienteId = params.cliente;
            this.nombre = params.nombre;
            this.clienteBuscar = params.clienteB === '' ? '' : params.clienteB;
            this.pedidoBuscar = params.pedidoB === '' ? '' : params.pedidoB;
            this.almacenBuscar = params.almacenB === '' ? '' : params.almacenB;
            this.procesoInter = false;
            this.devolucionCliente = false;
            this.folioFactura  = params.serieFactura;
          }
          // const tpPedido = !this.procesoInter && !this.devolucionCliente ? 'Pedido' : this.procesoInter ? 'Interno' : 'Devolucion';
          // console.log('Eset es el tp pediffdo');
          // console.log(tpPedido);
          // if (param.data === 'ProcesosInternos') {
          //   this.procesoInter = true;
          // } else if (param.data ===''){
          // }
          // else {
          //   const params = JSON.parse(param.data);
          //   this.pedidoId = params.pedido;
          //   this.almacenId = params.almacen;
          //   this.clienteId = params.cliente;
          //   this.nombre = params.nombre;
          //   this.clienteBuscar = params.clienteB === '' ? '' : params.clienteB;
          //   this.pedidoBuscar = params.pedidoB === '' ? '' : params.pedidoB;
          //   this.almacenBuscar = params.almacenB === '' ? '' : params.almacenB;
          //   this.procesoInter = false;
          //}
        }
      },
      (error) => {
        console.log({ error });
      }
    );
    this.empresaService.empresaId$
    .pipe(takeUntil(this.destroy$))
    .subscribe(id => {
      this.empresaId = id;
    });
  }

  ngOnInit() {
    this.spinner.hide('spinner-guia');
    this.getTokenEstafeta();
    this.cargarEstados();
    if (this.aditionalInfo) {
      const params = this.aditionalInfo;
      console.log('PARAMS',params);

      this.pedidoId = params.pedido;
      this.almacenId = params.almacen;
      this.clienteId = params.cliente;
      this.nombre = params.nombre;
      this.clienteBuscar = params.clienteB === '' ? '' : params.clienteB;
      this.pedidoBuscar = params.pedidoB === '' ? '' : params.pedidoB;
      this.almacenBuscar = params.almacenB === '' ? '' : params.almacenB;
      this.procesoInter = false;
      this.devolucionCliente = false;
      this.folioFactura  = params.serieFactura;

    }
    this.validaFomr();
    this.getRemitentes();
    this.test();

  }

  async test(){
    console.log("test");
    let disponibilidadRemitente: any = await this.sqlGenericService
      .excecuteQueryString(
        this.getQuerie(
          this.formGenGuia.get('CpRemitente').value,
          12
        )
      )
      .toPromise();
  }

  getTokenEstafeta() {
    this.spinner.show('spinner-guia');
    this.genericService
      .sendGetRequest(`${environment.WSEstafeta}/ws_get_token_estafeta`)
      .pipe(retry(3))
      .subscribe(
        (resp: any) => {
          this.globalTokenEstafeta = resp.data.access_token;
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide('spinner-guia');
          Swal.fire({
            type: 'warning',
            title: 'Advertencia',
            text: 'No podemos establecer conexión con la paquetería, intenta refrescando el navegador o contacta a un administrador',
          });
        }
      );
  }

  cargarEstados() {
    this.genericService
      .sendGetRequest(
        `${environment.WSPaqueteriaCatEstado}/get_all_paqueterias_estado`
      )
      .subscribe(
        (response: any) => {
          response.data.forEach((element) => {
            this.dataSucursalOcurre.estado.values.push({
              value: element.EstadoID,
              label: element.Nombre,
            });
          });
        },
        (error: HttpErrorResponse) => {}
      );
  }

  cargarCiudades(idEstado: any) {
    this.spinner.show('spinner-guia');
    this.dataSucursalOcurre.ciudad.values = [];
    this.genericService
      .sendGetRequest(
        `${environment.WSPaqWSPaqueteriaCatCiudad}/get_all_paqueterias_ciudad`
      )
      .subscribe(
        (response: any) => {
          response.data.forEach((element) => {
            if (idEstado == element.EstadoID) {
              this.dataSucursalOcurre.ciudad.values.push({
                value: element.Codigo,
                label: element.Nombre,
              });
            }
          });
          this.spinner.hide('spinner-guia');
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide('spinner-guia');
        }
      );
  }

  cargarAsentamientos(Codigo: any) {
    this.spinner.show('spinner-guia');
    this.dataSucursalOcurre.asentamiento.values = [];
    this.genericService
      .sendGetRequest(
        `${environment.WSPaqueteriaCatAsentamiento}/get_all_paqueterias_asentamiento`
      )
      .subscribe(
        (response: any) => {
          response.data.forEach((element) => {
            if (Codigo == element.CodigoCiudad) {
              this.dataSucursalOcurre.asentamiento.values.push({
                value: element.CodigoPostal,
                label: element.CodigoPostal,
              });
            }
          });
          this.spinner.hide('spinner-guia');
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide('spinner-guia');
        }
      );
  }

  getValidatorOcurre() {
    console.log('getValidator');

    return (
      this.formGenGuia?.get('TotalPaquetes').value?.length > 0 &&
      this.formGenGuia?.get('alto').value?.length > 0 &&
      this.formGenGuia?.get('ancho').value?.length > 0 &&
      this.formGenGuia?.get('largo').value?.length > 0 &&
      this.formGenGuia?.get('peso').value?.length > 0
    );
  }

  findLocations(zipCode) {
    this.spinner.show('spinner-guia');
    this.dataSucursalOcurre.locaciones.values = [];
    this.genericService
      .sendPostRequest(
        `${environment.WSEstafeta}/ws_get_location_by_zipcode`,
        {
          token: this.globalTokenEstafeta,
          zipCode: zipCode,
          Radio: 400,
          SearchMaxNumber: 10,
          ServiceWarranty: '30',
          NumberOfItems: this.formGenGuia.get('TotalPaquetes').value,
          Alto: this.formGenGuia.get('alto').value,
          Ancho: this.formGenGuia.get('ancho').value,
          Largo: this.formGenGuia.get('largo').value,
          Peso: this.formGenGuia.get('peso').value,
          IsParking: true,
          searchByZipCode: true,
          locationCode: this.dataSucursalOcurre.asentamiento.value,
        }
      )
      .subscribe(
        (response: any) => {
          this.spinner.hide('spinner-guia');
          response?.data?.LocationList.forEach((element) => {
            this.dataSucursalOcurre.locaciones.values.push({
              value: element.EquivalentCode,
              label: element.Address,
            });
          });
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide('spinner-guia');
        }
      );
  }

  public getDireccionDest = () => {
    this.almacenService
      .getDireccionDestinatario(this.almacenId, this.pedidoId, this.clienteId)
      .then((res) => {
        if (res.error === false) {
          const referecia =
            res.data[0].referencia +
            ' Horario de atención: ' +
            res.data[0].horarioEntrada +
            '-' +
            res.data[0].horarioSalida;
          this.setItemForm('NombreDestinatario', res.data[0].nombre);
          this.setItemForm('CalleDestinatario', res.data[0].calle);
          this.setItemForm('CpDestinatario', res.data[0].codigoPostal);
          this.setItemForm('ColoniaDestinatario', res.data[0].colonia);
          this.setItemForm('CiudadDestinatario', res.data[0].ciudad);
          this.setItemForm('EstadoDestinatario', res.data[0].estado);
          this.setItemForm('TelefonoDestinatario', res.data[0].telefono);
          this.setItemForm('GuiaReferencia', referecia);
          this.setItemForm('numeroDestinatario', res.data[0].numInterior);
          this.setItemForm('Calle1Destinatario',  res.data[0].calle1);
          this.setItemForm('RFCDestinatario',  res.data[0].rfc);
          this.setItemForm('CorreoDestinatario',  res.data[0].correo);
          console.log('is res',res.data[0]);
        }
      })
      .catch((err: any) => {
        Swal.fire({
          type: 'warning',
          title: 'Advertencia',
          text: err.error.text,
        });
      });
  };
  public getRemitentes = () => {
    this.almacenService
      .getRemitentes(2, this.clienteId)
      .then((res) => {
        if (res.error === false) {
          this.listaRemitentes = res.dataRemitentes;
          // this.listaDestinatarios = res.data;
          const resultados = this.listaRemitentes.filter(item => item.nombre === "KABIK NORTE");
          console.log('resultados',resultados)
          if (resultados.length > 0) {
              this.setItemForm('NombreRemitente', resultados[0].nombre);
              this.setItemForm('CalleRemitente', resultados[0].calle);
              this.setItemForm('CpRemitente', resultados[0].cp);
              this.setItemForm('ColoniaRemitente', resultados[0].colonia);
              this.setItemForm('CiudadRemitente', resultados[0].ciudad);
              this.setItemForm('EstadoRemitente', resultados[0].estado);
              this.setItemForm('TelefonoRemitente',resultados[0].telefono);
              // this.setItemForm('numeroRemitente', resultados[0].numero);
              this.setItemForm('numeroRemitente', resultados[0].numeroExt);
              this.setItemForm('CorreoRemitente', resultados[0].correo);
              this.setItemForm('RFCRemitente', resultados[0].rfc);
              this.setItemForm('FolioFactura', this.folioFactura);
          }
        }
      })
      .catch((err: any) => {
        console.error(err);
        Swal.fire({
          type: 'warning',
          title: 'Advertencia',
          text:
            'Favor de contactar a incidencias@kabik.com.mx Advertencia : ' +
            err.error.error.message,
        });
      });
  };

  validaFomr() {
    this.formGenGuia = this.fb.group({
      Sucursal: [''],
      CheckboxRemitente: [''],
      CheckboxDestinatario: [''],
      NombreRemitente: ['', [Validators.required]],
      RFCRemitente: ['',[Validators.required, Validators.pattern('^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-Z]|[0-9]){2}([A]|[0-9]){1})?$')] ],
      CalleRemitente: ['', [Validators.required]],
      Calle1Remitente: ['', [Validators.required]],
      Calle2Remitente: ['', [Validators.required]],
      CpRemitente: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(5)],
      ],
      ColoniaRemitente: ['', [Validators.required]],
      CiudadRemitente: ['', [Validators.required]],
      EstadoRemitente: ['', [Validators.required]],
      TelefonoRemitente: [''],
      CorreoRemitente: ['', [Validators.email]],
      NombreDestinatario: ['', [Validators.required]],
      RFCDestinatario: [ '', [Validators.required, Validators.pattern('^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-Z]|[0-9]){2}([A]|[0-9]){1})?$')]],
      CalleDestinatario: ['', [Validators.required]],
      Calle1Destinatario: ['', [Validators.required]],
      Calle2Destinatario: ['', [Validators.required]],
      CpDestinatario: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(5)],
      ],
      ColoniaDestinatario: ['', [Validators.required]],
      CiudadDestinatario: ['', [Validators.required]],
      EstadoDestinatario: ['', [Validators.required]],
      TelefonoDestinatario: [''],
      CorreoDestinatario: ['', [Validators.email]],
      GuiaReferencia: ['', [Validators.required]],
      TipoEnvio: ['', [Validators.required]],
      TotalPaquetes: ['', [Validators.required]],
      FolioFactura: ['',!this.procesoInter && !this.devolucionCliente? [Validators.required]: '',],
      libretaRemitentes: [''],
      libretaDestinatarios: [''],
      numeroDestinatario: ['', [Validators.required]],
      numeroRemitente: ['', [Validators.required]],
      largo: ['', [Validators.required]],
      ancho: ['', [Validators.required]],
      alto: ['', [Validators.required]],
      peso: ['', [Validators.required]],
      ContenidoDelEnvio: ['', [Validators.required]],
      valorContenido: ['', [Validators.required, Validators.min(16)/*, Validators.min(16)*/]],
    });
    this.getDireccionDest();
    //  this.getItemForm('TotalPaquetes').valueChanges.subscribe(value => {
    //   console.log(value);
    // })
  }
  getDireccion = (opcion: number) => {
    let cp;
    let ciudad;
    let estado;
    switch (opcion) {
      case 1:
        cp = 'CpRemitente';
        ciudad = 'CiudadRemitente';
        estado = 'EstadoRemitente';
        this.spinnerCpR = true;
        break;
      case 2:
        this.spinnerCpD = true;
        cp = 'CpDestinatario';
        ciudad = 'CiudadDestinatario';
        estado = 'EstadoDestinatario';
        break;
    }
    console.log('...,.,.,.,..,..,....,');

    this.find(this.getItemForm(cp));

    this.almacenService
      .getDireccion(this.getItemForm(cp))
      .then((res) => {
        opcion === 1
          ? (this.spinnerCpR = false)
          : opcion === 2
          ? (this.spinnerCpD = false)
          : (this.spinnerCpR = false),
          (this.spinnerCpD = false);
        if (res.error === false) {
          this.formGenGuia.get(ciudad).setValue(res.ciudad);
          this.formGenGuia.get(estado).setValue(res.estado);
          if (opcion === 1) {
            this.colonia = res.asentamiento;
          } else {
            this.coloniaDestinatario = res.asentamiento;
          }
        } else {
          this.openSnackBar(
            'Codigo postal incorrecto.Favor de intentar con Codigo postal valido.'
          );
        }
      })
      .catch((err: any) => {
        opcion === 1
          ? (this.spinnerCpR = false)
          : opcion === 2
          ? (this.spinnerCpD = false)
          : (this.spinnerCpR = false),
          (this.spinnerCpD = false);
        console.log(err);
      });
  };

  async find(cp) {
    let paqueteriaDisponible = paqueteriaEstafeta;
    let disponibilidadRemitente: any = await this.sqlGenericService
      .excecuteQueryString(
        this.getQuerie(
          this.formGenGuia.get('CpRemitente').value,
          paqueteriaDisponible
        )
      )
      .toPromise();

    if (
      disponibilidadRemitente &&
      disponibilidadRemitente.parameters?.length <= 0
    ) {
      Swal.fire({
        type: 'warning',
        title: 'Advertencia',
        text: `Actualmente no contamos con cobertura al código postal ${cp}, verifica coberturas, por favor contacte al administrador incidencias@kabik.com.mx.`,
      });
    }
  }

  getDireccionDestinatario() {}

  getQuerie(cp: string, paqueteriaDisponible) {
    return `SELECT
    wpca.CodigoPostal,
    wpca.AsentamientoID,
    wpcc.Nombre as NombreCiudad,
    wpcc.CiudadID,
    wpcc.Codigo as CodigoCiudad,
    wpce.Nombre as NombreEstado,
    wpce.EstadoID,
    wpce.Codigo as CodigoEstado,
    wpcp.Nombre as NombrePais,
    wpcp.PaisID
    FROM Web_PaqueteriaCatAsentamiento wpca
    INNER JOIN Web_PaqueteriaCatCiudad wpcc
    ON (wpcc.Codigo = wpca.CodigoCiudad)
    INNER JOIN Web_PaqueteriaCatEstado wpce
    ON (wpce.EstadoID = wpcc.EstadoID)
    INNER JOIN Web_PaqueteriaCatPais wpcp
    ON (wpcp.PaisID = wpce.PaisID)
    WHERE wpcp.EmpresaID = ${paqueteriaDisponible} AND wpca.CodigoPostal = '${cp}'`;
  }

  async setNewGuideGenerate() {
    if(this.formGenGuia.invalid){
      this.formGenGuia.markAllAsTouched();
      Swal.fire({
        type: 'warning',
        title: 'Advertencia',
        text: `Existen campos pendientes por llenar.`,
      });
      return;
    }
    if (this.formGenGuia.get('ancho').value > 120) {
      Swal.fire({
        type: 'warning',
        title: 'Advertencia',
        text: `El ancho del paquete debe ser menor a 120, verifica coberturas, por favor contacte al administrador incidencias@kabik.com.mx.`,
      });
      return;
    }

    if (this.formGenGuia.get('ancho').value > 190) {
      Swal.fire({
        type: 'warning',
        title: 'Advertencia',
        text: `La altura del paquete debe ser menor a 190, verifica coberturas, por favor contacte al administrador incidencias@kabik.com.mx.`,
      });
      return;
    }

    if (this.formGenGuia.get('peso').value > 190) {
      Swal.fire({
        type: 'warning',
        title: 'Advertencia',
        text: `El peso del paquete debe ser menor a 70.99kg, verifica coberturas, por favor contacte al administrador incidencias@kabik.com.mx.`,
      });
      return;
    }

    const tpPedido =
      !this.procesoInter && !this.devolucionCliente
        ? 'Pedido'
        : this.procesoInter
        ? 'Empresas'
        : 'Garantias';

    if (this.formGenGuia.invalid) {
      console.log(this.formGenGuia);

      return;
    }
    //http://192.168.122.142/api_test/WSEstafeta/ws_get_token_estafeta
    this.spinner.show('spinner-guia');
    let valorCtn: string = this.formGenGuia.get('valorContenido').value;
    if (!valorCtn.includes('.') || valorCtn.length <= 2) {
      valorCtn += '.00';
    } else if (valorCtn.length == 4) {
      valorCtn += '0';
    } else if (valorCtn.length == 3) {
      valorCtn += '00';
    }

    let paqueteriaDisponible = paqueteriaEstafeta; //esto se tiene que iterar al haber mas paqueterias
    let disponibilidadRemitente: any = await this.sqlGenericService
      .excecuteQueryString(
        this.getQuerie(
          this.formGenGuia.get('CpRemitente').value,
          paqueteriaDisponible
        )
      )
      .toPromise();

    let disponibilidadDestinatario: any = await this.sqlGenericService
      .excecuteQueryString(
        this.getQuerie(
          this.formGenGuia.get('CpDestinatario').value,
          paqueteriaDisponible
        )
      )
      .toPromise();

    if (
      disponibilidadRemitente &&
      disponibilidadRemitente.parameters?.length <= 0
    ) {
      Swal.fire({
        type: 'warning',
        title: 'Advertencia',
        text: `Actualmente no contamos con cobertura al código postal ${
          this.formGenGuia.get('CpRemitente').value
        }, verifica coberturas, por favor contacte al administrador incidencias@kabik.com.mx.`,
      });
      return;
    }
    if (
      disponibilidadDestinatario &&
      disponibilidadDestinatario.parameters?.length <= 0
    ) {
      Swal.fire({
        type: 'warning',
        title: 'Advertencia',
        text: `Actualmente no contamos con cobertura al código postal ${
          this.formGenGuia.get('CpDestinatario').value
        }, verifica coberturas, por favor contacte al administrador incidencias@kabik.com.mx.`,
      });
      return;
    }

    if(this.entregaSucursal && (!this.dataSucursalOcurre.locaciones.value || this.dataSucursalOcurre.locaciones.value.length <= 0)){
      Swal.fire({
        type: 'warning',
        title: 'Advertencia',
        text: `Para las entregas Ocurre debes seleccionar una locación, por favor contacte al administrador incidencias@kabik.com.mx.`,
      });
      return;
    }
    this.spinner.show('spinner-guia');
    let dataDomicilioRemitente: any = disponibilidadRemitente.parameters[0];
    let dataDomicilioDestinatario: any =
      disponibilidadDestinatario.parameters[0];
    const user = this.authService.getUserData();

    let token: any = this.globalTokenEstafeta;
    let telefono1: string = this.formGenGuia.get('TelefonoRemitente').value;
    telefono1 = telefono1.replaceAll(' ', '');
    telefono1 = telefono1.replaceAll('(', '');
    telefono1 = telefono1.replaceAll(')', '');
    let telefono2: string = this.formGenGuia.get('TelefonoDestinatario').value;
    telefono2 = telefono2.replaceAll(' ', '');
    telefono2 = telefono2.replaceAll('(', '');
    telefono2 = telefono2.replaceAll(')', '');

    let request: any = {
      token: token,

      cpOrigen: this.formGenGuia.get('CpRemitente').value,
      cpDestino: this.formGenGuia.get('CpDestinatario').value,
      esPaquete: this.formGenGuia.get('TipoEnvio').value == 2,
      Alto: this.formGenGuia.get('alto').value,
      Ancho: this.formGenGuia.get('ancho').value,
      Largo: this.formGenGuia.get('largo').value,
      Peso: this.formGenGuia.get('peso').value,
      esInternacional: false,
      esImportante: true,
      esMaterialPeligroso: false,
      nombreEmpresa: 'Kabik SA de CV',
      nombreContacto: 'Kabik SA de CV',
      telefonoContacto: '8334543560',
      telefonoAltContacto: '8334543560',
      extTelefonoContacto: '8334543560',
      emailContacto: 'incidencias@kabik.com.mx',
      rfcContacto: 'CKA170907PD3',

      nombreOrigen: this.formGenGuia.get('NombreRemitente').value,
      nombreContactoOrigen: this.formGenGuia.get('NombreRemitente').value,
      telefonoContactoOrigen: telefono1,
      telefonoAltContactoOrigen: telefono1,
      extTelefonoContactoOrigen: telefono1,
      emailContactoOrigen: this.formGenGuia.get('CorreoRemitente').value,
      rfcContactoOrigen: this.formGenGuia
        .get('RFCRemitente')
        .value.toUpperCase(),

      calleOrigen: this.formGenGuia.get('CalleRemitente').value,
      codigoMunicipioOrigen: dataDomicilioRemitente.CodigoCiudad,
      nombreMunicipioOrigen: dataDomicilioRemitente.NombreCiudad,
      codigoAsentamientoOrigen: '009',
      nombreCodAsentamientoOrigen: 'Col',
      nombreAsentamientoOrigen: this.formGenGuia.get('ColoniaRemitente').value,
      codigoEstadoOrigen: dataDomicilioRemitente.CodigoEstado,
      nombreEstadoOrigen: dataDomicilioRemitente.NombreEstado,
      codigoPostalEmpresaOrigen: '89318',
      codigoPaisOrigen: '484',
      codigoNombrePaisOrigen: 'MEX',
      referenciaLocacionOrigen: `Colonia --- ${
        this.formGenGuia.get('ColoniaRemitente').value
      }`,
      entreCallePrimeraOrigen: this.formGenGuia.get('Calle1Remitente').value,
      entreCalleSegundaOrigen: this.formGenGuia.get('Calle2Remitente').value,
      latitudContactoOrigen: '23.3262626',
      longitudContactoOrigen: '-98.8942902',
      numeroExteriorContactoOrigen:
        this.formGenGuia.get('numeroRemitente').value,

      nombreDestino: this.formGenGuia.get('NombreRemitente').value,
      nombreContactoDestino: this.formGenGuia.get('NombreRemitente').value,
      telefonoContactoDestino: telefono2,
      telefonoAltContactoDestino: telefono2,
      extTelefonoContactoDestino: telefono2,
      emailContactoDestino: this.formGenGuia.get('CorreoDestinatario').value,
      rfcContactoDestino: this.formGenGuia
        .get('RFCDestinatario')
        .value.toUpperCase(),
      calleDestino: this.formGenGuia.get('CalleDestinatario').value,
      codigoMunicipioDestino: dataDomicilioDestinatario.CodigoCiudad,
      nombreMunicipioDestino: dataDomicilioDestinatario.NombreCiudad,
      codigoAsentamientoDestino: '009',
      nombreCodAsentamientoDestino: 'Col',
      nombreAsentamientoDestino: this.formGenGuia.get('ColoniaDestinatario')
        .value,
      codigoEstadoDestino: dataDomicilioDestinatario.CodigoEstado,
      nombreEstadoDestino: dataDomicilioDestinatario.NombreEstado,

      codigoPostalEmpresaDestino: this.formGenGuia.get('CpDestinatario').value,
      codigoPaisDestino: '484',
      codigoNombrePaisDestino: 'MEX',
      referenciaLocacionDestino: this.formGenGuia.get('GuiaReferencia').value,
      entreCallePrimeraDestino:
        this.formGenGuia.get('Calle1Destinatario').value,
      entreCalleSegundaDestino:
        this.formGenGuia.get('Calle2Destinatario').value,
      latitudContactoDestino: '23.3262626',
      longitudContactoDestino: '-98.8942902',
      numeroExteriorContactoDestino:
        this.formGenGuia.get('numeroDestinatario').value,

      adicionalInfo: this.formGenGuia.get('ContenidoDelEnvio').value,
      contenido: this.formGenGuia.get('ContenidoDelEnvio').value,
      centroCosto: '0000001',
      cpSalidaMercancia: '89318',
      descripcionContenido: this.formGenGuia.get('ContenidoDelEnvio').value,
      valorContenido: valorCtn,
      totalPaquetes: this.formGenGuia.get('TotalPaquetes').value,
      CiudadRemitente: this.formGenGuia.get('CiudadRemitente').value,
      CiudadDestinatario: this.formGenGuia.get('CiudadDestinatario').value,
      FolioFactura: this.formGenGuia.get('FolioFactura').value,
      tpPedido: tpPedido,
      empresa: this.empresaId,
      almacen: this.almacenId,
      pedido: this.pedidoId,
      cliente: this.clienteId,
      quien: user.correo,
      tipo: this.devolucionCliente ? 'garantias' : 'entre empresas',
      paqueteria: 'Estafeta',
      ocurre: this.entregaSucursal,
      ocurreCode: this.dataSucursalOcurre.locaciones.value
    };

    /**
           this.clienteId,
        this.pedidoId,
        this.almacenId,
           */
        console.log(request);

    this.genericService
      .sendPostRequest(`${environment.WSEstafeta}/ws_create_guia`, request)
      .pipe(retry(3))
      .subscribe(
        (responseGuide: any) => {
          console.log(responseGuide);
          this.spinner.hide('spinner-guia');
          if (this.checkedDestinatario) {
            this.guardarDireccionDestinatario();
          }
          if (this.checkedRemitente) {
            this.guardarDireccionRemitente();
          }
          let guiasConcat = '';
          guiasConcat +=
            responseGuide.data.labelPetitionResult.elements[0].wayBill;
          /* FileSaver.saveAs(
                  environment.apiUrl +
                    'paqueteria/download_guia.php?guia=' +
                    responseGuide.data.labelPetitionResult.referenceNumber +
                    '',
                  '' +
                    responseGuide.data.labelPetitionResult.referenceNumber +
                    '.pdf'
                );
 */
          this.eventService.send('updatePedido', null);
          window.open(
            'data:application/pdf;base64,' + encodeURI(responseGuide.data.data),
            '_blank'
          );

          Swal.fire({
            type: 'success',
            title: 'Guia generada correctamente.',
            text:
              'Numero de cliente ' +
              this.clienteId +
              ' Numero de guía : ' +
              guiasConcat +
              ' Numero de rastreo : ' +
              responseGuide.data.labelPetitionResult.elements[0].trackingCode,
            confirmButtonText: 'OK',
            showConfirmButton: true,
          }).then((result) => {
            this.formGenGuia.reset();
            const dat = {
              pedidoId: this.pedidoBuscar,
              almacenId: this.almacenBuscar,
              clienteId: this.clienteBuscar,
            }; //      !this.procesoInter && !this.devolucionCliente

            // !this.procesoInter && !this.devolucionCliente
            //   ? this.eventService.send('updatePedido', null) //Pedido
            //   : this.router.navigate(['imprimir-guia']); //Imprimir guia

            this.closeModalGuia.emit(true);
          });

          /*  if (this.checkedDestinatario) {
                    this.guardarDireccionDestinatario();
                  }
                  if (this.checkedRemitente) {
                    this.guardarDireccionRemitente();
                  }
                  let guiasConcat = '';
                  res.guia.forEach((element) => {
                    guiasConcat += element.numeroGuia + ', ';
                    FileSaver.saveAs(
                      environment.apiUrl +
                        'paqueteria/download_guia.php?guia=' +
                        element.numeroGuia +
                        '',
                      '' + element.numeroGuia + '.pdf'
                    );
                  });

                  Swal.fire({
                    type: 'success',
                    title: 'Guia generada correctamente.',
                    text:
                      'Numero de cliente ' +
                      this.clienteId +
                      ' Numero de guia : ' +
                      guiasConcat +
                      'Precio flete' +
                      ' Zona envio' +
                      res.zonaEnvio,
                    confirmButtonText: 'OK',
                    showConfirmButton: true,
                  }).then((result) => {
                    this.formGenGuia.reset();
                    const dat = {
                      pedidoId: this.pedidoBuscar,
                      almacenId: this.almacenBuscar,
                      clienteId: this.clienteBuscar,
                    }; //      !this.procesoInter && !this.devolucionCliente

                    !this.procesoInter && !this.devolucionCliente
                      ? this.router.navigate([
                          'generar-guia',
                          JSON.stringify(dat),
                        ]) //Pedido
                      : this.router.navigate(['imprimir-guia']); //Imprimir guia
                  }); */
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide('spinner-guia');
          /* Swal.fire({
            type: 'warning',
            title: 'Advertencia',
            text: 'No se pudo realizar la guia, por favor contacte al administrador incidencias@kabik.com.mx.',
          }); */
        let message = "";
        if(error){
          console.log("<alert>",error.error.testError)
          if(error.error.testError){
             message += error.error.testError;
          }
        }
          Swal.fire({
            type: 'error',
            title: 'Advertencia',
            html: 'No se pudo realizar la guia, por favor contacte al administrador incidencias@kabik.com.mx.<br/><br/>Error: ' + error.message+'<br/><br/> Detalles: '+error.error.message + message,
            confirmButtonText: 'Aceptar',
            footer:
              'Contáctanos&nbsp;<a href="mailto:incidencias@kabik.mx">incidencias@kabik.mx</a>',
          });
        }
      );
  }


  setGeneraGuia() {
    //
    this.spinner.show('spinner-guia');
    var object = new Object();
    const direccionEnvioRemitente =
      this.formGenGuia.get('CalleRemitente').value +
      ' #' +
      this.formGenGuia.get('numeroRemitente').value;
    const direccionEnvioDestino =
      this.formGenGuia.get('CalleDestinatario').value +
      ' #' +
      this.formGenGuia.get('numeroDestinatario').value;
    const tpPedido =
      !this.procesoInter && !this.devolucionCliente
        ? 'Pedido'
        : this.procesoInter
        ? 'Empresas'
        : 'Garantias';
    object = {
      Remitente: this.formGenGuia.get('NombreRemitente').value,
      Calle_Remitente: direccionEnvioRemitente,
      CP_Remitente: this.formGenGuia.get('CpRemitente').value,
      Colonia_Remitente: this.formGenGuia.get('ColoniaRemitente').value,
      CiudadRemitente: this.formGenGuia.get('CiudadRemitente').value,
      EstadoRemitente: this.formGenGuia.get('EstadoRemitente').value,
      Telefono_Remitente: this.formGenGuia.get('TelefonoRemitente').value,
      CorreoRemitente: this.formGenGuia.get('CorreoRemitente').value,
      Destinatario: this.formGenGuia.get('NombreDestinatario').value,
      Calle_Destinatario: direccionEnvioDestino,
      CP_Destinatario: this.formGenGuia.get('CpDestinatario').value,
      Colonia_Destinatario: this.formGenGuia.get('ColoniaDestinatario').value,
      CiudadDestinatario: this.formGenGuia.get('CiudadDestinatario').value,
      EstadoDestinatario: this.formGenGuia.get('EstadoDestinatario').value,
      Telefono_Destinatario: this.formGenGuia.get('TelefonoDestinatario').value,
      CorreoDestinatario: this.formGenGuia.get('CorreoDestinatario').value,
      GuiaReferencia: this.formGenGuia.get('GuiaReferencia').value,
      K_TipoEnvio: this.formGenGuia.get('TipoEnvio').value,
      // K_TipoEntrega: this.formGenGuia.get('TipoEntrega').value,
      TotalPaquetes: 1, //2 paquetes dos guias diferentes //  this.formGenGuia.get('TotalPaquetes').value
      FolioFactura: this.formGenGuia.get('FolioFactura').value,
      NumeroDestinatario: this.formGenGuia.get('numeroDestinatario').value,
      NumeroRemitente: this.formGenGuia.get('numeroRemitente').value,
      Observaciones: this.formGenGuia.get('ContenidoDelEnvio').value,
    };

    this.almacenService
      .setGeneraGuia(
        object,
        this.clienteId,
        this.pedidoId,
        this.almacenId,
        tpPedido,
        this.formGenGuia.get('TotalPaquetes').value
      )
      .then((res) => {
        this.spinner.hide('spinner-guia');
        if (res.message == true) {
          if (this.checkedDestinatario) {
            this.guardarDireccionDestinatario();
          }
          if (this.checkedRemitente) {
            this.guardarDireccionRemitente();
          }
          let guiasConcat = '';

          res.guia.forEach((element) => {
            guiasConcat += element.numeroGuia + ', ';
            FileSaver.saveAs(
              environment.apiUrl +
                'paqueteria/download_guia.php?guia=' +
                element.numeroGuia +
                '',
              '' + element.numeroGuia + '.pdf'
            );
          });

          Swal.fire({
            type: 'success',
            title: 'Guia generada correctamente.',
            text:
              'Numero de cliente ' +
              this.clienteId +
              ' Numero de guia : ' +
              guiasConcat +
              'Precio flete' +
              ' Zona envio' +
              res.zonaEnvio,
            confirmButtonText: 'OK',
            showConfirmButton: true,
          }).then((result) => {
            this.formGenGuia.reset();
            const dat = {
              pedidoId: this.pedidoBuscar,
              almacenId: this.almacenBuscar,
              clienteId: this.clienteBuscar,
            }; //      !this.procesoInter && !this.devolucionCliente

            // !this.procesoInter && !this.devolucionCliente
            //   ? this.router.navigate(['generar-guia', JSON.stringify(dat)]) //Pedido
            //   : this.router.navigate(['imprimir-guia']); //Imprimir guia
              this.closeModalGuia.emit(true);
          });
        } else {
          this.spinner.hide('spinner-guia');
          Swal.fire({
            type: 'warning',
            title: 'Advertencia',
            text: res.data,
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
        this.spinner.hide('spinner-guia');
        Swal.fire({
          type: 'warning',
          title: 'Advertencia',
          text:
            'No se pudo realizar la guia, por favor contacte al administrador incidencias@kabik.com.mx.' +
            err.error.error.message,
        });
      });
  }

  guardarDireccionRemitente() {
    this.GuardaDireccRemitentes = {
      id: '1',
      empresa: String(this.empresaId),
      sucursal: '',
      nombre: this.getItemForm('NombreRemitente'),
      clienteId: 0,
      calle: this.getItemForm('CalleRemitente'),
      numero: this.getItemForm('numeroRemitente'),
      cp: this.getItemForm('CpRemitente'),
      colonia: this.getItemForm('ColoniaRemitente'),
      ciudad: this.getItemForm('CiudadRemitente'),
      estado: this.getItemForm('EstadoRemitente'),
      telefono: this.getItemForm('TelefonoRemitente'),
      correo: this.getItemForm('CorreoRemitente'),
      tipo: 'Remitente',
      nameDireccion: ' ',
    };
    this.almacenService
      .setRemitentes(this.GuardaDireccRemitentes)
      .then((res) => {
        if (res.error === false) {
        }
      })
      .catch((err: any) => {
        Swal.fire({
          type: 'warning',
          title: 'Advertencia',
          text:
            'Consulte al administrador incidencias@kabik.com.mx. error:  ' +
            err.error.error.message,
        });
      });
  }

  guardarDireccionDestinatario() {
    this.GuardaDireccDestinatario = {
      id: '1',
      empresa: String(this.empresaId),
      sucursal: '',
      clienteId: this.clienteId,
      nombre: this.getItemForm('NombreDestinatario'),
      calle: this.getItemForm('CalleDestinatario'),
      numero: this.getItemForm('numeroDestinatario'),
      cp: this.getItemForm('CpDestinatario'),
      colonia: this.getItemForm('ColoniaDestinatario'),
      ciudad: this.getItemForm('CiudadDestinatario'),
      estado: this.getItemForm('EstadoDestinatario'),
      telefono: this.getItemForm('TelefonoDestinatario'),
      correo: this.getItemForm('CorreoDestinatario'),
      tipo: 'Destinatario',
      nameDireccion: ' ',
    };
    this.almacenService
      .setRemitentes(this.GuardaDireccDestinatario)
      .then((res) => {
        if (res.error === false) {
        }
      })
      .catch((err: any) => {
        Swal.fire({
          type: 'warning',
          title: 'Advertencia',
          text:
            'Consulte al administrador incidencias@kabik.com.mx. error:  ' +
            err.error.error.message,
        });
      });
  }

  llenaInfoRemitentes(remitentes: any, opcion: number) {
    console.log('Remitentes',remitentes)
    switch (opcion) {
      case 1:
        this.setItemForm('NombreRemitente', remitentes.nombre);
        this.setItemForm('CalleRemitente', remitentes.calle);
        this.setItemForm('CpRemitente', remitentes.cp);
        this.setItemForm('ColoniaRemitente', remitentes.colonia);
        this.setItemForm('CiudadRemitente', remitentes.ciudad);
        this.setItemForm('EstadoRemitente', remitentes.estado);
        this.setItemForm('TelefonoRemitente', remitentes.telefono);
        this.setItemForm('CorreoRemitente', remitentes.correo);
        this.setItemForm('numeroRemitente', remitentes.numero);
        this.setItemForm('RFCRemitente', remitentes.rfc);
        //this.checkedDestinatario = true;
        break;
      case 2:
        this.setItemForm('NombreDestinatario', remitentes.nombre);
        this.setItemForm('CalleDestinatario', remitentes.calle);
        this.setItemForm('CpDestinatario', remitentes.cp);
        this.setItemForm('ColoniaDestinatario', remitentes.colonia);
        this.setItemForm('CiudadDestinatario', remitentes.ciudad);
        this.setItemForm('EstadoDestinatario', remitentes.estado);
        this.setItemForm('TelefonoDestinatario', remitentes.telefono);
        this.setItemForm('CorreoDestinatario', remitentes.correo);
        this.setItemForm('numeroDestinatario', remitentes.numero);
        this.setItemForm('Calle1Remitente', remitentes.calle1);
        this.setItemForm('RFCRemitente', remitentes.rfc);


        break;
    }
  }
  public validaPaquetes() {
    if (this.getItemForm('TotalPaquetes') > 1) {
      this.openSnackBar(
        'Se generara una guia por cada paquete, El costo de cada guia estara sujeto a las medidas y peso especificados.'
      );
    }
  }
  public getItemForm = (name) => {
    return this.formGenGuia.get(name).value;
  };

  public setItemForm = (name, value) => {
    this.formGenGuia.get(name).setValue(value);
  };
  public openSnackBar(mensaje: string) {
    this.snackBar.open(mensaje, 'Ok', {
      duration: 5200,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  public selected(event?: any, opcion?: number) {
    switch (opcion) {
      case 1:
        this.checkedRemitente = event.checked;
        break;
      case 2:
        this.checkedDestinatario = event.checked;
        break;
      case 3:
        this.entregaSucursal = event.checked;
        console.log(this.entregaSucursal);

        break;
    }
  }

  // public selected(event?: any, opcion?: number) {
  //   if (event.checked) {
  //     //REMITENTE 1 //DESTINATARIO 2
  //     opcion === 1
  //       ? (this.checkedDestinatario = true)
  //       : (this.checkedDestinatario = false);
  //     opcion === 2
  //       ? (this.checkedRemitente = true)
  //       : (this.checkedRemitente = false);

  //     // opcion === 1
  //     //   ? (this.checkedDestinatario = true)
  //     //   : (this.checkedRemitente = true);
  //   } else {
  //    opcion === 1
  //       ? (this.checkedDestinatario = false)
  //       : (this.checkedRemitente = false);
  //   }
  // }

  getKeys() {
    return Object.keys(this.dataSucursalOcurre);
  }
}
