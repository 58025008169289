import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-menu-items-guia',
  templateUrl: './menu-items-guia.component.html',
  styleUrls: ['./menu-items-guia.component.scss']
})
export class MenuItemsGuiaComponent implements OnInit ,OnChanges{
  @Input() listaDeColumnas: any[] = [];
  @Input() donde: string;
  @Input() limpiarAuxiliar: boolean;
  @Output() itemSelected: EventEmitter<any> = new EventEmitter();
  public listaDeColumnasAux: any = [];
  selection: SelectionModel<any> = new SelectionModel<any>(true, []);

  constructor() { }

  ngOnInit() {
 }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listaDeColumnas.length;
    return numSelected === numRows;
  }
  ngOnChanges() {
    // console.log('this selection', this.selection);
    if (this.listaDeColumnas?.length > 0 ) {
      // const listaAuxiliar =  this.listaDeColumnasAux;
        this.listaDeColumnas.forEach((row) => {
        //  const filter  =  this.listaDeColumnasAux.filter(item => item.id === row.id && item.Nombre === row.Nombre);
        //  if (filter.length > 0) {
        //   console.log(filter);
        console.log('is selection: ', this.selection.isSelected(row));
           if (this.selection.isSelected(row)) {

           }
          // }
        });
    } else {
      this.listaDeColumnasAux = [];
      this.selection.clear();
    }
  }
  public selected(event?: any, row?: any) {
    if (event.checked) {
      this.listaDeColumnasAux = [...this.listaDeColumnasAux, row];
    } else {
      this.removeToArray(row);
    }
    // console.log(row);
    event = event ? this.selection.toggle(row) : null;
    this.itemSelected.emit({donde: this.donde, columnas: this.listaDeColumnasAux});
    return event;
  }
  private removeToArray(row: any): void {
    let contador = 0;
    this.listaDeColumnasAux.forEach((value) => {
      if (value === row) {
        // console.log(this.listArticulos.length);
        if (this.listaDeColumnasAux.length <= 1) {
          this.listaDeColumnasAux = [];
        } else {
          // const cont = contador <= 1 ? 1 : contador;
          this.listaDeColumnasAux.splice(contador, 1);
        }
      }
      contador++;
    });
  }
  masterToggle() {
    this.listaDeColumnasAux = [];
    this.isAllSelected()
      ? this.selection.clear()
      : this.listaDeColumnas.forEach((row) => {
            this.listaDeColumnasAux = [...this.listaDeColumnasAux, row];
            this.selection.select(row);
        });
    this.itemSelected.emit({donde: this.donde, columnas: this.listaDeColumnasAux});
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }
  filtar ($event:any): void {
    // this.showClassDanger = true;
    // this.itemSelected.emit(this.listaDeColumnasAux);
    this.itemSelected.emit({donde: this.donde, columnas: this.listaDeColumnasAux});

  }
  clear(): void {
    // this.showClassDanger = false;
    this.listaDeColumnasAux = [];
    this.selection.clear();
    this.itemSelected.emit({donde: this.donde, columnas: this.listaDeColumnasAux});
  }
}
