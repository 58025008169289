import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment.prod';
import { GenericService } from '../../../../app/services/generic.service';
import { retry } from 'rxjs/operators';

@Component({
  selector: 'app-imprimir-guia-detalle',
  templateUrl: './imprimir-guia-detalle.component.html',
  styleUrls: ['./imprimir-guia-detalle.component.scss'],
})
export class ImprimirGuiaDetalleComponent implements OnInit {
  public data: any;
  public dataDet: any;
  public formBusquedaGuiaDet: FormGroup;
  public estatus: any = '';
  constructor(
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private genericService: GenericService,
    private router: Router
  ) {
    this.data = JSON.parse(localStorage.getItem('dt'));
    console.log(this.data);

    this.activeRoute.params.subscribe(
      (param) => {
        console.log(param);
        this.data = JSON.parse(localStorage.getItem('dt'));

        this.dataDet = this.data.detalleSeguimiento;
      },
      (error) => {
        console.log({ error });
      }
    );
  }

  ngOnInit() {
    this.fomr();
  }
  fomr() {
    this.formBusquedaGuiaDet = this.fb.group({
      NombreRemitente: ['', [Validators.required]],
      CalleRemitente: ['', [Validators.required]],
      CpRemitente: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(5)],
      ],
      ColoniaRemitente: ['', [Validators.required]],
      CiudadRemitente: ['', [Validators.required]],
      EstadoRemitente: ['', [Validators.required]],
      TelefonoRemitente: [''],
      CorreoRemitente: ['', [Validators.email]],
      NombreDestinatario: ['', [Validators.required]],
      CalleDestinatario: ['', [Validators.required]],
      CpDestinatario: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(5)],
      ],
      ColoniaDestinatario: ['', [Validators.required]],
      CiudadDestinatario: ['', [Validators.required]],
      EstadoDestinatario: ['', [Validators.required]],
      TelefonoDestinatario: [''],
      CorreoDestinatario: ['', [Validators.email]],
      GuiaReferencia: ['', [Validators.required]],
      TipoEnvio: ['', [Validators.required]],
      TipoEntrega: ['', [Validators.required]],
      TotalPaquetes: ['', [Validators.required]],
      GuiaRastreo: ['', [Validators.required]],
      EstatusRastreo: ['', [Validators.required]],
      F_Estatus: ['', [Validators.required]],
      F_Documentacion: ['', [Validators.required]],
      Recibio: ['', [Validators.required]],
    });
    this.getEstatus();
    this.Validadatos();
  }

  Validadatos() {
    this.formBusquedaGuiaDet
      .get('NombreRemitente')
      .setValue(this.data.Remitente);
    this.formBusquedaGuiaDet
      .get('CalleRemitente')
      .setValue(this.data.CalleRemitente);
    this.formBusquedaGuiaDet.get('CpRemitente').setValue(this.data.CpRemitente);
    this.formBusquedaGuiaDet
      .get('ColoniaRemitente')
      .setValue(this.data.ColoniaRemitente);

    this.formBusquedaGuiaDet
      .get('CiudadRemitente')
      .setValue(this.data.CiudadRemitente);
    this.formBusquedaGuiaDet
      .get('EstadoRemitente')
      .setValue(this.data.EstadoRemitente);

    this.formBusquedaGuiaDet
      .get('TelefonoRemitente')
      .setValue(this.data.TelefonoRemitente);
    this.formBusquedaGuiaDet
      .get('CorreoRemitente')
      .setValue(this.data.CorreoRemitente);
    this.formBusquedaGuiaDet
      .get('NombreDestinatario')
      .setValue(this.data.Destinatario);
    this.formBusquedaGuiaDet
      .get('CalleDestinatario')
      .setValue(this.data.CalleDestinatario);
    this.formBusquedaGuiaDet
      .get('CpDestinatario')
      .setValue(this.data.CpDestinatario);
    this.formBusquedaGuiaDet
      .get('ColoniaDestinatario')
      .setValue(this.data.ColoniaDestinatario);
    this.formBusquedaGuiaDet
      .get('CiudadDestinatario')
      .setValue(this.data.CiudadDestinatario);
    this.formBusquedaGuiaDet
      .get('EstadoDestinatario')
      .setValue(this.data.EstadoDestinatario);

    this.formBusquedaGuiaDet
      .get('TelefonoDestinatario')
      .setValue(this.data.TelefonoDestinatario);
    this.formBusquedaGuiaDet
      .get('CorreoDestinatario')
      .setValue(this.data.CorreoDestinatario);
    this.formBusquedaGuiaDet
      .get('GuiaReferencia')
      .setValue(this.data.GuiaReferencia);
    this.formBusquedaGuiaDet
      .get('TipoEnvio')
      .setValue(this.data.TipoEnvio == '1' ? 'Sobre' : 'Paquete');
    this.formBusquedaGuiaDet
      .get('TipoEntrega')
      .setValue(this.data.TpEntrega == '1' ? 'Ocurre' : 'EAD');
    this.formBusquedaGuiaDet
      .get('TotalPaquetes')
      .setValue(this.data.TotalPaquetes);
    this.formBusquedaGuiaDet.get('GuiaRastreo').setValue(this.data.Guia);
    this.formBusquedaGuiaDet.get('F_Estatus').setValue(this.data.FechaGuia.split(".")[0]);
    this.formBusquedaGuiaDet
    .get('EstatusRastreo')
    .setValue("...");
    if (this.dataDet != undefined) {
      this.formBusquedaGuiaDet
        .get('EstatusRastreo')
        .setValue(this.dataDet.Estatus);
      this.formBusquedaGuiaDet.get('F_Estatus').setValue(this.data.FechaGuia.split(".")[0]);
      this.formBusquedaGuiaDet
        .get('F_Documentacion')
        .setValue(this.dataDet.F_Documentacion);
      this.formBusquedaGuiaDet
        .get('Recibio')
        .setValue(
          this.dataDet.Recibio != '[object Object]' ? this.dataDet.Recibio : ''
        );
    }

    
  }

  getEstatus() {
    this.genericService
      .sendGetRequest(`${environment.WSEstafeta}/ws_get_token_estafeta`)
      .pipe(retry(3))
      .subscribe((resp: any) => {
        console.log(resp);

        let token: any = resp.data.access_token;

        let request: any = {
          token: token,
          numeroGuia: this.data.Guia,
          option: 'getTrackingByNumeroGuia',
        };

        this.genericService
          .sendPostRequest(
            `${environment.WSEstafeta}/ws_get_tracking_by_numero_guia`,
            request
          )
          .pipe(retry(3))
          .subscribe((estatus: any) => {
            console.log(estatus); 
            console.log(estatus?.data?.ExecuteQueryResponse?.ExecuteQueryResult?.trackingData?.TrackingData?.statusSPA);
            
          this.formBusquedaGuiaDet
            .get('EstatusRastreo')
            .setValue(estatus?.data?.ExecuteQueryResponse?.ExecuteQueryResult?.trackingData?.TrackingData?.statusSPA);
          });
      });
  }
  regresar() {
    this.router.navigate(['imprimir-guia']);
  }
}
