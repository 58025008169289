import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
// import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
interface Encuesta {
  message: string;
  encuestas?: {};
}
@Injectable({
  providedIn: 'root'
})
export class ReporteEncuestaService {

  constructor(private http: HttpClient) { }

  public exportAsExcelFile(
    json: any[],
  ): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = {
      Sheets: { General: worksheet },
      SheetNames: ['General']
    };

    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    this.saveAsExcelFile(excelBuffer);
  }

  private saveAsExcelFile(buffer: any): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(
      data,
      'kabik_encuestas_' + new Date().toLocaleDateString() + EXCEL_EXTENSION
    );
  }

  getEncuestas() {
    return this.http.get<Encuesta>(environment.apiUrl + "reportes/reporte-encuesta/encuesta.php").pipe(
      map(data => data)
    ).toPromise();
  }
}
