import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ABCService, } from '../ABC/utils/services/ABC.service';
import { Subscription, Observable, of, Subject } from 'rxjs';
import { Cliente,
  DataClientes,
  DataGrupo,
  Grupos,
  DataDepartamento,
  Departamento,
  DataReporteVentasTres,
  vendendores,
  DataVendedores,
  DataSucursales,
  sucursal,
  DataMarcas,
  marcas
  } from '../ABC/utils/models/ABC';
  import { DataVentaMarcas } from '../ABC/utils/models/VentasMarcas';
  import { distinctUntilChanged, map, startWith, take } from 'rxjs/operators';
  import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
  import Swal from 'sweetalert2';
  import { MatSnackBar } from '@angular/material/snack-bar';
  import { MatDialog } from '@angular/material/dialog';
  import { FiltrosComponent } from '../../core/components/filtros/filtros.component';
import { DateAdapter } from '@angular/material/core';
import { GetDateService } from './../../services/Date/get_date.service';
import { Date_ } from '../../models/date_';


@Component({
  selector: 'app-reporte-ventas-marcas',
  templateUrl: './reporte-ventas-marcas.component.html',
  styleUrls: ['./reporte-ventas-marcas.component.scss']
})
export class ReporteVentasMarcasComponent implements OnInit {

  public formFiltros: FormGroup;
  public clientesList: Cliente[] = [];
  public clientesListSelected: Cliente[] = [];
  public gruposList: Grupos[] = [];
  public gruposListFiltrado: Grupos[] = [];
  public gruposSelected: Grupos[] = [];
  public depatamentosList: Departamento[] = [];
  public depatamentosSelected: Departamento[] = [];
  public vendedoresList: vendendores[] = [];
  public vendedoresListSelected: vendendores[] = [];
  public sucursales: sucursal[] = [];
  public sucursales$: Observable<sucursal[]>;
  public marcasList: marcas[] = [];
  public marcasSelected: marcas[] = [];
  public dataVentaMarcas: DataVentaMarcas;
  public fechaFinal: Date;
  public maxDate: Date;
  public listReportes: any = [
    {
      value: 1, viewValue: 'Detallado (Departamento, Grupo, Cliente)'
    },
    {
      value: 2, viewValue: 'Resumen por Departamento'
    },
    {
      value: 3, viewValue: 'Resumen por Departamento y Grupo'
    },
  ]
  constructor(
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private abcService: ABCService,
    private form: FormBuilder,
    private matDialog: MatDialog,
    private _adapter: DateAdapter<any>,
    private getDate: GetDateService
  ) {
    this._adapter.setLocale('mx');
    this.buildForm();
    this.fecha();
    this.sucursales$ = this.formFiltros.get('sucursales').valueChanges.pipe(
      startWith(''),
      map(value => this._filterSucursales(value))
    );

  }

  ngOnInit() {


  }

  private buildForm() {


    const hoy = new Date();
    this.formFiltros = this.form.group({
      clientesAbc: new FormControl('', [Validators.required]),
      departamentoAbc: new FormControl('', [Validators.required]),
      grupoAbc: new FormControl(''),
      marcasAbc: new FormControl('', [Validators.required]),
      vendedoresAbc: new FormControl(''),
      reportes: new FormControl(''),
      sucursales : new FormControl('', [Validators.required]),
      FechaInicio: [new Date(hoy.getFullYear(), hoy.getMonth(), 1), [Validators.required]],
      FechaFin: [hoy, [Validators.required]],
    });
    this.formFiltros.controls.grupoAbc.disable();




    this.setDate(); // trae la hora del servidor
    this.getSucursales();
    this.getDeparamentosAbc(0);
    this.getGruposAbc(0);
    this.getMarcasAbc();
    this.getClientesAbc();
    this.getVendedoresAbc();
  }
  private setDate() {
    this.getInputValue('FechaInicio').disable();
    this.getInputValue('FechaFin').disable();
    this.getDate.get_Date('Y/m/01', 'Y/m/d').pipe().subscribe((response: Date_) => {
      console.groupCollapsed();
      console.log('reponse::', response);
      console.log('FI', new Date(response.fechaInicial), 'FF', new Date(response.fechaFinal));
      console.groupEnd();
     this.formFiltros.get('FechaInicio').setValue(new Date(response.fechaInicial));
     this.formFiltros.get('FechaFin').setValue(new Date(response.fechaFinal));
     this.getInputValue('FechaInicio').enable(); // Habilitado
     this.getInputValue('FechaFin').enable(); // Habilitado
    });

  }

  private getClientesAbc() {
    this.getInputValue('clientesAbc').disable();
    this.abcService.getClientesAbcDos().subscribe((res: DataClientes) => {
      this.clientesList = res.clientes;
      this.getInputValue('clientesAbc').enable();
    });
  }
  private getDeparamentosAbc(clienteId: number) {
    this.getInputValue('departamentoAbc').disable();
    this.abcService.getDepartamentosAbcDos(clienteId).subscribe((res: DataDepartamento) => {
      this.depatamentosList = res.departamentos;
      this.getInputValue('departamentoAbc').enable();
    });
  }
  private getGruposAbc(clienteId: number) {
    this.getInputValue('grupoAbc').disable();
    this.abcService.getGruposAbcDos(clienteId).subscribe((res: DataGrupo) => {
      this.gruposList = res.grupos;
      this.getInputValue('grupoAbc').enable();
    });
  }
  private getMarcasAbc() {
    this.getInputValue('marcasAbc').disable();
    this.abcService.getMarcasAbc().subscribe((res: DataMarcas) => {
      this.marcasList = res.marcas;
      this.getInputValue('marcasAbc').enable();
    });
  }

  private getVendedoresAbc() {
    this.getInputValue('vendedoresAbc').disable();
    this.abcService.getVendedoresAbc().subscribe((res: DataVendedores) => {
      this.vendedoresList = res.vendendores;
      this.getInputValue('vendedoresAbc').enable();
    });
  }

  private getSucursales() {
    this.abcService.getSucursales().subscribe((res: DataSucursales) => {
       this.sucursales = res.sucursales;
    });
  }
  fecha() {
    this.maxDate = new Date();
  }
  public getInputValue(control: string) {
    return this.formFiltros.get(control);
  }

  public modalClientes(opcion: number) {
    let list = [];
    let listCheck = [];
    switch (opcion) {
      case 1:
        list = this.depatamentosList;
        listCheck = this.depatamentosSelected;
        break;
      case 2:
        list = this.gruposListFiltrado.length > 0 ? this.gruposListFiltrado : this.gruposList;
        listCheck =  this.gruposSelected;
        break;
      case 3:
        list = this.clientesList;
        listCheck = this.clientesListSelected;
        break;
      case 4:
        list = this.vendedoresList;
        listCheck = this.vendedoresListSelected;
       break;
       case 5:
        list = this.marcasList;
        listCheck = this.marcasSelected;
       break;
    }
    const dialogRef = this.matDialog.open(FiltrosComponent, {
      disableClose: true,
      width: '28%',
      data: {
        opcion,
        lista: list,
        listaCheck : listCheck,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      switch (opcion) {
        case 1: // Departamentos
          this.depatamentosSelected = result;
          const departamento = this.depatamentosSelected.length > 0 ? this.depatamentosSelected[0].Nombre.slice(0, 20) : '';
          const departamento2 = this.depatamentosSelected.length > 1 ?
            ' (+' + (this.depatamentosSelected.length - 1) + ' ' + (this.depatamentosSelected.length === 2 ? 'Departamento' : 'Departamentos') + ')' : '';

            if(this.depatamentosSelected.length>0){
              this.getInputValue('departamentoAbc').setValue(departamento + ' ' + departamento2);
             }else{
              this.getInputValue('departamentoAbc').setValue('');
             }
             this.gruposListFiltrado = this.filterGrupos();

          break;
        case 2: // Grupos
          this.gruposSelected = result;
          const grupo = this.gruposSelected.length > 0 ? this.gruposSelected[0].Nombre.slice(0, 20) : '';
          const grupo2 = this.gruposSelected.length > 1 ?
            ' (+' + (this.gruposSelected.length - 1) + ' ' + (this.gruposSelected.length === 2 ? 'Grupo' : 'Grupos') + ')' : '';
          this.getInputValue('grupoAbc').setValue(grupo + ' ' + grupo2);

          break;
        case 3: // Clientes
          this.clientesListSelected = result;
          const cliente = this.clientesListSelected.length > 0 ? this.clientesListSelected[0].Nombre.slice(0, 20) : '';
          const clientes2 = this.clientesListSelected.length > 1 ?
            ' (+' + (this.clientesListSelected.length - 1) + ' ' + (this.clientesListSelected.length === 2 ? 'Cliente' : 'Clientes') + ')' : '';
            if(this.clientesListSelected.length>0){
              this.getInputValue('clientesAbc').setValue(cliente + ' ' + clientes2);
             }else{
              this.getInputValue('clientesAbc').setValue('');
             }
          break;
        case 4: // Departamentos
          this.vendedoresListSelected = result;
          const vendedor = this.vendedoresListSelected.length > 0 ? this.vendedoresListSelected[0].Nombre.slice(0, 20) : '';
          const vendedor2 = this.vendedoresListSelected.length > 1 ?
            ' (+' + (this.vendedoresListSelected.length - 1) + ' ' + (this.vendedoresListSelected.length === 2 ?
              'Vendedor' : 'Vendendores') + ')' : '';
          if (this.vendedoresListSelected.length > 0) {
            this.getInputValue('vendedoresAbc').setValue(vendedor + ' ' + vendedor2);
          } else {
            this.getInputValue('vendedoresAbc').setValue('');
          }
          break;

          case 5: // Departamentos
          this.marcasSelected = result;
          const marca = this.marcasSelected.length > 0 ? this.marcasSelected[0].Nombre.slice(0, 20) : '';
          const marca2 = this.marcasSelected.length > 1 ?
            ' (+' + (this.marcasSelected.length - 1) + ' ' + (this.marcasSelected.length === 2 ?
              'Marca' : 'Marcas') + ')' : '';
          if (this.marcasSelected.length > 0) {
            this.getInputValue('marcasAbc').setValue(marca + ' ' + marca2);
          } else {
            this.getInputValue('marcasAbc').setValue('');
          }
          break;
      }

    });
  }

  public getDataReporteVentasMarcas() {

    this.spinner.show('spinner-reporte-abc-tres');
    const allClientes = this.clientesList.length === this.clientesListSelected.length ? true : false;
    const allDepartamentos = this.depatamentosList.length === this.depatamentosSelected.length ? true : false;
  //  const allGrupos = this.gruposList.length === this.gruposSelected.length ? true : false; gruposListFiltrado
    const allGrupos = this.gruposList.length === this.gruposSelected.length ? true : false;
    const allVendedores = this.vendedoresList.length === this.vendedoresListSelected.length ? true : false;
    const allSucursales = this.formFiltros.controls.sucursales.value.length === this.sucursales.length ? true : false;
    const allmarcas = this.marcasList.length === this.marcasSelected.length ? true : false;
    const fechaInicio = this.formFiltros.get('FechaInicio').value;
    // new Date(this.formFiltros.get('FechaInicio').value).toISOString().slice(0, 10);
    const fechaFinal =  this.formFiltros.get('FechaFin').value;
     // new Date(this.formFiltros.get('FechaFin').value).toISOString().slice(0, 10);
    // console.log('IS lg', new Date(this.formFiltros.get('FechaFin').value));
    // console.log(new Date(this.formFiltros.get('FechaFin').value).toISOString().slice(0, 10));

    if (fechaInicio > fechaFinal) {
      Swal.fire({
        type: 'warning',
        title: 'Fechas incorrectas',
        text: 'La fecha inicio no puede ser mayor a la fecha final',
        confirmButtonText: 'Aceptar'
      });
      this.spinner.hide('spinner-reporte-abc-tres');
      return;
    }

    this.abcService.getReporteVentasMarcas(allClientes,
      allDepartamentos,
      allGrupos,
      allVendedores,
      allSucursales,
      allmarcas,
      1, // this.getInputValue('reportes').value
      allClientes ? [] : this.clientesListSelected,
      allDepartamentos ? [] : this.depatamentosSelected,
      allGrupos ? [] : this.gruposSelected,
      allVendedores ? [] : this.vendedoresListSelected,
      [this.formFiltros.controls.sucursales.value],
      allmarcas ? [] : this.marcasSelected,
      fechaInicio,
      fechaFinal
    ).subscribe((res: DataVentaMarcas) => {
      this.spinner.hide('spinner-reporte-abc-tres');
      if (res.error === false) {
        if (res.ventasMarcasDetallado.length > 0 || res.ventasMarcasResumen.length > 0 ) {
             this.dataVentaMarcas = res;
        } else {
             this.openSnackBar(res.message);
             this.dataVentaMarcas = res;
        }
      } else {
        this.dataVentaMarcas = null;
         this.openSnackBar(res.message);
      }
    });
  }

  public filterGrupos(): Grupos[] {
    return this.gruposList.filter((option) => {
      let coincidencia = false;
      this.depatamentosSelected.forEach(value => {
        if (option.DepartamentoID == value.DepartamentoID) {
          coincidencia = true;
        }
      });
      return coincidencia;
    });
  }

  public excelVentasMarcas(){


    this.spinner.show('spinner-reporte-abc-tres');
    // const items = this.response.columnas;
    // if(this.response.tipoReporte!=2){
    //   const removeItem  = this.response.columnas.indexOf('Departamento');
    //   if (removeItem !== -1) {
    //       items.splice(removeItem, 1);
    //   }
    // }


    this.abcService.getExportVentaMarcas(this.dataVentaMarcas).then((res) => {
      this.spinner.hide('spinner-reporte-abc-tres');
    }).catch((err: any) => {
      this.spinner.hide('spinner-reporte-abc-tres');
      Swal.fire({
        type: 'warning',
        title: 'Advertencia',
        text: err.message,
      });
    });

  }

  private _filterSucursales(value: string): sucursal[] {
    const filterValue = value.toLowerCase();
    return this.sucursales.filter(
      option => option.SucursalId.toLowerCase().includes(filterValue) ||
        option.Nombre.toLowerCase().includes(filterValue)
    );
  }

  private openSnackBar(mensaje: string) {
    this.snackBar.open(mensaje, 'Ok', {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
    setTimeout(() => {
    }, 5000);
  }
}
