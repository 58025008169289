import { Component, OnInit ,ViewChild} from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CanjesKabicoinsService } from '../../services/reportes/reporte-canjes-kabicoins/canjes-kabicoins.service';
import { Cliente, DataClientes } from '../ABC/utils/models/ABC';
import Swal from 'sweetalert2';
import { ABCService, } from '../ABC/utils/services/ABC.service';
import { FiltrosComponent } from '../../core/components/filtros/filtros.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-reporte-clientes-kabikcoins',
  templateUrl: './reporte-clientes-kabikcoins.component.html',
  styleUrls: ['./reporte-clientes-kabikcoins.component.scss']
})
export class ReporteClientesKabikcoinsComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private puntosKabicoinsService: CanjesKabicoinsService,
    private abcService: ABCService,
    private matDialog : MatDialog
  ) { }

  public form_: FormGroup;
  public columnskabiCoins: string[] = [];
  public dataKabiCoins: MatTableDataSource<any>;
  public listClientes: Cliente[] = [];
  public listClientesSelected: Cliente[] = [];
  @ViewChild('paginator_kabiCoins', { static: false }) private paginatorProduccion: MatPaginator;
   @ViewChild('input', { static: false }) private input: any;
  ngOnInit() {
    this.form();
    this.getClientes();
  }

  private form() {
    this.form_ = this.fb.group({
      clientes: new FormControl('', [Validators.required]),
    });
  }

  private getClientes() {
    // this.getInputValue('clientesAbc').disable();
    this.abcService.getClientesAbcDos().subscribe((res: DataClientes) => {
      this.listClientes = res.clientes;
      // this.getInputValue('clientesAbc').enable();
    });
  }
  getData() {
    this.spinner.show('spinner-reporte-kabiCoins');
    const allClientes = this.listClientes.length === this.listClientesSelected.length ? true : false;
    this.puntosKabicoinsService.getDataPuntosKabiCoins(allClientes ? [] : this.listClientesSelected, allClientes).subscribe(res =>
    {
      this.spinner.hide('spinner-reporte-kabiCoins');
      if (res.error === false) {
        if (res.dataPuntosKabiCoins.length <= 0 || res.dataPuntosKabiCoins === null) {
          this.openSnackBar('No se encotraron registros en la busqueda');
          this.columnskabiCoins = [];
          this.dataKabiCoins = new MatTableDataSource([]);
        } else {
          this.columnskabiCoins = res.columasPuntosKabiCoins;
          this.dataKabiCoins = new MatTableDataSource(res.dataPuntosKabiCoins);
          this.dataKabiCoins.paginator = this.paginatorProduccion;
        }
      }else{
        this.openSnackBar('Error: '+res.message);
        this.columnskabiCoins = [];
        this.dataKabiCoins = new MatTableDataSource([]);

      }
    });
  }
  public exportToExcel() {
    this.spinner.show('spinner-reporte-kabiCoins');
    this.puntosKabicoinsService.getExecelPuntosKabiCoins(this.dataKabiCoins.data,this.columnskabiCoins).then((res) => {
      this.spinner.hide('spinner-reporte-kabiCoins');
    }).catch((err: any) => {
      this.spinner.hide('spinner-reporte-kabiCoins');
      Swal.fire({
        type: 'warning',
        title: 'Advertencia',
        text: err.message,
      });
    });
  }

  public modalClientes(opcion: number) {


  const dialogRef = this.matDialog.open(FiltrosComponent, {
    disableClose: true,
    width: '28%',
    data: {
      opcion,
      lista: this.listClientes,
      listaCheck : this.listClientesSelected,
    },
  });
  dialogRef.afterClosed().subscribe((result) => {
    switch (opcion) {

        case 3: // Clientes
        this.listClientesSelected = result;
        const cliente = this.listClientesSelected.length > 0 ? this.listClientesSelected[0].Nombre : '';
        const clientes2 = this.listClientesSelected.length > 1 ?
          ' (+' + (this.listClientesSelected.length - 1) + ' ' + (this.listClientesSelected.length === 2 ? 'Cliente' : 'Clientes') + ')' : '';
          if(this.listClientesSelected.length>0){
            this.getInputValue('clientes').setValue(cliente + ' ' + clientes2);
           }else{
            this.getInputValue('clientes').setValue('');
           }
        break;
    }
  });
}

public getInputValue(control: string) {
  return this.form_.get(control);
}

  private openSnackBar(mensaje: string) {
    this.snackBar.open(mensaje, 'Ok', {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
    setTimeout(() => {
    }, 5000);
  }

  applyFilter(event: Event) {
    // console.log(event);
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataKabiCoins.filter = filterValue.trim().toLowerCase();
    // console.log(this.dataSource.filter);
    // console.log(this.dataSource);
    // console.log( this.dataSource.filter = filterValue.trim().toLowerCase());
    if (this.dataKabiCoins.paginator) {
      this.dataKabiCoins.paginator.firstPage();
    }
  }

  limpiarInput(event: Event) {
    console.log(this.input);
    console.log(event);
    // this.formFiltros.get('busqueda').setValue('');
    this.dataKabiCoins.filter = '';
  }

}
