import { Component, OnInit } from '@angular/core';
import { ReporteEncuestaService } from '../../services/reportes/reporte-encuesta.service';

@Component({
  selector: 'app-reporte-encuesta',
  templateUrl: './reporte-encuesta.component.html',
  styleUrls: ['./reporte-encuesta.component.scss']
})
export class ReporteEncuestaComponent implements OnInit {
  encuestas: any;
  constructor(private encuestaService: ReporteEncuestaService) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.encuestaService.getEncuestas().then(res => {
      if (res.message === "true") {
        this.encuestas = res.encuestas;
      }
    }).catch(err => {

    });
  }

  exportToExcel(): void {
    this.encuestaService.exportAsExcelFile(
      this.encuestas);
  }
}
