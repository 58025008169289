import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-views-ex-imagen',
  templateUrl: './views-ex-imagen.component.html',
  styleUrls: ['./views-ex-imagen.component.scss'],
})
export class ViewsExImagenComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ViewsExImagenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}
}
