import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
declare var $;
declare var XLSX;
declare var jsPDF;
export interface Paginator {
  actual: number,
  prev: number,
  next: number
};

export enum HeadFormatType {
  capitalize,
  lowerCase,
  upperCase
};

export enum HeadFormatText {
  camelCase,
  snakeCase,
  kebabCase,
  trainCase,
  kbkCase
};

export interface KbkHeaderTable {
  tag: string,
  type: string,
  label: string,
  editable?: boolean,
  headerWidth?: number
};

@Component({
  //changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './kbk-grid.html',
  styleUrls: ['./kbk-grid.scss'],
  selector: "kbk-grid",
  encapsulation: ViewEncapsulation.Emulated
})
export class KbkGridComponent implements OnInit {

  @Input() pagination: boolean = true;
  @Input() height: number = -1;
  @Input() backPagination: boolean = false;
  @Input() limitDataSearch: boolean = false;
  @Input() showAllData: boolean = false;
  @Input() backFilter: boolean = false;
  @Input() filterActive: boolean = true;
  @Input() searchActive: boolean = true;
  @Input() backOrder: boolean = false;
  @Input() hasCheckColumn: boolean = true;
  public temporaryHasCheckColumn: boolean = true;
  //if rsizable fixed head is not posible
  @Input() resizableColumns: boolean = true;
  @Input() selectable: boolean = true;
  @Input() fixedHeader: boolean = true;
  @Input() editableColumns: boolean = true;
  @Input() limitRows: number = 5;
  @Input() values: any[] = [];
  @Input() captionText: string = "";
  @Input() extraClass: string = "";
  @Input() headFormatType: HeadFormatType = HeadFormatType.capitalize;
  @Input() headFormatText: HeadFormatText = HeadFormatText.kbkCase;
  @Input() delimiterHeadText: string = "_";//que separador se usa en los parametros de la peticion
  @Input() prefixSearch: string = "Search ";

  @Input() customHeaders: KbkHeaderTable[] = [];

  @Input() pageBox: any[] = [{
    label:"5",
    value: 5
  },{
    label:"10",
    value: 10
  },{
    label:"15",
    value: 15
  }];
  //Paginator objects
  @Input() onTap: Function;
  @Input() limitShowPage: number = 3;
  @Input() paginado: Paginator = {
    actual: 1,
    prev: 0,
    next: 2
  };

  public customPagination: any = {
    prev: [],
    actual: 0,
    next: []
  };

  public totalPages: number = 0;

  @Input() autoIncrement: boolean = true;
  @Output() paginadoOut: EventEmitter<any> = new EventEmitter<any>();
  @Output() backFilterText: EventEmitter<any> = new EventEmitter<any>();
  @Output() backOrderByParameter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelectedElements: EventEmitter<any> = new EventEmitter<any>();

  //tooltip
  public xPosition: number = 0;
  public yPosition: number = 0;

  public tableValues: any = [];
  public tableValuesTmp: any = [];
  public tableValuesAllData: any = [];

  public tableSortObj = {};

  public headers = null;
  public source: any = null;

  public configItems: any = [
    {
      label: "Buscar elementos por texto",
      icon: "search",
      active: true,
      handler: () => {
        this.searchActive = !this.searchActive;
      }
    },
    {
      label: "Ordenar",
      icon: "filter_list",
      active: true,
      handler: () => {
        this.filterActive = !this.filterActive;
      }
    },
    {
      label: "Exportar",
      icon: "save_as",
      active: true,
      handler: () => {
        this.exportExcel();
      }
    },
    /* {//para una futura actualización
      label: "Exportar en pdf",
      icon: "picture_as_pdf",
      active: true,
      handler: () => {
        this.exportPDF();
      }
    }, */
    /* {
      label: "Graficar",
      icon: "pie_chart",
      active: true,
      handler: () => {

      }
    }, */
    {
      label: "Limpiar filtros",
      icon: "cleaning_services",
      active: true,
      handler: () => {
        this.clearAllFilter();
      }
    },
    {
      label: "Elementos seleccionados",
      icon: "check_box",
      active: true,
      handler: () => {
        let tmp: any[] = [...this.tableValues];
        let returnElements: any[] = [];
        let cleanArray: any[] = [];
        tmp.forEach(element => {
          if (element["check-kbk"]) {
            // delete element["check-kbk"];
            returnElements.push({ ...element });
          }
        });

        returnElements.forEach(element => {
          delete element["check-kbk"];
          cleanArray.push(element);
        });

        //console.log(cleanArray);
        this.onSelectedElements.emit(cleanArray);
      }
    }
  ];

  public options: any = [
    {
      label: "Editar",
      icon: "edit",
      handler: () => {

      }
    }, {
      label: "Copiar",
      icon: "content_copy",
      handler: () => {
        this.textTmp = this.tableValues[this.rowTmp][this.tagTmp];
        navigator.clipboard.writeText(this.tableValues[this.rowTmp][this.tagTmp]);
      }
    },
    {
      label: "Pegar",
      icon: "content_paste",
      handler: () => {
        let row: any = document.getElementsByClassName(`row-${this.rowTmp}`)[0];

        Array.from(row.children).forEach((findElement: any) => {
          if (findElement.id == this.activeColumn) {
            findElement.innerHTML += this.textTmp;
          }
        });
      }
    },
    {
      label: "Alinear",
      icon: "segment",
      handler: () => {

      }, children: [
        {
          icon: "format_align_left",
          value: "left",
          handler: () => {
            this.alignOperaton("left");
          }
        },
        {
          icon: "format_align_center",
          value: "format_align_center",
          handler: () => {
            this.alignOperaton("center");
          }
        },
        {
          icon: "format_align_right",
          value: "format_align_right",
          handler: () => {
            this.alignOperaton("right");
          }
        },
        {
          icon: "format_align_justify",
          value: "format_align_justify",
          handler: () => {
            this.alignOperaton("justify");
          }
        }
      ]
    },
    {
      label: "Insertar fila antes",
      icon: "publish",
      handler: () => {
        let obj = {};
        let hd: any = this.customHeaders ? this.getFromCustomHeaders('tag') : this.getHeadNoFormat(0);
        hd.forEach((itm) => {
          obj[itm] = "";
        });

        this.tableValues.splice(this.rowTmp, 0, obj);
      }
    },
    {
      label: "Insertar fila después",
      icon: "download",
      handler: () => {
        let obj = {};
        let hd: any = this.customHeaders ? this.getFromCustomHeaders('tag') : this.getHeadNoFormat(0);
        hd.forEach((itm) => {
          obj[itm] = "";
        });

        this.tableValues.splice(this.rowTmp + 1, 0, obj);
      }
    },
  ];

  public column: any[] = [];

  public colOptions: any = [
    {
      label: "Limpiar columna",
      icon: "backspace",
      handler: () => {
        let idCell: any = document.getElementsByClassName(`cell-${this.activeColumn}`);
        Array.from(idCell).forEach((itm: any) => {
          itm.children[0].innerHTML = "";
        });
        this.values.forEach(element => {
          element[this.customHeaders[this.activeColumn].tag] = "";
        });
      }
    },
    {
      label: "Solo lectura",
      icon: "block",
      handler: () => {
        let idCell: any = document.getElementsByClassName(`cell-${this.activeColumn}`);
        Array.from(idCell).forEach((itm: any) => {
          //   itm.children[0].innerHTML = "";
        });
        // console.log(this.tableValues);
        this.values.forEach(element => {
          if (!element[`${this.customHeaders[this.activeColumn].tag}_readOnlykbk`]) {
            element[`${this.customHeaders[this.activeColumn].tag}_readOnlykbk`] = true;
          } else if (element[`${this.customHeaders[this.activeColumn].tag}_readOnlykbk`]) {
            element[`${this.customHeaders[this.activeColumn].tag}_readOnlykbk`] = false;
          }
        });

      }
    },
    {
      label: "Filtrar",
      icon: "filter_list",
      handler: () => {

      },
      checks: {

      }
    },
    {
      label: "Sumatoria",
      icon: "functions",
      handler: () => {

      },
    },
    {
      label: "Sum. Gral.",
      icon: "functions",
      handler: () => {

      },
    },
  ];

  public rowTmp: any = null;


  public activeColumn: number = null;
  public tagTmp: number = null;
  public textTmp: number = null;
  public allCheckSelection: boolean = false;
  public hasFilter: boolean = false;


  constructor() {

  }

  ngOnInit() {
    this.initPaginator();
    if (!this.backPagination) {

      this.tableValuesAllData = this.values;
      if (this.showAllData) {
        this.tableValues = [...this.values];
      } else {
        this.tableValues = this.paginate(this.values, this.limitRows, this.paginado.actual);
      }
      // console.log(this.tableValues);

    } else {
      this.tableValues = this.values;
    }

    if (this.hasCheckColumn) {

      let obj: any = {
        tag: "check-kbk",
        type: "check",
        label: "check-kbk",
      };

      if (this.customHeaders.length > 0) {
        this.customHeaders.unshift(obj);
        this.tableValues.forEach(element => {
          element["check-kbk"] = false;
        });

        this.customHeaders.forEach((hd: any) => {
          this.tableValues.forEach(element => {
            if (hd.type == "boolean") {
              if (element[hd.tag] == 'true' || element[hd.tag] == true) {
                element[hd.tag] = "Si";
              } else if (element[hd.tag] == '1' || element[hd.tag] == 1) {
                element[hd.tag] = "Si";
              } else if (element[hd.tag] == 'false' || element[hd.tag] == false) {
                element[hd.tag] = "No";
              } else if (element[hd.tag] == '0' || element[hd.tag] == 0) {
                element[hd.tag] = "No";
              }
            }
          });
        });
      } else {

      }


    }


    if (this.tableValues[0]) {

      this.headers = this.getHeaders(0);

      let hd: any = this.customHeaders ? this.getFromCustomHeaders('tag') : this.getHeadNoFormat(0);
      hd.forEach((itm) => {
        this.tableSortObj[itm] = {
          sortDirection: false,
          hide: false
        }
      });
    }


    this.tableValuesTmp = [...this.tableValues];
    // console.log(this.tableValues);

    setTimeout(() => {

      if (this.selectable) {
        this.selectableFunction();
      } else {
        this.reOrderColumns();
      }

      if (this.resizableColumns) {
        this.resizableColumnsFunction();
      }

      document.addEventListener("click", this.printMousePos);


      this.moveRows();
      // console.log(this.tableValues);
    }, 1000);
  }

  changeRows(evt:any){
    this.tableValues = this.paginate(this.values, this.limitRows, this.paginado.actual);
  }

  alignOperaton(direction: string) {
    let r: any = document.getElementsByClassName(`row-${this.rowTmp}`);
    // console.log(r);

    Array.from(r[0].children).forEach((e: any) => {
      e.style.textAlign = direction;
    });
  }

  initPaginator() {
    this.totalPages = Math.ceil(this.values.length / this.limitRows);
    if ((this.limitShowPage * this.limitRows) > this.values.length) {
      this.limitShowPage = this.totalPages;
    }

    let middle: any = Math.ceil(this.limitShowPage / 2);

    let actual = 1;
    actual = actual;

    let prev: any = [];
    let next: any = [];
    for (let index = 0; index < actual; index++) {
      prev.push(index);
    }
    for (let index = actual + 1; index < middle; index++) {
      next.push(index);
    }

    this.customPagination = {
      prev: prev,
      actual: actual,
      next: next
    }
  }

  getHeaders(position: number = 0) {
    try {
      switch (this.headFormatType) {
        case HeadFormatType.capitalize:
          return Object.keys(this.tableValues[position]).map((itm) => {
            itm = this.getHeadTextWithFrmt(itm);
            return itm = itm.charAt(0).toUpperCase() + itm.slice(1);
          });
        case HeadFormatType.lowerCase:
          return Object.keys(this.tableValues[position]).map((itm) => {
            itm = this.getHeadTextWithFrmt(itm);
            return itm = itm.toLowerCase();
          });
        case HeadFormatType.upperCase:
          return Object.keys(this.tableValues[position]).map((itm) => {
            itm = this.getHeadTextWithFrmt(itm);
            return itm = itm.toUpperCase();
          });
      }

    } catch (error) {
      return [];
    }
  }

  getHeadNoFormat(position: number) {
    if (this.customHeaders.length <= 0) {
      return Object.keys(this.tableValues[position]);
    } else {
      return Object.keys(this.getFromCustomHeaders('tag'));
    }
  }

  getFromCustomHeaders(key: string) {
    let headers: any = [];
    this.customHeaders.forEach(element => {
      headers.push(element[key]);
    });
    return headers;
  }

  getHeadersByText(text: string, position: number = -1) {


    if (position >= 0) {
      text = this.customHeaders[position].tag;

    }
    let separated: any = text.split(" ");
    let str: string = "";
    if (this.customHeaders.length <= 0) {
      separated.forEach(element => {
        str += element.toLowerCase() + this.delimiterHeadText;
      });
      str = str.substring(0, str.length - (this.delimiterHeadText.length > 0 ? 2 : 0));
    } else {
      str = text;
    }

    return str;
  }

  getHeadTextWithFrmt(itm) {
    itm = itm.toLowerCase();
    let itmSeparate: any = itm.split(this.delimiterHeadText);
    let str: any = "";
    itmSeparate.forEach(element => {
      switch (this.headFormatText) {
        case HeadFormatText.camelCase:
          str += element.charAt(0).toUpperCase() + element.slice(1);
          break;
        case HeadFormatText.kbkCase:
          str += element + " ";
          break;
        case HeadFormatText.trainCase:
          str += element.charAt(0).toUpperCase() + element.slice(1) + "-";
          break;
        case HeadFormatText.snakeCase:
          str += element + "_";
          break;
        case HeadFormatText.kebabCase:
          str += element + "-";
          break;
      }
    });
    return str;
  }

  clickPage(clicked) {

  }

  resizableColumnsFunction() {
    var thElm;
    var startOffset;

    Array.prototype.forEach.call(
      document.querySelectorAll("table th"),
      function (th) {
        th.style.position = 'relative';

        let grip: any = document.createElement('div');
        grip.innerHTML = "&nbsp;";
        grip.style.top = 0;
        grip.style.right = 0;
        grip.style.bottom = 0;
        grip.style.width = '5px';
        grip.style.position = 'absolute';
        grip.style.cursor = 'col-resize';
        grip.addEventListener('mousedown', function (e) {
          thElm = th;
          startOffset = th.offsetWidth - e.pageX;
        });

        th.appendChild(grip);
      });

    document.addEventListener('mousemove', function (e) {
      if (thElm) {
        thElm.style.width = startOffset + e.pageX + 'px';
      }
    });

    document.addEventListener('mouseup', function () {
      thElm = undefined;
    });
  }

  paginate(array, page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  /**Metodo para paginar y aun sin consumo */
  paginar(next: boolean = true) {
    if (this.autoIncrement) {
      if (next) {
        this.paginado.actual++;
        this.paginado.prev++;
        this.paginado.next++;
      } else {
        if (this.paginado.actual > 0) {
          this.paginado.actual--;
          this.paginado.prev--;
          this.paginado.next--;
        }
      }
    }
    if (this.backPagination) {
      this.paginadoOut.emit(this.paginado);
    } else {
      this.tableValues = this.paginate(this.values, this.limitRows, this.paginado.actual);
    }
  }

  cellSelect(cell, property, it, cellClassId) {

    let elementsClassSelected: any = document.getElementsByClassName("selected");
    let elementsClassSelectedArray: any = [];
    elementsClassSelectedArray = Array.from(elementsClassSelected);
    if (elementsClassSelectedArray.length > 0) {

    } else {
      cell[`${property}_activekbk`] = true;
      setTimeout(() => {
        let id: any = document.getElementById(`${property}-${it}`);
        let row: any = document.getElementsByClassName(`row-${it}`)[0];
        id.focus();

        Array.from(row.children).forEach((findElement: any) => {
          if (findElement.id == cellClassId) {
            findElement.classList.add("selected");
            this.activeColumn = cellClassId;
            this.tagTmp = property;
          }
        });

      }, 100);
    }
  }

  hideFocus(evt: any, cell, property, it, cellClassId) {
    cell[`${property}_activekbk`] = false;
    let id: any = document.getElementById(`${property}-${it}`);
    let row: any = document.getElementsByClassName(`row-${it}`)[0];


    Array.from(row.children).forEach((findElement: any) => {
      if (findElement.id == cellClassId) {
        findElement.classList.remove("selected");
      }
    });
  }


  rightClickDetector(ev) {
    /*  if (ev.which == 3) {
       alert("Right mouse button clicked on element with id myId");
     } */
  }

  selectableFunction() {
    var table = $("#kbk-tbl");

    var isMouseDown = false;
    var startRowIndex = null;
    var startCellIndex = null;

    function selectTo(cell) {

      var row = cell.parent();
      var cellIndex = cell.index();
      var rowIndex = row.index();

      var rowStart, rowEnd, cellStart, cellEnd;

      if (rowIndex < startRowIndex) {
        rowStart = rowIndex;
        rowEnd = startRowIndex;
      } else {
        rowStart = startRowIndex;
        rowEnd = rowIndex;
      }

      if (cellIndex < startCellIndex) {
        cellStart = cellIndex;
        cellEnd = startCellIndex;
      } else {
        cellStart = startCellIndex;
        cellEnd = cellIndex;
      }

      for (var i = rowStart; i <= rowEnd; i++) {
        var rowCells = table.find("tr").eq(i).find("td");
        for (var j = cellStart; j <= cellEnd; j++) {
          rowCells.eq(j).addClass("selected");
        }
      }
    }

    table.find("td").mousedown(function (e) {
      isMouseDown = true;
      var cell = $(this);

      table.find(".selected").removeClass("selected"); // deselect everything

      if (e.shiftKey) {
        selectTo(cell);
      } else {
        cell.addClass("selected");
        startCellIndex = cell.index();
        startRowIndex = cell.parent().index();
      }

      return false; // prevent text selection
    })
      .mouseover(function () {
        if (!isMouseDown) return;
        table.find(".selected").removeClass("selected");
        selectTo($(this));
      })
      .bind("selectstart", function () {
        return false;
      });
    $(document).mouseup(function () {
      isMouseDown = false;
    });
  }

  search(evt: any, parameter: any) {
    // console.log(parameter);

    let param: any = this.getHeadersByText(parameter);
    if (this.backFilter) {
      this.backFilterText.emit({
        value: evt.target.value,
        parameter: param
      });
    } else {
      if (this.limitDataSearch) {
        this.tableValues = [...this.tableValuesAllData];
      } else {
        this.tableValues = [...this.tableValuesTmp];
      }
      // console.log(this.tableValues);
      // console.log(this.tableValuesAllData);

      let key = "";
      if (this.customHeaders.length > 0) {
        this.customHeaders.forEach((hd: any) => {
          if (hd.label == parameter) {
            key = hd.tag;
          }
        });
        param = key;
      }

      this.tableValues = this.filterArrayByParam(this.tableValues, param, evt.target.value.toLowerCase());
      this.tableValues = this.paginate(this.tableValues, this.limitRows, this.paginado.actual);
    }
  }



  filterArrayByParam(array: Array<any>, param: string, word: string) {
    let users = array, result = [];
    // console.log(param);

    result = users.filter((user) => {
      if (!user[param]) {
        return false;
      } else {
        return user[param].toLowerCase().indexOf(word.toLowerCase()) != -1;
      }
    });
    return result;
  }

  selectAllColumn(parameter: any, position) {
    let param: any = this.getHeadersByText(parameter);

    let cells: any = document.getElementsByClassName(`cell-${position}`);

    this.headers.forEach((element, index) => {
      let cellsRemove: any = document.getElementsByClassName(`cell-${index}`);
      Array.from(cellsRemove).forEach((itm: any) => {
        itm.classList.remove("selected");
      });
    });

    Array.from(cells).forEach((itm: any) => {
      itm.classList.add("selected");
    });

    //LANZAR TOOLTIP DE SUMAORIAS U OPERACIONES


  }

  order(evt: any, parameter: any, k: any = null) {
    let param: any = this.getHeadersByText(parameter, k);
    // console.log(param);

    this.tableSortObj[param].sortDirection = !this.tableSortObj[param].sortDirection;
    if (this.backOrder) {
      this.backOrderByParameter.emit({
        value: evt.target.value,
        parameter: param
      });
    } if (this.limitDataSearch) {
      // this.tableValues = [...this.tableValuesAllData];
    } else {
      // this.tableValues = [...this.tableValuesTmp];
    }

    //// console.log(this.tableValues);


    this.tableValues.sort((a, b) => {

      if (this.tableSortObj[param].sortDirection) {

      } else {
        let c = a;
        a = b;
        b = c;
      }

      if (a[param] < b[param]) {
        return -1;
      }
      if (a[param] > b[param]) {
        return 1;
      }
      return 0;
    });

  }

  reOrderColumns() {

  }

  isbefore(a, b) {
    if (a.parentNode == b.parentNode) {
      for (var cur = a; cur; cur = cur.previousSibling) {
        if (cur === b) {
          return true;
        }
      }
    }
    return false;
  }

  onDragOver(e) {
    var targetelem = e.target;
    if (targetelem.nodeName == "TD") {
      targetelem = targetelem.parentNode;
    }

    if (this.isbefore(this.source, targetelem)) {
      targetelem.parentNode.insertBefore(this.source, targetelem);
    } else {
      targetelem.parentNode.insertBefore(this.source, targetelem.nextSibling);
    }
  }

  onDragLeave(e) {
    this.source = e.target;
    e.dataTransfer.effectAllowed = 'move';
  }

  printMousePos(event) {
    /* document.body.textContent =
      "clientX: " + event.clientX +
      " - clientY: " + event.clientY; */
    this.xPosition = event.clientX;
    this.yPosition = event.clientY;
    let id: any = document.getElementById("tooltip-menu");
    id.style.display = "none";
    let id2: any = document.getElementById("colum-opc");
    id2.style.display = "none";
    //this.activeColumn = null;
  }

  clickToColumnOptions(event: any, h, k) {
    let idCell: any = document.getElementsByClassName(`cell-${k}`);
    this.activeColumn = k;

    if (this.customHeaders.length > 0) {
      let array: any = [];

      let elementsSlected: any = document.getElementsByClassName("selected");
      let arr: any = Array.from(elementsSlected);
      let suma: number = 0, sumaGlobal: number = 0;

      this.values.forEach(element => {

        array.push(element[this.customHeaders[k].tag]);
        if (this.customHeaders[k].type == "number") {
          sumaGlobal += Number(element[this.customHeaders[k].tag]);
        }

      });

      if (this.customHeaders[k].type == "number") {
        arr.forEach((it: any) => {
          if (it?.children[0]?.children[0]?.value) {
            suma += Number(it.children[0].children[0].value);
          }

        });

        setTimeout(() => {
          let idSumatoria: any = document.getElementById("toria");
          idSumatoria.value = suma;
          let idSumatoriaGlobal: any = document.getElementById("toria-gral");
          idSumatoriaGlobal.value = sumaGlobal;
        }, 300);
      }



      // console.log(array);
      var unique = array.filter(this.onlyUnique);
      // console.log(unique);
      let arrTmp = [];
      unique.forEach(element => {
        arrTmp.push({
          value: false,
          label: element
        });
      });

      if (!this.colOptions[2].checks[this.customHeaders[k].tag]) {
        this.colOptions[2].checks[this.customHeaders[k].tag] = arrTmp;
      } else {

      }
      //console.log(this.colOptions[2].checks[this.customHeaders[k].tag]);
    }

    let idClOpt: any = document.getElementById("colum-opc");
    idClOpt.style.top = `${event.clientY}px`;
    let x = event.x;
    if (Number(event.x) + 220 > Number(document.body.scrollWidth)) {
      x = Number(document.body.scrollWidth) - 236;
    }
    idClOpt.style.left = `${x}px`;
    idClOpt.style.display = "block";

    let id2: any = document.getElementById("tooltip-menu");
    if (id2) {
      id2.style.display = "none";
    }




  }

  onRightClick(event, cell, property, it, cellClass) {
    event.stopPropagation();
    let elementsClassSelected: any = document.getElementsByClassName("selected");
    console.log(elementsClassSelected);

    let elementsClassSelectedArray: any = [];
    elementsClassSelectedArray = Array.from(elementsClassSelected);
    console.log(elementsClassSelectedArray);

    if (elementsClassSelectedArray.length > 0) {

    } else {
      this.cellSelect(cell, property, it, cellClass);
    }
    this.rowTmp = it;

    event.preventDefault() //this will disable default action of the context menu
    let id: any = document.getElementById("tooltip-menu");
    id.style.top = `${event.clientY}px`;
    id.style.left = `${event.x}px`;
    id.style.display = "block";
    let id2: any = document.getElementById("colum-opc");
    if (id2) {
      id2.style.display = "none";
    }

  }


  paginarCustom(valor: number) {
    let middle = Math.ceil(this.limitShowPage / 2);
    let actual = valor;
    let prev: any = [];
    let next: any = [];

    for (let index = valor - middle; index < actual; index++) {
      if (index >= 0) {
        prev.push(index);
      }
    }
    let max = prev.length;


    for (let index = actual + 1; index < (valor + middle); index++) {
      if (index <= this.totalPages) {
        next.push(index);
      }
    }

    this.customPagination = {
      prev: prev,
      actual: actual,
      next: next
    };

    if (this.backPagination) {
      this.paginadoOut.emit(this.paginado);
    } else {
      this.tableValues = this.paginate(this.values, this.limitRows, this.customPagination.actual);
    }
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    let id: any = document.getElementById("tooltip-menu");
    id.style.display = "none";
  }

  exportExcel() {
    let wb = XLSX.utils.book_new();
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    wb.Props = {
      Title: 'Reporte de ordenes',
      Subject: 'Simsa',
      Author: 'Simsa',
      CreatedDate: new Date(),
    };

    let wsData = [
      ['A1ee', 'B1'],
      ['A2ss', 'B2'],
    ];

    let ws = XLSX.utils.json_to_sheet(wsData);
    let headers: any = [];
    let hs: any = [];
    let vls: any = this.values;
    if (this.customHeaders.length <= 0) {
      hs = this.headers;
    } else {
      hs = this.getFromCustomHeaders("label");
      let hTags = this.getFromCustomHeaders("tag");
      vls = [];

      this.values.forEach(v => {
        let obj: any = {};
        hTags.forEach(h => {
          if (h != "check-kbk") {
            obj[h] = v[h];
          }
        });
        vls.push(obj);
      });

      let newValues: any = [];
      // console.log(hTags);
      // console.log(hs);
      vls.forEach(vlsElement => {
        let obj: any = {};
        hTags.forEach((tag, index) => {
          // console.log(tag);
          // console.log(index);

          if (hs[index] != "check-kbk") {
            obj[hs[index]] = vlsElement[tag];
          }

        });
        newValues.push(obj);
      });
      vls = newValues;
    }

    let reportList: any[] = [];

    hs.forEach(element => {
      if (element != "check-kbk") {
        headers.push(element);
      }

    });

    let wsEmulate = XLSX.utils.json_to_sheet(vls, {
      header: headers,

    });

    let xlsx: any = XLSX;

    const workbook: any = { Sheets: { data: wsEmulate }, SheetNames: ["data"] };



    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelData: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE
    });
    XLSX.utils.book_append_sheet(wb, wsEmulate, this.formatDate(new Date()));
    XLSX.writeFile(wb, `kbk-report-${this.formatDate(new Date())}.xlsx`);

  }

  moveRows() {
    $(function () {
      $(".tbl-kbk-cls tbody").sortable({
        connectWith: ".fooBar tbody",
        items: '>tr:not(.subhead)',

        cursor: "move",
        axis: 'y',
        start: function (e, ui) {
          ui.item.addClass("selected");
        },
        stop: function (e, ui) {
          ui.item.removeClass("selected");
          saveOrder()
        },
      })
      reOrder()
    })

    function saveOrder() {
      var mapping = {}
      $(".tbl-kbk-cls tbody").each(function () {
        var key = $(this).attr("id")
        var data = $(this).children().map(function () {
          return $(this).attr("id")
        }).get()
        mapping[key] = data
      })
      localStorage.order = JSON.stringify(mapping)
    }

    function reOrder() {
      try {
        $.each(JSON.parse(localStorage?.order) || [], function (tbodyNumber, kids) {
          // console.log(tbodyNumber, kids)
          $.each(kids, function (i, id) {
            $("#" + id).appendTo("#" + tbodyNumber)
          })
        })
      } catch (error) {
        
      }
    }
  }

  formatDate(date) {
    return [
      this.padTo2Digits(date.getDate()),
      this.padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('_');
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  selectAllFilter(checks: any[], isClear: boolean = false) {
    // console.log(checks);

    checks.forEach(element => {
      element.value = !isClear;
    });
  }

  cancel() {
    document.getElementById("colum-opc").style.display = "none";
  }

  okFilter(checks: any[]) {
    this.tableValues = [...this.values];
    // console.log(this.tableValues);
    let arr: any[] = [];
    checks.forEach(element => {
      if (element.value) {
        this.filterArrayByParam(this.tableValues, this.customHeaders[this.activeColumn].tag, element.label.toLowerCase()).forEach((it) => {
          // console.log(it);

          arr.push(it);
        });
      }
    });
    this.tableValues = arr;
    this.hasFilter = true;
    document.getElementById("colum-opc").style.display = "none";
  }

  clearAllFilter() {
    this.hasFilter = false;
    this.tableValues = [...this.tableValuesTmp];
  }

  exportPDF() {
    var doc = new jsPDF('p', 'pt', 'a4');
    this.temporaryHasCheckColumn = false;

    setTimeout(() => {
      var elem = document.getElementById('kbk-table');
      var data = doc.autoTableHtmlToJson(elem);
      doc.autoTable(data.columns, data.rows, {
        tableLineColor: [189, 195, 199],
        tableLineWidth: 0.75,
        styles: {
          font: 'Meta',
          lineColor: [44, 62, 80],
          lineWidth: 0.55
        },
        headerStyles: {
          fillColor: [0, 0, 0],
          fontSize: 11
        },
        bodyStyles: {
          fillColor: [216, 216, 216],
          textColor: 50
        },
        alternateRowStyles: {
          fillColor: [250, 250, 250]
        },
        columnStyles: {
          0: { cellWidth: 100 },
          1: { cellWidth: 80 },
          2: { cellWidth: 80 },
          // etc
        },
        startY: 100,
        drawRow: function (row, data) {
          // Colspan
          doc.setFontStyle('bold');
          doc.setFontSize(10);
          if ($(row.raw[0]).hasClass("innerHeader")) {
            doc.setTextColor(200, 0, 0);
            doc.setFillColor(110, 214, 84);
            doc.rect(data.settings.margin.left, row.y, data.table.width, 20, 'F');
            doc.autoTableText("", data.settings.margin.left + data.table.width / 2, row.y + row.height / 2, {
              halign: 'center',
              valign: 'middle'
            });
            /*  data.cursor.y += 20; */
          };

          if (row.index % 5 === 0) {
            var posY = row.y + row.height * 6 + data.settings.margin.bottom;
            if (posY > doc.internal.pageSize.height) {
              data.addPage();
            }
          }
        },
        drawCell: function (cell, data) {
          // Rowspan
          // console.log(cell);
          if ($(cell.raw).hasClass("innerHeader")) {
            doc.setTextColor(200, 0, 0);
            doc.autoTableText(cell.text + '', data.settings.margin.left + data.table.width / 2, data.row.y + data.row.height / 2, {
              halign: 'center',
              valign: 'middle'
            });

            return false;
          }
        }
      });
      doc.save("table.pdf");
      this.temporaryHasCheckColumn = true;
    }, 1000);
  }

  changeSwitch(evt: any) {
    evt.stopPropagation();
    console.log(this.allCheckSelection);
    this.values.forEach(element => {
      element["check-kbk"] = this.allCheckSelection;
    });
  }
}
