import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, retry, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import * as FileSaver from 'file-saver';
import { EmpresaService } from '../../Empresa/Empresa.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ReporteListaPreciosService {
  empresaId =  String(environment.empresaId);
  private destroy$ = new Subject<void>();

constructor(private http: HttpClient, private empresaService: EmpresaService) {
  this.empresaService.empresaId$
  .pipe(takeUntil(this.destroy$))
  .subscribe(id => {
    this.empresaId = id;
  });
 }

  public setExportExcel(clienteId:string){

    const data = {
      option: 'getListaPrecios',
      clienteId,
      empresaId:this.empresaId
    }

    let f = new Date();
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
  .post<any>(
    environment.apiUrl +
      'reportes/reporte-lista-precios/reporte-lista-precios.php',
    data,
    { headers, responseType: 'blob' as 'json' }
     )
  .pipe(
    map((data1) => {
      var blob = new Blob([data1], { type: 'application/vnd.ms.excel' });
      const file = new File([blob], 'Lista_precios_kabik_cliente_'+clienteId+'_'+f.getDate()+'_'+(f.getMonth() +1)+'_'+f.getFullYear()+'.xlsx', {
        type: 'application/vnd.ms.excel',
      });
      FileSaver.saveAs(file);
    })
  )
  .toPromise();
};

getClientes= () => {
  const data = {
    option: "getVentasAbcClientes",
    empresaId: this.empresaId,
  }
  return this.http.post<any>(environment.apiUrl + '/reportes/reporte-abc/get_reporte_abc.php', data).pipe(retry(3),
    catchError(this.handleError), map(response => {
      return response;
    }));


}
private handleError = (error: HttpErrorResponse | any) => {

  Swal.fire({
    type: 'error',
    title: 'Error',
    text:
      'Lo sentimos, Ocurrio un error de comunicación, Favor de contactarse con el administrador del sitio Incidencias@kabik.com.mx' + error.message
  });
  return throwError(error);
}


}




