import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { HttpClient } from '@angular/common/http';
import { EmpresaService } from '../Empresa/Empresa.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
interface ImagenesSubidas {
  message: string;
  imagenes?: {};
}
@Injectable({
  providedIn: 'root'
})
export class ReporteImagenesSubidasService {
  empresaId =  String(environment.empresaId);
  private destroy$ = new Subject<void>();
  constructor(private http: HttpClient, private empresaService: EmpresaService) {
    this.empresaService.empresaId$
    .pipe(takeUntil(this.destroy$))
    .subscribe(id => {
      this.empresaId = id;
    });
  }

  public exportAsExcelFile(
    json: any[],
  ): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = {
      Sheets: { General: worksheet },
      SheetNames: ['General']
    };

    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    this.saveAsExcelFile(excelBuffer);
  }

  private saveAsExcelFile(buffer: any): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(
      data,
      'imagenes_subidas_kabik_' + new Date().toLocaleDateString() + EXCEL_EXTENSION
    );
  }

  getEncuestas() {
    const data = {
      empresa: this.empresaId
    };
    return this.http.post<ImagenesSubidas>(environment.apiUrl + "reportes/reporte-img-subidas/reporteImagenesSubidas.php", data).pipe(
      map(data1 => data1)
    ).toPromise();
  }
}
