import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-imagenes',
  templateUrl: './imagenes.component.html',
  styleUrls: ['./imagenes.component.scss']
})
export class ImagenesComponent implements OnInit {
  @Input() public size: string;
  @Input() public color: string;
  @Input() public colorSecundario: string;
  @Input() public opacity: boolean;
  constructor() { }

  ngOnInit() {
  }

}
