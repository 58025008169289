import { Component, OnInit, ViewChild } from '@angular/core';
import { takeUntil, catchError, switchMap, map } from 'rxjs/operators';
import { throwError, Subject, Observable } from 'rxjs';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { Date_ } from '../../../models/date_';
import { GetDateService } from './../../../services/Date/get_date.service';
import { ABCService } from '../../ABC/utils/services/ABC.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DataSucursales, sucursal } from '../../ABC/utils/models/ABC';
import { PeriodoInventarioService } from '../../../services/inventario/periodo/periodo-inventario.service';
import { ReporteCosteoInventarioService } from '../../../services/reportes/reporte-costeo-inventario/reporte-costeo-inventario.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalArticulosUPCComponent } from './modal-articulos-upc/modal-articulos-upc.component';
import { ArticulosInsumosService } from '../../../services/articulos-insumos/articulos-insumos.service';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { Console } from 'console';
import { ifStmt } from '@angular/compiler/src/output/output_ast';

interface ResumenCosteo {
  Data: any[];
  Columnas: ResumenColumnas[];
}
interface ResumenColumnas {
  field: string;
  width: string;
  type: string;
  align: string;
  index?: number;
}
@Component({
  selector: 'app-articulos-almacen-upc',
  templateUrl: './articulos-almacen-upc.component.html',
  styleUrls: ['./articulos-almacen-upc.component.scss'],
})
export class ArticulosAlmacenUpcComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  public selection: SelectionModel<any> = new SelectionModel<any>(true, []);
  columns: ResumenColumnas[] = [];
  public loading: boolean;
  public loadginExport :boolean;
  public textoLoading: string = 'Consultando..';
  public formGroup: FormGroup;
  public progressAlmacen: boolean = false;
  public sucursales: sucursal[] = [];
  public almacenes = [];
  private unsubscribe$ = new Subject<void>();
  public inventariCosteoUPC: ResumenCosteo;
  private listItemsUpc = [];
  public listItemsAux: any = [];
  public listItems: any[];
  public globalFilter = '';
  private listFilterdColumnas = {
    UPC: []
  };
  almacenID:string;
  sucursalID:string;
  clearFilter:boolean;
  constructor(
    private getDate: GetDateService,
    private abcService: ABCService,
    private formBuilder: FormBuilder,
    private periodoInventarioService: PeriodoInventarioService,
    private costeInventarioServices: ReporteCosteoInventarioService,
    private spinner: NgxSpinnerService,
    private articulosInsumosService: ArticulosInsumosService,
    public modalEditar: MatDialog

  ) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      sucursal: [null, [Validators.required]],
      almacen: ['', [Validators.required]],
      fechaInicial: ['', [Validators.required]],
    });
    this.getSucursales();
  }


  getSucursales = () => {
    this.getDate
      .get_Date('Y/m/d', 'Y/m/d')
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((err) => {
          return this.handleError(err);
        }),
        switchMap((response: Date_) => {
          this.formGroup
            .get('fechaInicial')
            .setValue(new Date(response.fechaFinal));
          return this.abcService.getSucursales();
        })
      )
      .subscribe((response: DataSucursales) => {
        if (response) {
          this.sucursales = response.sucursales;
        }
      });
  };

  getAlmacenes = (sucursal: number) => {
    this.formGroup.get('almacen').setValue('');
    this.progressAlmacen = true;
    this.periodoInventarioService
      .almacen(sucursal)
      .then((res) => {
        this.almacenes = res.response;
        this.progressAlmacen = false;
      })
      .catch((error) => {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: error.message,
          footer: 'Comunícate para resolver el problema',
        });
        this.progressAlmacen = false;
      });
  };
  onSubmit = () => {
    if (this.formGroup.invalid) {
      Swal.fire({
        type: 'warning',
        title: 'Faltan campos por completar',
        text: 'Por favor, escribe los datos que faltan',
        confirmButtonText: 'Aceptar',
      });
      this.spinner.hide('spinner');
      return;
    }
    this.loading = true;
    const fechaInicial: Date = this.formGroup.get('fechaInicial').value;
    const sucursal: string = this.formGroup.get('sucursal').value;
    const almacen: string = this.formGroup.get('almacen').value;
    this.costeInventarioServices
      .getCosteoArticuloUpc(sucursal, almacen, new Date(fechaInicial))
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((err) => {
          this.loading = false;
          this.spinner.hide('spinner');
          return this.handleError(err);
        })
      )
      .subscribe((response: ResumenCosteo) => {
        this.listItems = response.Data;
        const ListaUpc = response.Data.map((item) => { return item.UPC });
        const items = ListaUpc.filter((item, index) => { return ListaUpc.indexOf(item) === index});
        const itemsOrder =  this.orderAsc(items);
        this.listItemsUpc = itemsOrder;
        this.dataSource.data = response.Data;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = this.customFilterPredicate();
        if (this.listFilterdColumnas.UPC.length > 0) {
          const listUPC = this.listFilterdColumnas.UPC.map((item) => item.UPC);
          this.filterTable(listUPC);
        }
        this.setDisplayedColumns(response.Columnas);
        this.clearMasterToggle();
        this.loading = false;

        console.log(this.sucursalID +'!=='+ sucursal +'||'+ this.almacenID +'!=='+ almacen)
        console.log(this.sucursalID !== sucursal || this.almacenID !== almacen);
        if(this.sucursalID !== sucursal || this.almacenID !== almacen){
          this.almacenID = almacen;
          this.sucursalID = sucursal;
          this.clearFilter = true;
       }else{
        this.clearFilter = false;
       }
        this.spinner.hide('spinner');
      });
  };

  exportToExcel(): void {
    this.spinner.show('spinner');
    this.loadginExport = true;
    this.articulosInsumosService.exportToExcel(this.listItems  ,this.displayedColumns,'Reporte_articulos_upc_').then((res) => {
      this.spinner.hide('spinner');
      this.loadginExport = false;
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
      });
      this.loadginExport = false;
      this.spinner.hide('spinner');
    });
  }

  public openModalEditar(itemsUpc) {

    const data = {
      ClaveArticulo: itemsUpc.ClaveArticulo,
      Descripcion: itemsUpc.Descripcion,
      CodigoBarras: itemsUpc.UPC
    }
    const modal = this.modalEditar.open(ModalArticulosUPCComponent, {
      width: '40%',
      data: data,
      disableClose: true,
    });
    modal.afterClosed().subscribe((res) => {
      if (res) {
        this.spinner.show('spinner');
        this.onSubmit();
      }
    });
  }

  customFilterPredicate() {
    const myFilterPredicate = (data: any, filter: string): boolean => {
      let globalMatch = !this.globalFilter;
      if (this.globalFilter) {
        globalMatch = data.AlmacenID.toString().trim().toLowerCase().indexOf(this.globalFilter.toString().trim().toLowerCase()) !== -1 ||
        data.Articulo.toString().trim().toLowerCase().indexOf(this.globalFilter.toString().trim().toLowerCase()) !== -1 ||
        data.ClaveArticulo.toString().trim().toLowerCase().indexOf(this.globalFilter.toString().trim().toLowerCase()) !== -1 ||
        data.ClaveProveedor.toString().trim().toLowerCase().indexOf(this.globalFilter.toString().trim().toLowerCase()) !== -1 ||
        data.Descripcion.toString().trim().toLowerCase().indexOf(this.globalFilter.toString().trim().toLowerCase()) !== -1 ||
        data.Existencias.toString().trim().toLowerCase().indexOf(this.globalFilter.toString().trim().toLowerCase()) !== -1 ||
        data.UPC.toString().trim().toLowerCase().indexOf(this.globalFilter.toString().trim().toLowerCase()) !== -1 ||
        data.Sucursal.toString().trim().toLowerCase().indexOf(this.globalFilter.toString().trim().toLowerCase()) !== -1;
      }
      if (!globalMatch) {
        return;
      }
      const searchString = JSON.parse(filter);
      let evalGeneral = '';
      let countUPC = 0;
      let UPC = '';
      if (searchString.UPC.length > 0) {
        searchString.UPC.forEach((value) => {
           if (countUPC <= 0) {
            UPC +=  "data.UPC.toString().trim().toLowerCase() === '"+value.UPC.toString().trim().toLowerCase()+"' "
          }
          if (countUPC >= 1) {
            UPC +=  " || data.UPC.toString().trim().toLowerCase() === '"+value.UPC.toString().trim().toLowerCase()+"' "
          }
          countUPC++;
        });
      }else{
        UPC += "data.UPC.toString().trim().toLowerCase().indexOf('') !== -1 "
      }
      evalGeneral += "(" + UPC + ")";
      return (eval(evalGeneral));
    }
    return myFilterPredicate;
  }
  filterTableInput (event)  {
    this.globalFilter = event;
    this.dataSource.filter = JSON.stringify(this.listFilterdColumnas);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  public filterTable(filter){

    this.listFilterdColumnas.UPC = [];
    this.listFilterdColumnas.UPC = filter.map(item => {
      return {'UPC': item}
    });
    this.dataSource.filter = JSON.stringify(this.listFilterdColumnas);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }

  private handleError = (error: any) => {
    Swal.fire(
      'Lista de articulos',
      'Lo sentimos, ocurrio un error de comunicación.<br/><br/>Error: ' +
        error.message,
      'error'
    );
    return throwError(error);
  };

  private orderAsc(items) {
    return Array.from(items).sort((item1: any, item2: any) => {
      return this.orderByComparator(item1,item2);});
  }
  setDisplayedColumns(columnas) {
    if(this.columns.length<=0){
      console.log('displayedColumns', this.columns)
      this.columns =  columnas;
   }
    if(this.displayedColumns.length<=0){
      console.log('displayedColumns',this.displayedColumns)
      this.columns.forEach((column, index) => {
        column.index = index;
        this.displayedColumns[index] = column.field;
      });
    }
  }
  orderByComparator(a: any, b: any): number {

    if (
      isNaN(parseFloat(a)) || !isFinite(a) ||
      isNaN(parseFloat(b)) || !isFinite(b)
    ) {
      if (a.toLowerCase() < b.toLowerCase()) return -1;
      if (a.toLowerCase() > b.toLowerCase()) return 1;
    } else {
      if (parseFloat(a) < parseFloat(b)) return -1;
      if (parseFloat(a) > parseFloat(b)) return 1;
    }
    return 0;
  }
  public today = (): Date => {
    return new Date();
  };

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listItems.length;
    return numSelected === numRows;
  }

  public selected(event?: any, row?: any) {
    if (event.checked) {
      this.listItemsAux = [...this.listItemsAux, row];
    } else {
      this.removeToArray(row);
    }
    event = event ? this.selection.toggle(row) : null;
    return event;
  }

  private removeToArray(row: any): void {
    let contador = 0;
    this.listItemsAux.forEach((value) => {
      if (value === row) {
        if (this.listItemsAux.length <= 1) {
          this.listItemsAux = [];
        } else {
          this.listItemsAux.splice(contador, 1);
        }
      }
      contador++;
    });
  }

  masterToggle() {
    this.listItemsAux = [];
    this.isAllSelected()
      ? this.selection.clear()
      : this.listItems.forEach((row) => {
          this.listItemsAux = [...this.listItemsAux, row];
          this.selection.select(row);
        });
  }
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  clearMasterToggle(){
    this.listItemsAux = [];
    this.selection.clear();
  }
}
