import { Component, OnInit, ViewChild } from '@angular/core';
import { OfertasService } from '../../services/ofertas/ofertas.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-articulos',
  templateUrl: './articulos.component.html',
  styleUrls: ['./articulos.component.scss']
})
export class ArticulosComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource();
  displayedColumns = ['clave', 'descripcion', 'acciones'];
  categorias: any;
  articulos: any;

  searchText: string;

  productData: any;
  totalProducts: any;
  page: number = 1;
  perPage: number = 10;
  oderBy: string = "C";
  available: number = 1;

  totalPages: any;
  currentPage: number;

  errorFlag: boolean;
  constructor(private ofertasService: OfertasService, private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.newMethod();
    this.dataSource.paginator = this.paginator;
    this.ofertasService.currentArticle.subscribe((res) => {
      // this.loadData(params);
    });
  }

  filtrar(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private newMethod() {
    this.route.queryParams.subscribe(params => {
      if (params.search) {
        this.loadData(params);
      }
      else {
        Swal.fire({
          type: "error",
          title: "Oops...",
          text: "Intenta buscar algo"
        });
      }
    });
  }

  onChangeSeccion(seccion) {
    this.ofertasService.getCategoriasSeccion(seccion).then((res) => {
      this.categorias = res.categorias;
    });
  }

  loadData(params) {
    this.currentPage = parseInt(params.page);

    this.page = params.page;
    let page: number = params.page;
    let perPage: number = params.perPage;
    let orderBy: string = params.orderBy;
    let available: number = params.available;
    this.searchText = params.search;

    // this.dataService
    //   .search(this.searchText, orderBy, available, page, perPage)
    //   .then(results => {
    //     if (results.total > 0) {
    //       this.productData = results.productos;
    //       console.log(results);
    //       this.totalProducts = results.total;
    //       this.totalPages = Math.ceil(this.totalProducts / this.perPage);
    //       this.spinner.hide();
    //     } else {
    //       this.productData = null;
    //       this.spinner.hide();
    //     }
    //   }).catch(err => {
    //     this.spinner.hide();
    //     console.log(err);
    //     Swal.fire({
    //       type: "error",
    //       title: "Error",
    //       text: "Contacte al administrador"
    //     });
    //   });
  }
}
