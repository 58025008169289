import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'innerHtml'
})
export class InnerHtmlPipe implements PipeTransform {

  transform(value: any, opcion = ''): string {
    if (opcion === 'remplace') {
      return value.replaceAll(';', '<br>');
    } else {
      if (value.length > 0) {
        return value.match(/.{1,100}/g).join("<br/>");
      }
    }
   return value;
  }

}
