interface NavAttributes {
  [propName: string]: any;
}

interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}

interface NavBadge {
  text: string;
  variant: string;
}

interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
  padre?: boolean;
  nameVariant?: string;
}

export const navItems: NavData[] = [
  {
    name: 'Clientes',
    url: '/clientes',
    icon: 'icon-user-follow',
    class: "custom-nav id-1",
    children: [
      {
        name: 'Alta Clientes',
        url: '/clientes/list',
        icon: 'icon-user-follow',
        nameVariant: 'CLIENTES',
        class: "custom-nav-child",
      },
      {
        name: 'Clientes',
        url: '/clientes/reporte-lista-cliente',
        icon: 'icon-user-follow',
        class: "custom-nav-child",
      },
      {
        name: 'Clientes nuevos',
        url: '/clientes/nuevos',
        icon: 'icon-user-follow',
        class: "custom-nav-child",
      },
      {
        name: 'Estado de cuenta',
        url: '/clientes/Estado-de-cuenta',
        icon: 'icon-list',
        class: "custom-nav-child",
      },
    ],
  },

  {
    name: 'Imágenes Faltantes',
    url: '/imagenes-faltantes',
    icon: 'icon-folder',
    class: "custom-nav  id-2",
  },

  {
    name: 'Marketing',
    url: '/marketing',
    icon: 'icon-tag',
    class: "custom-nav  id-3",
    children: [
      {
        name: 'Recomendado',
        url: '/marketing/recomendado',
        icon: 'icon-trophy',
        class: "custom-nav-child",
      },
      {
        name: 'Marca',
        url: '/marketing/marca',
        icon: 'icon-diamond',
        class: "custom-nav-child",
      },
      {
        name: 'Solución destacada',
        url: '/marketing/solucion',
        icon: 'icon-wrench',
        class: "custom-nav-child",
      },
      {
        name: 'Ofertas y rebajas',
        url: '/marketing/ofertas-y-rebajas',
        icon: 'icon-present',
        class: "custom-nav-child",
      },
      {
        name: 'Flyers promociones',
        url: '/marketing/flyers-promociones',
        icon: 'icon-present',
        class: "custom-nav-child",
      },
      {
        name: 'Noticias',
        url: '/marketing/noticias',
        icon: 'icon-list',
        class: "custom-nav-child",
      },
      {
        name: 'Imágenes',
        url: '/marketing/imagenes-externas',
        icon: 'icon-folder',
        class: "custom-nav-child",
      },
      {
        name: 'Videos',
        url: '/marketing/videos',
        icon: 'icon-folder',
        class: "custom-nav-child",
      },
      {
        name: 'Marcas Popup',
        url: '/marketing/popup',
        icon: 'icon-folder',
        class: "custom-nav-child",
      },
      /* {
        name: 'Artículos Popup',
        url: '/marketing/articulos-popup',
        icon : 'icon-folder'
      }, */
      {
        name: 'Leyendas Popup',
        url: '/marketing/leyendas-popup',
        icon: 'icon-folder',
        class: "custom-nav-child",
      },
      {
        name: 'Eventos',
        url: '/marketing/eventos',
        icon: 'icon-event',
        class: "custom-nav-child",
      },
    ],
  },

  {
    name: 'Claves genéricas',
    url: '/claves-genericas',
    icon: 'icon-list',
    class:"custom-nav id-5"
  },//este debe ir arriba corregir

  {
    name: 'Reportes',
    url: '/reportes',
    icon: 'icon-folder',
    class: "custom-nav id-4",
    children: [
      {
        name: 'Ventas',
        url: '/reportes/ventas',
        icon: 'icon-chart',
        class: "custom-nav-child",
        children: [
          {
            name: 'Ventas diarias',
            url: '/reportes/ventas/reporte-ventas2',
            icon: 'icon-graph',
            class: "custom-nav-subchild",
          },
          {
            name: 'Vta marca x vend',
            url: '/reportes/ventas/reporte-marcas',
            icon: 'icon-graph',
            class: "custom-nav-subchild",
          },

          {
            name: 'Abc',
            url: '/reportes/ventas/abc',
            icon: 'icon-chart',
            class: "custom-nav-subchild",
            children: [
              {
                name: 'ABC presupuesto',
                url: '/reportes/ventas/abc/abc-presupuesto',
                icon: 'icon-graph',
                class: "custom-nav-thirdchild",
              },
              {
                name: 'ABC uno',
                url: '/reportes/ventas/abc/abc-uno',
                icon: 'icon-graph',
                class: "custom-nav-thirdchild",
              },
              {
                name: 'ABC dos',
                url: '/reportes/ventas/abc/abc-dos',
                icon: 'icon-graph',
                class: "custom-nav-thirdchild",
              },
              {
                name: 'ABC tres',
                url: '/reportes/ventas/abc/abc-tres',
                icon: 'icon-graph',
                class: "custom-nav-thirdchild",
              },
              //  {
              //   name: 'Vta marca x vend',
              //   url: '/abc/reporte-marcas',
              //   icon: 'icon-graph',
              //  }
            ],
          },
          {
            name: 'Vtas Ctes x Mes',
            url: '/reportes/ventas/clientes-por-mes',
            icon: 'icon-graph',
            class: "custom-nav-subchild",
          },
          {
            name: 'Análisis de ventas',
            url: '/reportes/ventas/analisis',
            icon: 'icon-graph',
          },
        ],
      },
      {
        name: 'Canjes KabiCoins',
        url: '/reportes/reporte-canjes-kabiCoins',
        icon: 'icon-folder',
      },
      {
        name: 'Ventas Clientes',
        url: '/reportes/reporte-ventas-cliente',
        icon: 'icon-folder',
      },
      {
        name: 'Participacion Web',
        url: '/reportes/reporte-participacion',
        icon: 'icon-folder',
      },
      {
        name: 'Encuesta',
        url: '/reportes/reporte-encuesta',
        icon: 'icon-folder',
      },
      {
        name: 'Lista de precios', //-VENTAS 2
        url: '/reportes/lista-precios',
        icon: 'icon-folder',
      },
      {
        name: 'Puntos KabiCoins', //-VENTAS 2
        url: '/reportes/clientes-kabikCoins',
        icon: 'icon-folder',
      },
    ],
  },



  {
    name: 'Recursos Humanos',
    url: '/recursos-humanos',
    icon: 'icon-user',
    class: "custom-nav  id-6",
    children: [
      {
        name: 'Bolsa de trabajo',
        url: '/recursos-humanos/bolsa-trabajo',
        icon: 'icon-briefcase',
        class: "custom-nav-child",
      },
      {
        name: 'Imagenes Subidas',
        url: '/recursos-humanos/reporte-imagenes-subidas',
        icon: 'icon-folder',
        class: "custom-nav-child",
      },
    ],
  },

  {
    name: 'Compras',
    icon: 'icon-folder',
    url: '/compras',
    class: "custom-nav  id-7",
    children: [
      {
        name: 'Pedidos Especiales',
        url: '/compras/pedidos-especiales',
        icon: 'icon-graph',
        class: "custom-nav-child",
      },
      {
        name: 'Artículos Insumos',
        url: '/compras/articulos-insumos',
        icon: 'icon-list',
        class: "custom-nav-child",
      },
      {
        name: 'Articulos Web',
        url: '/compras/articulos-web',
        icon: 'icon-list',
        class: "custom-nav-child",
      },
      {
        name: 'Departamentos Web',
        url: '/compras/departamentos-web',
        icon: 'icon-list',
        class: "custom-nav-child",
      },
      {
        name: 'Marcas Web',
        url: '/compras/marcas-web',
        icon: 'icon-list',
        class: "custom-nav-child",
      },
      {
        name: 'Grupos Web',
        url: '/compras/grupos-web',
        icon: 'icon-list',
        class: "custom-nav-child",
      },
      {
        name: 'Artículos Backorder',
        url: '/compras/ultimas-piezas',
        icon: 'icon-list',
        class: "custom-nav-child",
      },
      {
        name: 'Matriz',
        url: '/compras/matriz',
        icon: 'icon-list',
        class: "custom-nav-child",
      },
    ],
  },


  {
    name: 'Almacen',
    icon: 'icon-folder',
    url: '/almacen',
    class: "custom-nav  id-8",
    children: [
      {
        name: 'Produccion',
        url: '/almacen/produccion',
        icon: 'icon-graph',
        class: "custom-nav-child",
      },
      {
        name: 'Devoluciones',
        url: '/almacen/devoluciones',
        icon: 'icon-graph',
        class: "custom-nav-child",
      },
      {
        name: 'Articulos UPC',
        url: '/almacen/articulos-upc',
        icon: 'icon-graph',
        class: "custom-nav-child",
      },

      ///DESDE AQUI ESTABA COMENTADO
      {
        name: 'Consultar cobertura',
        url: '/consultar-cobertura',
        icon: 'icon-graph',
      },
      {
        name: 'Generar guias',
        url: '/guia/generar-guia',
        icon: 'icon-graph',
      },
      {
        name: 'Generar guias entre empresas',
        url: '/generar-guia-detalle/detalle/ProcesosInternos', //        url: '/generar-guia-detalle/detalle',
        icon: 'icon-graph',
      },
      {
        name: 'Generar guias  garantias',
        url: '/generar-guia-detalle/detalle/DevolucionClientes', //        url: '/generar-guia-detalle/detalle',
        icon: 'icon-graph',
      },
      {
        name: 'Consultar guias',
        url: '/imprimir-guia',
        icon: 'icon-graph',
      },
      {
        name: 'Direcciones',
        url: '/catalogo-remitentes',
        icon: 'icon-graph',
      },

      ///HASTA AQUI ESTABA COMENTADO
      // {
      //     name: 'Consultar cobertura',
      //     url: '/consultar-cobertura',
      //     icon: 'icon-graph',
      // },
      // {
      //     name: 'Generar guias',
      //     url: '/generar-guia',
      //     icon: 'icon-graph',
      // },
      // {
      //     name: 'Generar guias entre empresas',
      //     url: '/generar-guia-detalle/detalle/ProcesosInternos', //        url: '/generar-guia-detalle/detalle',
      //     icon: 'icon-graph',
      // },
      // {
      //     name: 'Generar guias  garantias',
      //     url: '/generar-guia-detalle/detalle/DevolucionClientes', //        url: '/generar-guia-detalle/detalle',
      //     icon: 'icon-graph',
      // },
      // {
      //     name: 'Consultar guias',
      //     url: '/imprimir-guia',
      //     icon: 'icon-graph',
      // },
      // {
      //     name: 'Direcciones',
      //     url: '/catalogo-remitentes',
      //     icon: 'icon-graph',
      // },
    ],
    //     {
    //       name: 'Consultar cobertura',
    //       url: '/consultar-cobertura',
    //       icon: 'icon-graph',
    //     },
    //     {
    //       name: 'Generar guias',
    //       url: '/generar-guia',
    //       icon: 'icon-graph',
    //     },
    //     {
    //       name: 'Generar guias entre empresas',
    //       url: '/generar-guia-detalle/detalle/ProcesosInternos', //        url: '/generar-guia-detalle/detalle',
    //       icon: 'icon-graph',
    //     },
    //     {
    //       name: 'Generar guias  garantias',
    //       url: '/generar-guia-detalle/detalle/DevolucionClientes', //        url: '/generar-guia-detalle/detalle',
    //       icon: 'icon-graph',
    //     },
    //     {
    //       name: 'Consultar guias',
    //       url: '/imprimir-guia',
    //       icon: 'icon-graph',
    //     },
    //     {
    //       name: 'Direcciones',
    //       url: '/catalogo-remitentes',
    //       icon: 'icon-graph',
    //     },
    //   ],
  },

  {
    //title: true,
    name: 'Tesorería',
    url: '/tesoreria',
    icon: 'icon-wallet',
    class: "custom-nav  id-9",
    children: [
      {
        name: 'Programación Pagos',
        url: '/tesoreria/programacion-pagos',
        icon: 'icon-list', class: "custom-nav-child",
      },
    ]
  },

  //multiinventarios
  {
    name: 'Multi Inventarios',
    url: '/multi-inventarios',
    icon: 'icon-list',
    class: "custom-nav  id-10",
    children: [
      {
        name: 'Departamentos-Web',
        url: '/multi-inventarios/Departamentos-Web',
        icon: 'icon-graph',
        class: "custom-nav-child",
      },
      {
        name: 'Grupos-Web',
        url: '/multi-inventarios/Grupos-Web',
        icon: 'icon-graph',
        class: "custom-nav-child",
      },
      {
        name: 'Marcas-Web',
        url: '/multi-inventarios/Marcas-Web',
        icon: 'icon-graph',
        class: "custom-nav-child",
      },
      {
        name: 'Margen Venta Dep',
        url: '/multi-inventarios/margen-departamento',
        icon: 'icon-graph',
        class: "custom-nav-child",
      },
      {
        name: 'Pedidos',
        url: '/multi-inventarios/pedidos',
        icon: 'icon-graph',
        class: "custom-nav-child",
      },
      {
        name: 'Pedidos-Inventario',
        url: '/multi-inventarios/pedidos-inventario',
        icon: 'icon-graph',
        class: "custom-nav-child",
      },
      {
        name: 'Permisos',
        url: '/multi-inventarios/pedido-permisos',
        icon: 'icon-user',
        class: "custom-nav-child",
      },
      {
        name: 'Clientes-Condicionados',
        url: '/multi-inventarios/clientes-condicionados',
        icon: 'icon-user',
        class: "custom-nav-child",
      }
    ],
  },


  {
    url: '/inventario',
    icon: 'icon-note',
    name: 'Inventario',
    class: "custom-nav  id-11",
    children: [
      {
        name: 'Periodos',
        url: '/inventario/periodo-inventario',
        icon: 'icon-list', class: "custom-nav-child",
      },
      {
        name: 'Reporte',
        url: '/inventario/reporte-inventario',
        icon: 'icon-list', class: "custom-nav-child",
      },
      {
        name: 'ResumenCosteoInv',
        url: '/reportes/resumen/costeo-inventario',
        icon: 'icon-list', class: "custom-nav-child",
      },
    ]
  },

  {
    //title: true,
    name: 'Herramientas',
    url: '/herramientas',
    icon: "icon-wrench",
    class: "custom-nav  id-12",
    children: [
      {
        name: 'Licencias Zafiro',
        url: '/herramientas/licencias-zafiro',
        icon: 'icon-list', class: "custom-nav-child",
      },
      {
        name: 'Firma',
        url: '/herramientas/firma',
        icon: 'icon-list', class: "custom-nav-child",
      },
    ]
  },

  /* {
    title: true,
    name: 'Permisos',
  },
  {
    name: 'Permisos',
    url: '/permisos',
    icon: 'icon-key',
  }, */
];
