import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { LicenciasZafiroService } from '../../services/licencias-zafiro/licencias-zafiro.service';

@Component({
  selector: 'app-licencias-zafiro',
  templateUrl: './licencias-zafiro.component.html',
  styleUrls: ['./licencias-zafiro.component.scss']
})

export class LicenciasZafiroComponent implements OnInit {
  public data = [];

  constructor(
    private spinner: NgxSpinnerService,
    private licenciasZafiroService: LicenciasZafiroService
  ) { }

  ngOnInit() {
    this.show();
  }

  show() {
    this.spinner.show('spinner');
    this.licenciasZafiroService.show().then((res) => {
      if (res.message === 'true') {
        this.data = res.response;
      }
      this.spinner.hide('spinner');
    }).catch((error) => {
      this.spinner.hide('spinner');
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message
      });
    });
  }
}