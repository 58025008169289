import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-imagen',
  templateUrl: './icon-imagen.component.html',
  styleUrls: ['./icon-imagen.component.scss']
})
export class IconImagenComponent implements OnInit {
  @Input() public size: string;
  @Input() public color: string;
  @Input() public opacity: boolean;
  constructor() { }

  ngOnInit() {
  }

}
