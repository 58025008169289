import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';


@Component({
  //changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './drag-drop-component.component.html',
  styleUrls: ['./drag-drop-component.component.scss'],
  selector: "drag-drop-component"
})
export class DragDropComponent implements OnInit {

  @Input()
  public leftList: any = [];
  @Input()
  public rightList: any = [];
  @Input()
  public keyAttribute: string = "";

  constructor(
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    console.log(this.leftList);
    console.log(this.rightList);
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  drop(event: CdkDragDrop<any[]>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

}
