import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-generar-guia-devolucion-cliente',
  templateUrl: './generar-guia-devolucion-cliente.component.html',
  styleUrls: ['./generar-guia-devolucion-cliente.component.scss']
})
export class GenerarGuiaDevolucionClienteComponent implements OnInit {
  alert: any;
  constructor() { }

  ngOnInit() {
  }

}
