import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { SelectionModel } from '@angular/cdk/collections';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { PermisosService } from './../../services/permisos/permisos.service';
import { Permisos } from './model-permisos/model-permisos';

@Component({
  selector: 'app-permisos',
  templateUrl: './permisos.component.html',
  styleUrls: ['./permisos.component.scss']
})

export class PermisosComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  matTableDataSource: MatTableDataSource<Permisos>;
  selection: SelectionModel<Permisos>;
  public permisos: Permisos[] = [];
  public columns: string[] = [];
  public data = [];
  public displayedColumns: string[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    private permisosService: PermisosService
  ) { }

  ngOnInit() {
    this.show();
  }

  exportToExcel(): void {
    this.spinner.show('spinner');
    this.permisosService.exportToExcel(this.data ,this.columns).then((res) => {
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
      });
      this.spinner.hide('spinner');
    });
  }

  public exportFilterToExcel = () => {
    this.spinner.show('spinner');
    let data = this.matTableDataSource.filteredData;
    this.permisosService.exportToExcel(data, this.columns).then((res) => {
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
      });
      this.spinner.hide('spinner');
    });
  }

  filter(event: Event) {
    const filter = (event.target as HTMLInputElement).value;
    this.matTableDataSource.filter = filter.trim().toLowerCase();
    if (this.matTableDataSource.paginator) {
      this.matTableDataSource.paginator.firstPage();
    }
  }

  show() {
    this.spinner.show('spinner');
    this.permisosService.show().then((res) => {
      if (res.message == 'true') {
        this.matTableDataSource = new MatTableDataSource(res.response);
        this.selection = new SelectionModel<Permisos>(true, []);
        this.matTableDataSource.sort = this.sort;
        this.matTableDataSource.paginator = this.paginator;
        this.columns = res.columns;
        this.displayedColumns = res.columns;
        this.data = res.response;
        this.displayedColumns.splice(0, 1);
        this.displayedColumns.splice(1, 2);
      }
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
      });
      this.spinner.hide('spinner');
    });
  }

  public update() {
    Swal.fire({
      title: 'Actualizando...',
      text: 'Por favor espere',
      allowOutsideClick: false,
    });
    Swal.showLoading();
    this.permisosService.update(this.permisos).subscribe(res => {
      if (res.message == 'true') {
        Swal.fire({
          type: 'success',
          title: '¡Correcto!',
          text: 'Se ha editado correctamente',
          allowOutsideClick: false,
          confirmButtonText: 'Aceptar'
        }).then((res) => {
          if (res.value) {
            this.permisos.splice(0, this.permisos.length);
            this.show();
          }
        });
      } else {
        throw new Error('Hubo un error al editar en la base de datos, contacte al administrador');
      }
    },
    (error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
        footer: 'Comunícate para resolver el problema',
      });
    });
  }

  selected(event?: any, row?: Permisos, column?: string) {
    if (event.checked) {
      row[column] = '1';
    } else {
      row[column] = '0';
    }
    let search = this.permisos.filter(function(search) {
      return search.CORREO == row.CORREO;
    });
    if (search.length <= 0) {
      this.permisos.push(row);
    }
  }
}