import { Injectable } from '@angular/core';
import { map, retry,catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { stringToArray } from 'handsontable/helpers';
import * as FileSaver from 'file-saver';
import { EmpresaService } from '../../Empresa/Empresa.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
interface ResumenCosteo {
  Data: any[];
  Columnas: ResumenColumnas[];
 }
 interface ResumenColumnas {
   field: string;
   width: string;
   type: string;
   align: string;
   index?: number;

 }
@Injectable({
  providedIn: 'root'
})
export class ReporteCosteoInventarioService {

  empresaId =  String(environment.empresaId);
  private destroy$ = new Subject<void>();
constructor(private http: HttpClient,  private empresaService: EmpresaService) {

  this.empresaService.empresaId$
  .pipe(takeUntil(this.destroy$))
  .subscribe(id => {
    this.empresaId = id;
  });
 }
getResumenCosteoInventario = (fecha: Date): Observable<ResumenCosteo> => {

  const data = {
    option: 'getResumenCosteo',
    fecha,
    empresaID: this.empresaId
  };
  return this.http.post<ResumenCosteo>(environment.apiUrl + '/reportes/reporte-costeo-inventario/getConsteoInventario.php', data).pipe(
    retry(3),
     map(response => {
      return response;
    }));
}
getCosteoArticuloUpc = (sucursal:string, almacen:string,fecha: Date): Observable<ResumenCosteo> => {

  const data = {
    option: 'getResumenCosteoUPC',
    fecha,
    empresaID: this.empresaId,
    sucursal,
    almacen
  };
  return this.http.post<ResumenCosteo>(environment.apiUrl + '/reportes/reporte-costeo-inventario/getConsteoInventario.php', data).pipe(
    retry(3),
     map(response => {
      return response;
    }));
}


getExportResumenCosteo = (
  resumenCosteo: ResumenCosteo
) => {
  const data = {
    option: 'getExportCosteo',
    empresa: this.empresaId,
    resumenCosteo
  };
  const f = new Date();
  const headers = new HttpHeaders().set('Content-Type', 'application/json');
  return this.http
    .post<any>(
      environment.apiUrl + '/reportes/reporte-costeo-inventario/getConsteoInventario.php',
      data,
      { headers, responseType: 'blob' as 'json' }
    )
    .pipe(
      map((data1) => {
      const  blob = new Blob([data1], { type: 'application/vnd.ms.excel' });
      const file = new File([blob], 'Resumen_costeo_inventario_kabik_' + f.getDate() + '_' + (f.getMonth() + 1) + '_' + f.getFullYear() + '.xlsx', {
          type: 'application/vnd.ms.excel',
        });
        FileSaver.saveAs(file);
      })
    )
    .toPromise();
};
getLIstaArticulosCosteoInv(sucursal:string, almacen:string, fecha:Date){

  const data = {
    option: 'getResumenCosteoUPC',
    fecha,
    empresaID: this.empresaId,
    sucursal,
    almacen
  };
  return this.http.post<ResumenCosteo>(environment.apiUrl + '/reportes/reporte-costeo-inventario/getConsteoInventario.php', data).pipe(
    retry(3),
     map(response => {
      return response;
    }));


}

}
