import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, retry, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { Categorias, Articulos, ValidaClave } from '../../views/ofertas/utils/models/oferta';

import { EmpresaService } from '../Empresa/Empresa.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


interface Respuesta {
  message: string;
}

// interface Categorias {
//   message: string;
//   categorias: {};
// }
// interface Articulos {
//   message: string;
//   articulos: {};
// }
interface Articulo {
  message: string;
  articulo?: {};
  error?: string;
}
@Injectable({
  providedIn: 'root'
})
export class OfertasService {
  empresaId =  String(environment.empresaId);
  private destroy$ = new Subject<void>();

  private articlesSource = new BehaviorSubject<Respuesta>({ message: '' });
  currentArticle = this.articlesSource.asObservable();

  private categoriesSource = new BehaviorSubject<Respuesta>({ message: '' });
  currentCategory = this.categoriesSource.asObservable();

  constructor(private http: HttpClient,  private empresaService: EmpresaService) {
    this.empresaService.empresaId$
    .pipe(takeUntil(this.destroy$))
    .subscribe(id => {
      this.empresaId = id;
    });
   }

  updateArticleObservable(article: Respuesta) {
    this.articlesSource.next(article);
  }

  updateCategoryObservable(category: Respuesta) {
    this.categoriesSource.next(category);
  }

  crearCategoria(nombre, seccion) {
    const data = {
      empresa: this.empresaId,
      nombre: nombre,
      seccion: seccion
    };
    return this.http
      .post<Respuesta>(environment.apiUrl + 'ofertas/create_category.php', data)
      .pipe(map(data => data))
      .toPromise();
  }

  eliminarCategoria(categoria) {
    const data = {
      empresa: this.empresaId,
      nombre: categoria.NombreCategoria
    };

    return this.http
      .post<Respuesta>(environment.apiUrl + 'ofertas/delete_category.php', data)
      .pipe(map(data => data))
      .toPromise();
  }

  eliminarArticulo(articulo) {
    const data = {
      empresa: this.empresaId,
      categoria: articulo.NombreCategoria,
      clave: articulo.ClaveArticulo,
      seccion: articulo.Seccion
    };

    return this.http
      .post<Respuesta>(environment.apiUrl + 'ofertas/delete_article.php', data)
      .pipe(map(data => data))
      .toPromise();
  }

  crearArticulo(clave, categoria, seccion) {
    const data = {
      empresa: this.empresaId,
      clave: clave,
      categoria: categoria,
      seccion: seccion
    };
    return this.http
      .post<Respuesta>(environment.apiUrl + 'ofertas/create_article.php', data)
      .pipe(map(data => data))
      .toPromise();
  }

  getCategorias() {
    return this.http
      .get<Categorias>(
        environment.apiUrl +
        'ofertas/get_categories.php?empresa=' +
        this.empresaId
      )
      .pipe(map(data => data))
      .toPromise();
  }

  getCategoriasSeccion(seccion) {
    return this.http
      .get<Categorias>(
        environment.apiUrl +
        'ofertas/get_categories.php?empresa=' +
        this.empresaId +
        '&seccion=' +
        seccion
      )
      .pipe(map(data => data))
      .toPromise();
  }

  getProductKey(clave: string) {
    const data = {
      clave: clave,
      empresa: this.empresaId
    };
    return this.http
      .post<Articulo>(environment.apiUrl + 'productos/getProductKey.php', data)
      .pipe(map(data1 => data1))
      .toPromise();
  }

  setArticuloInsumo(clave, articuloId, tipo) {
    const data = {
      clave: clave,
      empresa: this.empresaId,
      articuloId,
      tipo
    };
    return this.http
      .post<Articulo>(environment.apiUrl + 'productos/setArticulosInsumos.php', data)
      .pipe(map(data1 => data1))
      .toPromise();
  }


   //Apartir de aqui nueva version

  public getDataCategorias = (seccion: string):Observable<Categorias> => {

    const data = {
      option: 'getCategorias',
      seccion,
      empresa: this.empresaId
    }

    return this.http.post<Categorias>(environment.apiUrl + 'ofertas/OfertasAdmin.php', data).pipe(retry(3),
      catchError(this.handleError), map(response => {
        return response;
      }));

  }

  public getDataArticulos = (seccion: string): Observable<Articulos> => {

    const data = {
      option: 'getArticulos',
      seccion,
      empresa: this.empresaId
    }

    return this.http.post<Articulos>(environment.apiUrl + 'ofertas/OfertasAdmin.php', data).pipe(retry(3),
      catchError(this.handleError), map(response => {
        return response;
      }));

  }

  public delDataArticulos = (list: any, option: string) => {

    const data = {
      option, //:'delArticulos',
      list,
      empresa: this.empresaId
    }

    return this.http.post<Articulos>(environment.apiUrl + 'ofertas/OfertasAdmin.php', data).pipe(retry(3),
    catchError(this.handleError), map(response => {
      return response;
    }));
  }

  // public delDataCategoria = (list: any) => {
  //   const data = {
  //     option: 'delCategoria',
  //     list,
  //     empresa: this.empresaId
  //   }
  //   return this.http.post<Articulos>(environment.apiUrl + 'ofertas/OfertasAdmin.php', data).pipe(retry(3),
  //   catchError(this.handleError), map(response => {
  //     return response;
  //   }));
  // }

  public addDataArticulos = (clave: string, categoria: string, principal: string,seccion: string,dat:any) => {
    const data = {
      option: 'addArticulos',
      clave,
      categoria,
      principal,
      seccion,
      dat,
      empresa: this.empresaId
    }

    return this.http.post<Articulos>(environment.apiUrl + 'ofertas/OfertasAdmin.php', data).pipe(retry(3),
    catchError(this.handleError), map(response => {
      return response;
    }));
  }

  public updateDataArticulos =(id: string, clave: string, categoria: string, principal: string, seccion: string,dat: any) =>{
    const data = {
      option: 'updateArticulos',
      clave,
      categoria,
      principal,
      seccion,
      id,
      dat,
      empresa: this.empresaId
    }
    return this.http.post<Articulos>(environment.apiUrl + 'ofertas/OfertasAdmin.php', data).pipe(retry(3),
    catchError(this.handleError), map(response => {
      return response;
    }));
  }

  public updateDataCategoria = (id: string, nombreCategoria: string, posicion: string,  home: string, dat:any, diseño: string) => {
    const data = {
      option: 'updateCategorias',
      nombreCategoria,
      posicion,
      home,
      id,
      dat,
      diseño,
      empresa: this.empresaId
    };
    return this.http.post<Articulos>(environment.apiUrl + 'ofertas/OfertasAdmin.php', data).pipe(retry(3),
    catchError(this.handleError), map(response => {
      return response;
    }));
  }
  public validaClaveArticulos(clave: string, seccion: string){
   const data = {
      option: 'validaClave',
      clave,
      seccion,
      empresa: this.empresaId
   }
   return this.http.post<any>(environment.apiUrl + 'ofertas/OfertasAdmin.php', data).pipe(retry(3),
   catchError(this.handleError), map(response => {
     return response;
   }));
  }

  public validaCategoriaArticulos(clave: string, seccion: string, nombreCategoria: string){
    const data = {
       option: 'validaCategoria',
       clave,
       nombreCategoria,
       seccion,
       empresa: this.empresaId
    }
    return this.http.post<any>(environment.apiUrl + 'ofertas/OfertasAdmin.php', data).pipe(retry(3),
    catchError(this.handleError), map(response => {
      return response;
    }));
   }

  getArticulos(seccion) {
    return this.http
      .get<Articulos>(
        environment.apiUrl +
        'ofertas/getArticulosOfertas.php?empresa=' +
        this.empresaId + '&seccion=' + seccion
      )
      .pipe(map((data) => data));
  }

  /*Categorias */
  public addDataCategorias = (categoria: string, posicion: string, home: string, seccion: string, dat: any, diseño: string) => {

    const data = {
      option: 'addCategoria',
      categoria,
      posicion,
      home,
      seccion,
      empresa: this.empresaId,
      dat,
      diseño
    };

    return this.http.post<Articulos>(environment.apiUrl + 'ofertas/OfertasAdmin.php', data).pipe(retry(3),
    catchError(this.handleError), map(response => {
      return response;
    }));
  }

  public addSecciones = (seccion: string, tituloSeccion: string, home: string, dat: any ) => {

    const data = {
      option: 'addSecciones',
      seccion,
      tituloSeccion,
      home,
      empresa: this.empresaId,
      dat
    };

    return this.http.post<Articulos>(environment.apiUrl + 'ofertas/OfertasAdmin.php', data).pipe(retry(3),
    catchError(this.handleError), map(response => {
      return response;
    }));
  }
  public updateSecciones =(tituloSeccion: string,home:string,id:string, dat: any)=>{

    const data = {
      option: 'updateSecciones',
      tituloSeccion,
      home,
      id,
      dat,
      empresa: this.empresaId
    }
    return this.http.post<Articulos>(environment.apiUrl + 'ofertas/OfertasAdmin.php', data).pipe(retry(3),
    catchError(this.handleError), map(response => {
      return response;
    }));

  }

  private handleError = (error: HttpErrorResponse | any) => {

    Swal.fire({
      type: 'error',
      title: 'Error',
      text:
        'Lo sentimos, Ocurrio un error de comunicación, Favor de contactarse con el administrador del sitio Incidencias@kabik.com.mx' + error.message
    });
    return throwError(error);
  }
}
