import { Component, OnInit, Inject, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.scss']
})
export class FiltrosComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<FiltrosComponent>,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.setTable();
    this.formFiltros.get('filtros').valueChanges.subscribe(res => {
      this.filtros(res);
    });
  }

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  dataSource: MatTableDataSource<any>;
  selection: SelectionModel<any>;
  public formFiltros: FormGroup;
  public listaDeElemntos: any[] = [];
  public title: string;
  public columnId: string;
  public displayedColumns: string[] = []
  public selectedOneOpcion: boolean; //Solo puede seleccionar un elemento del check;
  public fitros: any = [
    { id: 0, Nombre: 'Sin filtros' },
    { id: 1, Nombre: 'Asc' },
    { id: 2, Nombre: 'Desc' },
    { id: 3, Nombre: 'Seleccionados' },
  ]

  ngOnInit() {
    // console.log(this.data);
    switch (this.data.opcion) {
      case 1:
        this.title = 'Departamentos';
        this.columnId = 'DepartamentoID'; //Columna ID debe ser igual a la comlumna que regresa el store sql
        break;
      case 2:
        this.title = 'Grupos';
        this.columnId = 'GrupoId';
        break;
      case 3:
        this.title = 'Clientes';
        this.columnId = 'ClienteID';
        if (this.data.reportAbc2 != undefined) {
          this.selectedOneOpcion = this.data.reportAbc2;
        }
        break;
      case 4:
        this.title = 'Vendedores';
        this.columnId = 'Vendedor';
        break;
      case 5:
        this.title = 'Marcas';
        this.columnId = 'MarcaId';
        break;
      case 6:
        this.title = 'Sucursales';
        this.columnId = 'SucursalId';
        break;
      case 7:
        this.title = 'Almacenes';
        this.columnId = 'id';
        break;
    }

  }
  ngAfterViewInit() {

    this.dataSource = new MatTableDataSource(this.data.lista);
    this.displayedColumns = ['CheckBox', this.columnId, 'Nombre'];
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.listaDeElemntos = this.data.listaCheck;
    this.selection = new SelectionModel<any>(true, this.data.listaCheck);
  }
  setTable() {
    this.formFiltros = this.fb.group({
      busqueda: new FormControl(''),
      filtros: new FormControl('')
    });
  }

  private filtros(data: any) {
    console.log(data);
    let list = this.dataSource.data;
    let arrayArticulos = [];
    switch (data.id) {
      case 0:
      case 1:
        this.dataSource.data = list.sort((item1: any, item2: any) => {
          return this.orderByAscDesc(
            item1['Nombre'],
            item2['Nombre'], 1
          );
        });
        break;
      case 2:
        this.dataSource.data = list.sort((item1: any, item2: any) => {
          return this.orderByAscDesc(
            item1['Nombre'],
            item2['Nombre'], 2
          );
        });
        break;
      case 3:
        list.forEach(val => {
          if (this.selection.isSelected(val)) {
            arrayArticulos.unshift(val); // = [...arrayArticulos, val];
          } else {
            arrayArticulos.push(val);
          }
        });
        this.dataSource.data = arrayArticulos;
        break;
    }
    // this.dataSource.data = list;
    this.cd.markForCheck();
  }

  private orderByAscDesc(a: any, b: any, args: number): number {
    if (args === 1) {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    } else {
      if (a > b) {
        return -1;
      } else if (a < b) {
        return 1;
      } else {
        return 0;
      }
    }
  }
  masterToggle() {
    this.listaDeElemntos = [];
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => {
        this.listaDeElemntos = [...this.listaDeElemntos, row];
        this.selection.select(row);
      });
    // console.log(this.listaDeElemntos);
  }

  checkboxLabel(row?: any): string {

    // if (!row) {
    // console.log('llego aqui' + this.isAllSelected());
    // console.log(this.isAllSelected() ? 'Marcar' : 'Desmarcar');
    return `${this.isAllSelected() ? 'Desmarcar' : 'Marcar'} Todo`;
    // }                true  :quiere decir que odoos esyas marcados : false ninguno
    // // console.log('llego aqui'+this.isAllSelected());
    // return `${this.selection.isSelected(row) ? 'Desmarcar' : 'Marcar'} row ${row[this.columnId] + 1
    //   }`;
  }
  private removeToArray(row: any): void {


    if (this.listaDeElemntos.length <= 1) {
      this.listaDeElemntos = [];
    } else {
      const index = this.listaDeElemntos.indexOf(row);
      if (index !== -1) {
        this.listaDeElemntos.splice(index, 1);
      }
    }
    // let contador = 0;
    // console.groupCollapsed('linea 98');
    // console.log(this.listaDeElemntos);
    // console.log(row);
    // console.groupEnd();
    // this.listaDeElemntos.forEach((remitentes) => {
    //   console.groupCollapsed('linea 98');
    //   console.log(remitentes['Vendedor']);
    //   console.log(row['Vendedor']);
    //   console.groupEnd();
    // if (remitentes.ClienteID === row.ClienteID) {
    //   if (this.listaDeElemntos.length <= 1) {
    //     this.listaDeElemntos = [];
    //   } else {
    //     const cont = contador <= 1 ? 1 : contador;
    //     this.listaDeElemntos.splice(contador, 1);
    //   }
    // }
    // contador++;
    //});
  }

  public limiparInput() {

  }

  public function() {

  }
  public selected(event?: any, row?: any) { //Cuando le da clic uno por uno;
    // console.log('llego linea 114');


    //  console.log(event);
    if (event.checked) {
      console.log('llego aqui');
      // console.log(event.checked);

      // console.log('Linea 119' + event);
      this.listaDeElemntos = [...this.listaDeElemntos, row];
    } else {
      // console.log('Remove to array');
      this.removeToArray(row);
    }
    event = event ? this.selection.toggle(row) : null;

    // console.log(row);
    // console.log(this.listaDeElemntos)
  }
  //  private selectedChecked(row: any[]){
  //    if(row.length > 0){
  //      row.forEach(element => {
  //           this.listaDeElemntos = [...this.listaDeElemntos, element];
  //           this.selection.toggle(element);
  //      });
  //    }
  //  }
  isAllSelected() {

    if (this.selection != undefined) {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
  }
  onNoClick() {
    // console.log(this.listaDeElemntos);
    this.dialogRef.close(this.listaDeElemntos);
  }

  applyFilter(event: Event) {
    // console.log(event);
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // console.log(this.dataSource.filter);
    // console.log(this.dataSource);
    // console.log( this.dataSource.filter = filterValue.trim().toLowerCase());
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  limpiarInput(event: Event) {
    this.formFiltros.get('busqueda').setValue('');
    this.dataSource.filter = '';
  }
}
