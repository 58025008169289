import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { AlmacenService } from '../../../services/Almacen/almacen.service';
import { ModalPedidoComponent } from '../../Multi-inventarios/pedidos/modal-pedido/modal-pedido.component';
import { ModalPedidoGuiaComponent } from './modal-guia/modal-guia.component';
export interface LIST {
  position: number;
  pedido: string;
  almacen: string;
  cliente: string;
  nombre: string;
  guia: string;
  fecha: string;
}

@Component({
  selector: 'app-generar-guia',
  templateUrl: './generar-guia.component.html',
  styleUrls: ['./generar-guia.component.scss'],
  encapsulation : ViewEncapsulation.None,
})
export class GenerarGuiaComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: MatTableDataSource<any>;

  public formGuia: FormGroup;
  isLoadingResults = false;
  public list: LIST[] = [];
  public array: object = [];
  public data: any;
  public maxDate: Date;
  public guiaGen: boolean = true;
  public displayedColumns: string[] = [
    'pedido',
    'almacen',
    'cliente',
    'nombre',
    'fecha',
    'guia',
  ];

  private width = window.innerWidth;
  public estatus = [];
  public tipoPedido = [];

  constructor(
    private fb: FormBuilder,
    private almacenService: AlmacenService,
    private activeRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router,
    public matDialog: MatDialog,
  ) {
    this.validaFomr();
    this.activeRoute.params.subscribe(
      (param) => {
        this.reload(param);
      },
      (error) => {
        console.log({ error });
      }
    );
  }

  ngOnInit() {
    this.maxDate = new Date();
    const date = new Date();
    this.formGuia.get('FechaFin').setValue(new Date().toISOString());
    this.formGuia
      .get('FechaInicio')
      .setValue(new Date().toISOString());
      //.setValue(new Date(date.getFullYear(), date.getMonth() - 1, 1));
    this.buscaPedido();
  }
  validaFomr() {
    this.formGuia = this.fb.group({
      pedido: [''],
      almacen: [''],
      cliente: [''],
      FechaInicio: [''],
      FechaFin: [''],
    });
  }

  reload(params) {
    if (Object.entries(params).length != 0){
    const param = JSON.parse(params.data);
    this.formGuia
      .get('pedido')
      .setValue(param.pedidoId != '' ? param.pedidoId : '');
    this.formGuia
      .get('almacen')
      .setValue(param.almacenId != '' ? param.almacenId : '');
    this.formGuia
      .get('cliente')
      .setValue(param.clienteId != '' ? param.clienteId : '');
    }
  }

  navigate(element) {
    var obj = {
      pedido: element.pedido,
      almacen: element.almacen,
      cliente: element.cliente,
      nombre: element.nombre,
      almacenB:
        this.formGuia.get('almacen').value === ''
          ? ''
          : this.formGuia.get('almacen').value,
      clienteB:
        this.formGuia.get('cliente').value === ''
          ? ''
          : this.formGuia.get('cliente').value,
      pedidoB:
        this.formGuia.get('pedido').value === ''
          ? ''
          : this.formGuia.get('pedido').value,
    };
    this.router.navigate([
      '/generar-guia-detalle/detalle',
      JSON.stringify(obj),
    ]);
  }

  buscaPedido() {
    this.spinner.show('spinner-guia');

    this.almacenService
      .getPedido(
        this.formGuia.value.pedido,
        this.formGuia.value.almacen,
        this.formGuia.value.cliente,
        this.formGuia.value.FechaInicio,
        this.formGuia.value.FechaFin
      )
      .then((res) => {
        this.spinner.hide('spinner-guia');
        if (res.message === 'true') {
          let index = 0;
          var unArray = [];
          var a = new Object();
          this.data = res.data;
          this.data.forEach((element) => {
            a = {
              position: index,
              pedido: element.PedidoID,
              almacen: element.AlmacenID,
              cliente: element.Cliente,
              nombre: element.Nombre,
              fecha: element.Fecha,
              guia: element.Guia,
            };
            unArray.push(a);
            index++;
          });
          this.list = unArray;
          this.dataSource = new MatTableDataSource(this.list);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        } else {
          this.spinner.hide('spinner-guia');
          Swal.fire({
            type: 'error',
            title: 'Error!',
            text: 'No se encontraron datos.',
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
        this.spinner.hide('spinner-guia');
        Swal.fire({
          type: 'error',
          title: 'Error!',
          text:
            'No se pudo relizar la consulta, contacte al administrador incidencias@kabik.com.mx.' +
            err.error.text,
        });
      });
  }

  openModal = (pedido?: any, event?: any, donde?: string) => {
    console.log(pedido);
    
    this.width = window.innerWidth;
    const element: HTMLElement = event.target;
    const e_g = document.getElementsByClassName('e-ghidden');
    for (let i = 0; i < e_g.length; i++) {
      e_g[i].classList.remove('e-ghidden');
    }
    if (donde !== 'PedidoId') {
      element.parentElement.classList.add('e-ghidden');
    } else {
      element.parentElement.parentElement.parentElement.classList.add('e-ghidden');
    }

    var obj = {
      pedido: pedido.pedido,
      almacen: pedido.almacen,
      cliente: pedido.cliente,
      nombre: pedido.nombre,
      almacenB:
        this.formGuia.get('almacen').value === ''
          ? ''
          : this.formGuia.get('almacen').value,
      clienteB:
        this.formGuia.get('cliente').value === ''
          ? ''
          : this.formGuia.get('cliente').value, 
      pedidoB:
        this.formGuia.get('pedido').value === ''
          ? ''
          : this.formGuia.get('pedido').value,
    };

    this.matDialog.open(ModalPedidoGuiaComponent, {
      width: this.width <= 1280 ? '78%' : '60%',
      data: { 'pedido': {
        PedidoId: pedido.pedido,
        AlmacenId: pedido.almacen,
        Cliente: pedido.cliente,
        
      }, 'estatus': this.estatus, objPedido: obj },
      disableClose: true
    });
  }
}
