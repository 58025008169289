import { Sucursal } from '../../../reporte-ventas2/model/ReporteVentas';
import { Component, Input, OnInit, ViewChild, OnDestroy, HostListener, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Date_ } from '../../../../models/date_';
import { GetDateService } from '../../../../services/Date/get_date.service';
import Swal from 'sweetalert2';
import { PeriodoInventarioService } from '../../../../services/inventario/periodo/periodo-inventario.service';
import { ABCService } from '../../../ABC/utils/services/ABC.service';
import { DataSucursales, sucursal, DataAlmacenes } from '../../../ABC/utils/models/ABC';
import { switchMap, catchError, takeUntil } from 'rxjs/operators';
import { Subject, throwError, of } from 'rxjs';

import { MatMenuTrigger } from '@angular/material/menu';
import { MatDatepicker } from '@angular/material/datepicker';

interface SelectedItems {
  Id: string;
  Nombre: string;
  Sucursal?: string;
}
interface GetPedidosItems {
    SucursalesSeleccionadas: SelectedItems[];
    AlmacenesSeleccionados: SelectedItems[];
    PedidosSeleccionados: SelectedItems[];
    fechaInicial: string;
    fechaFinal: string;
}
@Component({
  selector: 'app-context-menu-sucursal-guia',
  templateUrl: './context-menu-sucursal-guia.component.html',
  styleUrls: ['./context-menu-sucursal-guia.component.scss'],
})
export class ContextMenuSucursalGuiaComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: FormBuilder,
    private getDate: GetDateService,
    private periodoInventarioService: PeriodoInventarioService,
    private abcService: ABCService
  ) {}
  public formGroup: FormGroup;
  public progressAlmacen: boolean = false;
  public filtroSucursal: any[] = [];
  public filtroAlmacen: any[] = [];
  public filtroAlmacenAux: any[] = [];
  public SucursalesSeleccionadas: SelectedItems[];
  private AlmacenesSeleccionados: SelectedItems[];
  private PedidosSeleccionados: SelectedItems[];
  private unsubscribe$ = new Subject<void>();
  public limpiarAuxiliar:boolean;
  @Input() estatus: any;
  @Input() tipoPedido: any[];
  @Output() getPedidos: EventEmitter<GetPedidosItems> = new EventEmitter();
  @ViewChild('contextMenuSucursal',  { static: false, read: MatMenuTrigger }) menuSucursal: MatMenuTrigger;
  @ViewChild('contextMenuAlmacen',  { static: false, read: MatMenuTrigger }) menuAlmacen: MatMenuTrigger;
  @ViewChild('contextMenuPedido',  { static: false, read: MatMenuTrigger }) menuPedido: MatMenuTrigger;
  @ViewChild('fechaInicial', { static: false, read: undefined}) datePickerInicio: MatDatepicker<Date>;
  @ViewChild('fechaFinal', { static: false, read: undefined}) datePickerFinal: MatDatepicker<Date>;


//   @HostListener('document:keydown.shift.q', ['$event'])
//    eventShifQ (event: KeyboardEvent) {
//     console.log(event);
//     console.log('is event q', event.key);
//     console.log('is menu sucursal', this.menuSucursal);
//     if (this.menuSucursal !== undefined) {
//       if (this.menuSucursal.menuOpen) {
//        this.menuSucursal.closeMenu();
//        return;
//       }
//       this.menuSucursal.openMenu();
//       return;
//     }
//   }
//   @HostListener('document:keydown.shift.w', ['$event'])
//   eventShifW (event: KeyboardEvent) {
//   //  console.log(event);
//   //  console.log('is event w', event.key);
//    if (this.menuAlmacen !== undefined) {
//     if (this.menuAlmacen.menuOpen) {
//         this.menuAlmacen.closeMenu();
//         return;
//     }
//     this.menuAlmacen.openMenu();
//     return;
//    }
//  }
//  @HostListener('document:keydown.shift.e', ['$event'])
//  eventShifE (event: KeyboardEvent) {
//   console.log(event);
//   console.log('is event e', event.key);
//   if (this.menuPedido !== undefined) {
//     if (this.menuAlmacen.menuOpen) {
//       this.menuPedido.closeMenu();
//       return;
//     }
//     this.menuPedido.openMenu();
//     return;
//   }
// }
// @HostListener('document:keydown.shift.r', ['$event'])
// eventShifR (event: KeyboardEvent) {
//  console.log(event);
//  console.log('is event r', event.key);
//     this.datePickerInicio.open();
// }
// @HostListener('document:keydown.shift.t', ['$event'])
// eventShift (event: KeyboardEvent) {
//  console.log(event);
//  console.log('is event r', event.key);
//     this.datePickerFinal.open();
// }
// @HostListener('document:keydown.shift.enter', ['$event'])
// eventShiftEnter (event: KeyboardEvent) {
//  console.log(event);
//  console.log('is event enter', event.key);
//    this.onSubmit();
// }

  ngOnInit() {
    const hoy = new Date();
    this.formGroup = this.formBuilder.group({
      FechaInicial:
      [new Date(hoy.getFullYear(), hoy.getMonth(), 1), [Validators.required]],
      FechaFinal: [new Date(), [Validators.required]],
      Sucursal: ['', [Validators.required]],
      Almacen: ['', [Validators.required]],
      TipoPedido: ['', [Validators.required]],
    });
    this.getNewDate();
    // console.log('is fecha inicial:', this.datePicker);
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  private getNewDate(): void {
    this.formGroup.disable();
    this.getDate
      .get_Date('Y/m/d', 'Y/m/d', 'w')
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((err) => {
          this.progressAlmacen = false;
          return this.handleError(err);
        }),
        switchMap((response: Date_) => {
          this.formGroup.get('FechaInicial').setValue(new Date(response.fechaInicial));
          this.formGroup.get('FechaFinal').setValue(new Date(response.fechaFinal));
          this.formGroup.enable();
          this.getAlmacenes();
          return this.abcService.getSucursales();
        })
      )
      .subscribe((response: DataSucursales) => {
        const sucursales = response.sucursales;
        const sucursalesFilter = sucursales.filter(res  =>  res.SucursalId !== '-1');
        this.filtroSucursal = sucursalesFilter.map((res) => {
            return {
              Id: res.SucursalId,
              Nombre: res.Nombre
            };
        });
      });
  }
  onSubmit() {
    if (this.formGroup.invalid) {
      Swal.fire({
        type: 'warning',
        title: 'Faltan campos por completar',
        text: 'Por favor, escribe los datos que faltan',
        confirmButtonText: 'Aceptar',
      });
      return;
    }
    if (this.formGroup.value.FechaInicial > this.formGroup.value.FechaFinal) {
      Swal.fire({
        type: 'warning',
        title: 'Fechas incorrectas',
        text: 'La fecha inicial no puede ser mayor a la fecha final',
        confirmButtonText: 'Aceptar',
      });
      return;
    }
    if ((this.SucursalesSeleccionadas === undefined || this.SucursalesSeleccionadas === null) || this.SucursalesSeleccionadas.length < 0) {
      Swal.fire({
        type: 'warning',
        title: 'Sucursal incorrecta',
        text: 'No ha seleccionado ninguna sucursal, para continuar seleccione almenos una sucursal.',
        confirmButtonText: 'Aceptar',
      });
      return;
    }
    if ((this.AlmacenesSeleccionados === undefined || this.AlmacenesSeleccionados === null) || this.AlmacenesSeleccionados.length < 0) {
      Swal.fire({
        type: 'warning',
        title: 'Almacen incorrecta',
        text: 'No ha seleccionado ningun almacen, para continuar seleccione almenos un almacen.',
        confirmButtonText: 'Aceptar',
      });
      return;
    }
    if ((this.PedidosSeleccionados === undefined || this.PedidosSeleccionados === null) || this.PedidosSeleccionados.length < 0) {
      Swal.fire({
        type: 'warning',
        title: 'Tipo de pedido incorrecta',
        text: 'No ha seleccionado ninguna tipo de pedido, para continuar seleccione almenos un tipo de pedido.',
        confirmButtonText: 'Aceptar',
      });
      return;
    }
    const getPedidos: GetPedidosItems = {
      SucursalesSeleccionadas: this.SucursalesSeleccionadas,
      AlmacenesSeleccionados: this.AlmacenesSeleccionados,
      PedidosSeleccionados: this.PedidosSeleccionados,
      fechaInicial : this.formGroup.value.FechaInicial,
      fechaFinal: this.formGroup.value.FechaFinal
    };
    this.getPedidos.emit(getPedidos);
  }
  getAlmacenes = () => {
    // console.log('get sucursales seleccionadas', this.SucursalesSeleccionadas);
    // this.getInputValue('Almacen').disable();
    // this.progressAlmacen = true;
    this.abcService.getAlmacenes([{Id: '-1', Nombre: 'Todos'}])
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((err) => {
          this.progressAlmacen = false;
          return this.handleError(err);
        })
      )
      .subscribe((almacen: DataAlmacenes) => {
        const almacenesFilter = almacen.almacenes.filter(res  =>  res.AlmacenID !== '-1');
        this.filtroAlmacen = almacenesFilter.map((res) => {
            return {
              Id: res.AlmacenID,
              Nombre: res.Nombre,
              Sucursal: res.Sucursal
          };
        });
        this.filtroAlmacenAux = this.filtroAlmacen;
        // this.getInputValue('Almacen').enable();
        // this.progressAlmacen = false;
      });
  }
  private filterAlmacen = () => {
    if (this.SucursalesSeleccionadas.length > 0) {
      let row: any[];
      // console.log(this.SucursalesSeleccionadas);
      const almacenAux = [];
      this.SucursalesSeleccionadas.forEach((item: SelectedItems) => {
        row = this.filtroAlmacen.filter(res => res.Sucursal === item.Id);
        Array.from(row).forEach(element => {
          almacenAux.push(element);
          });
      });
      // console.log(almacenAux);
      this.filtroAlmacenAux  = null;
      this.filtroAlmacenAux  = almacenAux;
      // console.log('***', this.filtroAlmacenAux , '*****', almacenAux);
    }
  }
  public getInputValue(control: string) {
    return this.formGroup.get(control);
  }
  public today = (): Date => {
    return new Date();
  }
  public itemSelectedFilter(event: {
    donde: string;
    columnas: SelectedItems[];
  }) {
    if (event.donde === 'Sucursal') {
      this.SucursalesSeleccionadas = null;
      this.SucursalesSeleccionadas = event.columnas;
      // console.log( this.SucursalesSeleccionadas);
      let value = '';
      if (this.SucursalesSeleccionadas) {
        if (this.SucursalesSeleccionadas.length > 0) {
          value = this.SucursalesSeleccionadas[0].Nombre || '';
          if ((this.SucursalesSeleccionadas.length || 0) > 1) {
            value +=
              ' (+' +
              (this.SucursalesSeleccionadas.length - 1) +
              ' ' +
              (this.SucursalesSeleccionadas.length === 2
                ? 'Sucursal'
                : 'Sucursales') +
              ')';
          }
          // this.menuSucursal.closeMenu();
          this.AlmacenesSeleccionados = null;
          this.getInputValue('Almacen').setValue('');
          this.filterAlmacen();
          // this.getAlmacenes(); // optiene todos los almacenes.
        } else { // SI NO SELECCIONO ALGUNA SUCURSAL LIMPIAMOS LOS ALMACENES
          this.AlmacenesSeleccionados = null;
          this.getInputValue('Almacen').setValue('');
        }
      }
      this.getInputValue('Sucursal').setValue(value);
    } else if (event.donde === 'Almacen') {
      this.AlmacenesSeleccionados = null;
      this.AlmacenesSeleccionados = event.columnas;
      let value = '';
      if (this.AlmacenesSeleccionados) {
        if (this.AlmacenesSeleccionados.length > 0) {
          value = this.AlmacenesSeleccionados[0].Nombre || '';
          if ((this.AlmacenesSeleccionados.length || 0) > 1) {
            value +=
              ' (+' +
              (this.AlmacenesSeleccionados.length - 1) +
              ' ' +
              (this.AlmacenesSeleccionados.length === 2
                ? 'Almacen'
                : 'Almacenes') +
              ')';
          }
          // this.menuAlmacen.closeMenu();
        }
      }
      this.getInputValue('Almacen').setValue(value);
    } else if (event.donde === 'Pedido') {
      this.PedidosSeleccionados  = null ;
      this.PedidosSeleccionados = event.columnas;
      let value = '';
      if (this.PedidosSeleccionados) {
        if (this.PedidosSeleccionados.length > 0) {
          value = this.PedidosSeleccionados[0].Nombre || '';
          if ((this.PedidosSeleccionados.length || 0) > 1) {
            value +=
              ' (+' +
              (this.PedidosSeleccionados.length - 1) +
              ' ' +
              (this.PedidosSeleccionados.length === 2
                ? 'Tipo Pedido'
                : 'Tipos Pedido') +
              ')';
          }
          // this.menuPedido.closeMenu();
        }
      }
      this.getInputValue('TipoPedido').setValue(value);
    }
  }
  private handleError = (error: any) => {
    Swal.fire(
      'Multi Inventario Pedidos',
      'Lo sentimos, ocurrio un error de comunicación.<br/><br/>Error: ' +
        error.message,
      'error'
    );
    return throwError(error);
  }
  public menuClosed() {
    console.log('menu closed');
  }
}
