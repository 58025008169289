import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlmacenService } from '../../../services/Almacen/almacen.service';
import Swal from 'sweetalert2';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Datum } from '../model/Almacen';

@Component({
  templateUrl: './dialog-remitente-add.component.html',
  styleUrls: ['./dialog-remitente-add.component.scss'],
})
export class DialogRemitenteAddComponent implements OnInit {
  public formAddRemitente: FormGroup;
  public spinner: boolean = false;
  public colonia: any;
  public buscaCp: boolean = false;
  public buscaInformacion: boolean = false;
  public listaDestinatarios :Datum[];
  public tipo = ['Remitente', 'Destinatario'];
  constructor(
    private fb: FormBuilder,
    private almacenService: AlmacenService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<DialogRemitenteAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.form();
  }
  public form = () => {
    this.formAddRemitente = this.fb.group({
      clienteId: [''],
      tipo: ['', [Validators.required]],
      nombre: ['', [Validators.required]],
      calle: ['', [Validators.required]],
      telefono: ['', [Validators.required]],
      cp: [
        '',
        [Validators.required, Validators.maxLength(5), Validators.minLength(5)],
      ],
      estado: ['', [Validators.required]],
      ciudad: ['', [Validators.required]],
      colonia: ['', [Validators.required]],
      correo: ['', [Validators.required, Validators.email]],
      numero: ['', [Validators.required]],
    });
    if (this.data.opcion === 2) {
      this.setDatum();
    }
  };
  public addRemitente = () => {
    this.setItemFormDisable('estado', 2);
    this.setItemFormDisable('ciudad', 2);
    this.spinner = true;
    this.almacenService
      .setRemitentes(this.formAddRemitente.value)
      .then((res) => {
        this.spinner = false;
        if (res.error === false) {
          this.dialogRef.close(true);
        }
      })
      .catch((err: any) => {
        this.spinner = false;
        Swal.fire({
          type: 'warning',
          title: 'Advertencia',
          text:
            'Consulte al administrador incidencias@kabik.com.mx. error:  ' +
            err.message,
        });
      });
  };
  public updateRemitente = () => {
    this.setItemFormDisable('estado', 2);
    this.setItemFormDisable('ciudad', 2);
    this.spinner = true;
    this.almacenService
      .updateRemitentes(this.formAddRemitente.value, this.data.datum[0].id)
      .then((res) => {
        this.spinner = false;
        if (res.error === false) {
          this.dialogRef.close(true);
        }
      })
      .catch((err: any) => {
        this.spinner = false;
        Swal.fire({
          type: 'warning',
          title: 'Advertencia',
          text:
            'Consulte al administrador incidencias@kabik.com.mx. error:  ' +
            err.message,
        });
      });
  };
  public getCodigoPostal = () => {
    this.buscaCp = true;
    this.almacenService
      .getDireccion(this.getItemForm('cp'))
      .then((res) => {
        this.buscaCp = false;
        if (res.error === false) {
          this.setItemForm('estado', res.estado);
          this.setItemForm(
            'ciudad',
            res.ciudad === null ? res.municipio : res.ciudad
          );
          this.colonia = res.asentamiento;
          this.setItemFormDisable('estado', 1);
          this.setItemFormDisable('ciudad', 1);
        } else {
          this.setItemFormDisable('estado', 2);
          this.setItemFormDisable('ciudad', 2);
          this.setItemForm('estado', '');
          this.setItemForm('ciudad', '');
          this.openSnackBar('El codigo postal es invalido o no existe.');
        }
      })
      .catch((err: any) => {
        this.buscaCp = false;
        Swal.fire({
          type: 'warning',
          title: 'Advertencia',
          text:
            'Consulte al administrador incidencias@kabik.com.mx. error:' +
            err.message,
        });
      });
  };

  public getInformacionDestinario = () => {
    if (
      this.getItemForm('clienteId').length > 0 &&
      this.getItemForm('clienteId') != ''
    ) {
      this.buscaInformacion = true;
      this.almacenService
        .getRemitentes(6, this.getItemForm('clienteId'))
        .then((res) => {
          console.log(res);
          this.buscaInformacion = false;
          if (res.error === false) {
             this.listaDestinatarios = res.data;
             this.setDestinatario();
          }
        })
        .catch((err: any) => {
          this.buscaInformacion = false;
          console.error(err);
          Swal.fire({
            type: 'warning',
            title: 'Advertencia',
            text:
              'Favor de contactar a incidencias@letech.mx Advertencia : ' +
              err.error.error.message,
          });
        });
    }
  };

  public setDestinatario() {
    // console.log(this.data.datum[0].id);
    //this.formAddRemitente.get('tipo').setValue(this.data.datum[0].tipo);

    console.log('Lista de destinatarios');
    console.log(this.listaDestinatarios);
    this.formAddRemitente.get('nombre').setValue(this.listaDestinatarios[0].nombre);
    this.formAddRemitente.get('calle').setValue(this.listaDestinatarios[0].calle);
    this.formAddRemitente.get('telefono').setValue(this.listaDestinatarios[0].telefono);
    this.formAddRemitente.get('cp').setValue(this.listaDestinatarios[0].cp);
    this.formAddRemitente.get('estado').setValue(this.listaDestinatarios[0].estado);
    this.formAddRemitente.get('ciudad').setValue(this.listaDestinatarios[0].ciudad);
    this.formAddRemitente.get('colonia').setValue(this.listaDestinatarios[0].colonia);
    this.formAddRemitente.get('correo').setValue(this.listaDestinatarios[0].correo);
    this.formAddRemitente.get('numero').setValue(this.listaDestinatarios[0].numero);
  }

  public setDatum() {
    // console.log(this.data.datum[0].id);
    this.formAddRemitente.get('tipo').setValue(this.data.datum[0].tipo);
    this.formAddRemitente.get('nombre').setValue(this.data.datum[0].nombre);
    this.formAddRemitente.get('calle').setValue(this.data.datum[0].calle);
    this.formAddRemitente.get('telefono').setValue(this.data.datum[0].telefono);
    this.formAddRemitente.get('cp').setValue(this.data.datum[0].cp);
    this.formAddRemitente.get('estado').setValue(this.data.datum[0].estado);
    this.formAddRemitente.get('ciudad').setValue(this.data.datum[0].ciudad);
    this.formAddRemitente.get('colonia').setValue(this.data.datum[0].colonia);
    this.formAddRemitente.get('correo').setValue(this.data.datum[0].correo);
    this.formAddRemitente.get('numero').setValue(this.data.datum[0].numero);
  }

  public setItemFormDisable = (getValue, option: number) => {
    if (option === 1) {
      this.formAddRemitente.get(getValue).disable();
    } else {
      this.formAddRemitente.get(getValue).enable();
    }
  };
  public getItemForm = (getValue) => {
    return this.formAddRemitente.get(getValue).value;
  };
  public setItemForm = (input, value) => {
    this.formAddRemitente.get(input).setValue(value);
  };
  public validaTipo = () => {
    return this.getItemForm('tipo') === 'Destinatario' ? true : false;
  };
  public openSnackBar(mensaje: string) {
    this.snackBar.open(mensaje, 'Ok', {
      duration: 2200,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
  // public form = () => {
  //   this.formAddRemitente = this.fb.group({
  //     clienteId: [''],
  //     tipo: ['', [Validators.required]],
  //     nombre: ['', [Validators.required]],
  //     calle: ['', [Validators.required]],
  //     telefono: ['', [Validators.required]],
  //     cp: [
  //       '',
  //       [Validators.required, Validators.maxLength(5), Validators.minLength(5)],
  //     ],
  //     estado: ['', [Validators.required]],
  //     ciudad: ['', [Validators.required]],
  //     colonia: ['', [Validators.required]],
  //     correo: ['', [Validators.required, Validators.email]],
  //     numero: ['', [Validators.required]],

  //   });
  //   if (this.data.opcion === 2) {
  //     this.setDatum();
  //   }
  // };
  // public addRemitente = () => {
  //   console.log(this.formAddRemitente.value);
  //   this.setItemFormDisable('estado', 2);
  //   this.setItemFormDisable('ciudad', 2);
  //   this.spinner = true;
  //   this.almacenService
  //     .setRemitentes(this.formAddRemitente.value)
  //     .then((res) => {
  //       this.spinner = false;
  //       if (res.error === false) {
  //         this.dialogRef.close(true);
  //       }
  //     })
  //     .catch((err: any) => {
  //       this.spinner = false;
  //       Swal.fire({
  //         type: 'warning',
  //         title: 'Advertencia',
  //         text:
  //           'Consulte al administrador incidencias@kabik.com.mx. error:  ' +
  //           err.message,
  //       });
  //     });
  // };
  // public updateRemitente = () => {
  //   this.setItemFormDisable('estado', 2);
  //   this.setItemFormDisable('ciudad', 2);
  //   this.spinner = true;
  //   this.almacenService
  //     .updateRemitentes(this.formAddRemitente.value, this.data.datum[0].id)
  //     .then((res) => {
  //       this.spinner = false;
  //       if (res.error === false) {
  //         this.dialogRef.close(true);
  //       }
  //     })
  //     .catch((err: any) => {
  //       this.spinner = false;
  //       Swal.fire({
  //         type: 'warning',
  //         title: 'Advertencia',
  //         text:
  //           'Consulte al administrador incidencias@kabik.com.mx. error:  ' +
  //           err.message,
  //       });
  //     });
  // };
  // public getCodigoPostal = () => {
  //   this.buscaCp = true;
  //   this.almacenService
  //     .getDireccion(this.getItemForm('cp'))
  //     .then((res) => {
  //       this.buscaCp = false;
  //       if (res.error === false) {
  //         this.setItemForm('estado', res.estado);
  //         this.setItemForm(
  //           'ciudad',
  //           res.ciudad === null ? res.municipio : res.ciudad
  //         );
  //         this.colonia = res.asentamiento;
  //         this.setItemFormDisable('estado', 1);
  //         this.setItemFormDisable('ciudad', 1);
  //       } else {
  //         this.setItemFormDisable('estado', 2);
  //         this.setItemFormDisable('ciudad', 2);
  //         this.setItemForm('estado', '');
  //         this.setItemForm('ciudad', '');
  //         this.openSnackBar('El codigo postal es invalido o no existe.');
  //       }
  //     })
  //     .catch((err: any) => {
  //       this.buscaCp = false;
  //       Swal.fire({
  //         type: 'warning',
  //         title: 'Advertencia',
  //         text:
  //           'Consulte al administrador incidencias@kabik.com.mx. error:' +
  //           err.message,
  //       });
  //     });
  // };

  // public getInformacionDestinario = () => {
  //   if (
  //     this.getItemForm('clienteId').length > 0 &&
  //     this.getItemForm('clienteId') != ''
  //   ) {
  //     this.buscaInformacion = true;
  //     this.almacenService
  //       .getRemitentes(6, this.getItemForm('clienteId'))
  //       .then((res) => {
  //         console.log(res);
  //         this.buscaInformacion = false;
  //         if (res.error === false) {
  //            this.listaDestinatarios = res.data;
  //            this.setDestinatario();
  //         }
  //       })
  //       .catch((err: any) => {
  //         this.buscaInformacion = false;
  //         console.error(err);
  //         Swal.fire({
  //           type: 'warning',
  //           title: 'Advertencia',
  //           text:
  //             'Favor de contactar a incidencias@letech.mx Advertencia : ' +
  //             err.error.error.message,
  //         });
  //       });
  //   }
  // };

  // public validaTipo = () => {
  //   return this.getItemForm('tipo') === 'Destinatario' ? true : false;
  // };

  // public setDestinatario() {
  //   // console.log(this.data.datum[0].id);
  //   //this.formAddRemitente.get('tipo').setValue(this.data.datum[0].tipo);

  //   console.log('Lista de destinatarios');
  //   console.log(this.listaDestinatarios);
  //   this.formAddRemitente.get('nombre').setValue(this.listaDestinatarios[0].nombre);
  //   this.formAddRemitente.get('calle').setValue(this.listaDestinatarios[0].calle);
  //   this.formAddRemitente.get('telefono').setValue(this.listaDestinatarios[0].telefono);
  //   this.formAddRemitente.get('cp').setValue(this.listaDestinatarios[0].cp);
  //   this.formAddRemitente.get('estado').setValue(this.listaDestinatarios[0].estado);
  //   this.formAddRemitente.get('ciudad').setValue(this.listaDestinatarios[0].ciudad);
  //   this.formAddRemitente.get('colonia').setValue(this.listaDestinatarios[0].colonia);
  //   this.formAddRemitente.get('correo').setValue(this.listaDestinatarios[0].correo);
  //   this.formAddRemitente.get('numero').setValue(this.listaDestinatarios[0].numero);
  // }


  // public setDatum() {
  //   // console.log(this.data.datum[0].id);
  //   this.formAddRemitente.get('tipo').setValue(this.data.datum[0].tipo);
  //   this.formAddRemitente.get('nombre').setValue(this.data.datum[0].nombre);
  //   this.formAddRemitente.get('calle').setValue(this.data.datum[0].calle);
  //   this.formAddRemitente.get('telefono').setValue(this.data.datum[0].telefono);
  //   this.formAddRemitente.get('cp').setValue(this.data.datum[0].cp);
  //   this.formAddRemitente.get('estado').setValue(this.data.datum[0].estado);
  //   this.formAddRemitente.get('ciudad').setValue(this.data.datum[0].ciudad);
  //   this.formAddRemitente.get('colonia').setValue(this.data.datum[0].colonia);
  //   this.formAddRemitente.get('correo').setValue(this.data.datum[0].correo);
  //   this.formAddRemitente.get('numero').setValue(this.data.datum[0].numero);
  // }
  // public setItemFormDisable = (getValue, option: number) => {
  //   if (option === 1) {
  //     this.formAddRemitente.get(getValue).disable();
  //   } else {
  //     this.formAddRemitente.get(getValue).enable();
  //   }
  // };
  // public getItemForm = (getValue) => {
  //   return this.formAddRemitente.get(getValue).value;
  // };
  // public setItemForm = (input, value) => {
  //   this.formAddRemitente.get(input).setValue(value);
  // };
  // public openSnackBar(mensaje: string) {
  //   this.snackBar.open(mensaje, 'Ok', {
  //     duration: 2200,
  //     horizontalPosition: 'center',
  //     verticalPosition: 'top',
  //   });
  // }
}
