import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { UltimasPiezasService } from '../../../services/ultimas-piezas/ultimas-piezas.service';

@Component({
  selector: 'app-modal-ultimas-piezas',
  templateUrl: './modal-ultimas-piezas.component.html',
  styleUrls: ['./modal-ultimas-piezas.component.scss']
})

export class ModalUltimasPiezasComponent implements OnInit {
  public isModalEdit: boolean = false;
  public register = this.formBuilder.group({
    Id: '',
    ClaveArticulo: ['', [Validators.required]],
    MostrarBackOrder: false,
    MostrarUltimasPiezas: false
  });

  constructor(
    public matDialogRef: MatDialogRef<ModalUltimasPiezasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private ultimasPiezasService: UltimasPiezasService
  ) { }

  ngOnInit() {
    if (this.data != null) {
      this.isModalEdit = true;
      this.informacion(this.data);
    }
  }

  cerrarModal(): void {
    this.matDialogRef.close(false);
  }

  validate(event) {
    this.ultimasPiezasService.validate(event).subscribe((res) => {
      if (res.count <= 0) {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: 'La clave no existe o es incorrecta',
          confirmButtonText: 'Aceptar'
        }).then((res) => {
          if (res.value) {
            this.register.controls.ClaveArticulo.setValue('');
          }
        });
      }
    },
    (error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
        footer: 'Comunícate para resolver el problema',
      });
    });
  }

  onSubmit() {
    if (this.register.value.ClaveArticulo == '') {
      Swal.fire({
        type: 'warning',
        title: 'Faltan campos por completar',
        text: 'Por favor, escribe la clave del artículo',
        allowOutsideClick: false,
      });
      return;
    }
    
    if (this.isModalEdit) {
      this.update();
    } else {
      this.insert();
    }
  }

  public informacion(data: any) {
    this.register.patchValue(
      {
        Id: data[0].Id,
        ClaveArticulo: data[0].ClaveArticulo,
        MostrarBackOrder: data[0].MostrarBackOrder == 1 ? true : false,
        MostrarUltimasPiezas: data[0].MostrarUltimasPiezas == 1 ? true : false
      }
    );
  }

  insert() {
    Swal.fire({
      title: 'Guardando...',
      text: 'Por favor espere',
      allowOutsideClick: false,
    });
    Swal.showLoading();
    let ClaveArticulo: string = this.register.value.ClaveArticulo;
    this.ultimasPiezasService.insert(ClaveArticulo.trim(), this.register.value.MostrarBackOrder ? 1 : 0, this.register.value.MostrarUltimasPiezas ? 1 : 0).then((res) => {
      if (res.message == 'true') {
        Swal.fire({
          type: 'success',
          title: '¡Correcto!',
          text: 'Se ha guardado correctamente',
          allowOutsideClick: false,
          confirmButtonText: 'Aceptar'
        }).then((res) => {
          if (res.value) {
            this.matDialogRef.close(true);
          }
        });
      } else {
        throw new Error('Hubo un error al eliminar en la base de datos, contacte al administrador');
      }
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message
      });
    });
  }

  public update() {
    Swal.fire({
      title: 'Actualizando...',
      text: 'Por favor espere',
      allowOutsideClick: false,
    });
    Swal.showLoading();
    this.ultimasPiezasService.update(this.register.value.Id, this.register.value.MostrarBackOrder ? 1 : 0, this.register.value.MostrarUltimasPiezas ? 1 : 0).subscribe(res => {
      if (res.message == 'true') {
        Swal.fire({
          type: 'success',
          title: '¡Correcto!',
          text: 'Se ha editado correctamente',
          allowOutsideClick: false,
          confirmButtonText: 'Aceptar'
        }).then((res) => {
          if (res.value) {
            this.matDialogRef.close(true);
          }
        });
      } else {
        throw new Error('Hubo un error al eliminar en la base de datos, contacte al administrador');
      }
    },
    (error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
        footer: 'Comunícate para resolver el problema',
      });
    });
  }
}