import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { ArticulosWebService } from './service/articulos-web.service';
import { ListaArticulos } from './model/ListaArticulos';
import { DialogAddComponent } from './dialog-add/dialog-add.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-articulos-web',
  templateUrl: './articulos-web.component.html',
  styleUrls: ['./articulos-web.component.scss']
})
export class ArticulosWebComponent implements OnInit {
  displayedColumns: string[] = [
    'select',
    'ArticuloID',
    'ClaveArticulo',
    'Descripcion',
    'Quien',
    'Cuando',
    'Estado'
  ];

  public loadRefrech: boolean;
  public loadUpdate: boolean;
  public loadDelete: boolean;
  dataSource: MatTableDataSource<ListaArticulos>;
  // selection = new SelectionModel<ListaArticulos>(true, []);
  selection: SelectionModel<ListaArticulos>;
  public listArticulos: ListaArticulos[] = [];
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  constructor(
    private service: ArticulosWebService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    // console.log(this.selection);
    this.service.getListArticles().subscribe(data => {
      // console.log(data);
      this.dataSource = new MatTableDataSource(data);
      this.selection = new SelectionModel<ListaArticulos>(true, []);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  public updateAll(): void {
    this.loadUpdate = true;
    const mensaje = this.listArticulos.length > 1 ? 'Las claves se actualizarón correctamente' : 'La clave se actualizo correctamente';
    this.service.updateAll(this.listArticulos).subscribe(res => {
      if (res.estatus) {
        this.openSnackBar(mensaje);
        this.refreshClaves();
      }
      this.loadUpdate = false;
    });
  }
  public deleteListArticles(): void {
    this.loadDelete = true;
    const mensaje = this.listArticulos.length > 1 ? 'Las claves se eliminaron correctamente' : 'La clave se elimino correctamente';
    this.service.deleteListArticles(this.listArticulos).subscribe(res => {
      if (res.estatus) {
        this.openSnackBar(mensaje);
        this.refreshClaves();
      }
      this.loadDelete = false;
    });
  }
  public selected(event?: any, row?: ListaArticulos) {
    // console.log(event.checked);
    if (event.checked) {
      this.listArticulos = [...this.listArticulos, row];
    } else {
      this.removeToArray(row);
    }
    event = event ? this.selection.toggle(row) : null;
    return event;
  }
  private removeToArray(row: ListaArticulos): void {
    let contador = 0;
    this.listArticulos.forEach(articulos => {
      if (articulos.Id === row.Id) {
        // console.log(this.listArticulos.length);
        if (this.listArticulos.length <= 1) {
          this.listArticulos = [];
        } else {
          const cont = contador <= 1 ? 1 : contador;
          this.listArticulos.splice(contador, 1);
        }
      }
      contador++;
    });
  }
  masterToggle() {
    this.listArticulos = [];
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => {
        this.listArticulos = [...this.listArticulos, row];
        this.selection.select(row);
      });
    // console.log((this.listArticulos));

  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: ListaArticulos): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.Id + 1}`;
  }
  public refreshClaves(): void {
    this.loadRefrech = true;
    this.service.getListArticles().subscribe(data => {
      this.dataSource.data = data;
      this.selection = new SelectionModel<ListaArticulos>(true, []);
      this.loadRefrech = false;
      this.listArticulos = [];
    });
  }
  openSnackBar(mensaje: string) {
    this.snackBar.open(mensaje, 'Ok', {
      duration: 2200,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
    });
  }
  public openDialogAddNew(): void {
    const dialogRef = this.dialog.open(DialogAddComponent, {
      disableClose: true,
      data: { opcion: 1 },

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.openSnackBar('La clave se agrego correctamente');
        this.refreshClaves();
      }
    });
  }
}
