import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

export interface Licencias {
  Nombre: string;
  Departamento: string;
  StartDate: Date;
  SessionID: string;
}

export interface Data {
  message: string | object;
  response: Licencias[];
}

export interface Response {
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class LicenciasZafiroService {

  constructor(
    private http: HttpClient
  ) { }

  public show() {
    const data = {
      opcion: 'show'
    };
    return this.http.post<Data>(environment.apiUrl + 'admin/licencias_zafiro.php', data).pipe(map((data) => data)).toPromise();
  }

  public liberate() {
    const data = {
      opcion: 'liberate'
    };
    return this.http.post<any>(environment.apiUrl + 'admin/licencias_zafiro.php', data).pipe(map((data) => data)).toPromise();
  }
}
