import { Component, OnInit, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ClientesService } from '../../../services/clientes.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-clientes-nuevos',
  templateUrl: './clientes-nuevos.component.html',
  styleUrls: ['./clientes-nuevos.component.scss']
})
export class ClientesNuevosComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private clientesService: ClientesService, private fb: FormBuilder, private _adapter: DateAdapter<any>) {
    this._adapter.setLocale('mx');
  }

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  public displayedColumns: string[] = [];
  public formClientesNuevos: FormGroup;
  public data: any;
  public maxDate: Date;
  ngOnInit() {
    this.validaForm();
    this.fecha();
    this.getClientesNuevos();

  }
  validaForm() {
    this.formClientesNuevos = this.fb.group({
      fechaInicio: [''],
      fechaFin: [''],
    });
  }
  fecha() {
    this.maxDate = new Date();
    const date = new Date();
    this.formClientesNuevos.get('fechaInicio').setValue(new Date().toISOString());
    this.formClientesNuevos
      .get('fechaFin')
      .setValue(new Date().toISOString()); //date.getFullYear(), date.getMonth() - 1, 1 new Date().toISOString()
  }

  public getClientesNuevos() {
    const fechaInicio = new Date(this.formClientesNuevos.get('fechaInicio').value).toISOString().slice(0, 10) //this.formCanjesKabiCoin.get('FechaInicio').value;
    const fechaFin = new Date(this.formClientesNuevos.get('fechaFin').value).toISOString().slice(0, 10);
    if (fechaInicio > fechaFin) {
      Swal.fire({
        type: 'warning',
        title: 'Fechas incorrectas',
        text: 'La fecha inicio no puede ser mayor a la fecha final',
        confirmButtonText: 'Aceptar'
      });
      return;
    }
    this.spinner.show('spinner-reporte-clientes-nuevos');
    this.clientesService.getClientesNuevos(fechaInicio, fechaFin).subscribe(({
      next: (value: any) => {
        // console.log('llego aqui');
        this.spinner.hide('spinner-reporte-clientes-nuevos');
        if (value.error === false) {
          this.displayedColumns = value.columnas === null ? [] : value.columnas;
          this.dataSource = new MatTableDataSource(value.data);
          this.data = value.data;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          // console.log('llego aquicccccc');
        } else {
          this.spinner.hide('spinner-reporte-clientes-nuevos');
          this.dataSource = null;
          // console.log('llego aquixxxxxxxxxx');
        }
      }
    }))


  }

  public exportToExcel() {
    this.spinner.show('spinner-reporte-clientes-nuevos');
    this.clientesService.getExcelAbc(this.displayedColumns, this.data).then((res) => {
      this.spinner.hide('spinner-reporte-clientes-nuevos');
    }).catch((err: any) => {
      this.spinner.hide('spinner-reporte-clientes-nuevos');
      Swal.fire({
        type: 'warning',
        title: 'Advertencia',
        text: err.message,
      });
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
