import { Pipe, PipeTransform } from '@angular/core';
import { compareAsc, format } from 'date-fns';
import { es } from 'date-fns/locale';
@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(value: string, text?: boolean): any {
    if (text) {
      if (text === true) {
        return format(new Date(value),
          'dd \'de\' MMMM \'del\' yyyy', {
          locale: es
        });
      } else if (text === 'month') {
        return format(new Date(value),
          'MMMM \'del\' yyyy', {
          locale: es
        });
      }

    } else {
      return format(new Date(value),
        'iiii dd \'de\' MMMM \'del\' yyyy', {
        locale: es
      });
    }
  }

}
