import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';

const dbConfig: DBConfig  = {
  name: 'adminDB',
  version: 1,
  objectStoresMeta: [{
    store: 'oferta',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'titulo', keypath: 'titulo', options: { unique: false } },
      { name: 'clave', keypath: 'clave', options: { unique: false } },
      { name: 'descripcion', keypath: 'descripcion', options: { unique: false } },
      { name: 'estado', keypath: 'estado', options: { unique: false } }
    ]
  }, {
    store: 'singleArticulo',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'name', keypath: 'name', options: { unique: true } },
      { name: 'name', keypath: 'name', options: { unique: true } },
    ]
  }]
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxIndexedDBModule.forRoot(dbConfig)
  ], exports: [
    NgxIndexedDBModule
  ]
})
export class IndexedDbModule { }
