import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import Swal from 'sweetalert2';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalPeriodoInventarioComponent } from './modal-periodo-inventario/modal-periodo-inventario.component';
import { PeriodoInventarioService } from '../../../services/inventario/periodo/periodo-inventario.service';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-periodo-inventario',
  templateUrl: './periodo-inventario.component.html',
  styleUrls: ['./periodo-inventario.component.scss']
})

export class PeriodoInventarioComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  matTableDataSource: MatTableDataSource<any>;
  selectionModel: SelectionModel<any>;
  public formData = new FormData();
  public data = [];
  public periodo: any[] = [];
  public columns: string[] = [];
  displayedColumns: string[] = [
    'accion',
    'SucursalID',
    'AlmacenID',
    'DepartamentoID',
    'ClavePeriodo',
    'FechaApertura',
    'FechaInicio',
    'FechaFinal',
    'Quien',
    'Cuando',
    'ActivoPeriodo'
  ];

  constructor(
    private periodoInventarioService: PeriodoInventarioService,
    private _adapter: DateAdapter<any>,
    private spinner: NgxSpinnerService,
    public matDialogNew: MatDialog,
    public matDialogUpdate: MatDialog,
    public matDialogView: MatDialog,
  ) {
    this._adapter.setLocale('mx');
    this.show();
  }

  ngOnInit() {
    this.show();
  }

  exportToExcel(): void {
    this.spinner.show('spinner');
    this.periodoInventarioService.exportToExcel(this.data ,this.columns).then((res) => {
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
      });
      this.spinner.hide('spinner');
    });
  }

  exportFilterToExcel = () => {
    this.spinner.show('spinner');
    let data = this.matTableDataSource.filteredData;
    this.periodoInventarioService.exportToExcel(data, this.columns).then((res) => {
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
      });
      this.spinner.hide('spinner');
    });
  }

  filter(event: Event) {
    const filter = (event.target as HTMLInputElement).value;
    this.matTableDataSource.filter = filter.trim().toLowerCase();
    if (this.matTableDataSource.paginator) {
      this.matTableDataSource.paginator.firstPage();
    }
  }

  show() {
    this.spinner.show('spinner');
    this.periodoInventarioService.show().then((res) => {
      if (res.message === 'true') {
        this.matTableDataSource = new MatTableDataSource(res.response);
        this.selectionModel = new SelectionModel<any>(true, []);
        this.columns = res.columns;
        this.data = res.response;
        this.matTableDataSource.sort = this.sort;
        this.matTableDataSource.paginator = this.paginator;
      }
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message
      });
      this.spinner.hide('spinner');
    });
  }

  delete() {
    Swal.fire({
      type: 'warning',
      title: '¿Está seguro de eliminar?',
      text: 'Esta operación no se puede revertir, ¿desea continuar?',
      confirmButtonText: 'Aceptar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#D33',
      focusConfirm: false,
      focusCancel: true,
    }).then((res) => {
      if (res.value) {
        if (this.periodo) {
          Swal.fire({
            title: 'Eliminando...',
            text: 'Por favor espere',
            allowOutsideClick: false,
          });
          Swal.showLoading();
          this.periodoInventarioService.delete(this.periodo).subscribe((res) => {
            if (res.message == 'true') {
              Swal.fire({
                type: 'success',
                title: 'Correcto',
                text: 'Se ha eliminado correctamente',
                footer: 'Excelente',
                allowOutsideClick: false,
                confirmButtonText: 'Aceptar'
              }).then((res) => {
                if (res.value) {
                  this.periodo.splice(0, this.periodo.length);
                  this.show();
                }
              });
            } else {
              throw new Error('Hubo un error al eliminar en la base de datos, contacte al administrador');
            }
          },
          (error) => {
            Swal.fire({
              type: 'error',
              title: 'Error',
              text: error.message,
              footer: 'Comunícate para resolver el problema',
            });
          });
        }
      }
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
        footer: 'Comunícate para resolver el problema',
      });
    });
  }

  public openModalNew(): void {
    const modal = this.matDialogNew.open(ModalPeriodoInventarioComponent, {
      width: '45%',
      disableClose: true,
    });
    modal.afterClosed().subscribe((res) => {
      if (res) {
        this.periodo.splice(0, this.periodo.length);
        this.show();
      }
    });
  }

  public openModalUpdate() {
    const modal = this.matDialogUpdate.open(ModalPeriodoInventarioComponent, {
      width: '45%',
      data: this.periodo[0],
      disableClose: true,
    });
    modal.afterClosed().subscribe((res) => {
      if (res) {
        this.periodo.splice(0, this.periodo.length);
        this.show();
      }
    });
  }

  selected(event?: any, row?: any) {
    if (event.checked) {
      this.periodo.push(row);
    } else {
      this.removeToArray(row);
    }
    event = event ? this.selectionModel.toggle(row) : null;
    return event;
  }
  
  private removeToArray(row: any): void {
    let count = 0;
    this.periodo.forEach(res => {
      if (res.PeriodoID === row.PeriodoID) {
        if (this.periodo.length <= 1) {
          this.periodo = [];
        } else {
          const result = count <= 1 ? 1 : count;
          this.periodo.splice(count, 1);
        }
      }
      count++;
    });
  }

  isAllSelected() {
    const numSelected = this.selectionModel.selected.length;
    const numRows = this.matTableDataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.periodo = [];
    this.isAllSelected() ? this.selectionModel.clear() : this.matTableDataSource.data.forEach(res => {
      this.periodo.push(res);
      this.selectionModel.select(res);
    });
  }
}