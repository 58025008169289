import { KbkHeaderTable } from './../../../../../core/components/kbk-grid/kbk-grid';
import { HttpErrorResponse } from '@angular/common/http';
import { GenericService } from './../../../../../services/generic.service';
import { environment } from './../../../../../../environments/environment';
import { PeriodoInventarioService } from './../../../../../services/inventario/periodo/periodo-inventario.service';
import { DataVentaMarcas } from './../../../../ABC/utils/models/VentasMarcas';
import { FiltrosComponent } from './../../../../../core/components/filtros/filtros.component';
import { ABCService } from './../../../../ABC/utils/services/ABC.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import * as XLSX from 'xlsx';
import { Matrix } from '../model/matrix';
import { Column } from '../model/column';
import { ChangeNotificationService } from './change-notification.service';
import { ActivatedRoute } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  Cliente,
  DataClientes,
  DataDepartamento,
  DataGrupo,
  DataMarcas,
  DataSucursales,
  DataVendedores,
  Departamento,
  Grupos,
  marcas,
  sucursal,
  vendendores,
} from './../../../../../views/ABC/utils/models/ABC';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-pivot',
  templateUrl: './pivot.component.html',
  styleUrls: ['./pivot.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class PivotComponent implements OnInit {
  public selectedTab: number = 0;
  columns: any[] = [];
  selectedColumns: any[] = [];
  columnsToRemoveFromData: any[] = [];
  outputData: {}[] = [];
  originalOutputData: {}[] = [];
  selectedRows: [] = [];
  filteredRows: {}[] = [];

  public formFiltros: FormGroup;
  public clientesList: Cliente[] = [];
  public clientesListSelected: Cliente[] = [];
  public gruposList: Grupos[] = [];
  public gruposListFiltrado: Grupos[] = [];
  public gruposSelected: Grupos[] = [];
  public depatamentosList: Departamento[] = [];
  public depatamentosSelected: Departamento[] = [];
  public vendedoresList: vendendores[] = [];
  public vendedoresListSelected: vendendores[] = [];
  public sucursales: sucursal[] = [];
  public sucursales$: Observable<sucursal[]>;
  public marcasList: marcas[] = [];
  public sucursalesList: sucursal[] = [];
  public marcasSelected: marcas[] = [];
  public sucursalesSelected: sucursal[] = [];
  public almacenesSelected: any[] = [];

  public almacenesTmp = [];
  public almacenes = [];

  public dataVentaMarcas: DataVentaMarcas;
  public fechaFinal: Date;
  public maxDate: Date;
  public listReportes: any = [
    {
      value: 1,
      viewValue: 'Detallado (Departamento, Grupo, Cliente)',
    },
    {
      value: 2,
      viewValue: 'Resumen por Departamento',
    },
    {
      value: 3,
      viewValue: 'Resumen por Departamento y Grupo',
    },
  ];

  filterMatchModes = [
    { label: 'contains', value: 'contains' },
    { label: 'starts with', value: 'startsWith' },
    { label: 'ends with', value: 'endsWith' },
    { label: 'equals', value: 'equals' },
    { label: "doesn't equal", value: 'notEquals' },
    { label: 'less than', value: 'lt' },
    { label: 'greater than', value: 'gt' },
  ];

  booleanDropdownValues = [
    { label: 'all', value: undefined },
    { label: 'true', value: true },
    { label: 'false', value: false },
  ];

  public datos: any = [
    {
      checked: false,
      label: 'Existencias',
      tag: 'existencias',
    },
    {
      checked: false,
      label: 'ID Artículo',
      tag: 'articuloID',
    },
    {
      checked: false,
      label: 'Costo',
      tag: 'costo',
    },
  ];

  public clientes: any = [
    {
      checked: false,
      label: 'Descripción',
      tag: 'descripcion',
    },
    {
      checked: false,
      label: 'Artículo',
      tag: 'articulo',
    },
    {
      checked: false,
      label: 'Departamento',
      tag: 'departamento',
    },
    {
      checked: false,
      label: 'Grupo',
      tag: 'grupo',
    },
    {
      checked: false,
      label: 'Marca',
      tag: 'marca',
    },
    {
      checked: false,
      label: 'Cantidad',
      tag: 'cantidad',
    },
    {
      checked: false,
      label: 'Nombre Marca',
      tag: 'nombreMarca',
    },
    {
      checked: false,
      label: 'Clave Artículo Proveedor',
      tag: 'claveProveedor',
    },
  ];

  public articulos: any = [
    {
      checked: false,
      label: 'Cliente',
      tag: 'clienteID',
    },
    {
      checked: false,
      label: 'Nombre',
      tag: 'nombre',
    },
    {
      checked: false,
      label: 'LADA Nacional',
      tag: 'lada',
    },
    {
      checked: false,
      label: 'Teléfono 1',
      tag: 'telefono1',
    },
    {
      checked: false,
      label: 'Teléfono 2',
      tag: 'telefono2',
    },
    {
      checked: false,
      label: 'Celular',
      tag: 'celular',
    },
    {
      checked: false,
      label: 'Email',
      tag: 'email',
    },
  ];

  limitElements: number | undefined;

  displayShareDialog = false;
  urlForSharing: string | undefined;

  private _emailPattern =
    /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  private _httpUrlPattern = /^http[s]{0,1}:/;

  private readonly _pivotUIRows = 'pivotUIRows';
  private readonly _pivotUICols = 'pivotUICols';
  private readonly _pivotUIVals = 'pivotUIVals';
  private readonly _pivotUIRendererName = 'pivotUIRendererName';
  private readonly _pivotUIAggregatorName = 'pivotUIAggregatorName';

  public loader: boolean = false;
  public progressAlmacen: boolean = false;

  public dataSend: any = {
    dateInit: null,
    dateEnd: null,
    option: 'GET_RepAnalisisVenta',
    empresaID: environment.empresaId,
    selectAllSucursales: false,
    sucursales: [],
    datosGenerales: {
      articuloID: 0,
      costo: 0,
      existencias: 0,
    },
    datosClientes: {
      clienteID: 0,
      nombre: 0,
      lada: 0,
      telefono1: 0,
      telefono2: 0,
      celular: 0,
      email: 0,
    },
    datosArticulos: {
      descripcion: 0,
      articulo: 0,
      departamento: 0,
      grupo: 0,
      marca: 0,
      cantidad: 0,
      nombreMarca: 0,
      claveProveedor: 0,
    },
  };

  public listaArray: any = [];

  public customHeaders: KbkHeaderTable[] = [
    {
      tag: 'AlmacenID',
      label: 'ID Almacén',
      type: 'text',
    },
    {
      tag: 'AlmacenNombre',
      label: 'Nombre Almacén',
      type: 'text',
    },
    {
      tag: 'Cancelada',
      label: 'Cancelada',
      type: 'boolean',
    },
    {
      tag: 'ClaveArticulo',
      label: 'Clave Artículo',
      type: 'text',
    },
    {
      tag: 'ClienteID',
      label: 'ID Cliente',
      type: 'number',
    },
    {
      tag: 'ClienteNombre',
      label: 'Nombre de Cliente',
      type: 'text',
    },
    {
      tag: 'Costo',
      label: 'Costo',
      type: 'text',
    },
    {
      tag: 'CostoFecha',
      label: 'Fecha de Costo',
      type: 'text',
    },
    {
      tag: 'Departamento',
      label: 'Departamento',
      type: 'text',
    },
    {
      tag: 'DescripcionArticulo',
      label: 'Descripción dle Artículo',
      type: 'text',
    },
    {
      tag: 'HaFacturado',
      label: 'Faturado',
      type: 'text',
    },
    {
      tag: 'EmpresaID',
      label: 'ID Empresa',
      type: 'number',
    },
    {
      tag: 'EmpresaNombre',
      label: 'Empresa',
      type: 'text',
    },
    {
      tag: 'Factura',
      label: 'Factura',
      type: 'text',
    },
    {
      tag: 'FacturaID',
      label: 'ID Factura',
      type: 'text',
    },
    {
      tag: 'Fecha',
      label: 'Fecha',
      type: 'text',
    },
    {
      tag: 'FechaFinal',
      label: 'Fecha Final',
      type: 'text',
    },
    {
      tag: 'FechaImpresion',
      label: 'Fecha Impresión',
      type: 'text',
    },
    {
      tag: 'FechaInicio',
      label: 'Fecha Inicio',
      type: 'text',
    },
    {
      tag: 'Folio',
      label: 'Folio',
      type: 'text',
    },
    {
      tag: 'FolioFac',
      label: 'Folio Factura',
      type: 'text',
    },

    {
      tag: 'Grupo',
      label: 'Grupo',
      type: 'text',
    },
    {
      tag: 'Marca',
      label: 'Marca',
      type: 'text',
    },
    {
      tag: 'MargenPesos',
      label: 'Margen Pesos',
      type: 'text',
    },
    {
      tag: 'Moneda',
      label: 'Moneda',
      type: 'text',
    },
    {
      tag: 'NombreDocumento',
      label: 'Nombre Documento',
      type: 'text',
    },
    {
      tag: 'Origen',
      label: 'Origen',
      type: 'text',
    },
    {
      tag: 'Paridad',
      label: 'Paridad',
      type: 'text',
    },
    {
      tag: 'PedidoEspecial',
      label: 'Pedido Especial',
      type: 'boolean',
    },
    {
      tag: 'RangoFechas',
      label: 'Rango Fechas',
      type: 'text',
    },
    {
      tag: 'SerieID',
      label: 'ID Serie',
      type: 'text',
    },
    {
      tag: 'SubTotal',
      label: 'SubTotal',
      type: 'text',
    },

    {
      tag: 'SucursalID',
      label: 'ID Sucursal',
      type: 'text',
    },
    {
      tag: 'SucursalNombre',
      label: 'Nombre Sucursal',
      type: 'text',
    },
    {
      tag: 'TipoVenta',
      label: 'Tipo Venta',
      type: 'text',
    },
    {
      tag: 'Total',
      label: 'Total',
      type: 'text',
    },
    {
      tag: 'TotalImpuesto',
      label: 'Total Impuesto',
      type: 'text',
    },
    {
      tag: 'Utilidad',
      label: 'Utilidad',
      type: 'text',
    },
    {
      tag: 'UtilidadAlmacen',
      label: 'Utilidad Almacén',
      type: 'text',
    },
    {
      tag: 'UtilidadFactura',
      label: 'Utilidad Factura',
      type: 'text',
    },
    {
      tag: 'UtilidadGeneral',
      label: 'Utilidad General',
      type: 'text',
    },
    {
      tag: 'UtilidadSucursal',
      label: 'Utilidad Sucursal',
      type: 'text',
    },

    {
      tag: 'Vendedor',
      label: 'Vendedor',
      type: 'text',
    },
    {
      tag: 'VendedorDefault',
      label: 'Vendedor Default',
      type: 'text',
    },
    {
      tag: 'VendedorDefaultID',
      label: 'ID Vendedor Default',
      type: 'text',
    },
    {
      tag: 'VendedorID',
      label: 'ID Vendedor',
      type: 'text',
    },
    {
      tag: 'Venta',
      label: 'Venta',
      type: 'text',
    },
    {
      tag: 'VentaFecha',
      label: 'Venta Fecha',
      type: 'text',
    },
  ];

  public load: boolean = false;

  constructor(
    private _changeNotificationService: ChangeNotificationService,
    private _activatedRoute: ActivatedRoute,
    private _platformLocation: PlatformLocation,
    private formBuilder: FormBuilder,
    private abcService: ABCService,
    private matDialog: MatDialog,
    private periodoInventarioService: PeriodoInventarioService,
    private genericService: GenericService,
    private spinner: NgxSpinnerService
  ) {
    this.maxDate = new Date();
    this.buildForm();

    this.getSucursales();
    this.getDeparamentosAbc(0);
    this.getGruposAbc(0);
    this.getMarcasAbc();
    this.getClientesAbc();
    this.getVendedoresAbc();
    this.getAlmacenes(0);
  }

  ngOnInit() {
    this._activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams[this._pivotUIRows]) {
        localStorage.setItem(
          this._pivotUIRows,
          JSON.stringify(JSON.parse(queryParams[this._pivotUIRows]))
        );
      }
      if (queryParams[this._pivotUICols]) {
        localStorage.setItem(
          this._pivotUICols,
          JSON.stringify(JSON.parse(queryParams[this._pivotUICols]))
        );
      }
      if (queryParams[this._pivotUIVals]) {
        localStorage.setItem(
          this._pivotUIVals,
          JSON.stringify(JSON.parse(queryParams[this._pivotUIVals]))
        );
      }
      if (queryParams[this._pivotUIRendererName]) {
        localStorage.setItem(
          this._pivotUIRendererName,
          queryParams[this._pivotUIRendererName]
        );
      }
      if (queryParams[this._pivotUIAggregatorName]) {
        localStorage.setItem(
          this._pivotUIAggregatorName,
          queryParams[this._pivotUIAggregatorName]
        );
      }
    });

    const columnHeaders = [
      'brand',
      'lastYearSale',
      'thisYearSale',
      'lastYearProfit',
      'thisYearProfit',
      'salesperson',
      'eligible',
      'accountEmailAddress',
      'accountUrl',
      'numberOfRetailOutlets',
    ];
    for (const column of columnHeaders) {
      this.columns.push({
        field: column,
        header: column,
        filterMatchMode: 'contains',
        isBoolean: true,
        isNumeric: true,
        isEmailAddress: true,
        isHttpUrl: true,
      });
    }
    this.selectedColumns = this.columns;

    this.outputData = [];

    for (const row of this.outputData) {
      for (const column of this.columns) {
        // @ts-ignore
        this.getColumnWithDataType(row[column.field], column, row);
      }
    }

    this.limitElements = this.outputData.length;

    this.preselectFilterMatchModes();
  }

  private getClientesAbc() {
    this.getInputValue('clientesAbc').disable();
    this.abcService.getClientesAbcDos().subscribe((res: DataClientes) => {
      this.clientesList = res.clientes;
      this.getInputValue('clientesAbc').enable();
    });
  }

  getAlmacenes = (sucursal: number) => {
    console.log(sucursal);

    this.formFiltros.get('almacen').setValue('');
    //this.formFiltros.get('clavePeriodo').setValue(null);
    this.progressAlmacen = true;
    this.periodoInventarioService
      .almacen(sucursal)
      .then((res) => {
        this.almacenesTmp = res.response;
        //console.log(this.almacenes);

        this.progressAlmacen = false;
      })
      .catch((error) => {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: error.message,
          footer: 'Comunícate para resolver el problema',
        });
        this.progressAlmacen = false;
      });
  };

  private getDeparamentosAbc(clienteId: number) {
    this.getInputValue('departamentoAbc').disable();
    this.abcService
      .getDepartamentosAbcDos(clienteId)
      .subscribe((res: DataDepartamento) => {
        this.depatamentosList = res.departamentos;
        this.getInputValue('departamentoAbc').enable();
      });
  }
  private getGruposAbc(clienteId: number) {
    this.getInputValue('grupoAbc').disable();
    this.abcService.getGruposAbcDos(clienteId).subscribe((res: DataGrupo) => {
      this.gruposList = res.grupos;
      this.getInputValue('grupoAbc').enable();
    });
  }
  private getMarcasAbc() {
    this.getInputValue('marcasAbc').disable();
    this.abcService.getMarcasAbc().subscribe((res: DataMarcas) => {
      this.marcasList = res.marcas;
      this.getInputValue('marcasAbc').enable();
    });
  }

  private getVendedoresAbc() {
    this.getInputValue('vendedoresAbc').disable();
    this.abcService.getVendedoresAbc().subscribe((res: DataVendedores) => {
      this.vendedoresList = res.vendendores;
      this.getInputValue('vendedoresAbc').enable();
    });
  }

  private getSucursales() {
    this.getInputValue('sucursales').disable();
    this.abcService.getSucursales().subscribe((res: DataSucursales) => {
      this.sucursales = res.sucursales;
      this.getInputValue('sucursales').enable();
    });
  }

  public getInputValue(control: string) {
    return this.formFiltros.get(control);
  }

  public modalClientes(opcion: number) {
    let list = [];
    let listCheck = [];
    switch (opcion) {
      case 1:
        list = this.depatamentosList;
        listCheck = this.depatamentosSelected;
        break;
      case 2:
        list =
          this.gruposListFiltrado.length > 0
            ? this.gruposListFiltrado
            : this.gruposList;
        listCheck = this.gruposSelected;
        break;
      case 3:
        list = this.clientesList;
        listCheck = this.clientesListSelected;
        break;
      case 4:
        list = this.vendedoresList;
        listCheck = this.vendedoresListSelected;
        break;
      case 5:
        list = this.marcasList;
        listCheck = this.marcasSelected;
        break;
      case 6:
        list = this.sucursales;
        listCheck = this.sucursalesSelected;
        break;

      case 7:
        list = this.almacenes;
        listCheck = this.almacenesSelected;
        break;
    }
    const dialogRef = this.matDialog.open(FiltrosComponent, {
      disableClose: true,
      width: '28%',
      data: {
        opcion,
        lista: list,
        listaCheck: listCheck,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      switch (opcion) {
        case 1: // Departamentos
          this.depatamentosSelected = result;

          //this.dataSend.departamentos = this.depatamentosSelected;
          this.dataSend.selectAllDepartamentos =
            this.depatamentosSelected.length == this.depatamentosList.length;

          this.dataSend.departamentos = this.dataSend.selectAllDepartamentos
            ? []
            : this.depatamentosSelected;

          const departamento =
            this.depatamentosSelected.length > 0
              ? this.depatamentosSelected[0].Nombre.slice(0, 20)
              : '';
          const departamento2 =
            this.depatamentosSelected.length > 1
              ? ' (+' +
                (this.depatamentosSelected.length - 1) +
                ' ' +
                (this.depatamentosSelected.length === 2
                  ? 'Departamento'
                  : 'Departamentos') +
                ')'
              : '';

          if (this.depatamentosSelected.length > 0) {
            this.getInputValue('departamentoAbc').setValue(
              departamento + ' ' + departamento2
            );
          } else {
            this.getInputValue('departamentoAbc').setValue('');
          }
          this.gruposListFiltrado = this.filterGrupos();

          break;
        case 2: // Grupos
          this.gruposSelected = result;
          this.dataSend.selectAllGrupos =
            this.gruposSelected.length == this.gruposList.length;
          //this.dataSend.grupos = this.gruposSelected;

          this.dataSend.grupos = this.dataSend.selectAllGrupos
            ? []
            : this.gruposSelected;
          const grupo =
            this.gruposSelected.length > 0
              ? this.gruposSelected[0].Nombre.slice(0, 20)
              : '';
          const grupo2 =
            this.gruposSelected.length > 1
              ? ' (+' +
                (this.gruposSelected.length - 1) +
                ' ' +
                (this.gruposSelected.length === 2 ? 'Grupo' : 'Grupos') +
                ')'
              : '';
          this.getInputValue('grupoAbc').setValue(grupo + ' ' + grupo2);

          break;
        case 3: // Clientes
          this.clientesListSelected = result;

          this.dataSend.selectAllClientes =
            this.clientesListSelected.length == this.clientesList.length;
          //this.dataSend.clientes = this.clientesListSelected;

          this.dataSend.clientes = this.dataSend.selectAllClientes
            ? []
            : this.clientesListSelected;
          const cliente =
            this.clientesListSelected.length > 0
              ? this.clientesListSelected[0].Nombre.slice(0, 20)
              : '';
          const clientes2 =
            this.clientesListSelected.length > 1
              ? ' (+' +
                (this.clientesListSelected.length - 1) +
                ' ' +
                (this.clientesListSelected.length === 2
                  ? 'Cliente'
                  : 'Clientes') +
                ')'
              : '';
          if (this.clientesListSelected.length > 0) {
            this.getInputValue('clientesAbc').setValue(
              cliente + ' ' + clientes2
            );
          } else {
            this.getInputValue('clientesAbc').setValue('');
          }
          break;
        case 4: // Departamentos
          this.vendedoresListSelected = result;

          this.dataSend.selectAllVendedores =
            this.vendedoresListSelected.length == this.vendedoresList.length;
          //this.dataSend.vendedores = this.vendedoresListSelected;
          this.dataSend.vendedores = this.dataSend.selectAllVendedores
            ? []
            : this.vendedoresListSelected;
          const vendedor =
            this.vendedoresListSelected.length > 0
              ? this.vendedoresListSelected[0].Nombre.slice(0, 20)
              : '';
          const vendedor2 =
            this.vendedoresListSelected.length > 1
              ? ' (+' +
                (this.vendedoresListSelected.length - 1) +
                ' ' +
                (this.vendedoresListSelected.length === 2
                  ? 'Vendedor'
                  : 'Vendendores') +
                ')'
              : '';
          if (this.vendedoresListSelected.length > 0) {
            this.getInputValue('vendedoresAbc').setValue(
              vendedor + ' ' + vendedor2
            );
          } else {
            this.getInputValue('vendedoresAbc').setValue('');
          }
          break;

        case 5: // Departamentos
          this.marcasSelected = result;

          this.dataSend.selectAllMarcas =
            this.marcasSelected.length == this.marcasList.length;
          //this.dataSend.marcas = this.marcasSelected;
          this.dataSend.marcas = this.dataSend.selectAllMarcas
            ? []
            : this.marcasSelected;
          const marca =
            this.marcasSelected.length > 0
              ? this.marcasSelected[0].Nombre.slice(0, 20)
              : '';
          const marca2 =
            this.marcasSelected.length > 1
              ? ' (+' +
                (this.marcasSelected.length - 1) +
                ' ' +
                (this.marcasSelected.length === 2 ? 'Marca' : 'Marcas') +
                ')'
              : '';
          if (this.marcasSelected.length > 0) {
            this.getInputValue('marcasAbc').setValue(marca + ' ' + marca2);
          } else {
            this.getInputValue('marcasAbc').setValue('');
          }
          break;
        case 6: // sucursales
          this.sucursalesSelected = result;

          this.dataSend.selectAllSucursales =
            this.sucursalesSelected.length == this.sucursales.length;

          this.dataSend.sucursales = this.dataSend.selectAllSucursales
            ? []
            : this.sucursalesSelected;
          const sucursales =
            this.sucursalesSelected.length > 0
              ? this.sucursalesSelected[0].Nombre.slice(0, 20)
              : '';
          const sucursales2 =
            this.sucursalesSelected.length > 1
              ? ' (+' +
                (this.sucursalesSelected.length - 1) +
                ' ' +
                (this.sucursalesSelected.length === 2
                  ? 'Sucursal'
                  : 'Sucursales') +
                ')'
              : '';
          if (this.sucursalesSelected.length > 0) {
            this.getInputValue('sucursales').setValue(
              sucursales + ' ' + sucursales2
            );

            this.almacenes = [];
            this.sucursalesSelected.forEach((sucursal: sucursal) => {
              this.almacenesTmp.forEach((almacen: any) => {
                if (almacen.Sucursal == sucursal.SucursalId) {
                  this.almacenes.push({
                    id: almacen.AlmacenID,
                    Nombre: almacen.NombreAlmacen,
                  });
                }
              });
            });
          } else {
            this.getInputValue('sucursales').setValue('');
            this.almacenes = [];
          }

          break;
        case 7: // Departamentos
          this.almacenesSelected = result;

          this.dataSend.selectAllAlmacenes =
            this.almacenesSelected.length == this.almacenes.length;
          //this.dataSend.almacenes = this.almacenesSelected;
          this.dataSend.almacenes = this.dataSend.selectAllAlmacenes
            ? []
            : this.almacenesSelected;
          const almacen =
            this.almacenesSelected.length > 0
              ? this.almacenesSelected[0].Nombre.slice(0, 20)
              : '';
          const almacen2 =
            this.almacenesSelected.length > 1
              ? ' (+' +
                (this.almacenesSelected.length - 1) +
                ' ' +
                (this.almacenesSelected.length === 2
                  ? 'Almacén'
                  : 'Almacenes') +
                ')'
              : '';
          if (this.almacenesSelected.length > 0) {
            this.getInputValue('almacen').setValue(almacen + ' ' + almacen2);
          } else {
            this.getInputValue('almacen').setValue('');
          }
          break;
      }
    });
  }

  buildForm() {
    const hoy = new Date();
    this.formFiltros = this.formBuilder.group({
      clientesAbc: new FormControl('', [Validators.required]),
      departamentoAbc: new FormControl('', [Validators.required]),
      grupoAbc: new FormControl(''),
      marcasAbc: new FormControl('', [Validators.required]),
      vendedoresAbc: new FormControl(''),
      reportes: new FormControl(''),
      sucursales: new FormControl('', [Validators.required]),
      almacen: ['', [Validators.required]],
      FechaInicio: [
        new Date(hoy.getFullYear(), hoy.getMonth(), 1),
        [Validators.required],
      ],
      FechaFin: [new Date(), [Validators.required]],
    });
    this.formFiltros.controls.grupoAbc.disable();
  }

  onFileChange(fileChangeEvent: any): void {
    console.log(fileChangeEvent);

    if (
      fileChangeEvent.target.files[0].type !=
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
      fileChangeEvent.target.files[0].type != 'application/vnd.ms-excel' &&
      fileChangeEvent.target.files[0].type != 'text/csv'
    ) {
    } else {
      this.outputData = [];
      this.selectedRows = [];
      this.loader = true;

      const target: DataTransfer = <DataTransfer>fileChangeEvent.target;

      if (target.files.length !== 1) {
        throw new Error('Cannot use multiple files');
      }

      const reader: FileReader = new FileReader();

      reader.onload = (onLoadEvent: any) => {
        const inputData: string = onLoadEvent.target.result;
        const workBook: XLSX.WorkBook = XLSX.read(inputData, {
          type: 'binary',
        });

        const workSheetName: string = workBook.SheetNames[0];
        const workSheet: XLSX.WorkSheet = workBook.Sheets[workSheetName];

        let data: Matrix = <Matrix>(
          XLSX.utils.sheet_to_json(workSheet, { header: 1 })
        );
        console.log(data);

        console.log(data[0]);

        this.columns = [];
        for (const column of data[0]) {
          this.columns.push({
            field: column,
            header: column,
            filterMatchMode: 'contains',
            isBoolean: true,
            isNumeric: true,
            isEmailAddress: true,
            isHttpUrl: true,
          });
        }
        this.selectedColumns = this.columns;

        data = data.slice(1, data.length);

        for (const row of data) {
          const outputRow = {};

          let i = 0;
          for (const column of this.columns) {
            // @ts-ignore
            outputRow[column.field] = row[i];
            this.getColumnWithDataType(row[i], column, outputRow);

            i++;
          }

          this.outputData.push(outputRow);
        }

        this.loader = false;
        this.limitElements = this.outputData.length;

        this.preselectFilterMatchModes();
      };

      reader.readAsBinaryString(target.files[0]);
    }
  }

  export(): void {
    const targetRows = this.getRowsWithDeselectedColumnsRemoved();
    const outputRows = this.convertSelectedRows(targetRows);

    const workSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(outputRows);
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');

    XLSX.writeFile(workBook, 'export.xlsx');
  }

  onRowSelectionChanged(): void {
    this._changeNotificationService.onSelectionChanged(this.selectedRows);
  }

  onFilterChanged($event: { filters: {}; filteredValue: {}[] }): void {
    if (Object.keys($event.filters).length > 0) {
      this.filteredRows = $event.filteredValue;
    } else {
      this.filteredRows = [];
    }

    this._changeNotificationService.onSelectionChanged($event.filteredValue);
  }

  onColumnSelectionChanged(): void {
    this.columnsToRemoveFromData = [];
    for (const column of this.columns) {
      if (!this.selectedColumns?.includes(column)) {
        this.columnsToRemoveFromData.push(column);
      }
    }

    const targetRows = this.getRowsWithDeselectedColumnsRemoved();

    this._changeNotificationService.onSelectionChanged(targetRows);
  }

  showTopElements(value: number): void {
    if (!this.originalOutputData) {
      this.originalOutputData = this.outputData;
    }
    if (this.outputData.length < this.originalOutputData.length) {
      this.outputData = this.originalOutputData;
    }

    const limit = value ? value : this.outputData.length;

    this.outputData = this.outputData.slice(0, limit);

    this._changeNotificationService.onSelectionChanged(this.outputData);
  }

  displayShareCurrentPivotTableConfigurationDialog(): void {
    this.urlForSharing =
      `${location.protocol}//${
        location.host
      }${this._platformLocation.getBaseHrefFromDOM()}` + // @ts-ignore
      `?${this._pivotUIRows}=${encodeURIComponent(
        localStorage.getItem(this._pivotUIRows)
      )}` + // @ts-ignore
      `&${this._pivotUICols}=${encodeURIComponent(
        localStorage.getItem(this._pivotUICols)
      )}` + // @ts-ignore
      `&${this._pivotUIVals}=${encodeURIComponent(
        localStorage.getItem(this._pivotUIVals)
      )}` + // @ts-ignore
      `&${this._pivotUIRendererName}=${encodeURIComponent(
        localStorage.getItem(this._pivotUIRendererName)
      )}` + // @ts-ignore
      `&${this._pivotUIAggregatorName}=${encodeURIComponent(
        localStorage.getItem(this._pivotUIAggregatorName)
      )}`;

    this.displayShareDialog = true;
  }

  copyURLToClipboard(inputElementWithURL: HTMLInputElement): void {
    inputElementWithURL.select();
    document.execCommand('copy');
    inputElementWithURL.setSelectionRange(0, inputElementWithURL.value.length);
  }

  resetPivotTableConfiguration(): void {
    localStorage.clear();
    location.reload();
  }

  getInputFieldTypeForColumn(column: Column): string {
    if (column.isNumeric) {
      return 'number';
    }

    if (column.isEmailAddress) {
      return 'email';
    }

    if (column.isHttpUrl) {
      return 'url';
    }

    return 'text';
  }

  private convertSelectedRows(rows: {}[]): any[][] {
    const outputRows = [];

    let outputRow = [];
    // @ts-ignore
    for (const column of this.selectedColumns) {
      outputRow.push(column.field);
    }
    outputRows.push(outputRow);

    for (const row of rows) {
      outputRow = [];
      for (const column in row) {
        // @ts-ignore
        outputRow.push(row[column]);
      }
      outputRows.push(outputRow);
    }

    return outputRows;
  }

  private getRowsWithDeselectedColumnsRemoved(): {}[] {
    let sourceRows = this.outputData;
    if (this.selectedRows && this.selectedRows.length > 0) {
      sourceRows = this.selectedRows;
    }
    if (this.filteredRows && this.filteredRows.length > 0) {
      sourceRows = this.filteredRows;
    }

    let targetRows: {}[] = JSON.parse(JSON.stringify(sourceRows));

    for (const row of targetRows) {
      for (const column of this.columnsToRemoveFromData) {
        if (row.hasOwnProperty(column.field)) {
          // @ts-ignore
          delete row[column.field];
        }
      }
    }

    return targetRows;
  }

  private getColumnWithDataType(
    value: any,
    column: Column,
    outputRow: {}
  ): void {
    let interpretedValue = value;

    if (column.isBoolean || column.isNumeric) {
      try {
        interpretedValue = JSON.parse(value);

        column.isBoolean =
          column.isBoolean && !!interpretedValue == interpretedValue;
        column.isNumeric =
          !column.isBoolean && column.isNumeric && !isNaN(interpretedValue);
      } catch (error) {
        column.isBoolean = false;
        column.isNumeric = false;
      }
    }

    column.isEmailAddress =
      column.isEmailAddress && this._emailPattern.test(interpretedValue);
    column.isHttpUrl =
      column.isHttpUrl && this._httpUrlPattern.test(interpretedValue);

    if (column.isBoolean || column.isNumeric || column.isEmailAddress) {
      // @ts-ignore
      outputRow[column.field] = interpretedValue;
    }
  }

  private preselectFilterMatchModes(): void {
    for (const column of this.columns) {
      if (column.isBoolean) {
        column.filterMatchMode = 'equals';
      }
    }
  }

  public filterGrupos(): Grupos[] {
    return this.gruposList.filter((option) => {
      let coincidencia = false;
      this.depatamentosSelected.forEach((value) => {
        if (option.DepartamentoID == value.DepartamentoID) {
          coincidencia = true;
        }
      });
      return coincidencia;
    });
  }

  getReporte() {
    this.load = true;
    this.articulos.forEach((a: any) => {
      this.dataSend.datosArticulos[a.tag] = a.checked ? 1 : 0;
    });
    this.datos.forEach((a: any) => {
      this.dataSend.datosGenerales[a.tag] = a.checked ? 1 : 0;
    });
    this.clientes.forEach((a: any) => {
      this.dataSend.datosClientes[a.tag] = a.checked ? 1 : 0;
    });

    this.dataSend.dateInitParse = moment(this.dataSend.dateInit).format(
      'YYYY-MM-DD HH:mm:ss'
    );
    let s: any = this.dataSend.dateInitParse.split(' ');
    this.dataSend.fechaInicial = `${s[0]}T${s[1]}.000Z`;

    this.dataSend.dateEndParse = moment(this.dataSend.dateEnd).format(
      'YYYY-MM-DD HH:mm:ss'
    );
    let s2: any = this.dataSend.dateEndParse.split(' ');
    this.dataSend.fechaFinal = `${s2[0]}T${s2[1]}.000Z`;

    console.log(this.dataSend);
    /* delete this.dataSend.dateInit;
    delete this.dataSend.dateEnd;
     */

    let d: any = { ...this.dataSend };
    /*  delete d.dateInit;
    delete d.dateEnd; */

    console.log(d);
    this.spinner.show('spinner-pivot');
    this.listaArray = [];
    setTimeout(() => {
      this.genericService
        .sendPostRequest(
          `${environment.apiUrl}reportes/reporte-analisis-venta/reporteAnalisisVenta.php`,
          this.dataSend
        )
        .subscribe(
          (response: any) => {
            this.outputData = [];
            this.selectedRows = [];
            this.loader = true;
            this.listaArray = response.data;
            this.spinner.hide('spinner-pivot');

            this.columns = [];
            for (const column of this.customHeaders) {
              this.columns.push({
                field: column.label,
                header: column.label,
                tag: column.tag,
                filterMatchMode: 'contains',
                isBoolean: true,
                isNumeric: true,
                isEmailAddress: true,
                isHttpUrl: true,
              });
            }
            console.log(this.columns);

            this.selectedColumns = this.columns;

            //data = data.slice(1, data.length);

            for (const row of [...response.data]) {
              const outputRow = {};

              //let i = 0;
              for (const column of this.columns) {
                //console.log(row[i]);
                outputRow[column.tag] = row[column.tag];
                this.getColumnWithDataType(row[column.tag], column, outputRow);

                //i++;
              }

              this.outputData.push(outputRow);
            }

            this.loader = false;
            this.limitElements = this.outputData.length;
            this.load = false;
            this.preselectFilterMatchModes();
          },
          (error: HttpErrorResponse) => {
            this.load = false;
            this.spinner.hide('spinner-pivot');
          }
        );
    }, 1000);
  }

  /*checkbox change event*/
  onChange(event) {
    console.log(event);
    event.checked = !event.checked;
  }
}
