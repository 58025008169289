import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroColoniaDestino'
})
export class FiltroColoniaDestinoPipe implements PipeTransform {

  transform(value: any, args: any): any {
    if (typeof args === 'undefined' || args === '' || args?.length < 2) {
      return value;
    }
    const filterColoniaDestino= [];
    for (const f of value) {
      if (typeof f === 'string' && f.toLowerCase().indexOf(args.toLowerCase()) > -1) {
          filterColoniaDestino.push(f);
      }
    }
    return filterColoniaDestino;
  }

}
