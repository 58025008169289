import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-create-image',
  templateUrl: './create-image.component.html',
  styleUrls: ['./create-image.component.scss']
})
export class CreateImageComponent implements OnInit {
  @ViewChild('createImageModal', { static: false })
  public createImageModal: ModalDirective;

  constructor() {}

  ngOnInit() {}
}
