import { catchError, map } from 'rxjs/operators';
import { environment } from './../../../../environments/environment.prod';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListaArticulos, ListaDepartamentos, ListaMarcas, ListaGrupos, ListaCategoriasDePrecios} from '../model/ListaArticulos';
import { ResponseArticulosWeb, ResponseDepartamentosWeb, ResponseMarcasWeb, ResponseGruposWeb, ResponseCategoriasDePrecios} from '../model/ResponseArticulosWeb';
import { LoginService } from '../../../services/login/login.service';
import { dataMargenes, margenesDeptops } from '../../Multi-inventarios/model/departamentos.model';
import { EmpresaService } from '../../../services/Empresa/Empresa.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class ArticulosWebService {
  private apiUrl = environment.apiUrl;
  private apiUrlTest = environment.apiUrlTest;
  empresaId =  String(environment.empresaId);
private destroy$ = new Subject<void>();
  constructor(
    private http: HttpClient,
    private login: LoginService,
    private empresaService: EmpresaService
  ) {
    this.empresaService.empresaId$
    .pipe(takeUntil(this.destroy$))
    .subscribe(id => {
      this.empresaId = id;
    });
   }
  public getListArticles = (): Observable<ListaArticulos[]> => {
    const dataQuery = {
      option: 'getAll',
      empresaId: this.empresaId,
    };
    return this.http.post<ResponseArticulosWeb>(this.apiUrl + 'admin/articulos-web.php', dataQuery).pipe(
      map(response => {
        // console.log(response);
        if (!response.error) {
          return response.respuesta;
        }
        return [];
      }));
  }
  public insertArticle = (ClaveArticulo: string): Observable<ResponseArticulosWeb> => {
    const dataQuery = {
      option: 'insert',
      EmpresaID: this.empresaId,
      MostrarEnWeb: 0,
      ClaveArticulo,
      Quien: this.login.getUserData().correo
    };
    return this.http.post<ResponseArticulosWeb>(this.apiUrl + 'admin/articulos-web.php', dataQuery).pipe(
      map(res => {
        // console.log(res);
        return res;
      })
    );
  }
  public deleteListArticles = (listArticulos: ListaArticulos[]): Observable<ResponseArticulosWeb> => {
    const dataQuery = {
      option: 'deleteList',
      EmpresaID: this.empresaId,
      listArticulos,
    };
    return this.http.post<ResponseArticulosWeb>(this.apiUrl + 'admin/articulos-web.php', dataQuery).pipe(
      map(res => {
        return res;
      })
    );
  }
  public updateAll = (listArticulos: ListaArticulos[]): Observable<ResponseArticulosWeb> => {
    const dataQuery = {
      option: 'updateAll',
      EmpresaID: this.empresaId,
      listArticulos,
      // Quien: this.login.getUserData().correo
    };
    return this.http.post<ResponseArticulosWeb>(this.apiUrl + 'admin/articulos-web.php', dataQuery).pipe(
      map(res => {
        // console.log(res);
        return res;
      })
    );
  }

  public getListDeptops = (): Observable<ListaDepartamentos[]> => {
    const dataQuery = {
      option: 'getAll',
      empresaId: this.empresaId,
    };
    return this.http.post<ResponseDepartamentosWeb>(this.apiUrl + 'admin/departamento-web.php', dataQuery).pipe(
      map(response => {
        if (!response.error) {
          return response.respuesta;
        }
        return [];
      }));
  }


  public updateDeptops = (listDepartamentos: ListaDepartamentos[]): Observable<ResponseDepartamentosWeb> => {
    const dataQuery = {
      option: 'updatetDepartamento',
      EmpresaID: this.empresaId,
      listDepartamentos,
      // Quien: this.login.getUserData().correo
    };
    return this.http.post<ResponseDepartamentosWeb>(this.apiUrl + 'admin/departamento-web.php', dataQuery).pipe(
      map(res => {
        // console.log(res);
        return res;
      })
    );
  }

  public insertDeptops = (departamentoId: string): Observable<ResponseDepartamentosWeb> => {
    const dataQuery = {
      option: 'insert',
      EmpresaID: this.empresaId,
      MostrarEnWeb: 0,
      departamentoId,
      Quien: this.login.getUserData().correo
    };
    return this.http.post<ResponseDepartamentosWeb>(this.apiUrl + 'admin/departamento-web.php', dataQuery).pipe(
      map(res => {
        return res;
      })
    );
  }

  public deleteDeptos = (listDepartamentos: ListaDepartamentos[]): Observable<ResponseDepartamentosWeb> => {
    const dataQuery = {
      option: 'deleteList',
      EmpresaID: this.empresaId,
      listDepartamentos,
    };
    return this.http.post<ResponseDepartamentosWeb>(this.apiUrl + 'admin/departamento-web.php', dataQuery).pipe(
      map(res => {
        return res;
      })
    );
  }




  public getListMarcas = (): Observable<ListaMarcas[]> => {
    const dataQuery = {
      option: 'getAll',
      empresaId: this.empresaId,
    };
    return this.http.post<ResponseMarcasWeb>(this.apiUrl + 'admin/marcas-web.php', dataQuery).pipe(
      map(response => {
        if (!response.error) {
          return response.respuesta;
        }
        return [];
      }));
  }


  public updateMarcas = (listaMarcas: ListaMarcas[]): Observable<ResponseMarcasWeb> => {
    const dataQuery = {
      option: 'updatetMarca',
      EmpresaID: this.empresaId,
      ListaMarcas: listaMarcas,
      // Quien: this.login.getUserData().correo
    };
    return this.http.post<ResponseMarcasWeb>(this.apiUrl + 'admin/marcas-web.php', dataQuery).pipe(
      map(res => {
        // console.log(res);
        return res;
      })
    );
  }

  public insertMarcas = (MarcaID: string): Observable<ResponseMarcasWeb> => {
    const dataQuery = {
      option: 'insert',
      EmpresaID: this.empresaId,
      MostrarEnWeb: 0,
      MarcaID,
      Quien: this.login.getUserData().correo
    };
    return this.http.post<ResponseMarcasWeb>(this.apiUrl + 'admin/marcas-web.php', dataQuery).pipe(
      map(res => {
        return res;
      })
    );
  }

  public deleteMarcas = (ListaMarcas: ListaMarcas[]): Observable<ResponseMarcasWeb> => {
    const dataQuery = {
      option: 'deleteMarca',
      EmpresaID: this.empresaId,
      ListaMarcas,
    };
    return this.http.post<ResponseMarcasWeb>(this.apiUrl + 'admin/marcas-web.php', dataQuery).pipe(
      map(res => {
        return res;
      })
    );
  }
//  ---------------------------------------------------------


  public getListGrupos = (): Observable<ListaGrupos[]> => {
    const dataQuery = {
      option: 'getAll',
      empresaId: this.empresaId,
    };
    return this.http.post<ResponseGruposWeb>(this.apiUrl + 'admin/grupos-web.php', dataQuery).pipe(
      map(response => {
        if (!response.error) {
          return response.respuesta;
        }
        return [];
      }));
  }

  public updateGrupos = (listaGrupos: ListaGrupos[]): Observable<ResponseGruposWeb> => {
    const dataQuery = {
      option: 'updatetMarca',
      EmpresaID: this.empresaId,
      ListaGrupos: listaGrupos,
      Quien: this.login.getUserData().correo
    };
    return this.http.post<ResponseGruposWeb>(this.apiUrl + 'admin/grupos-web.php', dataQuery).pipe(
      map(res => {
        // console.log(res);
        return res;
      })
    );
  }

  public insertGrupos = (GrupoID: string): Observable<ResponseGruposWeb> => {
    const dataQuery = {
      option: 'insert',
      EmpresaID: this.empresaId,
      MostrarEnWeb: 0,
      GrupoID,
      Quien: this.login.getUserData().correo
    };
    return this.http.post<ResponseGruposWeb>(this.apiUrl + 'admin/grupos-web.php', dataQuery).pipe(
      map(res => {
        return res;
      })
    );
  }

  public deleteGrupos = (listaGrupos: ListaGrupos[]): Observable<ResponseGruposWeb> => {
    const dataQuery = {
      option: 'deleteMarca',
      EmpresaID: this.empresaId,
      ListaGrupos:listaGrupos,
    };
    return this.http.post<ResponseGruposWeb>(this.apiUrl + 'admin/grupos-web.php', dataQuery).pipe(
      map(res => {
        return res;
      })
    );
  }

  ///// Multi inventarios para



  public getListDepartamentos = (): Observable<ListaDepartamentos[]> => {
    const dataQuery = {
      option: 'getDepartamentos',
      empresaId: this.empresaId,
    };
    return this.http.post<ResponseDepartamentosWeb>(this.apiUrl + '/Servicios-web/Admin/departamentos.php', dataQuery).pipe(
      map(response => {
       return response.respuesta;
      }));
  }

  public updateDepartmanetos = (listDepartamentos: ListaDepartamentos[]): Observable<ResponseDepartamentosWeb> => {
    const dataQuery = {
      option: 'updateDepartamentos',
      EmpresaID: this.empresaId,
      listDepartamentos,
      Quien: this.login.getUserData().correo
    };
    return this.http.post<ResponseDepartamentosWeb>(this.apiUrl + '/Servicios-web/Admin/departamentos.php', dataQuery).pipe(
      map(res => {
        return res;
      })
    );
  }

  public getMargenesDepartamentos = (deptos: ListaDepartamentos): Observable<margenesDeptops[]> => {
    const data = {
      option: 'getDepartamentosMargenes',
      empresaId: this.empresaId,
      departamentoId : deptos.DepartamentoID,
      nombreDepto: deptos.Nombre
    };
    return this.http
    .post<dataMargenes>(environment.apiUrl + '/Servicios-web/Admin/departamentos.php', data).pipe(
      // catchError(this.handleError),
      map((response) => {
          if (response.error === false) {
            return response.margenesDeptos;
          } else {
            return response.margenesDeptos;
          }
      }));
   }
   public getActualizarMargenes = (departamentos: margenesDeptops[]) => {

    const data = {
      option: 'updateDepartamentosMargenes',
      empresaId: this.empresaId,
      usuario: this.login.getUserData().correo,
      departamentos
    }

    return this.http
    .post<any>(environment.apiUrl + '/Servicios-web/Admin/departamentos.php', data).pipe(
      // catchError(this.handleError),
      map((response) => {
          if (response.error === false) {
            return response;
          } else {
            return response;
          }
      }));
   }

   public getCategoriaPrecios = (): Observable<ListaCategoriasDePrecios[]> => {
    const dataQuery = {
      option: 'getCategoriasDePrecios',
      empresaId: this.empresaId,
    };
    return this.http.post<ResponseCategoriasDePrecios>(this.apiUrl + '/Servicios-web/Admin/departamentos.php', dataQuery).pipe(
      map(response => {
       return response.respuesta;
      }));
  }



  public getListMarcasMultiInventarios = (): Observable<ListaMarcas[]> => {
    const dataQuery = {
      option: 'getMarcas',
      empresaId: this.empresaId,
    };
    return this.http.post<ResponseMarcasWeb>(this.apiUrl + '/Servicios-web/Admin/marcas.php', dataQuery).pipe(
      map(response => {
       return response.respuesta;
      }));
  }

  public updateMarcasMultiInventarios = (listaMarcas: ListaMarcas[]): Observable<ResponseMarcasWeb> => {
    const dataQuery = {
      option: 'updateMarcas',
      EmpresaID: this.empresaId,
      listaMarcas,
      Quien: this.login.getUserData().correo
    };
    return this.http.post<ResponseMarcasWeb>(this.apiUrl + '/Servicios-web/Admin/marcas.php', dataQuery).pipe(
      map(res => {
        return res;
      })
    );
  }


  public getListGruposMultiInventarios = (): Observable<ListaGrupos[]> => {
    const dataQuery = {
      option: 'getGrupos',
      empresaId: this.empresaId,
    };
    return this.http.post<ResponseGruposWeb>(this.apiUrl + '/Servicios-web/Admin/grupos.php', dataQuery).pipe(
      map(response => {
       return response.respuesta;
      }));
  }

  public updateGruposMultiInventarios = (listaGrupos: ListaGrupos[]): Observable<ResponseGruposWeb> => {
    const dataQuery = {
      option: 'updateGrupos',
      EmpresaID: this.empresaId,
      listaGrupos,
      Quien: this.login.getUserData().correo
    };
    return this.http.post<ResponseGruposWeb>(this.apiUrl + '/Servicios-web/Admin/grupos.php', dataQuery).pipe(
      map(res => {
        return res;
      })
    );
  }
}
