import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-plus-light',
  templateUrl: './plus-light.component.html',
  styleUrls: ['./plus-light.component.scss'],
})
export class PlusLightComponent implements OnInit {
  @Input() public size: string;
  @Input() public color: string;
  @Input() public pointer: boolean;

  constructor() {}

  ngOnInit() {}
}
