import { DialogRemitenteAddComponent } from './../dialog-remitente-add/dialog-remitente-add.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgxSpinnerService } from 'ngx-spinner';
import { DevolucionesDet } from '../model/Almacen';
import { SelectionModel } from '@angular/cdk/collections';
import { AlmacenService } from '../../../services/Almacen/almacen.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogUserAddComponent } from '../generar-user-guia-devolucion/dialog-user-add/dialog-user-add.component';

@Component({
  selector: 'app-generar-user-guia-devolucion',
  templateUrl: './generar-user-guia-devolucion.component.html',
  styleUrls: ['./generar-user-guia-devolucion.component.scss'],
})
export class GenerarUserGuiaDevolucionComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: MatTableDataSource<DevolucionesDet>;
  selection: SelectionModel<DevolucionesDet>;
  public listaUsuarios: DevolucionesDet[] = [];
  public displayedColumns: string[] = [
     'checkBox',
    'numero_cliente',
    'nombre',
    'correo',
    'activo',
    'fecha_creacion',
    'fecha_actualizacion',
  ];
  constructor(
    private ngxSpinnerService: NgxSpinnerService,
    private almacenService: AlmacenService,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getUser();
  }
  public getUser() {
    this.ngxSpinnerService.show('spinner-user-guia');
    this.almacenService
      .getUsersDevoluciones(4, '','','','', '', '', 0)
      .then((res) => {
        this.ngxSpinnerService.hide('spinner-user-guia');
          if (res.error === false) {
            this.dataSource = new MatTableDataSource(res.data);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.selection = new SelectionModel<DevolucionesDet>(true, []);
          } else {
            this.dataSource = null;
            this.selection = null;
          }
      })
      .catch((err: any) => {
        this.ngxSpinnerService.hide('spinner-user-guia');
        Swal.fire({
          type: 'warning',
          title: 'Advertencia',
          text: err,
        });
      });
  }

  isAllSelected() {
    if (this.selection != undefined) {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
  }

  masterToggle() {
    this.listaUsuarios = [];
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => {
          this.listaUsuarios = [...this.listaUsuarios, row];
          this.selection.select(row);
        });
  }

  public selected(event?: any, row?: DevolucionesDet) {
    if (event.checked) {
      this.listaUsuarios = [...this.listaUsuarios, row];
    } else {
      this.removeToArray(row);
    }
    event = event ? this.selection.toggle(row) : null;
  }

  private removeToArray(row: DevolucionesDet): void {
    let contador = 0;
    this.listaUsuarios.forEach((remitentes) => {
      if (remitentes.id === row.id) {
        if (this.listaUsuarios.length <= 1) {
          this.listaUsuarios = [];
        } else {
          const cont = contador <= 1 ? 1 : contador;
          this.listaUsuarios.splice(contador, 1);
        }
      }
      contador++;
    });
  }

  checkboxLabel(row?: DevolucionesDet): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id + 1
    }`;
  }
  public openDialogAdd(): void {
    const dialogRef = this.matDialog.open(DialogUserAddComponent, {
      disableClose: true,
      width: '43%',
      data: { opcion: 1 },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.openSnackBar('Usuario agregado correctamente');
        this.getUser();
      }
    });
  }
  public openSnackBar(mensaje: string) {
    this.matSnackBar.open(mensaje, 'Ok', {
      duration: 2200,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

}
