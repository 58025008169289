import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'colName'
})
export class ColumnaName implements PipeTransform {

  transform(columnasTotales: any, columName: string): boolean {


    // console.log('Columnas totales', columnasTotales, 'ColumNmae', columName, 'IF', columnasTotales[columName] ? true : false);



    return columnasTotales[columName] ? true : false;
  }

}
