import { HttpErrorResponse } from '@angular/common/http';
import { GenericService } from './../../../services/generic.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlmacenService } from '../../../services/Almacen/almacen.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from './../../../../environments/environment.prod';
import { SqlGenericService } from '../../../services/sqlGenericService';


export interface LIST {
  position: number;
  Pais: string;
  Ciudad: string;
  Estado: string;
  Colonia: string;
  Cp: string;
  Cobertura: string;
  EAD: string;
  Ocurre: string;
}

@Component({
  selector: 'app-consulta-cobertura',
  templateUrl: './consulta-cobertura.component.html',
  styleUrls: ['./consulta-cobertura.component.scss'],
})
export class ConsultaCoberturaComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  public formBusquedaCobertura: FormGroup;
  public data: any;
  public list: LIST[] = [];
  public displayedColumns: string[] = [
    'Pais',
    'Estado',
    'Ciudad',
    'Colonia',
    'Codigo postal',
    'Cobertura',
    'EAD',
    'Ocurre',
  ];

  public zipCode: string = '';

  constructor(
    private fb: FormBuilder,
    private almacenService: AlmacenService,
    private spinner: NgxSpinnerService,
    private genericService: GenericService,
    private sqlGenericService: SqlGenericService
  ) {}

  ngOnInit() {
    this.validaForm();
  }

  validaForm() {
    this.formBusquedaCobertura = this.fb.group({
      codigopostal: ['', Validators.required],
      colonia: [''],
    });
  }

  getCoberturaPaqueteria() {
    let sql: string = `SELECT * FROM `;
    this.genericService
      .sendGetRequest(`${environment.WSPaqueteria}/get_all_paqueterias`)
      .subscribe(
        (response: any) => {
          console.log(response);
          response.data.forEach(async (paqueteria) => {
            let sqlQuerie: string = `SELECT 
        wpca.CodigoPostal, 
        wpca.AsentamientoID,
        wpcc.Nombre as NombreCiudad,
        wpcc.CiudadID,
        wpce.Nombre as NombreEstado,
        wpce.EstadoID,
        wpcp.Nombre as NombrePais,
        wpcp.PaisID
        FROM Web_PaqueteriaCatAsentamiento wpca
        INNER JOIN Web_PaqueteriaCatCiudad wpcc
        ON (wpcc.Codigo = wpca.CodigoCiudad)
        INNER JOIN Web_PaqueteriaCatEstado wpce
        ON (wpce.EstadoID = wpcc.EstadoID)
        INNER JOIN Web_PaqueteriaCatPais wpcp
        ON (wpcp.PaisID = wpce.PaisID)
        WHERE wpcp.EmpresaID = ${paqueteria.EmpresaID} AND wpca.CodigoPostal LIKE '%${this.zipCode}%'`;

            // let domicilios:any = this.genericService.sendGetRequest(`${environment.WSPaqueteria}/get_all_paqueterias`)
            let domicilios: any = await this.sqlGenericService
              .excecuteQueryString(sqlQuerie)
              .toPromise();
            console.log(domicilios);
            let index = 0;
            var unArray = [];
            var a = new Object();
            this.data = domicilios.parameters;
            this.data.forEach((element) => {
              a = {
                position: index,
                Pais: element.NombrePais,
                Estado: element.NombreEstado,
                Ciudad: element.NombreCiudad,
                Colonia: "N/A",
                Cp: element.CodigoPostal,
                Cobertura: 'Si',
                EAD: "N/A",
                Ocurre: "N/A",
              };
              unArray.push(a);
              index++;
            });
            this.list = unArray;
            this.dataSource = new MatTableDataSource(this.list);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
          });
        },
        (error: HttpErrorResponse) => {}
      );
  }

  getCobertura() {
    this.spinner.show('spinner-guia');
    this.almacenService
      .getCobertura(
        this.formBusquedaCobertura.get('codigopostal').value,
        this.formBusquedaCobertura.get('colonia').value
      )
      .then((res) => {
        this.spinner.hide('spinner-guia');
        if (res.message == true) {
          let index = 0;
          var unArray = [];
          var a = new Object();
          this.data = res.data;
          this.data.forEach((element) => {
            a = {
              position: index,
              Pais: element.D_Pais,
              Estado: element.D_Estado,
              Ciudad: element.D_Ciudad,
              Colonia: element.D_Colonia,
              Cp: element.Codigo_Postal,
              Cobertura: element.B_Cobertura == 'True' ? 'Si' : 'No',
              EAD: element.EAD == 'True' ? 'Si' : 'No',
              Ocurre: element.Ocurre == 'True' ? 'Si' : 'No',
            };
            unArray.push(a);
            index++;
          });
          this.list = unArray;
          this.dataSource = new MatTableDataSource(this.list);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        } else {
          Swal.fire({
            type: 'warning',
            title: 'Advertencia',
            text: res.messageError,
          });
        }
      })
      .catch((err: any) => {
        this.spinner.hide('spinner-guia');
        Swal.fire({
          type: 'warning',
          title: 'Advertencia',
          text: err,
        });
      });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  exportToExcel(): void {
    this.almacenService.exportAsExcelFileAlmacen(
      this.data,
      'Cobertura_inbox_',
      'Cobertura'
    );
  }
}
