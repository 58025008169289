import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlmacenService } from '../../../../services/Almacen/almacen.service';
import Swal from 'sweetalert2';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dialog-user-add',
  templateUrl: './dialog-user-add.component.html',
  styleUrls: ['./dialog-user-add.component.scss'],
})
export class DialogUserAddComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DialogUserAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private almacenService: AlmacenService,
    private snackBar: MatSnackBar
  ) {}
  public formAddUser: FormGroup;
  public buscaCliente: boolean;
  public hide: boolean = true;
  public activo: boolean;
  public spinner: boolean;

  ngOnInit() {
    this.formGroup();
  }

  formGroup = () => {
    this.formAddUser = this.fb.group({
      Ncliente: [''],
      Nombre: ['', [Validators.required]],
      Correo: ['', [Validators.required, Validators.email]],
      Contraseña: ['', [Validators.required]],
      Checkbox: [true],
    });
    console.log(this.formAddUser.invalid);
    console.log(this.formAddUser.errors);
  };
  buscarClientes = () => {
    this.buscaCliente = true;
    this.almacenService
      .getUsersDevoluciones(
        5,
        this.getFormValue('Ncliente'),
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        0
      )
      .then((res) => {
        this.buscaCliente = false;
        if (res.error === false) {
          this.setFormValue(res.data[0].correo, 'Correo');
          this.setFormValue(res.data[0].nombre, 'Nombre');
        } else {
          this.setFormValue('', 'Correo');
          this.setFormValue('', 'Nombre');
        }
      })
      .catch((err: any) => {
        this.buscaCliente = false;
        Swal.fire({
          type: 'warning',
          title: 'Advertencia',
          text: err,
        });
      });
  };
  agregarCliente = () => {
    this.spinner = true;
    this.almacenService
      .getUsersDevoluciones(
        3,
        this.getFormValue('Ncliente'),
        this.getFormValue('Nombre'),
        this.getFormValue('Correo'),
        this.getFormValue('Contraseña'),
        this.activo === true ? 'SI' : 'NO',
        '',
        0
      )
      .then((res) => {
        this.spinner = false;

        this.buscaCliente = false;
        if (res.error === false) {
          if(Number(res.data[0].id) > 0){
            this.dialogRef.close(true);
          } else {
            Swal.fire({
              type:  'info',
              title: 'Alerta',
              text:  'Upps.Ya existe un registro con el mismo correo electronico',
            });
            // this.openSnackBar('Upps.Ya existe un registro con el mismo correo electronico');
          }
        } else {
          this.openSnackBar('Upps.Ocurrio un error al agregar cliente');
        }
      })
      .catch((err: any) => {
        this.spinner = false;
        this.buscaCliente = false;
        Swal.fire({
          type: 'warning',
          title: 'Advertencia',
          text: err,
        });
      });
  };
  actualizaCliente = () =>{

  }
  selected = (event?: any) => {
    this.activo = event.checked ? true : false;
  };
  getFormValue = (value: string) => {
    return this.formAddUser.get(value).value;
  };
  setFormValue = (value: string, getValue: string) => {
    this.formAddUser.get(getValue).setValue(value);
  };
  public openSnackBar(mensaje: string) {
    this.snackBar.open(mensaje, 'Ok', {
      duration: 2200,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
