import { Component, OnInit ,ViewChild} from '@angular/core';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlmacenService } from '../../../services/Almacen/almacen.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';


@Component({
  templateUrl: './articulos-produccion-insumos.component.html',
  styleUrls: ['./articulos-produccion-insumos.component.scss']
})
export class ArticulosProduccionInsumosComponent implements OnInit {


  constructor(
       private spinner: NgxSpinnerService,
       private snackBar: MatSnackBar,
       private almacenService: AlmacenService
       ) { }

  public maxDate: Date;
  public columnsInsumos: string[] = [];
  public columnsProduccion: string[] = [];
  public dataInsumos : MatTableDataSource<any>; //any;
  public dataProduccion : MatTableDataSource<any>;  //any;
  public habilitarExportar: boolean;

  public columnsCantidadEquipos: string[] = [];
  public dataCantidadEquipos: MatTableDataSource<any>;  //any;

  // @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  // @ViewChild(MatPaginator, { static: false }) paginator2: MatPaginator;

  @ViewChild('paginator_produccion', { static: false }) private paginatorProduccion: MatPaginator;
  @ViewChild('paginator_insumos', { static: false }) private paginatorInsumos: MatPaginator;

  ngOnInit() {
  }


  getData() {
    this.spinner.show('spinner-reporte-produccion');
    const fechaInicio = ''; //new Date(this.form_.get('fechaInicio').value).toISOString().slice(0, 10);
    const fechaFin = ''; //new Date(this.form_.get('fechaFin').value).toISOString().slice(0, 10);
    this.almacenService.getArticulosProduccion(fechaInicio, fechaFin, 1).subscribe(({
      next: (value: any) => {
        this.spinner.hide('spinner-reporte-produccion');
        if (value.error === false) {
          if(value.insumos.length<=0 && value.produccion.length<=0){
            this.habilitarExportar = false;
            this.openSnackBar('No se encontraron resultados en la busqueda.¡Favor de utilizar otro rango de fechas!');
            this.columnsInsumos = [];
            this.columnsProduccion =  [];
            this.columnsCantidadEquipos = [];
            this.dataInsumos = new MatTableDataSource([]);
            this.dataProduccion = new MatTableDataSource([]);
            this.dataCantidadEquipos = new MatTableDataSource([]);

          }else{
            this.habilitarExportar = true;
            this.columnsInsumos = value.columnasInsumos;
            this.columnsProduccion = value.columnasProduccion;
            this.dataInsumos = new MatTableDataSource(value.insumos);
            this.dataInsumos.paginator = this.paginatorInsumos;
            this.dataProduccion = new MatTableDataSource(value.produccion);
            this.dataProduccion.paginator = this.paginatorProduccion;
            this.columnsCantidadEquipos = value.columnasCantidadEquipos;
            this.dataCantidadEquipos = new MatTableDataSource(value.cantidadEquipos);

          }
        }
        else {
          this.habilitarExportar = false;
          this.spinner.hide('spinner-reporte-produccion');
          // console.log('llego aquixxxxxxxxxx');
        }
      }
    }))
  }

  public getExcel(){
    this.spinner.show('spinner-reporte-produccion');
    this.almacenService.getArticulosProduccionExcel(this.columnsInsumos,this.dataInsumos.data,this.columnsProduccion,this.dataProduccion.data,this.columnsCantidadEquipos, this.dataCantidadEquipos.data).then(result => {
      this.spinner.hide('spinner-reporte-produccion');
    }).catch(err => {
      this.openSnackBar(err.message);
    })
  }

  private openSnackBar(mensaje: string) {
    this.snackBar.open(mensaje, 'Ok', {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
    setTimeout(() => {
    }, 5000);
  }
}
