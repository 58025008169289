import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ArticulosInsumosService } from '../../../../services/articulos-insumos/articulos-insumos.service';
import { LoginService } from '../../../../services/login/login.service';

@Component({
  selector: 'app-modal-articulos-upc',
  templateUrl: './modal-articulos-upc.component.html',
  styleUrls: ['./modal-articulos-upc.component.scss']
})

export class ModalArticulosUPCComponent implements OnInit {

  public permisoEditarInsumo:boolean = this.loginService.getPermisoEditarInsumo();
  public register = this.formBuilder.group({
    ClaveArticulo: '',
    Descripcion: '',
    TipoArticulo: ['', [Validators.required]],
    ManejaSeries: false,
    CodigoBarras: ''
  });

  constructor(
    public matDialogRef: MatDialogRef<ModalArticulosUPCComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private articulosInsumosService: ArticulosInsumosService,
    private  loginService : LoginService
  ) { }


  ngOnInit() {
    this.informacion(this.data);
    // console.log('is permiso :::', this.permisoEditarInsumo);
    if(!this.permisoEditarInsumo){
      this.register.get('TipoArticulo').disable();
      this.register.get('ManejaSeries').disable();
      this.register.get('TipoArticulo').clearValidators();
      this.register.get('TipoArticulo').updateValueAndValidity();
    }
    // this.register.get('ClaveArticulo').disable();
    // this.register.get('Descripcion').disable();
  }

  cerrarModal(ItUpdated:boolean): void {
    this.matDialogRef.close(ItUpdated);
  }

  onSubmit() {
    if (this.register.invalid) {
      Swal.fire({
        type: 'warning',
        title: 'Faltan campos por completar',
        text: 'Por favor, escribe los datos que faltan',
        allowOutsideClick: false,
      });
      return;
    }

    this.update();
  }

  public informacion(data: any) {
    console.log('patch value',data);
    this.register.patchValue({
      ClaveArticulo: data.ClaveArticulo,
      Descripcion: data.Descripcion,
      TipoArticulo: data.TipoArticulo,
      ManejaSeries: data.ManejaSeries == 1 ? true : false,
      CodigoBarras: data.CodigoBarras
    });
    console.log('Register', this.register);
  }

  public update() {
    Swal.fire({
      title: 'Actualizando...',
      text: 'Por favor espere',
      allowOutsideClick: false,
    });
    Swal.showLoading();
    const ClaveArticulo: string = this.register.value.ClaveArticulo !== null ? this.register.value.ClaveArticulo : '';
    const CodigoBarras: string = this.register.value.CodigoBarras!== null ?  this.register.value.CodigoBarras : '';
    const tipoArticulo = this.register.value.TipoArticulo;
    const manejaSeries =  this.register.value.ManejaSeries == true ? 1 : 0;
    console.log('register:::', this.register);
    this.articulosInsumosService.update(ClaveArticulo.trim(), tipoArticulo , manejaSeries, CodigoBarras.trim(),'Almacen').subscribe(res => {
      if (res.message == 'true') {
        Swal.fire({
          type: 'success',
          title: '¡Correcto!',
          text: 'Se ha editado correctamente',
          allowOutsideClick: false,
          confirmButtonText: 'Aceptar'
        }).then((res) => {
          if (res.value) {
            this.cerrarModal(true);
          }
        });
      } else {
          Swal.fire(
            'Error al actualizar la información',
            'Hubo un error al editar en la base de datos, contacte al administrador ' +
              res.message,
            'error',
          );
        throw new Error('Hubo un error al editar en la base de datos, contacte al administrador');
      }
    },
    (error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message +' - '+error?.error?.message,
        footer: 'Comunícate para resolver el problema',
      });
    });
  }
}
