import { Component, OnInit } from '@angular/core';
import { NgbDate, NgbCalendar, NgbPeriod } from '@ng-bootstrap/ng-bootstrap';
import { ReporteParticipacionService } from '../../services/reportes/reporte-participacion.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-reporte-participacion',
  templateUrl: './reporte-participacion.component.html',
  styleUrls: ['./reporte-participacion.component.scss']
})


export class ReporteParticipacionComponent implements OnInit {

  public modeselect = 'Web';
  participacionFiltro: string;
  fechaInicio: NgbDate;
  fechaFinal: NgbDate;
  maxDate: NgbDate;
  minDate: NgbDate;
  period: NgbPeriod;
  TipoPedido: any ;
  Testatus: any ;
  participacionArray: [];
  resetFilterArray: [];
  ConcentradoArray: [];
  excelArray: [];
  page: number = 1;
  pageSize: number = 15;
  length: number;
  lengthConcentrado: number;
  participacionA: any;

  constructor(
    private calendar: NgbCalendar,
    private participacion: ReporteParticipacionService,
    private spinner: NgxSpinnerService
  ) {
    this.maxDate = this.calendar.getToday();
  }
  fechaToString(date: NgbDate) {
    const day = date.day < 10 ? '0' + date.day.toString() : date.day.toString();
    const month =
      date.month < 10 ? '0' + date.month.toString() : date.month.toString();
    const year = date.year.toString();
    return year + month + day;
  }

  loadData(fechaInicio: NgbDate, fechaFinal: NgbDate) {
    if (this.fechaToString(fechaInicio) > this.fechaToString(fechaFinal)) {
      Swal.fire({
        type: 'warning',
        title: 'Fechas incorrectas',
        text: 'La fecha inicio no puede ser mayor a la fecha final',
        confirmButtonText: 'Aceptar'
      });
      return;
    }
    this.spinner.show('spinner-participacion');
    this.participacion.getDataParticipacion(
        this.fechaToString(fechaInicio),
        this.fechaToString(fechaFinal)
      )
      .then((res) => {
        if (res.message === 'true') {
          const part: any = res.resumenPedidos;
          this.participacionArray =part;
          this.length = part.length;
          this.resetFilterArray=part;
          const concentrado: any = res.concentradoPedidos;
          this.lengthConcentrado = concentrado.length;
          this.ConcentradoArray =concentrado;


        }

        this.spinner.hide('spinner-participacion');
      });
  }
  filtroTpPedido(tpPedido:String,estatus:String){
    this.TipoPedido === "";
    estatus === undefined? this.participacionArray  = this.resetFilterArray:this.participacionArray = this.Testatus;

    this.participacionArray  = this.resetFilterArray;
    switch(tpPedido){
      case 'App':
      case 'Web':
        this.TipoPedido= this.participacionArray.filter(menuData => menuData["TipoPedido"] === tpPedido);
        break;
      case 'Todo':
        this.TipoPedido  = this.resetFilterArray;
        break;
    }
    this.participacionArray =  this.TipoPedido;
    this.length = this.TipoPedido.length;
  }

  filtroEstatus(estatus:String,tpPedido:String){
    this.Testatus === "";
    tpPedido === undefined? this.participacionArray  = this.resetFilterArray:this.participacionArray = this.TipoPedido;

    switch(estatus){
      case '0':
        this.Testatus= this.participacionArray.filter(menuData => menuData["Cancelado"] === estatus);
        break;
      case '1':
        this.Testatus= this.participacionArray.filter(menuData => menuData["Cancelado"] === estatus);
          break;
      case 'Todo':
        this.Testatus  = this.resetFilterArray;
        break;
    }
    this.participacionArray =  this.Testatus;
    this.length = this.Testatus.length;
  }

  exportToExcel(): void {
    /*const nombres = [];
    nombres.push( this.participacionArray,this.ConcentradoArray);*/
    this.participacion.exportAsExcelFileReporteClientes(
      this.participacionArray,
      this.ConcentradoArray,
      'reporte_participacion_'
    );
  }


  ngOnInit() {

    this.fechaFinal = this.calendar.getToday();

    let f = this.calendar.getToday().day-1;
    this.period = 'd';
    this.participacionFiltro ='Web';
    this.fechaInicio = this.calendar.getPrev(this.fechaFinal,this.period,f);
    this.loadData(this.fechaInicio, this.fechaFinal);

  }

}
