import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { ListaGrupos } from '../model/ListaArticulos';
import { ArticulosWebService } from '../service/articulos-web.service';
import { DialogAddComponent } from '../dialog-add/dialog-add.component';
@Component({
  templateUrl: './grupos.component.html',
  styleUrls: ['./grupos.component.scss']
})
export class GruposComponent implements OnInit {

  displayedColumns: string[] = [
    'select',
    'GrupoID',
    'Descripcion',
    'Quien',
    'Cuando',
    'Estado'
  ];
  constructor(
    private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private service: ArticulosWebService,
  ) {

   }


  dataSource: MatTableDataSource<ListaGrupos>;
  selection: SelectionModel<ListaGrupos>;
  public listGrupos: ListaGrupos[] = [];
  public loadRefrech: boolean;
  public loadUpdate: boolean;
  public loadDelete: boolean;
  ngOnInit() {
    this.service.getListGrupos().subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.selection = new SelectionModel<ListaGrupos>(true, []);
    });
  }


  public updateAll(): void {
    this.loadUpdate = true;
    const mensaje = this.listGrupos.length > 1 ? 'Los grupos se actualizarón correctamente' : 'El grupo se actualizo correctamente';
    this.service.updateGrupos(this.listGrupos).subscribe(res => {
      if (res.estatus) {
        this.openSnackBar(mensaje);
        this.refreshClaves();
      }
      this.loadUpdate = false;
    });
  }

  public deleteListArticles(): void {
    this.loadDelete = true;
    const mensaje = this.listGrupos.length > 1 ? 'Los grupos  se eliminaron correctamente' : 'El grupo se elimino correctamente';
    this.service.deleteGrupos(this.listGrupos).subscribe(res => {
      if (res.estatus) {
        this.openSnackBar(mensaje);
        this.refreshClaves();
      }
      this.loadDelete = false;
    });
  }
  public refreshClaves(): void {
    this.loadRefrech = true;
    this.service.getListGrupos().subscribe(data => {
      this.dataSource.data = data;
      this.selection = new SelectionModel<ListaGrupos>(true, []);
      this.loadRefrech = false;
      this.listGrupos = [];
    });
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public selected(event?: any, row?: ListaGrupos) {
    if (event.checked) {
      this.listGrupos = [...this.listGrupos, row];
    } else {
      this.removeToArray(row);
    }
    event = event ? this.selection.toggle(row) : null;
    return event;
  }

  private removeToArray(row: ListaGrupos): void {
    let contador = 0;
    this.listGrupos.forEach(articulos => {
      if (articulos.Id === row.Id) {
        // console.log(this.listArticulos.length);
        if (this.listGrupos.length <= 1) {
          this.listGrupos = [];
        } else {
          const cont = contador <= 1 ? 1 : contador;
          this.listGrupos.splice(contador, 1);
        }
      }
      contador++;
    });
  }
  masterToggle() {
    this.listGrupos = [];
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => {
        this.listGrupos = [...this.listGrupos, row];
        this.selection.select(row);
      });
  }

  openSnackBar(mensaje: string) {
    this.snackBar.open(mensaje, 'Ok', {
      duration: 2200,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
    });
  }


  checkboxLabel(row?: ListaGrupos): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.Id + 1}`;
  }
  public openDialogAddNew(): void {
    const dialogRef = this.dialog.open(DialogAddComponent, {
      disableClose: true,
      data: { opcion: 4 },

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.openSnackBar('El Grupos se agrego correctamente');
        this.refreshClaves();
      }
    });
  }

}
