import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { User } from '../../models/user';
import { Respuesta } from '../images/images.service';

@Injectable({
  providedIn: 'root'
})
export class BolsaDeTrabajoService {
  usuario: User[];
  formData = new FormData();

  constructor(private http: HttpClient) { }

  getUserDataFull() {
    this.formData.append('idempresa', '1');
    this.formData.append('option', 'getDataCV');
    return this.http
      .post<User>(environment.apiUrl + 'bolsatrabajo/DataUserCV.php', this.formData)
      .pipe(map(response => {
        // console.log(response);
        return response;
      }));
  }
  getSearchUser(dato) {
    this.formData.append('idempresa', '1');
    this.formData.append('option', 'getSearchUser');
    this.formData.append('dato', dato);

    return this.http.post<User>(environment.apiUrl + 'bolsatrabajo/DataUserCV.php', this.formData
    ).pipe(map(response => {
      console.log(response);
      return response;
    }));
  }

  getCV(data) {
    this.formData.append('option', 'downloand');
    return this.http.post(environment.apiUrl + 'bolsatrabajo/DataUserCV.php', this.formData).pipe(map(response => {
      console.log(response);
      return response;
    }));
  }

  deleteCv(id) {
    this.formData.append('idempresa', '1');
    this.formData.append('id', id);
    this.formData.append('option', 'deleteDataCV');


    return this.http
      .post<Respuesta>(environment.apiUrl + 'bolsatrabajo/DataUserCV.php', this.formData)
      .pipe(map(response => {
        // console.log(response);
        return response;
      })).toPromise();
  }
}
