import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EmpresaService } from '../Empresa/Empresa.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

interface Participacion {
  message: string;
  concentradoPedidos?: {};
  resumenPedidos?: {};
}
@Injectable({
  providedIn: 'root'
})
export class ReporteParticipacionService {

  empresaId =  String(environment.empresaId);
  private destroy$ = new Subject<void>();
    constructor(private http: HttpClient, private empresaService: EmpresaService) {
      this.empresaService.empresaId$
      .pipe(takeUntil(this.destroy$))
      .subscribe(id => {
        this.empresaId = id;
      });
    }

    public getDataParticipacion(fechaInicio: any, fechaFinal: any) {
      const data = {
        fechaInicial: fechaInicio,
        fechaFinal: fechaFinal,
        empresaID: this.empresaId,
        option: 'getParticipacion'
      };
      return this.http
        .post<Participacion>(
          environment.apiUrl + 'reportes/participacion-web-app/get_participaciones.php',
          data
        )
        .pipe(map((data1) => data1))
        .toPromise();
    }

    exportAsExcelFileReporteClientes(ventas: any,resumen: any, excelFileName: string) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(ventas);
      const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(resumen);

      const workbook: XLSX.WorkBook = {
        Sheets: { General: worksheet ,Conetrado: worksheet2},
        SheetNames: ['General','Conetrado'],
      };

      const excelBuffer: any = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(
        data,
        fileName + 'Kabik_' + new Date().toLocaleDateString() + EXCEL_EXTENSION
      );
    }




}
