import { Component, Input, OnInit } from '@angular/core';

declare var Handsontable;

@Component({
  //changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './handtable.component.html',
  styleUrls: ['./handtable.component.scss'],
  selector: "handtable"
})
export class HandTableComponent implements OnInit {

  public table: any = null;
  @Input()
  public listaArray: any = [];
  @Input()
  public id: string = "";
  @Input()
  public colHeaders: string[] = [];

  @Input()
  public rowsOnSinglePage: number = 15; //can be changed
  public page: number = 1;
  public pages = null;

  public newDataSet:any = null;

  constructor(

  ) {
  }

  ngOnInit() {
    this.pages = document.querySelector(".pages");
    var example1 = document.getElementById(this.id);
    setTimeout(() => {
      this.table = new Handsontable(example1, {
        data: this.listaArray,
        class: "hot",
        colHeaders: this.colHeaders,
        rowHeaders: false,
        dropdownMenu: true,
        filters: true,
        contextMenu: true,
        multiColumnSorting: true,
        manualColumnMove: true,
        height: "550"
      });

      this.newDataSet = this.table.getData();
      this.clickPage(1);
      //JS functions
      this.table.updateSettings({
        afterColumnSort: ()=> {
          this.refreshPaging();
        },
        afterFilter: ()=> {
          this.refreshPaging();
        },
      });


      this.createPages(); //we define how many rows should be on a single page

      this.table.updateSettings({
        hiddenRows: {
          rows: this.getArray(1),
          indicators: false
        }
      });
    }, 500);
  }

  clickPage(clicked){
    this.page = clicked;
    //if (e.taget !== this.pages) {
      this.table.updateSettings({
        hiddenRows: {
          rows: this.getArray(clicked),
          indicators: false
        }
      });
    //}
  }

  getArray(clicked) {
    console.log(clicked);
    
    var arr = [];

    if (clicked === 1) {
      for (var i = clicked * this.rowsOnSinglePage; i < this.newDataSet.length; i++) {
        arr.push(i);
      }
      return arr;
    } else {
      for (var j = 0; j < clicked * this.rowsOnSinglePage - this.rowsOnSinglePage; j++) {
        arr.push(j);
      }
      for (var i = clicked * this.rowsOnSinglePage; i < this.newDataSet.length; i++) {
        arr.push(i);
      }
      return arr;
    }

    
  }

  createPages() {
    var bt,
      els = Math.ceil(this.newDataSet.length / this.rowsOnSinglePage);

    for (var i = 0; i < els; i++) {
      bt = document.createElement("BUTTON");
      bt.className = "myBt";
      bt.innerHTML = i + 1;
      this.pages.appendChild(bt);
    }
  }

  refreshPaging() {
    this.newDataSet = this.table.getData();
    this.pages.innerHTML = "";
    this.createPages();
    this.table.updateSettings({
      hiddenRows: {
        rows: this.getArray(1),
        indicators: false
      }
    });
    this.pages.firstElementChild.focus();
  }

}
