import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {
  private empresaIdSubject: BehaviorSubject<number>;
  empresaId$: any;

  constructor() {
    // Intentar obtener el valor de empresaId de localStorage, si no existe, usar el valor por defecto
    const storedEmpresaId = localStorage.getItem('empresaId');
    const initialEmpresaId = storedEmpresaId ? Number(storedEmpresaId) : environment.empresaId;

    // Inicializar el BehaviorSubject con el valor inicial
    this.empresaIdSubject = new BehaviorSubject<number>(initialEmpresaId);
    this.empresaId$ = this.empresaIdSubject.asObservable();
  }

  setEmpresaId(id: number) {
    // Actualizar el BehaviorSubject y almacenar el valor en localStorage
    this.empresaIdSubject.next(id);
    localStorage.setItem('empresaId', id.toString());
  }

  getEmpresaId(): number {
    return this.empresaIdSubject.getValue();
  }
}
