import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Injectable({
  providedIn: 'root',
})
export class DataBaseService {
  constructor(private DB: NgxIndexedDBService) {}

  public addDB(tabla: string, valor: {}) {
    this.DB.add(tabla, valor).then(
      () => {
        // Do something after the value was added
      },
      (error) => {
        console.log(error);
      }
    );
  }
  public deleteByIdDB(tabla: string, id: number): any {
    return this.DB.delete(tabla, id);
  }
  public countDB(tabla: string) {
    this.DB.count(tabla).then(
      (peopleCount) => {
        console.log(peopleCount);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  public updateDB(tabla: string, valorWithId: {}) {
    this.DB.update(tabla, valorWithId).then(
      () => {
        // Do something after update
      },
      (error) => {
        console.log(error);
      }
    );
  }
  cleanDB(tabla: string) {
    this.DB.clear(tabla).then(
      () => {
        // Do something after clear
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllDB(tabla: string): any {
    return this.DB.getAll(tabla); // aquí devolemos todo el metodo para poder hacer el then en el componente
  }
  getByIdDB(tabla: string, id: number) {
    this.DB.getByKey(tabla, id).then(
      (person) => {
        console.log(person);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  deleteTemDB(tabla: string, value: {}) {
    this.DB.openCursor(tabla, (evt) => {
      const cursor = (<any>evt.target).result;
      if (cursor) {
        console.log(cursor.value.id);
        this.updateDB(tabla, {
          id: cursor.value.id,
          titulo: cursor.value.titulo,
          clave: cursor.value.clave,
          descripcion: cursor.value.descripcion,
          estado: 0,
        });
        cursor.continue();
      } else {
        console.log('Entries all displayed.');
      }
    });
  }
  updateTitle(tabla: string, titulo: string) {
    this.DB.openCursor(tabla, (evt) => {
      const cursor = (<any>evt.target).result;
      if (cursor) {
        console.log(cursor.value.id);
        this.updateDB(tabla, {
          id: cursor.value.id,
          titulo: titulo,
          clave: cursor.value.clave,
          descripcion: cursor.value.descripcion,
          estado: cursor.value.estado,
        });
        cursor.continue();
      } else {
        console.log('Entries all displayed.');
      }
    });
  }
}
