import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagenesComponent } from './imagenes/imagenes.component';
import { PlusLightComponent } from './plus-light/plus-light.component';
import { IconImagenComponent } from './icon-imagen/icon-imagen.component';
import { IconCloudUploadComponent } from './icon-cloud-upload/icon-cloud-upload.component';
@NgModule({
  declarations: [
    ImagenesComponent,
    PlusLightComponent,
    IconImagenComponent,
    IconCloudUploadComponent,
  ],
  imports: [CommonModule],
  exports: [
    ImagenesComponent,
    PlusLightComponent,
    IconImagenComponent,
    IconCloudUploadComponent,
  ],
})
export class IconModule {}
