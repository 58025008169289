import { UtilService } from './../../../services/util.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { LoginService } from '../../../services/login/login.service';
import { ImagesService } from '../../../services/images/images.service';
import { ImagenesFaltantesService } from '../../../services/imagenes-faltantes/imagenes-faltantes.service';

@Component({
  selector: 'app-modal-imagenes-faltantes',
  templateUrl: './modal-imagenes-faltantes.component.html',
  styleUrls: ['./modal-imagenes-faltantes.component.scss']
})

export class ModalImagenesFaltantesComponent implements OnInit {
  public formData = new FormData();
  public files: any[] = [];
  public preview = false;
  public maximumSize: boolean;
  public formGroup = this.formBuilder.group({
    empresa: 'Kabik',
    clave: '',
    quien: this.login.getUserData().correo
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<ModalImagenesFaltantesComponent>,
    private formBuilder: FormBuilder,
    private login: LoginService,
    private imagesService: ImagesService,
    private imagenesFaltantesService: ImagenesFaltantesService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.patchValue(this.data);
  }

  public patchValue(data: any) {
    this.formGroup.patchValue({
      clave: data.ClaveArticulo
    });
  }

  modalClose(): void {
    this.imagesService.setImagenEnEdicion(this.data.ClaveArticulo, 'NO').subscribe((res) => { });
    this.matDialogRef.close(false);
  }

  onRemove(event) {
    this.files = null;
  }

  async onSelect(event) {
    console.log(event);
    
    this.maximumSize = false;
    let fileFound:any = event.addedFiles.length > 0 ? event.addedFiles : event.rejectedFiles;

    let file = await this.utilService.compress(fileFound[0], false, 5);
    console.log(file);
    this.files = [];
    this.files.push(file);
    console.log(this.files);
    
    let name = this.files[0].name;
    let extension = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
    if (extension != 'jpg' && extension != 'png') {
      Swal.fire({
        type: 'warning',
        title: 'Formato de imagen ' + extension + ' incorrecta',
        text: 'Por favor, seleccione una imagen en formato jpg ó png'
      });
      this.files = null;
      return;
    }
    if (event.rejectedFiles.length !== 0) {
      this.maximumSize = event.rejectedFiles.reason === 'size';
    }
  }

  onSubmit() {
    if (this.files === null) {
      Swal.fire({
        type: 'warning',
        title: 'No ha seleccionado la imagen',
        text: 'Por favor, seleccione una imagen'
      });
      return;
    }

    for (const [key, value] of Object.entries(this.formGroup.value)) {
      this.formData.append(key, value.toString());
    }

    this.insert();
  }

  insert() {
    Swal.fire({
      title: 'Guardando...',
      text: 'Por favor espere',
      allowOutsideClick: false,
    });
    Swal.showLoading();
    this.formData.append('files[]', this.files[0], this.files[0].name);
    this.formData.append('opcion', 'insert');
    this.imagenesFaltantesService.insert(this.formData).then((res) => {
      if (res.message == 'true') {
        this.imagenesFaltantesService.updateObservable(res);
        Swal.fire({
          type: 'success',
          title: 'Correcto',
          text: 'Se ha guardado correctamente',
          footer: 'Excelente',
          allowOutsideClick: false,
          confirmButtonText: 'Aceptar'
        }).then((res) => {
          if (res.value) {
            this.matDialogRef.close(true);
          }
        });
      } else {
        throw new Error(res.message);
      }
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
        footer: 'Comunícate para resolver el problema'
      });
    });
  }
}