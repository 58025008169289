import { Component, OnInit } from '@angular/core';
import { AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ReportePedidosEspecialesService } from './../../services/reportes/reporte-pedidos-especiales.service';
import { environment } from '../../../environments/environment';
import { MomentModule } from 'ngx-moment';
import moment from 'moment';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ABCService } from '../ABC/utils/services/ABC.service';
import {   DataSucursales, sucursal } from '../ABC/utils/models/ABC'; //     ../utils/models/ABC';
import { DateAdapter } from '@angular/material/core';
import { GetDateService } from './../../services/Date/get_date.service';
import { Date_ } from '../../models/date_';


// export interface UserData {

//   ALMACENID: number;
//   COSTEADA: string;
//   ESPECIAL: number;
//   FECHAOC: Date;
//   FECHAPEDIDO: Date;
//   FECHARECEPCION: Date;
//   FECHAREQUI: Date;
//   FOLIOFAC: number;
//   NOMBRE: string;
//   ORDENCOMPRA: number;
//   PEDIDOID: number;
//   RECEPCION: number;
//   REQUISICION: number;
//   SUBTOTAL: number;
//   USUARIOGENERA: string;
// }

@Component({
  selector: 'app-reporte-pedidos-especiales',
  templateUrl: './reporte-pedidos-especiales.component.html',
  styleUrls: ['./reporte-pedidos-especiales.component.scss'],
})
export class ReportePedidosEspecialesComponent implements OnInit, AfterViewInit {
  public page: number = 1;
  public pageSize: number = 10;
  public lengthNumber: number;
  public infoPedidos = [];
  private urlApi = environment.apiUrl;
  private dataTable: any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public inputcosteada = new FormControl(null, []);
  private threeMonthAfter = new Date().toISOString();
  public columnas: string[] = [];
  public dataSource: MatTableDataSource<any>;
  public sucursales: sucursal[] = [];

  // displayedColumns: string[] = [
  //   'PEDIDOID',
  //   'ALMACENID',
  //   'FECHAPEDIDO',
  //   'NOMBRE',
  //   'SUBTOTAL',
  //   'ESPECIAL',
  //   'USUARIOGENERA',
  //   'FOLIOFAC',
  //   'REQUISICION',
  //   'FECHAREQUI',
  //   'ORDENCOMPRA',
  //   'FECHAOC',
  //   'RECEPCION',
  //   'FECHARECEPCION',
  //   'COSTEADA',
  // ];

  public formDates: FormGroup;
  public maxDate: Date = new Date();
  constructor(
    private pedidosEspeciales: ReportePedidosEspecialesService,
    private loadSpiner: NgxSpinnerService,
    private formbuilder: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private abcService: ABCService,
    private _adapter: DateAdapter<any>,
    private getDate: GetDateService

  ) {
    this._adapter.setLocale('mx');
    this.formDates = this.formbuilder.group({
      fechaInicio: [this.threeMonthAfter, [Validators.required]],
      fechaFinal: [new Date().toISOString(), [Validators.required]],
      sucursales: ['', [Validators.required]]
    });


  }
  ngAfterViewInit() {
    // this.moment.
  }
  // private format(date): string {
  //   return moment(date).locale('es').format('YYYYMMDD');
  // }
  ngOnInit() {
    this.setDate();
    this.getSucursales();
    // this.loadSpiner.show('spinner-pedidos-especiales');
    // this.pedidosEspeciales
    //   .getInfoPedidosEspeciales('pedidosEspeciales_')
    //   .subscribe((res) => {
    //     if (res.error === false) {
          // const arrayListPedidos: any = res.pedidos;
          // this.lengthNumber = arrayListPedidos.length;
          // this.infoPedidos = arrayListPedidos;
          // const users = (res.pedidos);
          // this.dataSource = new MatTableDataSource(users);
          // this.dataTable = new MatTableDataSource(this.dataSource.data);

          // this.loadSpiner.hide('spinner-pedidos-especiales');
          // setTimeout(() => {
          //   this.dataSource.paginator = this.paginator;
          //   this.dataSource.sort = this.sort;

          // }, 500);
          // this.filter();
      //   }
      // });
  }

  private setDate() {

    this.formDates.disable();
    this.getDate.get_Date('Y/m/01', 'Y/m/d').pipe().subscribe((response: Date_) => {

      console.groupCollapsed();
      console.log('reponse::', response);
      console.log('FI', new Date(response.fechaInicial), 'FF', new Date(response.fechaFinal));
      console.groupEnd();
      this.formDates.get('fechaInicio').setValue(new Date(response.fechaInicial));
      this.formDates.get('fechaFinal').setValue(new Date(response.fechaFinal));
      this.formDates.enable(); // Habilitado

    });

  }

  public getData() {

      this.loadSpiner.show('spinner-pedidos-especiales');
      const fechaInicio = this.formDates.get('fechaInicio').value;
      const fechaFinal  = this.formDates.get('fechaFinal').value;
      if (fechaInicio > fechaFinal) {
        Swal.fire({
          type: 'warning',
          title: 'Fechas incorrectas',
          text: 'La fecha inicial no puede ser mayor a la fecha final',
          confirmButtonText: 'Aceptar'
        });
        this.loadSpiner.hide('spinner-pedidos-especiales');
        return;
      }
    this.pedidosEspeciales
      .getInfoPedidosEspeciales(this.getInputValue('sucursales').value, fechaInicio, fechaFinal, 'pedidosEspeciales_')
      .subscribe((res) => {
       if (res.error === false) {
          if (res.pedidos.length > 0) {
            this.dataSource = new MatTableDataSource(res.pedidos);
            this.dataTable  = new MatTableDataSource(this.dataSource.data);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.columnas = res.columnas;
            this.infoPedidos = res.pedidos;
            this.deleteFilters();
          } else {
              this.dataSource = null;
              this.columnas = [];
              this.openSnackBar('No se encontraron resultados en la busqueda.');
          }
        } else {
             this.dataSource = null;
              this.columnas = [];
          this.openSnackBar(res.message);
        }
          this.loadSpiner.hide('spinner-pedidos-especiales');

        // if (res.message === 'true') {
        //   const arrayListPedidos: any = res.pedidos;
        //   this.lengthNumber = arrayListPedidos.length;
        //   this.infoPedidos = arrayListPedidos;
        //   const users = (res.pedidos);
        //   this.dataSource = new MatTableDataSource(users);
        //   this.dataTable = new MatTableDataSource(this.dataSource.data);
        //   this.loadSpiner.hide('spinner-pedidos-especiales');
        //   setTimeout(() => {
        //     this.dataSource.paginator = this.paginator;
        //     this.dataSource.sort = this.sort;

        //   }, 500);
        //   this.filter();
        // }
      });
  }

  private getSucursales() {
    this.abcService.getSucursales().subscribe((res: DataSucursales) => {
       this.sucursales = res.sucursales;
    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }


  }
  public deleteFilters = () => {
    // this.formDates.get('fechaInicio').setValue(this.threeMonthAfter);
    // this.formDates.get('fechaFinal').setValue(new Date());
    this.inputcosteada.setValue(null);
    this.filter();
  }
  public filter = () => {
    // console.log('liena 178');
    if (this.formDates.valid) {
      // console.log('liena 179');
      const costeada = this.inputcosteada.value;
      this.dataSource.data = this.dataTable.data;
      // const fechaInicio = (new Date(this.formDates.get('fechaInicio').value).toISOString().slice(0, 10));
      // const fechaFinal = (new Date(this.formDates.get('fechaFinal').value).toISOString().slice(0, 10));
      const list: any = [];
      let dataTable: any = this.dataSource;
      if (dataTable.filteredData) {
        dataTable = dataTable.filteredData;
      }
      console.log(costeada);
      dataTable.forEach(element => {
        if (costeada) {
          if(element.Costeada === costeada){
             list.push(element);
          }

        }else{
           list.push(element);
        }
        // console.log(element.FECHAPEDIDO);
        // console.log(moment(element.FECHAPEDIDO).isBetween(fechaInicio, fechaFinal));
        // if (costeada) {
        //   if (moment(element.FechaPedido).isBetween(fechaInicio, fechaFinal,null, '[]') && element.Costeada === costeada) {
        //     list.push(element);
        //   }
        // } else {
        //   if (moment(element.FechaPedido).isBetween(fechaInicio, fechaFinal,null, '[]')) {
        //     list.push(element);
        //   }
        // }
      });
      if(list.length<=0){
        this.openSnackBar('No se encontraron resultados en la busqueda.¡Favor de utilizar otro rango de fechas!')
      }
      this.dataSource.data = list;
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    } else {
      this.formDates.markAllAsTouched();
    }
  }
  converDate = (date: string): Date => {
    const dateTransform = moment(date).locale('es').format('YYYY/MM/DD 00:00:0000');
    return new Date(dateTransform);
  }
  exportToExcel(): void {
    this.loadSpiner.show('spinner-pedidos-especiales');
    this.pedidosEspeciales.exportExcelPedidosEspeciales(this.infoPedidos ,this.columnas).then((res) => {
      this.loadSpiner.hide('spinner-pedidos-especiales');
    }).catch((error)=>{
      this.loadSpiner.hide('spinner-pedidos-especiales');
      Swal.fire({
            type: 'warning',
            title: 'Advertencia',
            text: error.message,
          });
    });



    // this.abcService.getExcelAbcTres(items,this.response.ventasAbcTres, this.response.tipoReporte,this.response.columnasMesArray,this.response.sumaMes).then((res) => {
    //   this.spinner.hide('spinner-reporte-abc-tres');
    // }).catch((err: any) => {
    //   this.spinner.hide('spinner-reporte-abc-tres');
    //   Swal.fire({
    //     type: 'warning',
    //     title: 'Advertencia',
    //     text: err.message,
    //   });
    // });

    // this.pedidosEspeciales
    //   .exportAsExcelFile()
    //   .then((res) => {
    //     if (res.message === 'true') {
    //       location.href =
    //         this.urlApi +
    //         'reportes/reporte-pedidos-especiales/Files/PedidosEspecialesKABIK.xlsx';
    //     }
    //   })
    //   .catch((err) => console.log({ fatal: err }));
  }
  public showDetails(data): void {
    this.router.navigate(['pedidos-especiales', 'detalle', data.PEDIDOID, data.ALMACENID])
  }
  public exportToExcelWidthFilter = () => {
 let dataTable = this.dataSource.sortData(this.dataSource.data, this.sort);
     dataTable = this.dataSource.filteredData ? this.dataSource.filteredData : dataTable;
    this.loadSpiner.show('spinner-pedidos-especiales');
    this.pedidosEspeciales.exportExcelPedidosEspeciales(dataTable,this.columnas).then((res) => {
      this.loadSpiner.hide('spinner-pedidos-especiales');
    }).catch((error)=>{
      this.loadSpiner.hide('spinner-pedidos-especiales');
      Swal.fire({
            type: 'warning',
            title: 'Advertencia',
            text: error.message,
          });
    });

    // let dataTable = this.dataSource.sortData(this.dataSource.data, this.sort);
    // dataTable = this.dataSource.filteredData ? this.dataSource.filteredData : dataTable;
    // this.pedidosEspeciales.exportAsExcelWidthFilter(dataTable).subscribe(result => {
    //   if (result.message === 'true') {
    //     location.href =
    //       this.urlApi +
    //       'reportes/reporte-pedidos-especiales/Files/PedidosEspecialesKABIK.xlsx';
    //   }
    // });
  }
  public getInputValue(control: string) {
    return this.formDates.get(control);
  }
  private openSnackBar(mensaje: string) {
    this.snackBar.open(mensaje, 'Ok', {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
    setTimeout(() => {
    }, 5000);
  }
}
