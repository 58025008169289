import { Component } from '@angular/core';
import { LoginService } from '../../services/login/login.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmpresaService } from '../../services/Empresa/Empresa.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls:[
    'login.scss'
  ]
})
export class LoginComponent {
  alert: any;
  public isCliente: boolean = false;
  public selectedEmpresaId: number;

  constructor(
    private auth: LoginService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activeRoute: ActivatedRoute,
    private empresaService: EmpresaService
  ) {
    this.selectedEmpresaId = this.empresaService.getEmpresaId();
    this.activeRoute.params.subscribe(
      (param) => {
        if (Object.entries(param).length != 0) {
          // if (param.generar_guia_devolucion === 'generar_guia_devolucion') {

          // } else {
             this.isCliente = false;
         // }
        }
      },
      (error) => {
        console.log({ error });
      }
    );
    if (this.auth.isLogged) {
      if(this.isCliente === false) {
         this.router.navigate(['/']);
      }
    }
  }
  ngOnInit() {
    // Inicializa el select con el valor del environment
    this.selectedEmpresaId = environment.empresaId;
    this.selectedEmpresaId = this.empresaService.getEmpresaId();

  }

  onEmpresaChange(event: any) {
    const selectedId = +event.target.value;
    // this.empresaService.setEmpresaId(selectedId);
    this.empresaService.setEmpresaId(selectedId);
  }
  login(form) {
    if (form.valid) {
      this.spinner.show('spinner-login');
      if(this.isCliente === false){
        this.auth
        .login(form.controls.user.value, form.controls.pwd.value)
        .then((res) => {
          this.spinner.hide('spinner-login');
          if (res.message === 'true') {
             this.router.navigate(['/']);
            // this.router.navigate(['/generar-guia-detalle/detalle/ProcesosInternos']);
          } else {
            this.alert = {
              type: 'danger',
              message: res.error,
            };
          }
        })
        .catch((err) => {
          this.spinner.hide('spinner-login');
          Swal.fire({
            type: 'error',
            title: 'Error',
            text: 'Contacte al administrador',
          });
        });
      }
      /*else{

        // console.log('Console log entro al else');
        this.auth.loginCliente(form.controls.user.value, form.controls.pwd.value)
        .then((res) => {
          this.spinner.hide('spinner-login');
          if(res.error === 'false'){
            this.router.navigate(['/generar-guia-detalle/detalle/ProcesosInternos']);
         } else {
           this.alert = {
             type: 'danger',
             message: res.message,
           };
         }
        })
      }*/
    }
  }

  closeAlert() {
    this.alert = null;
  }
}
