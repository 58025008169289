import { Component, OnInit } from '@angular/core';
import { NgbDate, NgbCalendar, NgbPeriod } from '@ng-bootstrap/ng-bootstrap';
import { ReporteVentasService } from '../../services/reportes/reporte-ventas.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-reporte-ventas-cliente',
  templateUrl: './reporte-ventas-cliente.component.html',
  styleUrls: ['./reporte-ventas-cliente.component.scss']
})
export class ReporteVentasClienteComponent implements OnInit {
  fechaInicio: NgbDate;
  fechaFinal: NgbDate;
  maxDate: NgbDate;
  minDate: NgbDate;
  period: NgbPeriod;
  ventasIndividuales: any;
  ventas: any;
  page: number = 1;
  pageSize: number = 5;
  length: number;
  constructor(
    private calendar: NgbCalendar,
    private reporte: ReporteVentasService,
    private spinner: NgxSpinnerService
  ) {
    this.maxDate = this.calendar.getToday();
  }

  fechaToString(date: NgbDate) {
    const day = date.day < 10 ? '0' + date.day.toString() : date.day.toString();
    const month =
      date.month < 10 ? '0' + date.month.toString() : date.month.toString();
    const year = date.year.toString();
    return year + month + day;
  }

  loadData(fechaInicio: NgbDate, fechaFinal: NgbDate) {
    this.spinner.show('spinner-ventas');
    this.reporte
      .getDataVentasCliente(
        this.fechaToString(fechaInicio),
        this.fechaToString(fechaFinal)
      )
      .then((res) => {
        // console.log(res);
        if (res.message === 'true') {
          const vent: any = res.ventas;
          this.length = vent.length;
          for (let index = 0; index < vent.length; index++) {
            vent[index].ENE = parseFloat(vent[index].ENE);
            vent[index].FEB = parseFloat(vent[index].FEB);
            vent[index].MAR = parseFloat(vent[index].MAR);
            vent[index].ABR = parseFloat(vent[index].ABR);
            vent[index].MAY = parseFloat(vent[index].MAY);
            vent[index].JUN = parseFloat(vent[index].JUN);
            vent[index].JUL = parseFloat(vent[index].JUL);
            vent[index].AGO = parseFloat(vent[index].AGO);
            vent[index].SEP = parseFloat(vent[index].SEP);
            vent[index].OCT = parseFloat(vent[index].OCT);
            vent[index].NOV = parseFloat(vent[index].NOV);
            vent[index].DIC = parseFloat(vent[index].DIC);
            vent[index].TOT = parseFloat(vent[index].TOT);
          }
          this.ventas = vent;
        }
        this.spinner.hide('spinner-ventas');
      }).catch(err => {
        console.log(err);
        this.spinner.hide('spinner-ventas');
      });
  }

  exportToExcel(): void {
    this.reporte.exportAsExcelFileReporteClientes(
      this.ventas,
      'reporte_ventas_clientes_'
    );
  }

  ngOnInit() {
    this.fechaFinal = this.calendar.getToday();
    this.period = 'm';
    this.fechaInicio = this.calendar.getPrev(this.fechaFinal, this.period, 0);
    this.loadData(this.fechaInicio, this.fechaFinal);
  }
}
