import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getExtension'
})
export class GetExtensionPipe implements PipeTransform {

  transform(archivo: string): string {
   const extencion =  archivo.slice((archivo.lastIndexOf('.') - 1 >>> 0) + 2);
    if (extencion === 'pdf' || extencion === 'PDF') {
      return 'https://docs.google.com/viewer?url=' + archivo + '&amp;embedded=true';
    }
    return archivo;
  }
}
