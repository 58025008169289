import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'autocomplete'
})
export class AutocompletePipe implements PipeTransform {

  transform(value: any, arg: any, type: string): any {
    // console.log('el valor es value', value);
    // console.log('args', arg);
    // console.log('Tipe', type);
    const resultadoSearch: any = [];
    if (value !== undefined && value !== null) {
      switch (type) {
        case 'text':
          // console.log(value);
          // console.log('ins',arg);
          for (const array of value) {
            if (array.toLowerCase().indexOf(arg.toLowerCase()) > - 1) {
              resultadoSearch.push(array);
            }
          }
          return resultadoSearch;
        case 'categoria':
          for (const array of value) {
            if (array.departamento.toLowerCase().indexOf(arg.toLowerCase()) > - 1) {
              resultadoSearch.push(array);
            }
          }
          return resultadoSearch;
        case 'marca':
          for (const array of value) {
            if (array.Nombre.toLowerCase().indexOf(arg.toLowerCase()) > - 1) {
              resultadoSearch.push(array);
            }
          }
          return resultadoSearch;
      }
    }
  }
}
