import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { AltaClientesListComponent } from './views/clientes/alta-clientes-list/alta-clientes-list.component';
import { DetailClienteComponent } from './views/clientes/detail-cliente/detail-cliente.component';
import { ImagenesExternasComponent } from './views/imagenes-externas/imagenes-externas.component';
import { ReporteVentasComponent } from './views/reporte-ventas/reporte-ventas.component';
import { ArticulosComponent } from './views/articulos/articulos.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { VentasGuard } from './guards/reportes/ventas.guard';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { AltasGuard } from './guards/clientes/alta-cliente/altas.guard';
import { ImagenesGuard } from './guards/imagenes/imagenes.guard';
import { BackorderGuard } from './guards/backorder/backorder.guard';
import { OfertasGuard } from './guards/ofertas/ofertas.guard';
import { ArticulosGuard } from './guards/imagenes/articulos.guard';
import { ClavesGenericasComponent } from './views/claves-genericas/claves-genericas.component';
import { ImagenesFaltantesComponent } from './views/imagenes-faltantes/imagenes-faltantes.component';
import { ArticulosImagenesGuard } from './guards/articulos-imagenes/articulos-imagenes.guard';
import { ArticulosInsumosComponent } from './views/articulos-insumos/articulos-insumos.component';
import { ClavesGenericasGuard } from './guards/claves-genericas/claves-genericas.guard';
import { ReporteVentasClienteComponent } from './views/reporte-ventas-cliente/reporte-ventas-cliente.component';
import { VentasClienteGuard } from './guards/reportes/ventas-cliente.guard';
import { BolsaDeTrabajoComponent } from './views/recursos-humanos/bolsa-de-trabajo/bolsa-de-trabajo.component';
import { BolsaDeTrabajoGuard } from './guards/recursos-humanos/bolsa-de-trabajo/bolsa-de-trabajo.guard';
import { ReporteEncuestaComponent } from './views/reporte-encuesta/reporte-encuesta.component';
import { EncuestaGuard } from './guards/reportes/encuesta.guard';
import { ReporteImagenesSubidasComponent } from './views/reporte-imagenes-subidas/reporte-imagenes-subidas.component';
import { ImagenesSubidasGuard } from './guards/reportes/imagenes-subidas.guard';
import { ReporteParticipacionComponent } from './views/reporte-participacion/reporte-participacion.component';
import { ParticipacionGuard } from './guards/reportes/participacion.guard';
import { ReporteAbcComponent } from './views/reporte-abc/reporte-abc.component';
import { AbcGuard } from './guards/reportes/abc.guard';

import { ReporteAbc2Component } from './views/reporte-abc2/reporte-abc2.component';
import { Abc2Guard } from './guards/reportes/abc2.guard';

import { ReporteListaClienteComponent } from './views/reporte-lista-cliente/reporte-lista-cliente.component';
import { ListaClienteGuard } from './guards/reportes/lista-cliente.guard';
import { ReportePedidosEspecialesComponent } from './views/reporte-pedidos-especiales/reporte-pedidos-especiales.component';
import { ReportePedidosEspecialesGuard } from './guards/reportes/reporte-pedidos-especiales.guard';
import { DetallePedidosEspecialesComponent } from './views/reporte-pedidos-especiales/detalle-pedidos-especiales/detalle-pedidos-especiales.component';
import { ArticulosWebComponent } from './views/articulos-web/articulos-web.component';
import { GenerarGuiaComponent } from './views/Almacenes/generar-guia/generar-guia.component';
import { GenerarGuiaGuard } from './guards/Almacenes/generar-guia.guard';
import { GenerarGuiaDetalleComponent } from './views/Almacenes/generar-guia-detalle/generar-guia-detalle.component';
import { GenerarGuiaDetalleGuard } from './guards/Almacenes/generar-guia-detalle.guard';
import { ImprimirGuiaComponent } from './views/Almacenes/imprimir-guia/imprimir-guia.component';
import { ImprimirGuiaGuard } from './guards/Almacenes/imprimir-guia.guard';

import { ImprimirGuiaDetalleComponent } from './views/Almacenes/imprimir-guia-detalle/imprimir-guia-detalle.component';
import { ImprimirGuiaDetalleGuard } from './guards/Almacenes/imprimir-guia-detalle.guard';
import { ConsultaCoberturaComponent } from './views/Almacenes/consulta-cobertura/consulta-cobertura.component';
import { ConsultaCoberturaGuard } from './guards/Almacenes/consulta-cobertura.guard';
import { CatologoRemitentesComponent } from './views/Almacenes/catologo-remitentes/catologo-remitentes.component';
// import {GenerarGuiaDevolucionClienteComponent} from './views/Almacenes/generar-guia-devolucion-cliente/generar-guia-devolucion-cliente.component';
// import {GenerarUserGuiaDevolucionComponent} from './views/Almacenes/generar-user-guia-devolucion/generar-user-guia-devolucion.component';
// import { ReporteVentas2Component } from './views/reporte-ventas2/reporte-ventas2.component';
import { DepartamentosComponent } from './views/articulos-web/departamentos-web/departamentos.component';
import { MarcasComponent } from './views/articulos-web/marcas-web/marcas.component';
import { GruposComponent } from './views/articulos-web/grupos-web/grupos.component';
import { ReporteCanjesKabicoinsComponent } from './views/reporte-canjes-kabicoins/reporte-canjes-kabicoins.component';
import { ClientesNuevosComponent } from './views/clientes/clientes-nuevos/clientes-nuevos.component';
import { ArticulosProduccionInsumosComponent } from './views/Almacenes/articulos-produccion-insumos/articulos-produccion-insumos.component';
import { ReporteListaPreciosComponent } from './views/reporte-lista-precios/reporte-lista-precios.component';
import { ListaPreciosGuard } from './guards/reportes/lista-precios/lista-precios.guard';
import { ArticulosProduccionGuard } from './guards/reportes/articulos-produccion/articulos-produccion.guard';
import { ReporteClientesKabikcoinsComponent } from './views/reporte-clientes-kabikcoins/reporte-clientes-kabikcoins.component';
import { ClientesKabiCoinsGuard } from './guards/reportes/clientes-kabiCoins/clientes-kabi-coins.guard';
import { UltimasPiezasComponent } from './views/ultimas-piezas/ultimas-piezas.component';
import { ReporteVentasMarcasComponent } from './views/reporte-ventas-marcas/reporte-ventas-marcas.component';
import {VentasMarcasGuard} from './guards/ventas-marcas/ventas-marcas.guard';
import { PermisosComponent } from './views/permisos/permisos.component';
import { PermisosGuard } from './guards/permisos/permisos.guard';
import { ArticulosInsumosGuard } from './guards/articulos-insumos/articulos-insumos.guard';
import { ProgramacionPagosComponent } from './views/programacion-pagos/programacion-pagos.component';
import { ProgramacionPagosGuard } from './guards/programacion-pagos/programacion-pagos.guard';
import { DevolucionesComponent } from './views/devoluciones/devoluciones.component';
import { LicenciasZafiroComponent } from './views/licencias-zafiro/licencias-zafiro.component';
import { LicenciasZafiroGuard } from './guards/licencias_zafiro/licencias_zafiro.guard';
import { FirmaComponent } from './views/firma/firma.component';
import { FirmaGuard } from './guards/firma/firma.guard';
import { PeriodoInventarioComponent } from './views/inventario/periodo-inventario/periodo-inventario.component';
import { PeriodosGuard } from './guards/inventario/periodos/periodos.guard';
import { ReporteInventarioComponent } from './views/inventario/reporte-inventario/reporte-inventario.component';
import { AnalisisComponent } from './views/reportes/ventas/analisis/analisis.component';

import { ReporteListaClienteGuard } from './guards/clientes/lista-cliente/lista-cliente.guard';
import { EstadoCuentaGuard } from './guards/clientes/estado-cuenta/estado-cuenta.guard';
import { ClientesNuevosGuard } from './guards/clientes/clientes-nuevos/clientes-nuevos.guard';
import { ReporteVentasGuard } from './guards/reportes/reporte-ventas/reporte-ventas.guard';


import { GruposWebGuard } from './guards/compras/grupos-web/grupos-web.guard';
import { ArticulosWebGuard } from './guards/compras/articulos-web/articulos-web.guard';
import { DeparmanetosWebGuard } from './guards/compras/departamentos-web/deparmanetos-web.guard';
import { MarcasWebGuard } from './guards/compras/marcas-web/marcas-web.guard';
import { ArticulosAlmacenUpcComponent } from './views/Almacenes/articulos-almacen-upc/articulos-almacen-upc.component';
import { ArticuloUpcGuard } from './guards/Almacenes/articulos-upc.guard';
import { AlmacenDevolucionesGuard } from './guards/Almacenes/almacen-devoluciones.guard';
import { PedidosComponent } from './views/Multi-inventarios/pedidos/pedidos.component';
import { MatrizComponent } from './views/matriz/matriz.component';
import { MatrizWebGuard } from './guards/compras/matriz-web/matriz-web.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
   {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404',
    },
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500',
    },
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page',
    },
  },
  // {
  //   path: 'login-clientes/:generar_guia_devolucion',
  //   component: LoginComponent,
  //   data: {
  //     title: 'Login cliente',
  //   },
  // },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Kabik',
    },
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          title: 'Dashboard',
        },
      },
      {
        path: 'clientes/list',
        component: AltaClientesListComponent,
        data: {
          title: 'Alta de Clientes',
        },
        canActivate: [AltasGuard],
        canLoad: [AltasGuard],
      },
      {
        path: 'clientes/nuevos',
        component: ClientesNuevosComponent,
        data: {
          title: 'Clientes nuevos'
        },
        canActivate: [ClientesNuevosGuard],
        canLoad: [ClientesNuevosGuard],
      },
      {
        path: 'clientes/detalles/:id/:cliente_id',
        component: DetailClienteComponent,
        data: {
          title: 'Detalles',
        },
        canActivate: [AltasGuard],
        canLoad: [AltasGuard],
      },
      {
        path: 'clientes/reporte-lista-cliente',
        component: ReporteListaClienteComponent,
        data: {
          title: 'Lista de clientes',
        },
        canActivate: [ReporteListaClienteGuard],
        canLoad: [ReporteListaClienteGuard],
      },
      {
        path: 'marketing/imagenes-externas',
        component: ImagenesExternasComponent,
        data: {
          title: 'Imagenes',
        },
        canActivate: [ImagenesGuard],
        canLoad: [ImagenesGuard],
      },
      {
        path: 'imagenes-faltantes',
        component: ImagenesFaltantesComponent,
        data: {
          title: 'Imágenes Faltantes',
        },
        canActivate: [ArticulosImagenesGuard],
        canLoad: [ArticulosImagenesGuard],
      },
      {
        path: 'marketing',
        loadChildren: () =>
          import('./views/ofertas/ofertas.module').then((m) => m.OfertasModule),
        canActivate: [OfertasGuard],
        canLoad: [OfertasGuard],
      },
      {
        path: 'reporte-ventas',
        component: ReporteVentasComponent,
        data: {
          title: 'Reporte Ventas',
        },
        canActivate: [VentasGuard],
        canLoad: [VentasGuard],
      },
      // {
      //   path: 'reporte-ventas2',
      //   component: ReporteVentas2Component,
      //   data: {
      //     title: 'Reporte Ventas',
      //   },
      //   canActivate: [VentasGuard],
      //   canLoad: [VentasGuard],
      // },
      {
        path: 'reportes/ventas/reporte-ventas2',
        loadChildren: () =>
          import('./views/reporte-ventas2/reporte-ventas2.module').then((m) => m.ReporteVentas2Module),
          canActivate:[ReporteVentasGuard],
          canLoad: [ReporteVentasGuard]
      },
      {
          path: 'reportes/ventas/reporte-marcas',
          component: ReporteVentasMarcasComponent,
          data:
          {
            title: 'Venta marca por vendedor'
          },
          canActivate: [VentasMarcasGuard],
          canLoad: [VentasMarcasGuard],
        },
      {
        path: 'reportes/reporte-ventas-cliente',
        component: ReporteVentasClienteComponent,
        data: {
          title: 'Reporte Ventas Clientes',
        },
        canActivate: [VentasClienteGuard],
        canLoad: [VentasClienteGuard],
      },
      {
        path: 'reportes/reporte-canjes-kabiCoins',
        component: ReporteCanjesKabicoinsComponent,
        data: {
          title: 'Reporte Ventas Clientes',
        },
        canActivate: [VentasClienteGuard],
        canLoad: [VentasClienteGuard],
      },
      {
        path: 'reportes/reporte-participacion',
        component: ReporteParticipacionComponent,
        data: {
          title: 'Participacion Web',
        },
        canActivate: [ParticipacionGuard],
        canLoad: [ParticipacionGuard],
      },

      {
        path: 'reportes/ventas/abc',
        // component: ReporteAbcComponent,
        loadChildren: () =>
          import('./views/ABC/abc.module').then((m) => m.ABCModule),
        canActivate: [AbcGuard],
        canLoad: [AbcGuard],
      },

      // {
      //   path: 'reporte-abc',
      //   component: ReporteAbcComponent,
      //   data: {
      //     title: 'Ventas Cte ABC',
      //   },
      //   canActivate: [AbcGuard],
      //   canLoad: [AbcGuard],
      // },

      // {
      //   path: 'reporte-abc2',
      //   component: ReporteAbc2Component,
      //   data: {
      //     title: 'Ventas Cte ABC2',
      //   },
      //   canActivate: [Abc2Guard],
      //   canLoad: [Abc2Guard],
      // },
      {
        path: 'reportes/reporte-encuesta',
        component: ReporteEncuestaComponent,
        data: {
          title: 'Reporte encuesta',
        },
        canActivate: [EncuestaGuard],
        canLoad: [EncuestaGuard],
      },
      {
        path: 'recursos-humanos/reporte-imagenes-subidas',
        component: ReporteImagenesSubidasComponent,
        data: {
          title: 'Reporte imagenes subidas',
        },
        canActivate: [ImagenesSubidasGuard],
        canLoad: [ImagenesSubidasGuard],
      },
      {
        path: 'articulos',
        component: ArticulosComponent,
        data: {
          title: 'Articulos',
        },
        canActivate: [ArticulosGuard],
        canLoad: [ArticulosGuard],
      },
      {
        path: 'claves-genericas',
        component: ClavesGenericasComponent,
        data: {
          title: 'Claves Genéricas',
        },
        canLoad: [ClavesGenericasGuard],
        canActivate: [ClavesGenericasGuard],
      },
      {
        path: 'compras/articulos-insumos',
        component: ArticulosInsumosComponent,
        data: {
          title: 'Artículos Insumos',
        },
        canActivate: [ArticulosInsumosGuard],
        canLoad: [ArticulosInsumosGuard],
      },
      {
        path: 'recursos-humanos/bolsa-trabajo',
        component: BolsaDeTrabajoComponent,
        data: {
          title: 'Bolsa de trabajo',
        },
        canActivate: [BolsaDeTrabajoGuard],
        canLoad: [BolsaDeTrabajoGuard],
      },
      {
        path: 'compras/pedidos-especiales',
        component: ReportePedidosEspecialesComponent,
        data: {
          title: 'Pedidos Especiales',
        },
        canActivate: [ReportePedidosEspecialesGuard],
        canLoad: [ReportePedidosEspecialesGuard],
      },
      {
        path: 'compras/pedidos-especiales/detalle/:pedidoId/:almacenId',
        component: DetallePedidosEspecialesComponent,
        data: {
          title: 'Detalle Pedidos Especiales',
        },
        canActivate: [ReportePedidosEspecialesGuard],
        canLoad: [ReportePedidosEspecialesGuard],
      },
      {
        path: 'compras/articulos-web',
        component: ArticulosWebComponent,
        data: {
          title: 'Articulos Web',
        },
        canActivate: [ArticulosWebGuard],
        canLoad: [ArticulosWebGuard],
      },
      {
        path: 'compras/departamentos-web',
        component: DepartamentosComponent,
        data: {
          title: 'Departamentos Web',
        },
        canActivate: [DeparmanetosWebGuard],
        canLoad: [DeparmanetosWebGuard],
      },
      {
        path: 'compras/grupos-web',
        component: GruposComponent,
        data: {
          title: 'Grupos Web',
        },
        canActivate: [GruposWebGuard],
        canLoad: [GruposWebGuard],
      },
      {
        path: 'compras/ultimas-piezas',
        component: UltimasPiezasComponent,
        data: {
          title: 'Backorder',
        },
        canLoad: [BackorderGuard],
        canActivate: [BackorderGuard],
      },
      {
        path: 'compras/marcas-web',
        component: MarcasComponent,
        data: {
          title: 'Marcas Web',
        },
        canActivate: [MarcasWebGuard],
        canLoad: [MarcasWebGuard],
      },
      {
        path: 'generar-guia/:data',
        component: GenerarGuiaComponent,
        data: {
          title: 'Generar guia',
        },
        canActivate: [GenerarGuiaGuard],
        canLoad: [GenerarGuiaGuard],
      },
      {
        path: 'compras/matriz',
        component: MatrizComponent,
        data: {
          title: 'Matriz',
        },
        canLoad: [MatrizWebGuard],
        canActivate: [MatrizWebGuard],
      },
      {
        path: 'guia',
        loadChildren: () =>
        import('./views/Multi-inventarios/multi-inventarios.module').then((m) => m.ComprasModule),

      },
      {
        path: 'multi-inventarios',
        loadChildren: () =>
          import('./views/Multi-inventarios/multi-inventarios.module').then((m) => m.ComprasModule),
      },
      //  //        path: 'generar-guia-detalle/detalle/:pedidoId/:almacenId/:clienteId/:Nombre/:pedidoBuscar/:almacenBuscar/:clienteBuscar',
      {
        path: 'generar-guia-detalle/detalle/:data',
        component: GenerarGuiaDetalleComponent,
        data: {
          title: 'Documentacion de envios',
        },
        canActivate: [GenerarGuiaDetalleGuard],
        canLoad: [GenerarGuiaDetalleGuard],
      },
      {
        path: 'generar-guia-detalle/detalle',
        component: GenerarGuiaDetalleComponent,
        data: {
          title: 'Documentacion de envios',
        },
        canActivate: [GenerarGuiaDetalleGuard],
        canLoad: [GenerarGuiaDetalleGuard],
      },
      {
        path: 'imprimir-guia',
        component: ImprimirGuiaComponent,
        data: {
          title: 'Consultar guias',
        },
        canActivate: [ImprimirGuiaGuard],
        canLoad: [ImprimirGuiaGuard],
      },
      {
        path: 'imprimir-guia-detalle/detalle/:posicion/:data',
        component: ImprimirGuiaDetalleComponent,
        data: {
          title: 'Detalle seguimieno',
        },
        canActivate: [ImprimirGuiaDetalleGuard],
        canLoad: [ImprimirGuiaDetalleGuard],
      },
      {
        path: 'consultar-cobertura',
        component: ConsultaCoberturaComponent,
        data: {
          title: 'Consultar cobertura',
        },
        canActivate: [ConsultaCoberturaGuard],
        canLoad: [ConsultaCoberturaGuard],
      },
      {
        path: 'catalogo-remitentes',
        component: CatologoRemitentesComponent,
        data: {
          title: 'Direcciones',
        },
        canActivate: [ConsultaCoberturaGuard],
        canLoad: [ConsultaCoberturaGuard],
      },
      {
        path: 'almacen/produccion',
        component: ArticulosProduccionInsumosComponent,
        data: {
          title: 'Produccion',
        },
        canActivate: [ArticulosProduccionGuard],
        canLoad: [ArticulosProduccionGuard],
      },
      {
        path: 'almacen/devoluciones',
        component: DevolucionesComponent,
        data: {
          title: 'Devoluciones',
        },
        canActivate: [AlmacenDevolucionesGuard],
        canLoad: [AlmacenDevolucionesGuard],
      },
      {
        path: 'reportes/lista-precios',
        component: ReporteListaPreciosComponent,
        data: {
          title: 'Lista de precios',
        },
        canActivate: [ListaPreciosGuard],
        canLoad: [ListaPreciosGuard],
      },
      {
        path: 'reportes/clientes-kabikCoins',
        component: ReporteClientesKabikcoinsComponent,
        data: {
          title: 'Reporte clientes kabiCoins',
        },
        canActivate: [ClientesKabiCoinsGuard],
        canLoad: [ClientesKabiCoinsGuard],
      },
      {
        path: 'tesoreria/programacion-pagos',
        component: ProgramacionPagosComponent,
        data: {
          title: 'Programación Pagos',
        },
        canActivate: [ProgramacionPagosGuard],
        canLoad: [ProgramacionPagosGuard],
      },
      {
        path: 'herramientas/licencias-zafiro',
        component: LicenciasZafiroComponent,
        data: {
          title: 'Licencias Zafiro',
        },
        canActivate: [LicenciasZafiroGuard],
        canLoad: [LicenciasZafiroGuard],
      },
      {
        path: 'herramientas/firma',
        component: FirmaComponent,
        data: {
          title: 'Firma',
        },
        canActivate: [FirmaGuard],
        canLoad: [FirmaGuard],
      },
      {
        path: 'inventario/periodo-inventario',
        component: PeriodoInventarioComponent,
        data: {
          title: 'Periodo Inventario',
        },
        canActivate: [PeriodosGuard],
        canLoad: [PeriodosGuard],
      },
      {
        path: 'inventario/reporte-inventario',
        component: ReporteInventarioComponent,
        data: {
          title: 'Reporte Inventario',
        },
        canActivate: [PeriodosGuard],
        canLoad: [PeriodosGuard],
      },

      {
        path: 'clientes/Estado-de-cuenta',
        loadChildren: () =>
          import('./views/clientes/account-status/account-status.module').then((m) => m.AccountStatusModule),
          canActivate: [EstadoCuentaGuard],
          canLoad: [EstadoCuentaGuard],
      },
      {
        path: 'reportes/resumen',
        loadChildren: () =>
          import('./views/reporte-costeo-inventario/reporte-costeo-inventario.module').then((m) => m.ReporteCosteoInventarioModule),
          // canActivate: [EstadoCuentaGuard],
          // canLoad: [EstadoCuentaGuard],
      },

      {
        path: 'reportes/ventas',
        loadChildren: () =>
          import('./views/reporte-ventas-ctes-x-mes/ventas-ctes-x-mes.module').then((m) => m.VentasCtesMesModule),
          // canActivate: [EstadoCuentaGuard],
          // canLoad: [EstadoCuentaGuard],
      },


      {
        path: 'almacen/articulos-upc',
        component: ArticulosAlmacenUpcComponent,
        data: {
          title: 'Articulos UPC',
        },
        canActivate: [ArticuloUpcGuard],
        canLoad: [ArticuloUpcGuard],
      },
      /* {
        path: 'permisos',
        component: PermisosComponent,
        data: {
          title: 'Permisos',
        },
        canActivate: [PermisosGuard],
        canLoad: [PermisosGuard],
      }, */

      {
        path:"reportes/ventas",
        children:[
          {
            path:"analisis",
            component: AnalisisComponent
          }
        ]
      }
    ],
  },
  { path: '**', component: P404Component }
];

@NgModule({
  //imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
