import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';
import { PedidosService } from '../../../../services/pedidos/pedidos.service';
import { Observable } from 'rxjs';
// import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-modal-guia',
  templateUrl: './modal-guia.component.html',
  styleUrls: ['./modal-guia.component.scss'],
})
export class ModalPedidoGuiaComponent implements OnInit {
  public formGroup = this.formBuilder.group({
    IdEstatus: [null, [Validators.required]],
    Obervaciones: '',
  });
  public detalleArticulos$: Observable<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public matDialogRef: MatDialogRef<ModalPedidoGuiaComponent>,
    private pedidosService: PedidosService
  ) // private spinner: NgxSpinnerService
  {}

  ngOnInit() {
    console.log(this.data);

    const tpPedido = this.data.pedido.TipoPedido === 'Cedis' ? 1 : 0;
    this.detalleArticulos$ = this.pedidosService.getDetalleArticulos(
      this.data.pedido.AlmacenId,
      this.data.pedido.PedidoId,
      this.data.pedido.Cliente,
      tpPedido
    );
    console.log(this.detalleArticulos$);

    //  Empresa,almacen,Cliente,TipoPedido

    // this.patchValue(this.data.pedido);
  }

  modalClose = () => {
    this.data.reload = false;
    this.matDialogRef.close(this.data);
  };
  close = (event) => {
    console.log('event',event);
    if(event){
      this.data.reload = true;
      this.matDialogRef.close(this.data);
    }
  };




  patchValue = (data: any) => {
    this.formGroup.patchValue({
      IdEstatus: data.estatus[0].EstatusId,
    });
  };

  // onSubmit = () => {
  //   if (this.formGroup.invalid) {
  //     return;
  //   }
  //   this.insert();
  // }

  // insert = () => {
  //   Swal.fire({
  //     title: 'Actualizando...',
  //     text: 'Por favor espere',
  //     allowOutsideClick: false
  //   });
  //   Swal.showLoading();
  //   let observaciones: string = this.eliminarAcentos(this.formGroup.value.Obervaciones);
  //   this.pedidosService.insert(
  //     this.data.pedido.estatus[0].IdPedidoEstatus,
  //     this.data.pedido.IdWsPedido,
  //     this.data.pedido.AlmacenId,
  //     this.data.pedido.PedidoId,
  //     this.formGroup.value.IdEstatus,
  //     observaciones.trim()).subscribe((res) => {
  //     if (res.message === 'true') {
  //       Swal.fire({
  //         type: 'success',
  //         title: 'Correcto',
  //         text: res.response,
  //         footer: 'Excelente',
  //         allowOutsideClick: false,
  //         confirmButtonText: 'Aceptar'
  //       }).then((res) => {
  //         if (res.value) {
  //           this.matDialogRef.close(this.data);
  //         }
  //       });
  //     } else {
  //       Swal.fire({
  //         type: 'info',
  //         text: res.response,
  //         allowOutsideClick: false,
  //         confirmButtonText: 'Aceptar'
  //       });
  //     }
  //   },
  //   (error) => {
  //     Swal.fire({
  //       type: 'error',
  //       title: 'Error',
  //       text: error.message,
  //       footer: 'Comunícate para resolver el problema'
  //     });
  //   });
  // }

  // public observacionesLength = (observaciones: string): string => {

  // }

  public eliminarAcentos = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };
}
