import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filtroRemitentes',
})
export class FiltroRemitentesPipe implements PipeTransform {
  transform(value: any, args: any): any {
    const filtroArray = [];
    if (typeof args === undefined || args === '' || args.length < 2)
      return value;

    value.forEach((element) => {
      if (
        element.nameDireccion.toLowerCase().indexOf(args.toLowerCase()) > -1
      ) {
        return filtroArray.push(element);
      }
    });
    return filtroArray;
  }
}
