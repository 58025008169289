import { Component, OnInit } from '@angular/core';
import { BolsaDeTrabajoService } from '../../../services/bolsa-de-trabajo/bolsa-de-trabajo.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bolsa-de-trabajo',
  templateUrl: './bolsa-de-trabajo.component.html',
  styleUrls: ['./bolsa-de-trabajo.component.scss']
})
export class BolsaDeTrabajoComponent implements OnInit {
  dataUser: {};
  datos = '';
  constructor(
    private recursosHumanos: BolsaDeTrabajoService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show('spinner-bolsa');
    this.loadData();
    this.spinner.hide('spinner-bolsa');
  }

  loadData() {
    this.recursosHumanos.getUserDataFull().subscribe(res => {
       this.dataUser = res;
       console.log('this data user');
       console.log(this.dataUser);
    });
  }

  searchData(dato: string) {
    this.spinner.show('spinner-bolsa');
    this.recursosHumanos.getSearchUser(dato).subscribe(res => {
      this.dataUser = res;
      this.spinner.hide('spinner-bolsa');
    });
  }

  refrech() {
    if (this.datos === '') {
      this.loadData();
    } else {
      console.log(this.datos);
      this.searchUser(this.datos);
    }
  }

  descargarCv(img, name) {
    const datos = {
      nameImg: img,
      nameCliente: name
    };
    this.recursosHumanos.getCV(datos);
  }

  searchUser(dato: string) {
    if (dato === '') {
      this.loadData();
    } else {
      this.searchData(dato);
    }
    this.datos = dato;
  }

  deleteJobBoard(id: number) {
    Swal.fire({
      type: 'warning',
      title: 'Advertencia!',
      text: 'Éste cambio no se puede deshacer, ¿desea continuar?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar'
    }).then(respuesta => {
      if (respuesta.value) {
        this.recursosHumanos.deleteCv(id).then(res => {
          if (res.message === 'true') {
            Swal.fire({
              type: 'success',
              title: 'Correcto!',
              text: 'Se ha eliminado exitosamente.'
            });
            this.loadData();
          } else {
            Swal.fire({
              type: 'warning',
              title: 'Error!',
              text: 'No se ha eliminado exitosamente.'
            });
          }
        }).catch(err => {
          console.log(err);
          Swal.fire({
            type: 'warning',
            title: 'Error!',
            text: 'No se ha eliminado exitosamente. Contacte al administrador'
          });
        });
      }
    });
  }
}
