import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ArticulosWebService } from '../service/articulos-web.service';

@Component({
  selector: 'app-dialog-add',
  templateUrl: './dialog-add.component.html',
  styleUrls: ['./dialog-add.component.scss']
})
export class DialogAddComponent implements OnInit {
  public formulario: FormGroup;
  public textHit: string;
  public spinner: boolean;
  constructor(
    private service: ArticulosWebService,
    private dialogRef: MatDialogRef<DialogAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }


  ngOnInit() {
   //data 1 = articulos
   //data 2 = deptarmentos
  //data 3 = marcas
   // if (this.data.opcion === 2) {}
  }
  public search(value: string): void {

     switch(this.data.opcion){
       case 1:
         this.insertArticulo(value);
         break;
        case 2:
          this.insertDepartamento(value);
          break;
        case 3:
          this.insertMarca(value);
      break;
      case 4:
        this.insertGrupo(value);
    break;
     }
  }

  insertArticulo(value:string){
    if (value) {
      this.spinner = true;
      this.service.insertArticle(value.trim()).subscribe(response => {
        if (response.estatus) {
          this.dialogRef.close(true);
        } else {
          this.textHit = response.mensaje;
          this.timeOut();
        }
        this.spinner = false;
      });
    }
  }

  insertDepartamento(value:string){
    if (value) {
      this.spinner = true;
      this.service.insertDeptops(value.trim()).subscribe(response => {
        if (response.estatus) {
          this.dialogRef.close(true);
        } else {
          this.textHit = 'Ocurrio un error al insertar el Departamento ' + response.mensaje;
          this.timeOut();
        }
        this.spinner = false;
      });
    }
  }

  insertMarca(value:string){
    if (value) {
      this.spinner = true;
      this.service.insertMarcas(value.trim()).subscribe(response => {
        if (response.estatus) {
          this.dialogRef.close(true);
        } else {
          this.textHit = response.mensaje;
          this.timeOut();
        }
        this.spinner = false;
      });
    }
  }
  private timeOut(): void {
    setTimeout(() => {
      this.textHit = null;

    }, 2800);
  }


  insertGrupo(value:string){
    if (value) {
      this.spinner = true;
      this.service.insertGrupos(value.trim()).subscribe(response => {
        if (response.estatus) {
          this.dialogRef.close(true);
        } else {
          this.textHit = response.mensaje;
          this.timeOut();
        }
        this.spinner = false;
      });
    }
  }
}
