import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

export const TIME_OUT = 1000 * 60 * 1; //ultimo número define en minutos
/**Clase provider que es básicamente un servicio generico para las peticiones a servicios */
@Injectable(
    {
        providedIn: "root"
    }
)
export class GenericService {

    public user: any = null;
    constructor(
        private http: HttpClient) {
    }

    /**Método que hace peticiones tipo GET */
    sendGetRequest(webservice_URL: string, clase: any = null) {
        let observable: any = this.http.get(webservice_URL);

        if (clase) {
            return observable.pipe(map((data: any) => {
                let arr: any = data;

                let obj: any = null;
                if (!Array.isArray(arr)) {
                    obj = clase.fromJson(arr);
                } else {
                    obj = arr.map(item => clase.fromJson(item));
                }
                return obj;
            }))
        } else {
            return observable;
        }
    }

    /**Método que hace peticiones tipo GET  con parámetros*/
    sendGetRequestParams(webservice_URL: string, params: any) {
        //return this.http.get(webservice_URL, params).timeout(TIME_OUT);
        return this.http.get(webservice_URL, params)
    }

    /**Método que hace peticiones tipo GET  con parámetros*/
    sendGetParams(webservice_URL: string, params: any) {
        //return this.http.get(webservice_URL, params).timeout(TIME_OUT);
        let options: any = {};
        options.params = params;
        return this.http.get(webservice_URL, options);
    }

    /**Método que hace peticiones tipo POST  con parámetros específicos*/
    sendPostRequestParams(webservice_URL: string, params: any, httpOptions: any) {
        //return this.http.post(webservice_URL, params, httpOptions).timeout(TIME_OUT);
        return this.http.post(webservice_URL, params, httpOptions);
    }

    /**Método que hace peticiones tipo POST */
    sendPostRequest(webservice_URL: string, request: { }) {
        //return this.http.post(webservice_URL, request).timeout(TIME_OUT);
        return this.http.post(webservice_URL, request);
    }

    /**Método que hace peticiones tipo PUT */
    sendPutRequest(webservice_URL: string, request: {} = {}) {
        //return this.http.post(webservice_URL, request).timeout(TIME_OUT);
        return this.http.put(webservice_URL, request);
    }

    /**Método que hace peticiones tipo DELETE */
    sendDeleteRequest(webservice_URL: string) {
        //return this.http.delete(webservice_URL).timeout(TIME_OUT);
        return this.http.delete(webservice_URL);
    }

    /**Método que hace peticiones tipo DELETE */
    sendDelete(webservice_URL: string) {
        //return this.http.delete(webservice_URL).timeout(TIME_OUT);
        return this.http.delete(webservice_URL);
    }

}