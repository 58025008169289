import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientesService } from '../../../services/clientes.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-alta-clientes-list',
  templateUrl: './alta-clientes-list.component.html',
  styleUrls: ['./alta-clientes-list.component.scss']
})
export class AltaClientesListComponent implements OnInit {
  clientes: any;
  constructor(
    private clienteService: ClientesService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loadClientes();
    this.clienteService.currentClient.subscribe((res) => {
      if (res) {
        this.loadClientes();
      }
    });
  }

  loadClientes() {
    this.clienteService.getClientes().then((data) => {
      if (data.message === 'true') {
        this.clientes = data.clientes;
      }else{
        this.clientes = [];
      }
    });
  }

  delete(rfc, correo,id) {
    Swal.fire({
      type: 'warning',
      title: '¡ADVERTENCIA!',
      text: 'Esté cambio no se puede revertir, ¿desea continuar?',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'De acuerdo',
      confirmButtonClass: 'btn-danger'
    }).then((res) => {
      if (res.value) {
        this.clienteService
          .deleteCliente(rfc, correo,id)
          .then((res) => {
            if (res.message === 'true') {
              this.clienteService.updateCliente({ message: '', data: {} });
              this.clienteService
                .deleteFiles(rfc)
                .then((res) => {
                  this.loadClientes();
                  if (res.message === 'true') {
                    Swal.fire({
                      type: 'success',
                      title: '¡EXITO!',
                      text: 'Se eliminó el registro corretamente',
                      showConfirmButton: true,
                      confirmButtonText: 'De acuerdo',
                      confirmButtonClass: 'btn-succes'
                    }).then((res) => {
                      this.router.navigate(['/clientes/list']);
                    });
                  } else {
                    Swal.fire({
                      type: 'error',
                      title: '¡ERROR!',
                      text:
                        'No se eliminaron los archivos, contacta al administrador. Clave de carpeta: ' +
                        rfc,
                      showConfirmButton: true,
                      confirmButtonText: 'De acuerdo',
                      confirmButtonClass: 'btn-succes'
                    }).then((res) => {
                      this.router.navigate(['/clientes/list']);
                    });
                  }
                })
                .catch((err) => {
                  Swal.fire({
                    type: 'error',
                    title: '¡ERROR!',
                    text:
                      'No se eliminaron los archivos, contacta al administrador. Clave de carpeta: ' +
                      rfc,
                    showConfirmButton: true,
                    confirmButtonText: 'De acuerdo',
                    confirmButtonClass: 'btn-succes'
                  }).then((res) => {
                    this.router.navigate(['/clientes/list']);
                  });
                });
            } else {
              Swal.fire({
                type: 'error',
                title: '¡ERROR!',
                text: 'No se eliminó elregistro, contacta al administrador.',
                showConfirmButton: true,
                confirmButtonText: 'De acuerdo',
                confirmButtonClass: 'btn-succes'
              }).then((res) => {
                this.router.navigate(['/clientes/list']);
              });
            }
          })
          .catch((err) => {
            Swal.fire({
              type: 'error',
              title: '¡ERROR!',
              text: 'No se eliminó elregistro, contacta al administrador.',
              showConfirmButton: true,
              confirmButtonText: 'De acuerdo',
              confirmButtonClass: 'btn-succes'
            }).then((res) => {
              this.router.navigate(['/clientes/list']);
            });
          });
      }
    });
  }
}
