import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlmacenService } from '../../../services/Almacen/almacen.service';
import Swal from 'sweetalert2';
import { DetalleGuiasGeneradas } from '../model/Almacen';

import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateAdapter } from '@angular/material/core';

// export interface LIST {
//   position: number;
//   asignado: string;
//   guia: string;
//   cliente: string;
//   pedido: string;
//   factura: string;
//   tenvio: string;
//   tentrega: string;
//   destinatario: string;
//   estatus: string;
//   fcreacion: Date;
// }

@Component({
  selector: 'app-imprimir-guia',
  templateUrl: './imprimir-guia.component.html',
  styleUrls: ['./imprimir-guia.component.scss'],
})
export class ImprimirGuiaComponent implements OnInit {
  public fechaFinal: Date;
  public formBusquedaGuia: FormGroup;
  public maxDate: Date;
  public list: DetalleGuiasGeneradas[];
  public data: any;
  public displayedColumns: string[] = [
    'guia',
    'tipo',
    'cliente',
    'pedido',
    'factura',
    'tipoEnvio',
    'tipoEntrega',
    'destinatario',
    'fecha',
    'acciones',
  ];

  constructor(
    private fb: FormBuilder,
    private almacenService: AlmacenService,
    private dateAdapter: DateAdapter<Date>,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.dateAdapter.setLocale('es-ES');
  }

  ngOnInit() {
    this.validaForm();
    this.fecha();
    this.getGuiasGeneradas();
  }

  validaForm() {
    this.formBusquedaGuia = this.fb.group({
      FechaInicio: [''],
      FechaFin: [''],
    });
  }

  fecha() {
    this.maxDate = new Date();
    const date = new Date();
    this.formBusquedaGuia.get('FechaFin').setValue(new Date().toISOString());
    this.formBusquedaGuia
      .get('FechaInicio')
      .setValue(new Date(date.getFullYear(), date.getMonth(), 1)); //date.getFullYear(), date.getMonth() - 1, 1 new Date().toISOString()
  }
  getGuiasGeneradas() {
    this.spinner.show('spinner-guia');
    this.almacenService
      .getGuiasGeneradas(
        this.formBusquedaGuia.get('FechaInicio').value,
        this.formBusquedaGuia.get('FechaFin').value
      )
      .then((res) => {
        if (res.message === true) {
          this.list = res.data;
          this.spinner.hide('spinner-guia');

          this.data = res.data;
          // console.log(this.data);
          // let index = 0;
          // var unArray = [];
          // var object = new Object();
          // this.data.forEach((element) => {
          //   object = {
          //     position: index,
          //     asignado : element.tipo,
          //     cliente :element.cliente,
          //     pedido: element.pedido,
          //     factura:element.factura,
          //     guia: element.guia,
          //     destinatario: element.destinatario,
          //     tentrega: element.tipoEntrega == '1' ? 'Ocurre' : 'EAD',
          //     tenvio: element.tipoEnvio == '1' ? 'Sobre' : 'Paquete',
          //     estatus: element.estatus,
          //     fcreacion: element.fecha,
          //   };
          //   unArray.push(object);
          //   index++;
          //   this.spinner.hide('spinner-guia');
          // });
          //  console.log('Data');
          //  console.log(unArray);
        } else {
          this.spinner.hide('spinner-guia');
          Swal.fire({
            type: 'warning',
            title: 'Advertencia',
            text: 'No se encontraron registros.',
          });
        }
      })
      .catch((err: any) => {
        this.spinner.hide('spinner-guia');
        Swal.fire({
          type: 'warning',
          title: 'Advertencia',
          text: err,
        });
      });
  }

  navigate(element) {
    console.log(element);
    localStorage.setItem('dt', JSON.stringify(element));
    this.router.navigate(['imprimir-guia-detalle/detalle']);
  }

  exportToExcel() {
    this.spinner.show('spinner-guia');

    this.almacenService
      .getExcelGuiasGeneradas(this.list)
      .then((res) => {
        this.spinner.hide('spinner-guia');
      })
      .catch((err: any) => {
        this.spinner.hide('spinner-guia');
        Swal.fire({
          type: 'warning',
          title: 'Advertencia',
          text: err.message,
        });
      });

    // this.almacenService.exportAsExcelFileAlmacen(
    //   this.list,
    //   'Consultar_Guias_',
    //   'Cobertura'
    // );
  }
}
//navigate this.data[element.position]
