import { ComponentFactoryResolver, Injectable, OnDestroy } from "@angular/core";

/**Clase provider que se utiliza para generar mensajes de error, alerta o éxito
 * Se hizo de forma genérica para evitar repetir esta clase de código
 */
@Injectable({
  providedIn: "root"
})
export class UtilService implements OnDestroy {
  /**Constructor del servicio en el que se inyecta el controlador de alertas de ionic
   * y eventos de escucha para el momento de un cierre de sesión inesperado
   */
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  getRandomText(length) {
    var charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".match(/./g);
    var text = "";
    for (var i = 0; i < length; i++)
      text += charset[Math.floor(Math.random() * charset.length)];
    return text;
  }

  ngOnDestroy(): void {
    throw new Error("Method not implemented.");
  }

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  hexToRgbA(hex, transparentPercentage) {
    if (transparentPercentage > 100 || transparentPercentage < 0) {
      transparentPercentage = 1;
    }
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `,${transparentPercentage / 100})`;
    }
    throw new Error('Bad Hex');
  }

  shuffle(array) {//ordena aleatoriamente un array
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {

      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  compress(file: any, returnBase64: boolean = true, factor: number = 5): Promise<any> {
    console.log(file);
    file = file.target ? file.target.files[0] : file;
    const width = 500;
    const height = 500;
    const fileName = file.name;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = (event: any) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          let widthi = 0;
          let heighti = 0;
          if (file.size > 1000000) {
            widthi = img.width / factor;
            heighti = img.height / factor;
          } else {
            widthi = img.width;
            heighti = img.height;
          }

          const elem = document.createElement('canvas');
          elem.width = widthi;
          elem.height = heighti;
          const ctx = elem.getContext('2d');
          //ctx.globalAlpha = 0.2;
          // img.width and img.height will contain the original dimensions
          ctx.drawImage(img, 0, 0, widthi, heighti);
          ctx.canvas.toBlob(async (blob) => {
            let filet: any = new File([blob], fileName, {
              type: 'image/png',
              lastModified: Date.now()
            });
            let resultado = await this.getBase64(file);
            resolve(returnBase64 ? resultado : filet);
            //Logica de nuevo file


          }, 'image/png', 1);

        }, img.onerror = (error: any) => {
          reject(error);
        };
      },
        reader.onerror = (error: any) => {
          reject(error);
        };
    });
  }

  getBase64(file): Promise<any> {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }

  replaceAllByArrayString(array: string[], text: string, textReplace: string = "_") {
    let formatString = text;
    array.forEach(element => {
      formatString = formatString.replaceAll(element, textReplace);
    });
    return formatString;
  }
}
