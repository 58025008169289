
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListCheckPipe } from './filtros-pipes/list-check.pipe';
import { InnerHtmlPipe } from './innerHtml/innerHtml.pipe';
import { FormatDatePipe } from './formatDate/formatDate.pipe';
import { IntPipe } from './int/int.pipe';
@NgModule({
  declarations: [
    ListCheckPipe,
    InnerHtmlPipe,
    FormatDatePipe,
    IntPipe
   ],
  imports: [
    CommonModule
  ],
  exports: [
    ListCheckPipe,
    InnerHtmlPipe,
    FormatDatePipe,
    IntPipe
  ]

})
export class PipesCoreModule { }
