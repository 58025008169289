import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ComponetsCoreModule } from './components/componets-core.module';
import { PipesCoreModule } from './pipes/pipes-core.module';
// import { MaterialModule } from './module/material/material.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    ComponetsCoreModule,
    PipesCoreModule,
    // MaterialModule,
  ],
  exports: [
    ComponetsCoreModule,
    PipesCoreModule,
    // MaterialModule,
  ],
  providers: [

    ],

})
export class CoreModule { }
