import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import Swal from 'sweetalert2';
import { SelectionModel } from '@angular/cdk/collections';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { Articulos, UltimasPiezasService } from '../../services/ultimas-piezas/ultimas-piezas.service';
import { ModalUltimasPiezasComponent } from './modal-ultimas-piezas/modal-ultimas-piezas.component';

@Component({
  selector: 'app-ultimas-piezas',
  templateUrl: './ultimas-piezas.component.html',
  styleUrls: ['./ultimas-piezas.component.scss']
})

export class UltimasPiezasComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: MatTableDataSource<Articulos>;
  selection: SelectionModel<Articulos>;
  public articulos: Articulos[] = [];
  public columns: string[] = [];
  public data = [];
  displayedColumns: string[] = [
    'accion',
    'ClaveArticulo',
    'Descripcion',
    'MostrarBackOrder',
    'MostrarUltimasPiezas',
    'Quien',
    'Cuando'
  ];

  constructor(
    private ultimasPiezasService: UltimasPiezasService,
    private spinner: NgxSpinnerService,
    public modalEditar: MatDialog,
    public modalNuevo: MatDialog,
  ) { }

  ngOnInit() {
    this.loadData();
  }

  exportToExcel(): void {
    this.spinner.show('spinner');
    this.ultimasPiezasService.exportToExcel(this.data ,this.columns).then((res) => {
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
      });
      this.spinner.hide('spinner');
    });
  }

  public exportFilterToExcel = () => {
    this.spinner.show('spinner');
    let data = this.dataSource.filteredData;
    this.ultimasPiezasService.exportToExcel(data, this.columns).then((res) => {
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
      });
      this.spinner.hide('spinner');
    });
  }

  filter(event: Event) {
    const filter = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filter.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loadData() {
    this.spinner.show('spinner');
    this.ultimasPiezasService.show().then((res) => {
      if (res.message == 'true') {
        this.dataSource = new MatTableDataSource(res.response);
        this.selection = new SelectionModel<Articulos>(true, []);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.columns = res.columns;
        this.data = res.response;
      }
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
      });
      this.spinner.hide('spinner');
    });
  }

  eliminarArticulos(): void {
    Swal.fire({
      type: 'warning',
      title: '¿Está seguro de eliminar?',
      text: 'Esta operación no se puede revertir, ¿desea continuar?',
      confirmButtonText: 'Aceptar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#D33',
      focusConfirm: false,
      focusCancel: true,
    }).then(res => {
      if (res.value) {
        if (this.articulos) {
          Swal.fire({
            title: 'Eliminando...',
            text: 'Por favor espere',
            allowOutsideClick: false,
          });
          Swal.showLoading();
          this.ultimasPiezasService.delete(this.articulos).subscribe(res => {
            if (res.message == 'true') {
              Swal.fire({
                type: 'success',
                title: '¡Correcto!',
                text: 'Se ha eliminado correctamente',
                allowOutsideClick: false,
                confirmButtonText: 'Aceptar'
              }).then((res) => {
                if (res.value) {
                  this.articulos.splice(0, this.articulos.length);
                  this.loadData();
                }
              });
            } else {
              throw new Error('Hubo un error al eliminar en la base de datos, contacte al administrador');
            }
          },
          (error) => {
            Swal.fire({
              type: 'error',
              title: 'Error',
              text: error.message,
              footer: 'Comunícate para resolver el problema'
            });
          });
        }
      }
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
        footer: 'Comunícate para resolver el problema'
      });
    });
  }

  public openModalNuevo(): void {
    const modal = this.modalNuevo.open(ModalUltimasPiezasComponent, {
      width: '35%',
      disableClose: true,
    });
    modal.afterClosed().subscribe((res) => {
      if (res) {
        this.articulos.splice(0, this.articulos.length);
        this.loadData();
      }
    });
  }

  public openModalEditar() {
    const modal = this.modalEditar.open(ModalUltimasPiezasComponent, {
      width: '35%',
      data: this.articulos,
      disableClose: true,
    });
    modal.afterClosed().subscribe((res) => {
      if (res) {
        this.articulos.splice(0, this.articulos.length);
        this.loadData();
      }
    });
  }

  selected(event?: any, row?: Articulos) {
    if (event.checked) {
      this.articulos.push(row);
    } else {
      this.removeToArray(row);
    }
    event = event ? this.selection.toggle(row) : null;
    return event;
  }
  
  private removeToArray(row: Articulos): void {
    let count = 0;
    this.articulos.forEach(res => {
      if (res.Id === row.Id) {
        if (this.articulos.length <= 1) {
          this.articulos = [];
        } else {
          const result = count <= 1 ? 1 : count;
          this.articulos.splice(count, 1);
        }
      }
      count++;
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.articulos = [];
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(res => {
      this.articulos.push(res);
      this.selection.select(res);
    });
  }
}