import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-cloud-upload',
  templateUrl: './icon-cloud-upload.component.html',
  styleUrls: ['./icon-cloud-upload.component.scss']
})
export class IconCloudUploadComponent implements OnInit {
  @Input() public size: string;
  @Input() public color: string;
  @Input() public opacity: boolean;
  @Input() public notClassFa3x = false;
  constructor() { }

  ngOnInit() {
  }

}
