import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ImagesService } from '../../../../services/images/images.service';
import { FormControl } from '@angular/forms';
import { DataBaseService } from '../../../../services/DB/data-base.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-ofertas-img-externas',
  templateUrl: './ofertas-img-externas.component.html',
  styleUrls: ['./ofertas-img-externas.component.scss'],
})
export class OfertasImgExternasComponent implements OnInit, OnDestroy {
  /*   variable para enviar info */
  @Input()
  public InputTitulo: string = '';
  @Output()
  public ofertasList = new EventEmitter<Array<Object>>();

  public txtDexcripcion: string = '';
  public imagenAdcional: boolean = false;
  public clave = new FormControl('', []);
  public titulo = new FormControl('', []);
  public nameURL = new FormControl('', []);
  public isClave: string = '';
  public tituloState: boolean = false;
  public ulrState: boolean = false;

  public listClaves: any = [];
  public descripcion: string = '';
  public validando: boolean = false;

  constructor(
    private imagesService: ImagesService,
    private dataBase: DataBaseService
  ) {}

  ngOnInit() {
    this.nameURL.disable();
    this.clave.disable();
    if (this.InputTitulo !== '') {
      this.titulo.setValue(this.InputTitulo);
      this.nameURL.setValue(this.InputTitulo);
      this.fillInformation(this.InputTitulo);
    } else {
      document.querySelector('mat-dialog-content').scroll(0, 10000000);
    }
  }

  ngOnDestroy(): void {
    this.dataBase.cleanDB('oferta');
    localStorage.removeItem('--titulo--imagen--oferta');
    this.deleteData();
  }

  public fillInformation(titulo) {
    this.clave.setErrors(null);
    Swal.fire({
      title: 'Buscando Información...',
      text: 'Porfavor espere',
      allowOutsideClick: false,
    });
    Swal.showLoading();
    /*     buscamos todos los articulos cn el mismo campo del nombrepromocion */
    this.imagesService
      .getListOferta(titulo)
      .then((resp) => {
        const respuesta = <Array<any>>resp.respuesta;
        if (respuesta.length > 0) {
          /*          recorremos cada uno de los items */
          respuesta.forEach((val, k) => {
            /*          buscamos la descripcion de cada uno de los articulos */
            this.imagesService
              .verificarArticulo(val['ClaveArticulo'])
              .then((response) => {
                const descripcion = response.producto[0].Corta;
                this.dataBase.addDB('oferta', {
                  titulo: this.titulo.value,
                  url: this.titulo.value,
                  clave: val['ClaveArticulo'],
                  descripcion: descripcion,
                  estado: val['Activa'],
                });
                this.getAll();
                this.addTitulo();
                Swal.close();
              })
              .catch((error) => {
                Swal.close();
                Swal.fire({
                  type: 'error',
                  title: 'Oops...',
                  text: `Algo salio mal, ${error.message}`,
                  footer: 'Comunicate para resolver el problema',
                });
              });
          });
        } else {
          throw new Error('No se encontraron registros de esta oferta');
        }
      })
      .catch((err) => {
        console.log(err.message);
        Swal.close();
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: `Algo salio mal, ${err.message}`,
          footer: 'Comunicate para resolver el problema',
        });
      });
  }

  public getErrorMessage() {
    this.clave.setErrors({ incorrect: true });
    return 'La clave que ingresaste es incorrecta o no exite';
  }

  public addTitulo() {
    if (this.titulo.value) {
      localStorage.setItem('--titulo--imagen--oferta', this.titulo.value);
      this.tituloState = true;
      this.clave.enable();
      this.titulo.disable();
      if (this.listClaves.length > 0) {
        this.updateTitle();
      }
    }
  }

  public editTitulo() {
    if (this.titulo.value) {
      this.tituloState = false;
      this.clave.disable();
      this.titulo.enable();
    }
  }
  /*  format pascal case  */
  format = () => {
    this.nameURL.setValue(
      String(' ' + this.titulo.value)
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
    );
  };

  public key() {
    this.clave.setErrors(null);
    this.isClave = '';
    if (this.clave.hasError('incorrect')) {
      // console.log(this.clave);
    }
  }

  public cleanInput() {
    this.clave.setErrors(null);
    this.isClave = '';
    this.clave.setValue('');
  }

  public validarClave() {
    this.clave.disable();
    this.clave.setErrors(null);
    this.isClave = '';
    this.validando = true;
    this.imagesService
      .verificarArticulo(this.clave.value.trim())
      .then((response) => {
        try {
          console.log(response);

          if (response.total > 0) {
            const descripcion = response.producto[0].Corta;
            // this.clave.setValidators
            this.dataBase.addDB('oferta', {
              titulo: this.titulo.value,
              url: this.nameURL.value,
              clave: this.clave.value,
              descripcion: descripcion,
              estado: 1,
            });
            this.clave.enable();
            this.cleanInput();
            this.getAll();
          } else {
            this.clave.enable();
            this.getErrorMessage();
          }
          this.isClave = response.total > 0 ? 'ok' : 'notOK';
          this.validando = false;
          // console.log(this.listClaves);
        } catch (error) {
          console.log(error);
          this.clave.setErrors(null);
          this.clave.setValue('');
          this.isClave = 'notOk';
          this.validando = false;
        }
      });
  }
  public getAll() {
    this.dataBase.getAllDB('oferta').then((result) => {
      this.listClaves = result;
      this.ofertasList.emit(this.listClaves);
    });
  }
  public deleteItem(id) {
    this.dataBase.deleteByIdDB('oferta', id).then(
      () => {
        this.getAll();
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  private updateTitle() {
    this.dataBase.updateTitle('oferta', this.titulo.value);
  }

  deleteData() {
    this.dataBase.cleanDB('oferta');
  }
}
