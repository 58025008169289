import { environment } from './../../../../environments/environment.prod';
import { Component, OnInit, Inject, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { GenericService } from './../../../../app/services/generic.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  //changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sugerencia.component.html',
  styleUrls: ['./sugerencia.component.scss'],
  selector: "sugerencia"
})
export class SugerenciaComponent implements OnInit {

  public loader:boolean = false;

  public malasPalabras = [
    /* 'nalgas',
    'trasero',
    "puto",
    "puta",
    "putos", 
    "putas",
    "put@",
    "putæs",
    "put@s",
    "putæz",
    "put@z",
    "put4z",
    "put4",
    "put4s",
    "cul0",
    "cul0s",
    "cul0z",
    "cul0s",
    "cu10",
    "cu10s",
    "cu10z",
    "cu1oz",
    "cu1os",
    "ching",
    "ch1ng",
    "mam0n",
    "m4m0n",
    "m4mon",
    "mamon",
    "mam4n",
    "m4m4n",
    "m4man",
    "maman",
    "chlng" */
  ];

  public data: any = {
    name: {
      value: "",
      error: false,
      badWord: false
    },
    mail: {
      value: "",
      error: false,
      badWord: false
    },
    comment: {
      value: "",
      error: false,
      badWord: false
    }
  };

  public badWord: boolean = false;

  constructor(
    private genericService: GenericService,
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit() {

  }

  press(pos: number) {
    switch (pos) {
      case 1:
        if (this.data.comment.value.length > 0) {
          this.data.comment.error = false;
          this.data.comment.badWord = false;
        }
        break;
      case 2:
        if (this.data.name.value.length > 0) {
          this.data.name.badWord = false;
        }
        break;
      case 3:
        if (this.data.mail.value.length > 0) {
          this.data.mai.badWord = false;
        }
        break;
    }
  }

  send() {
    if (this.data.comment.value.length <= 0) {
      this.data.comment.error = true;
    } else if (this.getBadWords(this.data.comment.value) || this.getBadWords(this.data.name.value) || this.getBadWords(this.data.mail.value)) {
      if (this.getBadWords(this.data.comment.value)) {
        this.data.comment.badWord = true;
      }
      if (this.getBadWords(this.data.name.value)) {
        this.data.name.badWord = true;
      }
      if (this.getBadWords(this.data.mail.value)) {
        this.data.mail.badWord = true;
      }
    } else {
      ////AQUI CONSUMO DE ENDPOINT
      this.data.comment.badWord = false;
      this.data.name.badWord = false;
      this.data.mail.badWord = false;

      let request: any = {
        empresa: environment.empresaId,
        nombre: this.data.name.value,
        email: this.data.mail.value,
        comentarios: this.data.comment.value,
        asunto: "MENSAJE A LA DIRECCION"
      };
      this.loader = true;
      this.genericService.sendPostRequest(`${environment.apiUrl}quejas/quejas_sugerencias_admin.php`, request).subscribe((response: any) => {
        this.data.comment.value = "";
        this.data.name.value = "";
        this.data.mail.value = "";
        this.loader = false;
        Swal.fire({
          type: 'success',
          title: '¡Correcto!',
          text: 'Se ha enviado tu mensaje a Dirección',
        });
      }, (error: HttpErrorResponse) => {
        this.loader = false;
        Swal.fire(
          'Oops',
          'Lo sentimos, ocurrio un error de comunicación.<br/><br/>Error: ' +
            error.message,
           'error',
        );
      });
      /*  return this.http.post<any>
         (this.UrlAPI + 'quejas/quejasv2.php', dataQuery).pipe(map(response => response)); */

    }
  }

  getBadWords(palabra: string) {

    var rgx = new RegExp(this.malasPalabras.join("|") + "|" + "/gi");
    return false;//(rgx.test(palabra));
  }
}
