import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import Swal from 'sweetalert2';
import { Validators, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DevolucionesService } from '../../services/devoluciones/devoluciones.service';
import { Devoluciones } from './model-devoluciones/model-devoluciones';

@Component({
  selector: 'app-devoluciones',
  templateUrl: './devoluciones.component.html',
  styleUrls: ['./devoluciones.component.scss']
})

export class DevolucionesComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  matTableDataSource: MatTableDataSource<Devoluciones>;
  public data = [];
  public columns: string[] = [];
  public formGroup = this.formBuilder.group({
    serie: ['', Validators.required],
    folio: ['', [Validators.required]]
  });
  public displayedColumns = [
    'ArticuloId',
    'ClaveArticulo',
    'Descripcion',
    'Cantidad',
    'Precio',
    'EsObsequio',
    'TienePromocion',
    'Promocion',
    'TipoPromocion',
    'Observaciones'
  ];

  constructor(
    private devolucionesService: DevolucionesService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.spinner.show('spinner');
    setTimeout(() => {
      this.spinner.hide('spinner');
    }, 500);
  }

  exportToExcel(): void {
    this.spinner.show('spinner');
    this.devolucionesService.exportToExcel(this.data ,this.columns).then((res) => {
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
      });
      this.spinner.hide('spinner');
    });
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      Swal.fire({
        type: 'warning',
        title: 'Faltan campos por completar',
        text: 'Por favor, escribe los datos que faltan',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    this.show(this.formGroup.value.serie, this.formGroup.value.folio);
  }

  show(serie: string, folio: number) {
    this.spinner.show('spinner');
    this.devolucionesService.show(serie, folio).then((res) => {
      if (res.message == 'true') {
        this.matTableDataSource = new MatTableDataSource(res.response);
        this.matTableDataSource.sort = this.sort;
        this.matTableDataSource.paginator = this.paginator;
        this.columns = res.columns;
        this.data = res.response;
      }
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message
      });
      this.spinner.hide('spinner');
    });
  }

  validateNumbers(event) {
    if(event.charCode >= 48 && event.charCode <= 57) {
      return true;
    }
    return false;
  }
}