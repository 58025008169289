import { format } from 'date-fns';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map, retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Date_ } from '../../models/date_';

@Injectable({
  providedIn: 'root'
})
export class GetDateService {

constructor(private http: HttpClient) { }


get_Date = (formatDateI: string, formatDateF: string, formatoInicioDeSemana?: string): Observable<Date_> => {

  const data = {
    formatDateI,
    formatDateF,
    formatoInicioDeSemana
  };
  return this.http.post<Date_>(environment.apiUrl + '/Servicios-web/Admin/get_date.php', data).pipe(
    retry(3),
     map(response => {
      return response;
    }));
}

}
