import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { AlmacenService } from '../../../services/Almacen/almacen.service';
import { DialogRemitenteAddComponent } from '../dialog-remitente-add/dialog-remitente-add.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Datum } from '../model/Almacen';
import Swal from 'sweetalert2';
import { MatSort } from '@angular/material/sort';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-catologo-remitentes',
  templateUrl: './catologo-remitentes.component.html',
  styleUrls: ['./catologo-remitentes.component.scss'],
})
export class CatologoRemitentesComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: MatTableDataSource<Datum>;
  selection: SelectionModel<Datum>;
  public listaRemitentes: Datum[] = [];
  public loadDelete: boolean;
  public loadUpdate: boolean;
  public update: boolean;
  public displayedColumns: string[] = [
    'checkBox',
    'nombre',
    'calle',
    'numero',
    'cp',
    'colonia',
    'ciudad',
    'estado',
    'telefono',
    'correo',
    'tipo',
  ];

  constructor(
    private almacenService: AlmacenService,
    private ngxSpinnerService: NgxSpinnerService,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getRemitentes();
  }
  public getRemitentes = () => {
    this.ngxSpinnerService.show('spinner-guia');
    this.almacenService
      .getRemitentes(5, 0)
      .then((res) => {
        this.ngxSpinnerService.hide('spinner-guia');
        if (res.error === false) {
          this.dataSource = new MatTableDataSource(res.data);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.selection = new SelectionModel<Datum>(true, []);
        } else {
          this.dataSource = null;
          this.selection = null;
        }
      })
      .catch((err: any) => {
        this.ngxSpinnerService.hide('spinner-guia');
        Swal.fire({
          type: 'warning',
          title: 'Advertencia',
          text: err,
        });
      });
  };
  public openDialogAdd(): void {
    const dialogRef = this.matDialog.open(DialogRemitenteAddComponent, {
      disableClose: true,
      width: '43%',
      data: { opcion: 1 },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.openSnackBar('Remitente agregado correctamente');
        this.getRemitentes();
      }
    });
  }

  public deleteRemitentes() {
    this.loadDelete = true;
    let id = '';
    this.listaRemitentes.forEach((remitentes) => {
      id = id + remitentes.id + ',';
    });

    this.almacenService
      .delteRemitentes(id.substr(0, id.length - 1))
      .then((res) => {
        this.loadDelete = false;
        this.ngxSpinnerService.hide('spinner-guia');
        if (res.error === false) {
          this.listaRemitentes = [];
          this.getRemitentes();
        } else {
          Swal.fire({
            type: 'warning',
            title: 'Advertencia',
            text:
              'Ocurrio un error al tratar de eliminar el registro.Favor de contactar al administrador',
          });
        }
      })
      .catch((err: any) => {
        this.ngxSpinnerService.hide('spinner-guia');
        Swal.fire({
          type: 'warning',
          title: 'Advertencia',
          text: err,
        });
      });
  }

  public updateRemitentes() {
    this.loadUpdate = true;
    const dialogRef = this.matDialog.open(DialogRemitenteAddComponent, {
      disableClose: true,
      width: '43%',
      data: { opcion: 2, datum: this.listaRemitentes },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.loadUpdate = false;
      this.listaRemitentes = [];
      if (result) {
        this.openSnackBar('Actualizado agregado correctamente');
        this.getRemitentes();
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  isAllSelected() {
    if (this.selection != undefined) {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
  }

  masterToggle() {
    this.listaRemitentes = [];
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => {
          this.listaRemitentes = [...this.listaRemitentes, row];
          this.selection.select(row);
        });
  }

  public selected(event?: any, row?: Datum) {
    if (event.checked) {
      this.listaRemitentes = [...this.listaRemitentes, row];
    } else {
      this.removeToArray(row);
    }
    event = event ? this.selection.toggle(row) : null;
  }

  private removeToArray(row: Datum): void {
    let contador = 0;
    this.listaRemitentes.forEach((remitentes) => {
      if (remitentes.id === row.id) {
        if (this.listaRemitentes.length <= 1) {
          this.listaRemitentes = [];
        } else {
          const cont = contador <= 1 ? 1 : contador;
          this.listaRemitentes.splice(contador, 1);
        }
      }
      contador++;
    });
  }

  checkboxLabel(row?: Datum): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id + 1
    }`;
  }

  public openSnackBar(mensaje: string) {
    this.matSnackBar.open(mensaje, 'Ok', {
      duration: 2200,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
