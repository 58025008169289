import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import Swal from 'sweetalert2';
import { Validators, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProgramacionPagosService } from '../../services/programacion-pagos/programacion-pagos.service';
import { ProgramacionPagos } from './model-programacion-pagos/model-programacion-pagos';
import { GetDateService } from './../../services/Date/get_date.service';
import { Date_ } from '../../models/date_';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-programacion-pagos',
  templateUrl: './programacion-pagos.component.html',
  styleUrls: ['./programacion-pagos.component.scss']
})

export class ProgramacionPagosComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  matTableDataSource: MatTableDataSource<ProgramacionPagos>;
  public data = [];
  public columns: string[] = [];
  public formGroup = this.formBuilder.group({
    Estatus: ['', Validators.required],
    FechaInicial: [new Date(), [Validators.required]],
    FechaFinal: [new Date(), [Validators.required]]
  });
  public displayedColumns = [
    'ProgramacionID',
    'Fecha',
    'FechaProgramada',
    'Estatus',
    'Importe',
    'Autorizo',
    'FechaAutorizacion',
    'Cancelo',
    'FechaCancelacion',
    'Concepto',
    'Folio',
    'Pagado',
    'ImporteDocumento',
    'FacturaProveedor',
    'FechaFacturaProveedor'
  ];

  constructor(
    private programacionPagosService: ProgramacionPagosService,
    private _adapter: DateAdapter<any>,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private getDate: GetDateService
  ) {
    this._adapter.setLocale('mx');
  }

  ngOnInit() {
    this.setDate();
    this.spinner.show('spinner');
    setTimeout(() => {
      this.spinner.hide('spinner');
    }, 500);
  }

  exportToExcel(): void {
    this.spinner.show('spinner');
    this.programacionPagosService.exportToExcel(this.data ,this.columns).then((res) => {
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
      });
      this.spinner.hide('spinner');
    });
  }

  private setDate() {
    this.formGroup.disable();
    this.getDate.get_Date('Y/m/01', 'Y/m/d').pipe().subscribe((response: Date_) => {
      console.groupCollapsed();
      console.log('reponse::', response);
      console.log('FI', new Date(response.fechaInicial), 'FF', new Date(response.fechaFinal));
      console.groupEnd();

      this.formGroup.get('FechaInicial').setValue(new Date(response.fechaInicial));
      this.formGroup.get('FechaFinal').setValue(new Date(response.fechaFinal));
      this.formGroup.enable(); // Habilitado
      // this.formGroup.enable(); // Habilitado
    });

  }


  public exportFilterToExcel = () => {
    this.spinner.show('spinner');
    let data = this.matTableDataSource.filteredData;
    this.programacionPagosService.exportToExcel(data, this.columns).then((res) => {
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
      });
      this.spinner.hide('spinner');
    });
  }

  filter(event: Event) {
    const filter = (event.target as HTMLInputElement).value;
    this.matTableDataSource.filter = filter.trim().toLowerCase();
    if (this.matTableDataSource.paginator) {
      this.matTableDataSource.paginator.firstPage();
    }
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      Swal.fire({
        type: 'warning',
        title: 'Faltan campos por completar',
        text: 'Por favor, escribe los datos que faltan',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    if (this.formGroup.value.FechaInicial > this.formGroup.value.FechaFinal) {
      Swal.fire({
        type: 'warning',
        title: 'Fechas incorrectas',
        text: 'La fecha inicial no puede ser mayor a la fecha final',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    this.show(this.formGroup.value.Estatus,this.formGroup.value.FechaInicial, this.formGroup.value.FechaFinal);
  }

  show(Estatus, FechaInicial, FechaFinal) {
    this.spinner.show('spinner');
    this.programacionPagosService.show(Estatus, FechaInicial, FechaFinal).then((res) => {
      if (res.message == 'true') {
        this.matTableDataSource = new MatTableDataSource(res.response);
        this.matTableDataSource.sort = this.sort;
        this.matTableDataSource.paginator = this.paginator;
        this.columns = res.columns;
        this.data = res.response;
      }
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message
      });
      this.spinner.hide('spinner');
    });
  }

  public today = (): Date => {
    return new Date();
  };
}
