import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  NgbCalendar,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { NgbPeriod } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';
import Swal from 'sweetalert2'
import { CanjesKabicoinsService } from '../../services/reportes/reporte-canjes-kabicoins/canjes-kabicoins.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { DataCanjes, ReporteCanjesKabiCoins } from '././model/canjes-kabiCoins';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { GetDateService } from './../../services/Date/get_date.service';
import { Date_ } from '../../models/date_';

@Component({
  templateUrl: './reporte-canjes-kabicoins.component.html',
  styleUrls: ['./reporte-canjes-kabicoins.component.scss']
})
export class ReporteCanjesKabicoinsComponent implements OnInit {

  public displayedColumns: string[] = [
    // 'EmpresaID',
    'AlmacenID',
    'FechaCanje',
    'Cliente',
    'Nombre',
    'PedidoID',
    'ClaveArticulo',
    'Descripcion',
    'Cantidad',
    'Precio',
    'CostoPromedio',
    'ImporteCosto'
  ];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public dataSource: MatTableDataSource<ReporteCanjesKabiCoins>;
  public data:ReporteCanjesKabiCoins[];

  public fechaFinal: Date;
  public formCanjesKabiCoin: FormGroup;
  public maxDate: Date;
  public period: NgbPeriod;
  public listCanjesKabiCoins: DataCanjes[];
  public total:number = 0;
  public valid: boolean;


  constructor(
    // private calendar: NgbCalendar,
    private ngxSpinnerService: NgxSpinnerService,
    private canjesKabicoinsService: CanjesKabicoinsService,
    private fb: FormBuilder,
    private dateAdapter: DateAdapter<Date>,
    private _adapter: DateAdapter<any>,
    private getDate: GetDateService
  ) {
    // this.maxDate = this.calendar.getToday();
    this._adapter.setLocale('mx');
  }
  ngOnInit() {
    this.validaForm();
    this.setDate();
    // this.fecha();
    // this.getData();
  }
  validaForm() {
    this.formCanjesKabiCoin = this.fb.group({
      FechaInicio: [''],
      FechaFin: [''],
      NumeroCliente: [''],
    });
  }
  // fecha() {
  //   this.maxDate = new Date();
  //   const date = new Date();
  //   this.formCanjesKabiCoin.get('FechaFin').setValue(new Date().toISOString());
  //   this.formCanjesKabiCoin
  //     .get('FechaInicio')
  //     .setValue(new Date(date.getFullYear(), date.getMonth(), 1).toISOString()); //date.getFullYear(), date.getMonth() - 1, 1 new Date().toISOString()
  // }


  private setDate() {
    this.maxDate = new Date();
    this.formCanjesKabiCoin.disable();
    this.getDate.get_Date('Y/m/01', 'Y/m/d').pipe().subscribe((response: Date_) => {

      console.groupCollapsed();
      console.log('reponse::', response);
      console.log('FI', new Date(response.fechaInicial), 'FF', new Date(response.fechaFinal));
      console.groupEnd();

      this.formCanjesKabiCoin.get('FechaInicio').setValue(new Date(response.fechaInicial));
      this.formCanjesKabiCoin.get('FechaFin').setValue(new Date(response.fechaFinal));
      this.formCanjesKabiCoin.enable();
      this.valid = true;
      this.getData();
    });
  }


  public getData() {
     const fechaInicio  = this.formCanjesKabiCoin.get('FechaInicio').value;
     const fechaFin     = this.formCanjesKabiCoin.get('FechaFin').value;
     if (fechaInicio > fechaFin) {
      Swal.fire({
        type: 'warning',
        title: 'Fechas incorrectas',
        text: 'La fecha inicio no puede ser mayor a la fecha final',
        confirmButtonText: 'Aceptar'
      });
       return;
     }
    this.ngxSpinnerService.show('spinner-canjes');

    this.canjesKabicoinsService.getDataCanjesKabicoins(
      fechaInicio,
      fechaFin,
      this.formCanjesKabiCoin.get('NumeroCliente').value,
    ).then((res) => {
      this.ngxSpinnerService.hide('spinner-canjes');
      if (res.error === false) {


        this.dataSource = new MatTableDataSource(res.reporteCanjesKabiCoins);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.data = res.reporteCanjesKabiCoins;
        this.total = res.total;
      }else{
        this.dataSource = null;
        this.data=null;
        // this.selection = null;
      }
    }
    ).catch((err: any) => {
      this.ngxSpinnerService.hide('spinner-canjes');
      Swal.fire({
        type: 'warning',
        title: 'Advertencia',
        text: err.message,
      });
    });
  }

  public getExcel(){

    this.ngxSpinnerService.show('spinner-canjes');
    this.canjesKabicoinsService.getExecelCanjes(this.data,this.total).then((res) => {
    this.ngxSpinnerService.hide('spinner-canjes');
     })
  .catch((err: any) => {
    this.ngxSpinnerService.hide('spinner-canjes');
    Swal.fire({
      type: 'warning',
      title: 'Advertencia',
      text: err.message,
    });
  });
  }
}
