import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, retry, catchError } from 'rxjs/operators';

import * as FileSaver from 'file-saver';
// /import { saveAs,FileSaver } from 'file-saver';

import * as XLSX from 'xlsx';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { LoginService } from '../../services/login/login.service';
import {
  GetRemitentes,
  Datum,
  DireccionDest,
  UsersDevoluciones,
  GuiasGeneradas,
  DetalleGuiasGeneradas
} from '../../views/Almacenes/model/Almacen';
import Swal from 'sweetalert2';
import { EmpresaService } from '../../services/Empresa/Empresa.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
const ApiProduccion = 'paqueteria/paqueteria_inbox.php';
//const ApiSandbox = 'paqueteria/paqueteria_inbox_sandbox.php';
// const ApiProduccion = 'paqueteria/paqueteria_inbox_sandboxv3.php';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

interface Pedidos {
  message: string;
  data?: {};
}
interface Direccion {
  error: boolean;
  tipo_asentamiento: string;
  municipio: string;
  estado: string;
  ciudad: string;
  pais: string;
  cp: string;
  asentamiento?: {};
}

interface Guia {
  message: boolean;
  guia: NameGuia[];
  precioEnvio: string;
  zonaEnvio: string;
  data: string;
  download_guia: boolean;
}
interface NameGuia {
  numeroGuia: string;
}
// interface GuiasGeneradas {
//   message: boolean;
//   data?: {};
// }

interface Sucursal {
  message: string;
  data?: {};
}

interface Cobertura {
  message: boolean;
  data?: {};
  messageError: string;
}

@Injectable({
  providedIn: 'root',
})
export class AlmacenService {
  empresaId =  String(environment.empresaId);
  private destroy$ = new Subject<void>();
  constructor(private http: HttpClient, private loginService: LoginService, private empresaService: EmpresaService) {
    this.empresaService.empresaId$
    .pipe(takeUntil(this.destroy$))
    .subscribe(id => {
      this.empresaId = id;
    });
  }

  public getPedido(
    pedido: any,
    almacen: any,
    cliente: any,
    fechaIni: Date,
    fechaFin: Date
  ) {
    const data = {
      pedido: pedido,
      almacen: almacen,
      cliente: cliente,
      empresa: this.empresaId,
      option: 'wsBuscaPedido',
      fechaIni,
      fechaFin,
    };
    return this.http
      .post<Pedidos>(environment.apiUrl + ApiProduccion, data)
      .pipe(map((data) => data))
      .toPromise();
  }

  public getDireccion(codigopostal: number) {
    const data = {
      option: 'getCodigoPostal',
      codigopostal,
    };
    return this.http
      .post<Direccion>(
        environment.apiUrl + 'account/addresses/getCodigoPostal.php',
        data
      )
      .pipe(map((data) => data))
      .toPromise();
  }

  public getDireccionSucursal() {
    const data = {
      option: 'getSucursales',
      empresa: this.empresaId,
    };
    return this.http
      .post<Sucursal>(environment.apiUrl + ApiProduccion, data)
      .pipe(map((data) => data))
      .toPromise();
  }

  public setGeneraGuia(
    data: any,
    cliente: number,
    pedido: number,
    almacen: number,
    tipo: string,
    totalGuias: number
  ) {
    const user = this.loginService.getUserData();

    const dat = {
      option: 'wsGenerarGuia',
      empresa: this.empresaId,
      quien: user.correo,
      almacen,
      cliente,
      pedido,
      tipo,
      paqueteria: 'INBOX',
      data,
      totalGuias,
    };
    return this.http
      .post<Guia>(environment.apiUrl + ApiProduccion, dat)
      .pipe(map((data) => data))
      .toPromise();
  }

  public getGuiasGeneradas(fechaInicial: number, fechaFinal: number) {
    const dat = {
      option: 'GuiasGeneradas',
      empresa: this.empresaId,
      paqueteria: 'INBOX',
      fechaInicial,
      fechaFinal,
    };
    return this.http
      .post<GuiasGeneradas>(environment.apiUrl + ApiProduccion, dat)
      .pipe(map((data) => data))
      .toPromise();
  }

  public getCobertura(CodigoPostal: number, Colonia: string) {
    const dat = {
      option: 'wsBuscaDomicilio',
      empresa: this.empresaId,
      paqueteria: 'INBOX',
      CodigoPostal,
      Colonia,
    };
    return this.http
      .post<Cobertura>(environment.apiUrl + ApiProduccion, dat)
      .pipe(map((data) => data))
      .toPromise();
  }

  public getRemitentes(opcion: number, clienteId: number) {
    //5 es del catalogo 2 es de Gia
    const dat = {
      option: 'getRemitentes',
      opcion,
      empresa: this.empresaId,
      clienteId,
    };
    return this.http
      .post<GetRemitentes>(environment.apiUrl + ApiProduccion, dat)
      .pipe(map((data) => data))
      .toPromise();
  }

  public setRemitentes = (datum: Datum) => {
    const data = {
      option: 'setRemitentes',
      opcion: 1,
      id: 1,
      empresa:  this.empresaId,
      datum,
    };
    return this.http
      .post<GetRemitentes>(environment.apiUrl + ApiProduccion, data)
      .pipe(map((data) => data))
      .toPromise();
  };

  public updateRemitentes = (datum: Datum, id: number) => {
    const data = {
      option: 'setRemitentes',
      id,
      opcion: 3,
      empresa: this.empresaId,
      datum,
    };
    return this.http
      .post<GetRemitentes>(environment.apiUrl + ApiProduccion, data)
      .pipe(map((data) => data))
      .toPromise();
  };

  public delteRemitentes = (id: string) => {
    const data = {
      option: 'deleteRemitentes', //Elimina los registros deleteRemitentes
      id,
      opcion: 4,
      empresa:  this.empresaId,
    };
    return this.http
      .post<GetRemitentes>(environment.apiUrl + ApiProduccion, data)
      .pipe(map((data) => data))
      .toPromise();
  };

  public getDireccionDestinatario = (
    almacen: number,
    pedido: number,
    cliente: number
  ) => {
    const data = {
      option: 'getDireccion',
      empresa: this.empresaId,
      almacen,
      pedido,
      cliente,
      direccionId: '',
      esPublicoGeneral: '',
    };
    return this.http
      .post<DireccionDest>(environment.apiUrl + ApiProduccion, data)
      .pipe(map((data) => data))
      .toPromise();
  };

  public getUsersDevoluciones = (
    opcion: number,
    clienteId: string,
    nombre: string,
    correo: string,
    contraseña: string,
    activo: string,
    guia: string,
    id: number
  ) => {
    const data = {
      option: 'getUserDevoluciones',
      empresa: this.empresaId,
      opcion,
      clienteId,
      nombre,
      correo,
      password: contraseña,
      activo,
      guia,
      id,
    };
    return this.http
      .post<UsersDevoluciones>(environment.apiUrl + ApiProduccion, data)
      .pipe(map((data) => data))
      .toPromise();
  };

  exportAsExcelFileAlmacen(data: any, excelFileName: string, shetName: string) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

    const workbook: XLSX.WorkBook = {
      Sheets: { shetName: worksheet },
      SheetNames: ['shetName'],
    };

    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + 'Kabik' + new Date().toLocaleDateString() + EXCEL_EXTENSION
    );
  }

  public getExcelGuiasGeneradas = (reporteEnviosInbox: DetalleGuiasGeneradas[]) => {
    const data = {
      option: 'SetResumenEnviosInbox',
      empresa: this.empresaId,
      reporteEnviosInbox,
    };
    let f = new Date();
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post<any>(
        environment.apiUrl +
          'reportes/reporte-paqueteria-inbox/setExcelGuiasGeneradas.php',
        data,
        { headers, responseType: 'blob' as 'json' }
      )
      .pipe(
        map((data1) => {
          let blob = new Blob([data1], { type: 'application/vnd.ms.excel' });
          const file = new File(
            [blob],
            'Reporte_Guias_kabik_' +
              f.getDate() +
              '_' +
              (f.getMonth() + 1) +
              '_' +
              f.getFullYear() +
              '.xlsx',
            {
              type: 'application/vnd.ms.excel',
            }
          );
          FileSaver.saveAs(file);
        })
      )
      .toPromise();
  };

  public getArticulosProduccion(fechaInicial: string, fechaFinal: string,sucursal:number){
    const data = {
      option: 'ArticulosProduccion',
      empresaId: this.empresaId,
      fechaInicial,
      fechaFinal,
      sucursal
    };
    return this.http.post<any>(environment.apiUrl + '/reportes/reporte-almacen/produccion_insumos.php', data).pipe(retry(3), catchError(this.handleError), map(response => {
      return response;
    }));

  }

 public getArticulosProduccionExcel(columnasIsumos:string[],dataInsumos:any,columasProduccion: string[],dataProduccion:any,columasCantEquipos:string[],dataCantEquipos:any){

  const data ={
    option: 'exportarArticulosProduccion',
    columnasIsumos,
    dataInsumos,
    columasProduccion,
    dataProduccion,
    columasCantEquipos,
    dataCantEquipos,
    empresaId: this.empresaId,

  };

    let f = new Date();
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http
      .post<any>(
        environment.apiUrl +
        '/reportes/reporte-almacen/produccion_insumos.php',
        data,
        { headers, responseType: 'blob' as 'json' }
      )
      .pipe(
        map((data1) => {
        var  blob = new Blob([data1], { type: 'application/vnd.ms.excel' });
        const file = new File([blob], 'Reporte_de_produccion_kabik_' + f.getDate() + '_' + (f.getMonth() + 1) + '_' + f.getFullYear() + '.xlsx', {
            type: 'application/vnd.ms.excel',
          });
          FileSaver.saveAs(file);
        })
      )
      .toPromise();
 }

 public setProveedorGuia = (dataForm) => {
  return this.http
    .post<any>(environment.apiUrl + 'paqueteria/paqueteria_inbox.php', dataForm)
    .pipe(map(data => {
      return data;
    }));
}
  private handleError = (error: HttpErrorResponse | any) => {
    Swal.fire({
      type: 'error',
      title: 'Error',
      text:
        'Lo sentimos, Ocurrio un error de comunicación, Favor de contactarse con el administrador del sitio Incidencias@kabik.com.mx' + error.message
    });
    return throwError(error);
  }
}
