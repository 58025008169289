import { Component, OnInit,ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { throwError, Subject, Observable } from 'rxjs';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { switchMap, catchError, takeUntil } from 'rxjs/operators';
import { GetDateService } from './../../services/Date/get_date.service';
import { Date_ } from '../../models/date_';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReporteVentasClienteXMesService } from '../../services/reportes/reporte-ventas-clientes-por-mes/reporte-ventas-cliente-x-mes.service'
interface VentasClientesPorMes {
  Data: any[];
  Columnas: Columnas[];
 }
 interface Columnas {
   field: string;
   width: string;
   type: string;
   align: string;
   index?: number;
 }
@Component({
  selector: 'app-reporte-lista-cliente',
  templateUrl: './reporte-lista-cliente.component.html',
  styleUrls: ['./reporte-lista-cliente.component.scss']
})
export class ReporteListaClienteComponent implements OnInit {

  constructor( private formBuilder: FormBuilder,
    private ventasClienteXMesService: ReporteVentasClienteXMesService,
    private spinner: NgxSpinnerService,
    private getDate: GetDateService) { }

    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    displayedColumns: string[] = [];
    dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
    columns: Columnas[] = [];
    public loading: boolean;
    public textoLoading: string =  'Consultando..';
    public loadginExport:boolean;
    public textoLoadingExport: string =  'Exportando..';
    private unsubscribe$ = new Subject<void>();
    public formGroup: FormGroup;
    private ventasClienteXmes: VentasClientesPorMes;



  ngOnInit() {
    const hoy = new Date();
    this.formGroup = this.formBuilder.group({
      FechaInicial: [new Date(hoy.getFullYear(), hoy.getMonth(), 1), [Validators.required]],
      FechaFinal: [new Date(), [Validators.required]],
    });
    this.getNewDate();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getNewDate(): void {
    this.formGroup.disable();
    this.getDate
      .get_Date('Y/m/01', 'Y/m/d')
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((err) => {
          this.loading = false;
          return this.handleError(err);
        }),
      )
      .subscribe((response: Date_) => {
        this.formGroup.get('FechaInicial').setValue(new Date(response.fechaInicial));
        this.formGroup.get('FechaFinal').setValue(new Date(response.fechaFinal));
        this.loading = false;
        this.formGroup.enable();
      });
  }

  public geatDtaVentas(){
    this.loading = true;
    this.spinner.show('spinner');
    this.ventasClienteXMesService.getVentasClientePorMes(new Date(this.formGroup.get('FechaInicial').value),new Date(this.formGroup.get('FechaFinal').value),'ListaDeClientes') .pipe(
        takeUntil(this.unsubscribe$),
        catchError((err) => {
          this.loading = false;
          this.spinner.hide('spinner');
          console.log(err);
          return this.handleError(err);
        })
      ).subscribe((response: VentasClientesPorMes) => {
      this.dataSource.data = response.Data;
      this.ventasClienteXmes = response;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.columns  = response.Columnas;
      this.setDisplayedColumns();
      this.loading = false;
      this.spinner.hide('spinner');
    });;
  }
  public exportReporteVentas(){

    this.spinner.show('spinner');
    this.loadginExport = true;
    this.ventasClienteXMesService.exportToExcel(this.ventasClienteXmes  ,this.displayedColumns,'Kabik_reporte_ventas_cliente_por_mes_').then((res) => {
      this.spinner.hide('spinner');
      this.loadginExport = false;
    }).catch((error) => {
      this.loadginExport = false;
      this.spinner.hide('spinner');
      return this.handleError(error);
      // Swal.fire({
      //   type: 'error',
      //   title: 'Error',
      //   text: error.message,
      // });
    });

  }

  applyFilter (event)  {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  public onSubmit(){
    this.geatDtaVentas();
  }
  setDisplayedColumns() {
    this.columns.forEach((column, index) => {
      column.index = index;
      this.displayedColumns[index] = column.field;
    });
  }
  private handleError = (error: any) => {
    console.log(error);
    Swal.fire(
      'Ventas Cliente Por Mes',
      'Lo sentimos, ocurrio un error de comunicación.<br/><br/>Error: ' +
        error.message,
      'error'
    );
    return throwError(error);
  }

  public today = (): Date => {
    return new Date();
  }
}
