import { Component, OnInit } from '@angular/core';
import {
  NgbCalendar,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { NgbPeriod } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';
import { ReporteVentasService } from '../../services/reportes/reporte-ventas.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-reporte-ventas',
  templateUrl: './reporte-ventas.component.html',
  styleUrls: ['./reporte-ventas.component.scss']
})
export class ReporteVentasComponent implements OnInit {
  fechaInicio: NgbDate;
  fechaFinal: NgbDate;
  maxDate: NgbDate;
  minDate: NgbDate;
  period: NgbPeriod;
  btnExportar:boolean = true;
  ventasIndividuales: any;
  ventas: any;
  constructor(
    private calendar: NgbCalendar,
    private reporte: ReporteVentasService,
    private spinner: NgxSpinnerService
  ) {
    this.maxDate = this.calendar.getToday();
  }

  fechaToString(date: NgbDate) {
    const day = date.day < 10 ? '0' + date.day.toString() : date.day.toString();
    const month =
      date.month < 10 ? '0' + date.month.toString() : date.month.toString();
    const year = date.year.toString();
    return year + month + day;
  }

  loadData(fechaInicio: NgbDate, fechaFinal: NgbDate) {
    this.spinner.show('spinner-ventas');
    this.reporte
      .getDataVentas(
        this.fechaToString(fechaInicio),
        this.fechaToString(fechaFinal)
      )
      .then((res) => {
        // console.log(res);
        if (res.message === 'true') {
          let vent: any = res.ventas;
          for (let i = 0; i < vent.length; i++) {
            vent[i].SUBTOTAL = parseFloat(vent[i].SUBTOTAL);
            vent[i].IVA = parseFloat(vent[i].IVA);
            vent[i].TOTAL = parseFloat(vent[i].TOTAL);
            vent[i].COSTO = parseFloat(vent[i].COSTO);
            vent[i].UTILIDAD = parseFloat(vent[i].UTILIDAD);
          }
          this.ventas = vent;
          // console.log('Ventas generales', this.ventas);
          this.getVentasIndividuales();
        }
        this.spinner.hide('spinner-ventas');
      });
  }

  getVentasIndividuales() {
    const ventasInd = [];
    let ventas;
    ventas = this.ventas;
    for (let index = 0; index < ventas.length; index++) {
      this.reporte
        .getDataVentasIndividuales(ventas[index].VENDEDOR)
        .then((res) => {
          if (res.message === 'true') {
            const vent: any = res.ventas;
            for (let i = 0; i < vent.length; i++) {
              vent[i].iva = parseFloat(vent[i].iva);
              vent[i].subtotal = parseFloat(vent[i].subtotal);
              vent[i].costo = parseFloat(vent[i].costo);
              vent[i].utilidad = parseFloat(vent[i].utilidad);
              vent[i].total = parseFloat(vent[i].total);
            }
            ventasInd.push(vent);
          }
        });
    }
    this.ventasIndividuales = ventasInd;
    this.btnExportar = false ;
  }

  exportToExcel(): void {
    this.reporte.exportAsExcelFile(
      this.ventas,
      'reporte_ventas_',
      this.ventasIndividuales,2
    );
  }

  ngOnInit() {
    this.fechaFinal = this.calendar.getToday();
    this.period = 'm';
    this.fechaInicio = this.calendar.getPrev(this.fechaFinal, this.period, 0);
    this.loadData(this.fechaInicio, this.fechaFinal);
  }
}
