import { UtilService } from './../../../services/util.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import {
  NgbDate,
  NgbPeriod,
  NgbCalendar,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { concatMap, map, tap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';
import {
  ImagesService,
  ResponceOfertas,
  Respuesta,
} from '../../../services/images/images.service';
import { EmpresaService } from '../../../services/Empresa/Empresa.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-modal-imagenes-externas',
  templateUrl: './modal-imagenes-externas.component.html',
  styleUrls: ['./modal-imagenes-externas.component.scss'],
})
export class ModalImagenesExternasComponent implements OnInit {
  public fdInfoImage = new FormData();
  public title: string = 'Nueva imagen';
  public files: any[] = [];
  public maxSize: boolean;
  public vistaPrevia = false;
  public isModalEdit: boolean = false;
  public permitirSubirImagen: boolean = false;
  public listOfertas: any = [];
  public sendInputTitle: string;
  public imgOld: string;
  empresaId =  String(environment.empresaId);
  private destroy$ = new Subject<void>();
  // formulario
  public formRegister = this.fb.group({
    id: '',
    empresa: environment.empresaId,
    descripcion: ['', [Validators.required], Validators.maxLength[7]],
    lugar: ['', [Validators.required]],
    posicion: ['', [Validators.required]],
    fechaInicial: [Validators.required, Validators.maxLength[0]],
    fechaFinal: [Validators.required, Validators.maxLength[0]],
    tipo: ['none', Validators.required],
    url: '',
    UrlExterno: false
  });

  public iteraccionImg: string = 'none';
  public externas = ([] = [
    { value: 'slider', viewValue: 'Carousel principal' },
    { value: 'lateral', viewValue: 'Banner lateral' },
    { value: 'central', viewValue: 'Banner central' },
  ]);
  public internas = ([] = [
    { value: 'slider', viewValue: 'Carousel principal' },
    { value: 'lateral', viewValue: 'Banner lateral' },
    { value: 'central', viewValue: 'Banner central' },

    // { value: 'Banner izquierdo', viewValue: 'Banner izquierdo' },
  ]);

  constructor(
    public dialogRef: MatDialogRef<ModalImagenesExternasComponent>,
    @Inject(MAT_DIALOG_DATA) public dataImg: any,
    private calendar: NgbCalendar,
    private fb: FormBuilder,
    private imgService: ImagesService,
    private utilService: UtilService,
    private empresaService: EmpresaService
  ) {
    this.empresaService.empresaId$
    .pipe(takeUntil(this.destroy$))
    .subscribe(id => {
      this.empresaId = id;
      this.formRegister.controls.empresa.setValue(this.empresaId);
    });
  }

  ngOnInit() {
    if (this.dataImg != null) {
      this.title = 'Editar imagen';
      this.isModalEdit = true;
      this.fillInformation(this.dataImg);
      this.imgOld = this.dataImg.imagen; //Imagen a remplazar;
      // console.log(this.dataImg);
    } else {
      this.permitirSubirImagen = true;
      this.sendInputTitle = '';
    }
  }
  /*
*******************************************
*******************************************
                FUNCIONES GENERLES
*******************************************
 *******************************************
*/

  //  CIERRA EL MODAL
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  // OBTIENE EL DATO DEL DROP ZONE
  async onSelect(event) {
    this.maxSize = false;
    /* let file = await this.utilService.compress(event.addedFiles[0], false);
    this.files = [...file]; */
    //console.log(event.addedFiles);
    let fileFound:any = event.addedFiles.length > 0 ? event.addedFiles : event.rejectedFiles;

    let file = await this.utilService.compress(fileFound[0], false, 5);
    console.log(file);
    this.files = [];
    this.files.push(file);
    //this.files = event.addedFiles;
    /* let file = await this.utilService.compress(event.addedFiles[0], false, 20);
    console.log(file);
    this.files = [];
    this.files.push(file);
    console.log(this.files); */

    /* this.formRegister.get('file').setValue(this.files[0]); */
    if (event.rejectedFiles.length !== 0) {
      this.maxSize = event.rejectedFiles[0].reason === 'size';
    }
  }
  onSubmitForm() {
    /* Validamos que files este nulo y que el modal sea el de crear
    Mas adelante se valida el file tenga un nuevo archivo en el modal de editar  */
    if (
      this.formRegister.invalid ||
      (this.files === null && this.isModalEdit === false) ||
      (this.permitirSubirImagen && this.files === null) ||
      (this.formRegister.get('tipo').value === 'oferta' &&
        this.listOfertas.length === 0) ||
      (this.formRegister.get('url').value === '' &&
        (this.formRegister.get('tipo').value === 'articulo' ||
          this.formRegister.get('tipo').value === 'categoria'))
    ) {
      Swal.fire({
        type: 'warning',
        title: 'Faltan campos por completar',
        text: 'Porfavor llena la información que hace falta',
        allowOutsideClick: false,
      });
      return;
    }

    if (this.formRegister.get('tipo').value === 'none') {
      this.formRegister.get('url').setValue('');
    }

    this.formRegister.controls.fechaInicial.setValue(
      this.parseDateSQL(this.formRegister.controls.fechaInicial.value)
    );
    this.formRegister.controls.fechaFinal.setValue(
      this.parseDateSQL(this.formRegister.controls.fechaFinal.value)
    );

    for (const [key, value] of Object.entries(this.formRegister.value)) {
      if(key == "UrlExterno"){
        this.fdInfoImage.append(key, value ? "1" : "0");
      }else{
        this.fdInfoImage.append(key, value.toString());
      }
    }
    this.fdInfoImage.append('imgOld', this.imgOld);
    if (this.isModalEdit) {
      this.sendInfoToEdit();
    } else {
      this.sendInfoToCreateNew();
    }
  }

  // REMUEVE EL FILE DEL DROP
  onRemove(event) {
    this.files = null;
  }

  public propagarLista(lista: Array<Object>) {
    this.listOfertas = lista;
    console.log('propagadas->', this.listOfertas);
    if (this.listOfertas.length !== 0) {
      this.formRegister.get('url').setValue(this.listOfertas[0].url);
    }
  }

  public formatInputDate(date: any) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + date;
    }
    return [year, month, day].join('/');
  }
  // OBTIENE LA FECHA DE INICIO DEL INPUT
  public getFechaInicio = (event) => {
    this.parseDateSQL(event);
  };

  public getFechaFin = (event) => {
    this.parseDateSQL(event);
  };

  private parseDateSQL = (date: Date): string => {
    const day =
      date.getDate() < 10
        ? '0' + date.getDate().toString()
        : date.getDate().toString();
    const month =
      date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1).toString()
        : (date.getMonth() + 1).toString();
    const year = date.getFullYear().toString();

    return year + month + day;
  };

  /*
  *******************************************
  *******************************************
            Funciones de Edicion
  *******************************************
  *******************************************
  */
  // Lena el formBuilder
  public sendInfoToEdit() {
    if (this.permitirSubirImagen) {
      this.fdInfoImage.append('file', this.files[0], this.files[0].name);
    }

    Swal.fire({
      title: 'Guardando información...',
      text: 'Porfavor espere',
      allowOutsideClick: false,
    });
    Swal.showLoading();

    this.imgService
      .deleteAllOferta(this.formRegister.get('url').value)
      .pipe(
        concatMap((respOferta: ResponceOfertas) => {
          if (respOferta.respuesta) {
            return this.imgService.editarImagen(this.fdInfoImage);
          } else {
            return throwError('Hubo un problema al editar la info');
          }
        }),
        catchError((err) => throwError(err))
      )
      .subscribe(
        (resp) => {
          if (resp.message === 'true') {
            console.log(resp);
            this.imgService.updateImageObservable(resp);
            this.sendOfertaList();
            Swal.fire({
              type: 'success',
              title: 'Correcto',
              text: 'Se ha subido correctamente la información',
              footer: 'Excelente',
            });
          } else {
            throw new Error('no se pudo guardar la información');
          }
        },
        (error) => {
          console.error(error);
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: `Algo salio mal, ${error.message}`,
            footer: 'Comunicate para resolver el problema',
          });
        },
        () => {
          this.dialogRef.close(true);
        }
      );
  }

  public fillInformation(data: any) {
    this.formRegister.patchValue({
      id: data.id,
      descripcion: data.descripcion,
      lugar: data.lugar,
      posicion: data.posicion,
      fechaInicial: new Date(data.FechaInicial),
      fechaFinal: new Date(data.FechaFinal),
      tipo: data.tipo,
      url: data.url,
      UrlExterno: Number(data.UrlExterno) > 0
    });
    this.sendInputTitle =
      this.formRegister.get('tipo').value === 'oferta'
        ? this.formRegister.get('url').value
        : '';
  }
  public confirmEliminatedImage(confirmacion: boolean) {
    this.permitirSubirImagen = confirmacion ? true : false;
  }
  /*
 *******************************************
 *******************************************
           Funciones de Creacion
 *******************************************
 *******************************************
 */
  public sendInfoToCreateNew() {
    this.fdInfoImage.append('file', this.files[0], this.files[0].name);

    Swal.fire({
      title: 'Guardando información...',
      text: 'Porfavor espere',
      allowOutsideClick: false,
    });
    Swal.showLoading();
    this.imgService
      .subirImagen(this.fdInfoImage)
      .then((resp) => {
        if (resp.message === 'true') {
          if (this.formRegister.get('tipo').value === 'oferta') {
            const readerOferta = this.sendOfertaList();
            if (readerOferta) {
              this.imgService.updateImageObservable(resp);
              Swal.fire({
                type: 'success',
                title: 'Correcto',
                text: 'Se ha subido correctamente la información',
                footer: 'Excelente',
              });
            } else {
              throw new Error(
                'Alguno o ninguno de los registros no se guardaron correctamente'
              );
            }
          } else {
            this.imgService.updateImageObservable(resp);
            Swal.fire({
              type: 'success',
              title: 'Correcto',
              text: 'Se ha subido correctamente la información',
              footer: 'Excelente',
            });
          }
        } else {
          throw new Error(resp.message);
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: `Algo salio mal, ${error.message}`,
          footer: 'Comunicate para resolver el problema',
        });
        this.dialogRef.close(false);
      })
      .finally(() => {
        this.dialogRef.close(true);
      });
  }

  private sendOfertaList(): boolean {
    const checkerInserts = [];
    for (const [key, value] of Object.entries(this.listOfertas)) {
      const listOfertasJson = {};
      /* ************************************ */
      listOfertasJson['option'] = 'agregarItemOferta';
      listOfertasJson['claveArticulo'] = value['clave'];
      listOfertasJson['nombrePromocion'] = value['url'];
      listOfertasJson['empresaID'] = this.empresaId;
      listOfertasJson['fechaInicial'] = this.formRegister.get(
        'fechaInicial'
      ).value;
      listOfertasJson['fechaFinal'] = this.formRegister.get('fechaFinal').value;
      listOfertasJson['activa'] = value['estado'].toString();

      this.imgService.agregarItemOferta(listOfertasJson).subscribe(
        (resp) => {
          const response = !!resp.respuesta;
          if (response) {
            checkerInserts.push(response);
          } else {
            checkerInserts.push(response);
          }
        },
        (err) => {
          console.error(err.message);
          checkerInserts.push(false);
        },
        () => { }
      );
    }
    return !checkerInserts.some((val) => val === false);
  }
  /*
  *******************************************
            VARIABLES EN FUNCION
  *******************************************
  */
  public today = (): Date => {
    return new Date();
  };
  public dateMin = (): Date => {
    const date = new Date();
    return new Date(date.getFullYear(), 0, 1);
  };
  public dateMax = (): Date => {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 3, 0);
  };
}
