import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ventas-cliente',
  templateUrl: './ventas-cliente.component.html',
  styleUrls: ['./ventas-cliente.component.scss']
})
export class VentasClienteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
