import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analisis',
  templateUrl: './analisis.component.html',
  styleUrls: ['./analisis.component.scss'],
})
export class AnalisisComponent implements OnInit {

  currentYear = new Date().getFullYear();
  
  constructor() { }

  ngOnInit() {
  }

}
