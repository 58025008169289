import { Component, OnInit } from '@angular/core';
import { ReporteImagenesSubidasService } from '../../services/reportes/reporte-imagenes-subidas.service';

@Component({
  selector: 'app-reporte-imagenes-subidas',
  templateUrl: './reporte-imagenes-subidas.component.html',
  styleUrls: ['./reporte-imagenes-subidas.component.scss']
})
export class ReporteImagenesSubidasComponent implements OnInit {

  imagenes: any;
  constructor(private encuestaService: ReporteImagenesSubidasService) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.encuestaService.getEncuestas().then(res => {
      if (res.message === "true") {
        this.imagenes = res.imagenes;
        console.log(res);
      }
    }).catch(err => {

    });
  }

  exportToExcel(): void {
    this.encuestaService.exportAsExcelFile(
      this.imagenes);
  }
}
