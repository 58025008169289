import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltrosComponent} from './filtros/filtros.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../module/material/material.module';
import { PipesCoreModule } from '../pipes/pipes-core.module';
import { SugerenciaComponent } from './sugerencia/sugerencia.component';
import { HandTableComponent } from './handtable/handtable.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragDropComponent } from './drag-drop-component/drag-drop-component.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
@NgModule({

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PipesCoreModule,
    NgbModule,
    DragDropModule
  ],
  declarations: [
    FiltrosComponent,
    SugerenciaComponent,
    HandTableComponent,
    DragDropComponent
  ],
  exports: [
    FiltrosComponent,
    SugerenciaComponent,
    HandTableComponent,
    DragDropComponent
  ],
  providers: [
  ],
  entryComponents:[
    FiltrosComponent,
    SugerenciaComponent,
    HandTableComponent
  ]
})
export class ComponetsCoreModule { }
