import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertToDecimal'
})
export class ReporteVentasMarcasPipe implements PipeTransform {

  transform(value: any): any {
    return parseFloat(value).toFixed(2);
  }

}
