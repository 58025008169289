import { GetExtensionPipe } from './pipes/extraer-extencion/get-extension.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { KbkGridComponent } from './core/components/kbk-grid/kbk-grid';

import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

import { AppComponent } from './app.component';
import { MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { MomentModule } from 'ngx-moment';

const APP_CONTAINERS = [DefaultLayoutComponent];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AltaClientesListComponent } from './views/clientes/alta-clientes-list/alta-clientes-list.component';
import { DetailClienteComponent } from './views/clientes/detail-cliente/detail-cliente.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
// Servicios

import { ClientesService } from './services/clientes.service';
import { HttpClientModule } from '@angular/common/http';
import { ImagenesExternasComponent } from './views/imagenes-externas/imagenes-externas.component';
import { ImagesService } from './services/images/images.service';
import { CreateImageComponent } from './views/modals/create-image/create-image.component';
import { ReporteVentasComponent } from './views/reporte-ventas/reporte-ventas.component';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AuthGuard } from './guards/auth/auth.guard';
import { VentasGuard } from './guards/reportes/ventas.guard';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ClavesGenericasComponent } from './views/claves-genericas/claves-genericas.component';
import { ModalClavesGenericasComponent } from './views/claves-genericas/modal-claves-genericas/modal-claves-genericas.component';
import { ImagenesFaltantesComponent } from './views/imagenes-faltantes/imagenes-faltantes.component';
import { ModalImagenesFaltantesComponent } from './views/imagenes-faltantes/modal-imagenes-faltantes/modal-imagenes-faltantes.component';
import { ArticulosInsumosComponent } from './views/articulos-insumos/articulos-insumos.component';
import { ModalArticulosInsumosComponent } from './views/articulos-insumos/modal-articulos-insumos/modal-articulos-insumos.component'
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ReporteVentasClienteComponent } from './views/reporte-ventas-cliente/reporte-ventas-cliente.component';
import { VentasClienteComponent } from './views/reportes/ventas-cliente/ventas-cliente.component';
import { BolsaDeTrabajoComponent } from './views/recursos-humanos/bolsa-de-trabajo/bolsa-de-trabajo.component';
import { ReporteEncuestaComponent } from './views/reporte-encuesta/reporte-encuesta.component';
import { ReporteImagenesSubidasComponent } from './views/reporte-imagenes-subidas/reporte-imagenes-subidas.component';
import { ReporteParticipacionComponent } from './views/reporte-participacion/reporte-participacion.component';
import { ReporteAbcComponent } from './views/reporte-abc/reporte-abc.component';
import { ReporteAbc2Component } from './views/reporte-abc2/reporte-abc2.component';
import { ReporteListaClienteComponent } from './views/reporte-lista-cliente/reporte-lista-cliente.component';
import { ListaClientesPipe } from './pipes/reportes/lista-clientes.pipe';
import { TipoImpuestoPipe } from './pipes/reportes/tipo-impuesto.pipe';
import { ReportePedidosEspecialesComponent } from './views/reporte-pedidos-especiales/reporte-pedidos-especiales.component';
import { ArticulosWebComponent } from './views/articulos-web/articulos-web.component';
// tslint:disable-next-line: max-line-length
import { DetallePedidosEspecialesComponent } from './views/reporte-pedidos-especiales/detalle-pedidos-especiales/detalle-pedidos-especiales.component';
import { ViewsExImagenComponent } from './views/imagenes-externas/views-ex-imagen/views-ex-imagen.component';
import { ModalImagenesExternasComponent } from './views/imagenes-externas/modal-imagenes-externas/modal-imagenes-externas.component';
// tslint:disable-next-line: max-line-length
import { OfertasImgExternasComponent } from './views/imagenes-externas/modal-imagenes-externas/ofertas-img-externas/ofertas-img-externas.component';
// tslint:disable-next-line: max-line-length
import { ViewEditExternasComponent } from './views/imagenes-externas/modal-imagenes-externas/view-edit-externas/view-edit-externas.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { IconModule } from './views/icons/icon.module';
// MODULES
import { MaterialModule } from './module/material/material.module';
import { IndexedDbModule } from './module/indexed-db/indexed-db.module';
import { IMaskModule } from 'angular-imask';
import { NgxCurrencyModule } from 'ngx-currency';
import { DialogAddComponent } from './views/articulos-web/dialog-add/dialog-add.component';


import { GenerarGuiaComponent } from './views/Almacenes/generar-guia/generar-guia.component';
import { GenerarGuiaDetalleComponent } from './views/Almacenes/generar-guia-detalle/generar-guia-detalle.component';
import { FiltroColoniaPipe } from './pipes/Almacenes/filtro-colonia.pipe';
import { FiltroColoniaDestinoPipe } from './pipes/Almacenes/filtro-colonia-destino.pipe';
import { ImprimirGuiaComponent } from './views/Almacenes/imprimir-guia/imprimir-guia.component';
import { ImprimirGuiaDetalleComponent } from './views/Almacenes/imprimir-guia-detalle/imprimir-guia-detalle.component';
import { ConsultaCoberturaComponent } from './views/Almacenes/consulta-cobertura/consulta-cobertura.component';
import { CatologoRemitentesComponent } from './views/Almacenes/catologo-remitentes/catologo-remitentes.component';
import { DialogRemitenteAddComponent } from './views/Almacenes/dialog-remitente-add/dialog-remitente-add.component';
import { FiltroRemitentesPipe } from './pipes/Almacenes/filtro-remitentes/filtro-remitentes.pipe';
import { GenerarGuiaDevolucionClienteComponent } from './views/Almacenes/generar-guia-devolucion-cliente/generar-guia-devolucion-cliente.component';
import { GenerarUserGuiaDevolucionComponent } from './views/Almacenes/generar-user-guia-devolucion/generar-user-guia-devolucion.component';
import { DialogUserAddComponent } from './views/Almacenes/generar-user-guia-devolucion/dialog-user-add/dialog-user-add.component';
// import {ReporteVentas2Component} from './views/reporte-ventas2/reporte-ventas2.component';
import { ReporteVentas2Component } from './views/reporte-ventas2/reporte-ventas2.component';
import { DepartamentosComponent } from './views/articulos-web/departamentos-web/departamentos.component';
import { MarcasComponent } from './views/articulos-web/marcas-web/marcas.component';
import { GruposComponent } from './views/articulos-web/grupos-web/grupos.component';
import { ReporteCanjesKabicoinsComponent } from './views/reporte-canjes-kabicoins/reporte-canjes-kabicoins.component';
import { ClientesNuevosComponent } from './views/clientes/clientes-nuevos/clientes-nuevos.component';
// import { GarantiasComponent } from './views/Almacenes/garantias/articulos-produccion-insumos.component';
import { ArticulosProduccionInsumosComponent } from './views/Almacenes/articulos-produccion-insumos/articulos-produccion-insumos.component';
import { ReporteListaPreciosComponent } from './views/reporte-lista-precios/reporte-lista-precios.component';
import { ReporteClientesKabikcoinsComponent } from './views/reporte-clientes-kabikcoins/reporte-clientes-kabikcoins.component';
import { CoreModule } from './core/core.module';
import { UltimasPiezasComponent } from './views/ultimas-piezas/ultimas-piezas.component';
import { ModalUltimasPiezasComponent } from './views/ultimas-piezas/modal-ultimas-piezas/modal-ultimas-piezas.component';
import { ReporteVentasMarcasComponent } from './views/reporte-ventas-marcas/reporte-ventas-marcas.component';
import { ReporteVentasMarcasUnoComponent } from './views/reporte-ventas-marcas/reporte-ventas-marcas-uno/reporte-ventas-marcas-uno.component';
import { MatrizComponent } from './views/matriz/matriz.component';
import { MatrizUnoComponent } from './views/matriz/matriz-uno/matriz-uno.component';
// Here we import the IgxGridModule, so we can use the igxGrid!
import { ReporteVentasMarcasPipe } from './pipes/reportes/reporte-ventas-marca/reporte-ventas-marcas.pipe';

import { ProgramacionPagosComponent } from './views/programacion-pagos/programacion-pagos.component';
import { ColumnaName } from './pipes/reportes/reporte-ventas-marca/colName.pipe';
import { DevolucionesComponent } from './views/devoluciones/devoluciones.component';

import { PeriodoInventarioComponent } from './views/inventario/periodo-inventario/periodo-inventario.component';
import { ModalPeriodoInventarioComponent } from './views/inventario/periodo-inventario/modal-periodo-inventario/modal-periodo-inventario.component';

import { PermisosComponent } from './views/permisos/permisos.component';
import { LicenciasZafiroComponent } from './views/licencias-zafiro/licencias-zafiro.component';
import { FirmaComponent } from './views/firma/firma.component';
import { ReporteInventarioComponent } from './views/inventario/reporte-inventario/reporte-inventario.component';
import { InventarioPipe } from './pipes/inventario/inventario.pipe';
import { ArticulosComponent } from './views/articulos/articulos.component';
import { AnalisisComponent } from './views/reportes/ventas/analisis/analisis.component';
import { PivotTableComponent } from './views/reportes/ventas/analisis/pivot/pivot-table/pivot-table.component';
import { PivotComponent } from './views/reportes/ventas/analisis/pivot/pivot.component';

import { TableModule } from 'primeng/table';
import { Dialog, DialogModule } from 'primeng/dialog';
import { ConfirmDialog, ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { ListboxModule } from 'primeng/listbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PanelModule } from 'primeng/panel';
// import { CalendarModule } from 'primeng/calendar';
import { AccordionModule } from 'primeng/accordion';
import { TabViewModule } from 'primeng/tabview';
import { FocusTrapModule } from 'primeng/focustrap';
import { CheckboxModule } from 'primeng/checkbox';
import { TreeTableModule } from 'primeng/treetable';
import { TreeModule } from 'primeng/tree';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FilterService } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AutocompletePipe } from './pipes/Auto-Complete/autocomplete.pipe';
import { FiltrosArticulosAlmacenComponent } from './views/Almacenes/articulos-almacen-upc/filtros-articulos-almacen/filtros-articulos-almacen.component';
import { ArticulosAlmacenUpcComponent } from './views/Almacenes/articulos-almacen-upc/articulos-almacen-upc.component';
import { ModalPedidoGuiaComponent } from './views/Almacenes/generar-guia/modal-guia/modal-guia.component';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
//import { MenuItemsGuiaComponent } from './views/Almacenes/generar-guia/context-menu-sucursal-guia/menu-items/menu-items-guia.component';
import { ContextMenuSucursalGuiaComponent } from './views/Almacenes/generar-guia/context-menu-sucursal-guia/context-menu-sucursal-guia.component';
import { MenuItemsGuiaComponent } from './views/Almacenes/generar-guia/context-menu-sucursal-guia/menu-items/menu-items-guia.component';
import { ModalArticulosUPCComponent } from './views/Almacenes/articulos-almacen-upc/modal-articulos-upc/modal-articulos-upc.component';

import { otrosProveedoresGuiasComponent } from './views/Almacenes/otros-proveedores-guias/otros-proveedores-guias.component';
// import { EdoCuentaClientesComponent } from './views/clientes/edo-cuenta-clientes/edo-cuenta-clientes.component';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};
@NgModule({
  imports: [
    BrowserModule,
    NgbModule,
    CoreModule,
    // CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    HttpClientModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgxSpinnerModule,
    IconModule,
    MaterialModule,
    IndexedDbModule,
    IMaskModule,
    NgxMaskModule.forRoot(options),
    NgxDropzoneModule,
    NgxCurrencyModule,
    MomentModule,
    MatTabsModule,
    MatDialogModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    TableModule,
    DialogModule,
    ConfirmDialogModule,
    DropdownModule,
    MenubarModule,
    ButtonModule,
    ListboxModule,
    RadioButtonModule,
    PanelModule,
    AccordionModule,
    // CalendarModule,
    TabViewModule,
    FocusTrapModule,
    CheckboxModule,
    TreeTableModule,
    TreeModule,
    ProgressSpinnerModule,
    MultiSelectModule,
    MatNativeDateModule,
    MatRippleModule,
    MatSliderModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatCardModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDividerModule,
    MatExpansionModule,
    MatSelectModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatIconModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatTableModule,
    MatInputModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTabsModule,
    FormsModule,
    NgxSkeletonLoaderModule
   // HotTableModule,
   //DkDataGridModule
  ],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    NgbModule,
    MatTabsModule,
    MatNativeDateModule,
    MatRippleModule,
    MatSliderModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatCardModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDividerModule,
    MatExpansionModule,
    MatSelectModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatIconModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatTableModule,
    MatInputModule,
    MatSidenavModule,
    MatToolbarModule,
    FormsModule,
    GetExtensionPipe,
  ],
  entryComponents: [
    CreateImageComponent,
    ViewsExImagenComponent,
    DialogAddComponent,
    ModalImagenesExternasComponent,
    ModalImagenesFaltantesComponent,
    ModalArticulosInsumosComponent,
    DialogRemitenteAddComponent,
    DialogUserAddComponent,
    ModalUltimasPiezasComponent,
    ModalClavesGenericasComponent,
    ModalPeriodoInventarioComponent,
    ModalPedidoGuiaComponent,
    MenuItemsGuiaComponent,
    ContextMenuSucursalGuiaComponent,
  ],
  declarations: [
    AppComponent,
    APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    AltaClientesListComponent,
    DetailClienteComponent,
    ImagenesExternasComponent,
    CreateImageComponent,
    ReporteVentasComponent,
    DashboardComponent,
    ClavesGenericasComponent,
    ImagenesFaltantesComponent,
    ArticulosInsumosComponent,
    ModalArticulosInsumosComponent,
    ReporteVentasClienteComponent,
    VentasClienteComponent,
    KbkGridComponent,
    BolsaDeTrabajoComponent,
    ReporteEncuestaComponent,
    ReporteImagenesSubidasComponent,
    ReporteParticipacionComponent,
    ReporteAbcComponent,
    ReporteAbc2Component,
    ReporteListaClienteComponent,
    ListaClientesPipe,
    TipoImpuestoPipe,
    ReportePedidosEspecialesComponent,
    DetallePedidosEspecialesComponent,
    ViewsExImagenComponent,
    ModalImagenesExternasComponent,
    ModalImagenesFaltantesComponent,
    OfertasImgExternasComponent,
    ViewEditExternasComponent,
    ArticulosWebComponent,
    DialogAddComponent,
    GenerarGuiaComponent,
    GenerarGuiaDetalleComponent,
    FiltroColoniaPipe,
    FiltroColoniaDestinoPipe,
    ImprimirGuiaComponent,
    ImprimirGuiaDetalleComponent,
    ConsultaCoberturaComponent,
    CatologoRemitentesComponent,
    DialogRemitenteAddComponent,
    FiltroRemitentesPipe,
    GenerarGuiaDevolucionClienteComponent,
    GenerarUserGuiaDevolucionComponent,
    DialogUserAddComponent,
    // ReporteVentas2Component,
    DepartamentosComponent,
    MarcasComponent,
    GruposComponent,
    ReporteCanjesKabicoinsComponent,
    ClientesNuevosComponent,
    ArticulosProduccionInsumosComponent,
    ModalArticulosUPCComponent,
    // GarantiasComponent,
    ReporteListaPreciosComponent,
    ReporteClientesKabikcoinsComponent,
    UltimasPiezasComponent,
    ModalUltimasPiezasComponent,
    ModalClavesGenericasComponent,
    ReporteVentasMarcasComponent,
    ReporteVentasMarcasUnoComponent,
    ReporteVentasMarcasPipe,
    ColumnaName,
    ProgramacionPagosComponent,
    PermisosComponent,
    DevolucionesComponent,
    LicenciasZafiroComponent,
    FirmaComponent,
    PeriodoInventarioComponent,
    ModalPeriodoInventarioComponent,
    ReporteInventarioComponent,
    ArticulosComponent,
    InventarioPipe,
    ModalArticulosUPCComponent,
    AnalisisComponent,
    PivotTableComponent,
    PivotComponent,
    ArticulosAlmacenUpcComponent,
    FiltrosArticulosAlmacenComponent,
    AutocompletePipe,
    ModalPedidoGuiaComponent,
    GetExtensionPipe,
    MenuItemsGuiaComponent,
    ContextMenuSucursalGuiaComponent,
    otrosProveedoresGuiasComponent,
    MatrizComponent,
    MatrizUnoComponent
    // EdoCuentaClientesComponent,
  ],
  providers: [ClientesService, ImagesService, AuthGuard, VentasGuard, FilterService, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },],

  bootstrap: [AppComponent],
})
export class AppModule { }
