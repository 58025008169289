import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoImpuesto'
})
export class TipoImpuestoPipe implements PipeTransform {

  transform(value: any, args: any[]): any {
    return value === 'N' || value === 'n' ? 'Nacional' 
    : value === 'f' || value === 'F' ? 'Fronterizo'
    : 'Indefinido';
  }

}
