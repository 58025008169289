import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../../services/login/login.service';
@Injectable({
  providedIn: 'root'
})
export class ImprimirGuiaDetalleGuard implements CanActivate, CanLoad {

  constructor(private authService: LoginService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.authService.getPermisoConsultarGuia()) {
        return true;
      }
      this.router.navigate(['/']);
      return false;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
      if (this.authService.getPermisoConsultarGuia()) {
        return true;
      }
      this.router.navigate(['/']);
      return false;
  }
}
