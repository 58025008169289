import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroColonia'
})
export class FiltroColoniaPipe implements PipeTransform {

  transform(value: any, args: any): any {
    if (typeof args === undefined || args === '' || args.length < 2) return value;
    const filterColonia = [];
    if (value != undefined) {
      for (const f of value) {
        if (f.toLowerCase().indexOf(args.toLowerCase()) > -1) {
          filterColonia.push(f);
        }
      }
    }
    return filterColonia;
  }
}
