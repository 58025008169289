import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../../services/common/common.service';
import { ModalClavesGenericasComponent } from './modal-claves-genericas/modal-claves-genericas.component';
import { ClavesGenericas } from './model-claves-genericas/model-claves-genericas';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { GetDateService } from './../../services/Date/get_date.service';
import { Date_ } from '../../models/date_';
import { DateAdapter } from '@angular/material/core';
@Component({
  selector: 'app-claves-genericas',
  templateUrl: './claves-genericas.component.html',
  styleUrls: ['./claves-genericas.component.scss']
})

export class ClavesGenericasComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  matTableDataSource: MatTableDataSource<ClavesGenericas>;
  public data = [];
  public columns: string[] = [];
 ;
  displayedColumns: string[] = [
    'Clave',
    'Descripcion',
    'NumeroParte',
    'Cuando',
    'Quien',
    'ClaveSAT',
    'DescripcionSAT'
  ];
  public valid: boolean;
  public formGroup:FormGroup;


  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public matDialogNew: MatDialog,
    private _adapter: DateAdapter<any>,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private getDate: GetDateService

  ) {
    this._adapter.setLocale('mx');
  }

  ngOnInit() {
     this.formGroup = this.formBuilder.group({
      FechaInicial: [new Date(), [Validators.required]],
      FechaFinal: [new Date(), [Validators.required]]
    });
    this.setDate();
    this.spinner.show('spinner');
    setTimeout(() => {
      this.spinner.hide('spinner');
    }, 500);
  }

  exportToExcel(): void {
    this.spinner.show('spinner');
    this.commonService.exportToExcel(this.data, this.columns).then((res) => {
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
      });
      this.spinner.hide('spinner');
    });
  }


  private setDate() {
    console.log('set date');

    this.formGroup.disable();
    // this.formGroup.get('FechaInicial').disable();
    // this.formGroup.get('FechaFin').disable();
    this.getDate.get_Date('Y/m/01', 'Y/m/d').pipe().subscribe((response: Date_) => {
      console.groupCollapsed();
      console.log('reponse::', response);
      console.log('FI', new Date(response.fechaInicial), 'FF', new Date(response.fechaFinal));
      console.groupEnd();
      this.formGroup.controls['FechaInicial'].setValue(new Date(response.fechaInicial));
      this.formGroup.controls['FechaFinal'].setValue(new Date(response.fechaFinal));
      console.log('is enabled',this.formGroup.enabled);
      this.valid = true;
      this.formGroup.enable();
      // console.log('is enabled2',this.formGroup.enabled);
    });
  }


  public exportFilterToExcel = () => {
    this.spinner.show('spinner');
    let data = this.matTableDataSource.filteredData;
    this.commonService.exportToExcel(data, this.columns).then((res) => {
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
      });
      this.spinner.hide('spinner');
    });
  }

  filter(event: Event) {
    const filter = (event.target as HTMLInputElement).value;
    this.matTableDataSource.filter = filter.trim().toLowerCase();
    if (this.matTableDataSource.paginator) {
      this.matTableDataSource.paginator.firstPage();
    }
  }

  onSubmit() {
    if (this.formGroup.invalid || (this.formGroup.value.FechaInicial == null) || (this.formGroup.value.FechaFinal == null) || (this.formGroup.value.FechaInicial > this.formGroup.value.FechaFinal)) {
      Swal.fire({
        type: 'warning',
        title: 'Fechas incorrectas',
        text: 'Por favor, verifique las fechas',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    this.report(this.formGroup.value.FechaInicial, this.formGroup.value.FechaFinal);
  }

  report(FechaInicial: Date, FechaFinal: Date) {
    this.spinner.show('spinner');
    this.commonService.report(FechaInicial, FechaFinal).then((res) => {
      if (res.message == 'true') {
        this.matTableDataSource = new MatTableDataSource(res.response);
        this.matTableDataSource.sort = this.sort;
        this.matTableDataSource.paginator = this.paginator;
        this.data = res.response;
        this.columns = res.columns;
      }
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message
      });
      this.spinner.hide('spinner');
    });
  }

  public openModalNew(): void {
    const modal = this.matDialogNew.open(ModalClavesGenericasComponent, {
      width: '40%',
      disableClose: true,
    });
    modal.afterClosed().subscribe((res) => {
      if (res) {
        this.report(this.formGroup.value.FechaInicial, this.formGroup.value.FechaFinal);
      }
    });
  }

  public today = (): Date => {
    return new Date();
  };
}
