import { version } from 'process';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Observable } from 'rxjs';
import { EmpresaService } from '../Empresa/Empresa.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const EXCELDOC = {
  type:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  extension: '.xlsx',
};

interface infoPedidosEspeciales {
  message: string;
  error: boolean;
  pedidos?: {};
}
interface detallePedidosEspeciales {
  info: [];
  productos: [];
}
interface excelPedidos {
  message: string;
  d: {};
}

@Injectable({
  providedIn: 'root',
})
export class ReportePedidosEspecialesService {
  empresaId =  String(environment.empresaId);
private destroy$ = new Subject<void>();

  constructor(private http: HttpClient,private empresaService: EmpresaService) {
    this.empresaService.empresaId$
    .pipe(takeUntil(this.destroy$))
    .subscribe(id => {
      this.empresaId = id;
    });
  }

  public getInfoPedidosEspeciales(sucursal: string, fechaInicio: Date, fechaFinal: Date, option: string): Observable<any> {
    const data = {
      option,
      sucursal,
      fechaInicio,
      fechaFinal,
      empresaId: this.empresaId,
      version: environment.version
    };
    return this.http
      .post<infoPedidosEspeciales>(
        environment.apiUrl +
        'reportes/reporte-pedidos-especiales/getPedidosEspeciales.php ',
        data
      )
      .pipe(map((data1) => data1));
  }

  public getDetallePedido(option: string, pedidoId: Number, almacen: Number) {
    const info = {
      option,
      pedidoId,
      empresaId: this.empresaId,
      almacen,
    };
    return this.http
      .post<detallePedidosEspeciales>(
        environment.apiUrl + 'pedidos_web/getPedidos.php',
        info
      )
      .pipe(map((data) => data))
      .toPromise();
  }



  exportExcelPedidosEspeciales = (pedidosEspeciales: any, columnas: string[]) => {
    const f = new Date();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const data = {
                  option: 'getExecelPedidosEspeciales_',
                  empresaId: this.empresaId,
                  pedidosEspeciales,
                  columnas
                 };
    return this.http
      .post<any>(
        environment.apiUrl + 'reportes/reporte-pedidos-especiales/getPedidosEspeciales.php',
        data, { headers, responseType: 'blob' as 'json' } )
      .pipe(
        map((data1) => {
          const blob = new Blob([data1], { type: 'application/vnd.ms.excel' });
          const file = new File([blob],
           'Reporte_pedidos_especiales_kabik_' + f.getDate() + '_' + (f.getMonth() + 1) + '_' + f.getFullYear() + '_' + f.getSeconds() + '.xlsx',
           {
            type: 'application/vnd.ms.excel',
           });
          FileSaver.saveAs(file);
        })
      )
      .toPromise();
  }

  // public exportAsExcelFile(): Promise<excelPedidos> {
  //   const dataSend = {
  //     option: 'pedidosEspeciales',
  //     empresaId: this.empresaId,
  //     bandera: true
  //   }
  //   return this.http.post<excelPedidos>(environment.apiUrl + 'reportes/reporte-pedidos-especiales/getPedidosEspeciales.php', dataSend)
  //     .toPromise();
  // }
  // public exportAsExcelWidthFilter(data): Observable<any> {
  //   const dataSend = {
  //     option: 'pedidosEspecialesFilter',
  //     empresaId: this.empresaId,
  //     bandera: true,
  //     data
  //   };
  //   return this.http.post<any>
  //     (environment.apiUrl + 'reportes/reporte-pedidos-especiales/getPedidosEspeciales.php', dataSend);
  // }
}
