import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { SelectionModel } from '@angular/cdk/collections';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';
import { Articulos } from '../../views/articulos-insumos/model-articulos-insumos/model-articulos-insumos';
import { ArticulosInsumosService } from '../../services/articulos-insumos/articulos-insumos.service';
import { ModalArticulosInsumosComponent } from './modal-articulos-insumos/modal-articulos-insumos.component';

@Component({
  selector: 'app-articulos-insumos',
  templateUrl: './articulos-insumos.component.html',
  styleUrls: ['./articulos-insumos.component.scss']
})

export class ArticulosInsumosComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: MatTableDataSource<Articulos>;
  selection: SelectionModel<Articulos>;
  public articulos: Articulos[] = [];
  public columns: string[] = [];
  public data = [];
  public formGroup = this.formBuilder.group({
    ClaveArticulo: ['', [Validators.required]]
  });
  displayedColumns: string[] = [
    'accion',
    'ClaveArticulo',
    'Descripcion',
    'TipoArticulo',
    'ManejaSeries',
    'CodigoBarras'
  ];

  constructor(
    private articulosInsumosService: ArticulosInsumosService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private formBuilder: FormBuilder,
    public modalEditar: MatDialog
  ) { }

  ngOnInit() {
    this.spinner.show('spinner');
    setTimeout(() => {
      this.spinner.hide('spinner');
    }, 500);
  }

  exportToExcel(): void {
    this.spinner.show('spinner');
    this.articulosInsumosService.exportToExcel(this.data ,this.columns).then((res) => {
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
      });
      this.spinner.hide('spinner');
    });
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }

    this.articulos = [];

    this.show();
  }

  show() {
    this.spinner.show('spinner');
    let ClaveArticulo: string = this.formGroup.value.ClaveArticulo;
    this.articulosInsumosService.show(ClaveArticulo.trim()).then((res) => {
      if (res.message == 'true') {
        this.dataSource = new MatTableDataSource(res.response);
        this.selection = new SelectionModel<Articulos>(true, []);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.columns = res.columns;
        this.data = res.response;
      }
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
      });
      this.spinner.hide('spinner');
    });
  }

  public openModalEditar() {
    const modal = this.modalEditar.open(ModalArticulosInsumosComponent, {
      width: '40%',
      data: this.articulos[0],
      disableClose: true,
    });
    modal.afterClosed().subscribe((res) => {
      if (res) {
        this.router.navigateByUrl('/articulos-insumos');
      }
      this.articulos.splice(0, this.articulos.length);
      this.show();
    });
  }

  selected(event?: any, row?: Articulos) {
    if (event.checked) {
      this.articulos.push(row);
    } else {
      this.removeToArray(row);
    }
    event = event ? this.selection.toggle(row) : null;
    return event;
  }
  
  private removeToArray(row: Articulos): void {
    let count = 0;
    this.articulos.forEach(res => {
      if (res.ClaveArticulo === row.ClaveArticulo) {
        if (this.articulos.length <= 1) {
          this.articulos = [];
        } else {
          const result = count <= 1 ? 1 : count;
          this.articulos.splice(count, 1);
        }
      }
      count++;
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.articulos = [];
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(res => {
      this.articulos.push(res);
      this.selection.select(res);
    });
  }
}