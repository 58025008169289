import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { environment } from '../../../../../environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-edit-externas',
  templateUrl: './view-edit-externas.component.html',
  styleUrls: ['./view-edit-externas.component.scss'],
})
export class ViewEditExternasComponent implements OnInit {
  @Input()
  public imagenNombre: string;
  @Output()
  public setEliminatedImge = new EventEmitter<boolean>();

  public ruta = environment.apiUrl + 'imagenes_pagina/Kabik/Pagina/';
  constructor() {}

  ngOnInit() {
    this.ruta += this.imagenNombre;
  }

  public eliminarImagen() {
    Swal.fire({
      type: 'warning',
      title: '¿Subir nueva imagen?',
      text: 'Este cambio se guardará una vez que haya enviado toda la infomación',
      confirmButtonText: 'Si, subir nueva',
      showCancelButton: true,
      cancelButtonText: 'No, dejar actual',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      focusConfirm: false,
      focusCancel: true,
    }).then((res) => {
      if (res.value) {
        this.setEliminatedImge.emit(true);
      }
    });
  }
}
