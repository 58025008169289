import { Component, OnInit } from '@angular/core';
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';


@Component({
  selector: 'app-reporte-abc2',
  templateUrl: './reporte-abc2.component.html',
  styleUrls: ['./reporte-abc2.component.scss']
})
export class ReporteAbc2Component implements OnInit {
  url: string = "https://api.kabik.mx/reportes/kabik-reporte/views/index_abc2.html";
  urlSafe: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer) { }


  

  ngOnInit() {
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

  }

}
