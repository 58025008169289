import { Component, OnInit } from '@angular/core';
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';


@Component({
  selector: 'app-reporte-abc',
  templateUrl: './reporte-abc.component.html',
  styleUrls: ['./reporte-abc.component.scss']
})
export class ReporteAbcComponent implements OnInit {
  url: string = "https://api.kabik.mx/reportes/kabik-reporte/views/index_abc.html";
  urlSafe: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer) { }


  ngOnInit() {
     this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

  }

}
