import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportePedidosEspecialesService } from '../../../services/reportes/reporte-pedidos-especiales.service'
@Component({
  selector: 'app-detalle-pedidos-especiales',
  templateUrl: './detalle-pedidos-especiales.component.html',
  styleUrls: ['./detalle-pedidos-especiales.component.scss']
})
export class DetallePedidosEspecialesComponent implements OnInit {
  public priceTotal: Number;
  public detalleInfo: any;
  public detalleProductos: any;

  constructor(
    private activeRoute: ActivatedRoute,
    private pedidosService: ReportePedidosEspecialesService
  ) {
    this.activeRoute.params.subscribe(param => {
      this.loadDetallePedido(parseInt(param.pedidoId), parseInt(param.almacenId));
    }, error => {
      console.log({ error })
    });
  }

  ngOnInit() { }

  loadDetallePedido(pedido: number, almacen: number) {
    return this.pedidosService.getDetallePedido('singlePedido', pedido, almacen).then(response => {
      this.detalleInfo = response.info;
      this.detalleProductos = response.productos
      this.priceTotal = parseFloat(this.detalleInfo[0].SubTotal) + parseFloat(this.detalleInfo[0].TotalImpuesto);

      console.log(this.detalleInfo, this.detalleProductos, this.priceTotal)

    })

  }
}
