import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { ReporteListaPreciosService } from '../../services/reportes/reporte-lista-precios/reporte-lista-precios.service';
export interface Cliente {
  ClienteID: string;
  Nombre: string;
}

@Component({
  selector: 'app-reporte-lista-precios',
  templateUrl: './reporte-lista-precios.component.html',
  styleUrls: ['./reporte-lista-precios.component.scss']
})



export class ReporteListaPreciosComponent implements OnInit {

  constructor(private reporteListaPreciosService: ReporteListaPreciosService,  private spinner: NgxSpinnerService,
    ) { }

    clienteId = new FormControl('',[Validators.required]);
    public listaClientes: Cliente[] = [];
    filteredOptions: Observable<Cliente[]>;
    public valueInput:string;
    public cliente: string;
  ngOnInit() {
    this.getClientes();
    this.filteredOptions = this.clienteId.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }
  private getClientes() {
    this.reporteListaPreciosService.getClientes().subscribe(data => {
       if(data){
         if(data.error==false){
            this.listaClientes = data.clientes;
         }
       }
    })
  }
  public getListaPrecios(){
    this.spinner.show('spinner-reporte-lista-clientes');
    if(this.clienteId.value.length>0){
        this.reporteListaPreciosService.setExportExcel(this.cliente).then((res) => {
          this.spinner.hide('spinner-reporte-lista-clientes');
        }).catch((err: any) => {
          this.spinner.hide('spinner-reporte-lista-clientes');
          Swal.fire({
            type: 'warning',
            title: 'Advertencia',
            text: err.message,
          });
        });
    }
  }


   public selectLista(cliente:Cliente){

     this.valueInput = cliente.ClienteID+' - '+cliente.Nombre;
     this.cliente = cliente.ClienteID;

     // Importpublic valueInput:string;
     //  public cliente: s
   }
  exportToExcel(){

  }

  private _filter(value: string): Cliente[] {
    const filterValue = value.toLowerCase();
    return this.listaClientes.filter(option => option.ClienteID.toLowerCase().includes(filterValue) ||
                                               option.Nombre.toLowerCase().includes(filterValue) );
  }
}
