import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { ListaDepartamentos } from '../model/ListaArticulos';
import { ArticulosWebService } from '../service/articulos-web.service';
import { DialogAddComponent } from '../dialog-add/dialog-add.component';

@Component({
  selector: 'app-departamentos',
  templateUrl: './departamentos.component.html',
  styleUrls: ['./departamentos.component.scss']
})
export class DepartamentosComponent implements OnInit {

  constructor(private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private service: ArticulosWebService,
              ) { }
  displayedColumns: string[] = [
    'select',
    'DepartamentoID',
    'Descripcion',
    'Quien',
    'Cuando',
    'Estado'
  ];
  dataSource: MatTableDataSource<ListaDepartamentos>;
  selection: SelectionModel<ListaDepartamentos>;
  public listDepartamentos: ListaDepartamentos[] = [];


  public loadRefrech: boolean;
  public loadUpdate: boolean;
  public loadDelete: boolean;
  ngOnInit() {
    this.service.getListDeptops().subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.selection = new SelectionModel<ListaDepartamentos>(true, []);
    });
  }



  public updateAll(): void {
    this.loadUpdate = true;
    const mensaje = this.listDepartamentos.length > 1 ? 'Los departamentos se actualizarón correctamente' : 'el departamento se actualizo correctamente';
    this.service.updateDeptops(this.listDepartamentos).subscribe(res => {
      if (res.estatus) {
        this.openSnackBar(mensaje);
        this.refreshClaves();
      }
      this.loadUpdate = false;
    });
  }

  public deleteListArticles(): void {
    this.loadDelete = true;
    const mensaje = this.listDepartamentos.length > 1 ? 'Los departamentos se eliminaron correctamente' : 'el departamento se elimino correctamente';
    this.service.deleteDeptos(this.listDepartamentos).subscribe(res => {
      if (res.estatus) {
        this.openSnackBar(mensaje);
        this.refreshClaves();
      }
      this.loadDelete = false;
    });
  }

  public refreshClaves(): void {
    this.loadRefrech = true;
    this.service.getListDeptops().subscribe(data => {
      this.dataSource.data = data;
      this.selection = new SelectionModel<ListaDepartamentos>(true, []);
      this.loadRefrech = false;
      this.listDepartamentos = [];
    });
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public selected(event?: any, row?: ListaDepartamentos) {
    if (event.checked) {
      this.listDepartamentos = [...this.listDepartamentos, row];
    } else {
      this.removeToArray(row);
    }
    event = event ? this.selection.toggle(row) : null;
    return event;
  }
  private removeToArray(row: ListaDepartamentos): void {
    let contador = 0;
    this.listDepartamentos.forEach(articulos => {
      if (articulos.Id === row.Id) {
        // console.log(this.listArticulos.length);
        if (this.listDepartamentos.length <= 1) {
          this.listDepartamentos = [];
        } else {
          const cont = contador <= 1 ? 1 : contador;
          this.listDepartamentos.splice(contador, 1);
        }
      }
      contador++;
    });
  }
  masterToggle() {
    this.listDepartamentos = [];
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => {
        this.listDepartamentos = [...this.listDepartamentos, row];
        this.selection.select(row);
      });
  }

  openSnackBar(mensaje: string) {
    this.snackBar.open(mensaje, 'Ok', {
      duration: 2200,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
    });
  }


  checkboxLabel(row?: ListaDepartamentos): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.Id + 1}`;
  }
  public openDialogAddNew(): void {
    const dialogRef = this.dialog.open(DialogAddComponent, {
      disableClose: true,
      data: { opcion: 2 },

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.openSnackBar('El departamento se agrego correctamente');
        this.refreshClaves();
      }
    });
  }
}
