import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { DataVentaMarcas, DataGeneric,VentasMarcasDetallado, VentasMarcasResuman } from '../../ABC/utils/models/VentasMarcas';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
// import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
interface ResumenColumnas {
  field: string;
  width: string;
  type: string;
  align: string;
  index?: number;

}
@Component({
  selector: 'app-matriz-uno',
  templateUrl: './matriz-uno.component.html',
  styleUrls: ['./matriz-uno.component.scss']
})
export class MatrizUnoComponent implements OnInit, OnChanges {

  constructor() { }

  @Input() response: DataGeneric;
  @ViewChild('tableResumen', { static: false }) private paginatorResumen: MatPaginator;
  @ViewChild('tableDetallado', { static: false }) private paginatorDetallado: MatPaginator;

  @ViewChild('paginatorDetalladoGeneral', { static: false }) private paginatorDetalladoGeneral: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public columnasMarcasResumen: string[] = [];
  public columnasMarcasDetallado: string[] = [];

  // public columnasMarcasDetalladoGeneral: any[] = [];
  columns: ResumenColumnas[] = [];
  displayedColumns: string[] = [];


  public columnasMascMony: string[] = [];
  public columnasNotMascMony: string[] = [];
  public  ventasDetallado: MatTableDataSource<any>;
  public  ventasResumen: MatTableDataSource<any>;

  public  ventasDetalladoGeneral: MatTableDataSource<any>;
  ngOnChanges() {
    this.data();
  }
  ngOnInit() {
  }

  data() {
    if (this.response !== undefined) {
      if (this.response.error === false) {
        if (this.response.columns.length > 0 || this.response.columns.length > 0 ) {
          this.columnasMarcasResumen = this.response.columns;
          this.ventasResumen = new MatTableDataSource(this.response.data);
          this.ventasResumen.paginator = this.paginatorResumen;

          // this.columnasMarcasDetallado =  this.response.columnasMarcasDetalladoMes; // this.response.columnasMarcasDetallado;
          // this.ventasDetallado =  new MatTableDataSource(this.response.ventasMarcasDetalladoMes);
          // this.ventasDetallado.paginator = this.paginatorDetallado;

          // this.columns=  this.response.columnasMarcasDetalladoMesGeneral; // this.response.columnasMarcasDetallado;
          this.setDisplayedColumns();
          // this.ventasDetalladoGeneral =  new MatTableDataSource(this.response.ventasMarcasDetalladoMesGeneral);
          // this.ventasDetalladoGeneral.sort = this.sort;
          // this.ventasDetalladoGeneral.paginator = this.paginatorDetalladoGeneral;

          // this.columnasMascMony = this.response.columnasMascMony;
          // this.columnasNotMascMony = this.response.columnasNotMascMonyDetMes;

            const removeItem = this.columnasMarcasResumen.indexOf('EmpresaID');
            if (removeItem !== -1) {
                this.columnasMarcasResumen.splice(removeItem, 1);
            }
            const removeItem2 = this.columnasMarcasDetallado.indexOf('EmpresaID');
            if (removeItem2 !== -1) {
                this.columnasMarcasDetallado.splice(removeItem2, 1);
            }
        } else {
          this.ventasDetallado = new MatTableDataSource([]);
          this.ventasDetalladoGeneral = new MatTableDataSource([]);
          this.ventasResumen = new MatTableDataSource([]);
        }
      } else {
          this.columnasMarcasResumen = [];
          this.columnasMarcasDetallado = [];
          this.columns = [];
          this.displayedColumns = [];
          this.ventasDetallado = new MatTableDataSource([]);
          this.ventasResumen = new MatTableDataSource([]);
          this.ventasDetalladoGeneral = new MatTableDataSource([]);

      }
    }
  }

  setDisplayedColumns() {
    this.columns.forEach((column, index) => {

      column.index = index;
      this.displayedColumns[index] = column.field;
    });
    console.log(this.displayedColumns);
  }

}
