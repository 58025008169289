import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inventario'
})
export class InventarioPipe implements PipeTransform {

  transform(columnas: string[], nombre: string): boolean {
    for (let i = 0; i < columnas.length; i++) {
      if (columnas[i] === nombre) {
        return true;
      }
    }
  }

}