import { Component, OnInit, Input,Output,EventEmitter,ViewChild,OnChanges, SimpleChanges } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Validators, FormBuilder, FormGroup,FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
@Component({
  selector: 'app-filtros-articulos-almacen',
  templateUrl: './filtros-articulos-almacen.component.html',
  styleUrls: ['./filtros-articulos-almacen.component.scss'],
})
export class FiltrosArticulosAlmacenComponent implements OnInit,OnChanges {
  @Input() field: string;
  @Input() clearFilter:boolean;
  @Input() listaItems: any[];
  @Output() itemSelected: EventEmitter<any> = new EventEmitter();
  @ViewChild('contextMenuSucursal',  { static: false, read: MatMenuTrigger }) menuSucursal: MatMenuTrigger;

  // @ViewChild("menuFiltros",{static:false}) trigger: MatMenuTrigger;

  // @ViewChild('contextMenuAlmacen',  { static: false, read: MatMenuTrigger }) trigger: MatMenuTrigger;


  public listaItemsAux: any = [];
  public selection: SelectionModel<any> = new SelectionModel<any>(true, []);
  public search =  new FormControl('');
  constructor() {}

  ngOnInit() {
  }
  ngOnChanges(): void {
      if(this.clearFilter){
        console.log('Clear---')
        this.clear();
      }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listaItems.length;
    return numSelected === numRows;
  }

  public selected(event?: any, row?: any) {
    if (event.checked) {
      this.listaItemsAux = [...this.listaItemsAux, row];
    } else {
      this.removeToArray(row);
    }
    event = event ? this.selection.toggle(row) : null;
    return event;
  }
  private removeToArray(row: any): void {
    let contador = 0;
    this.listaItemsAux.forEach((value) => {
      if (value === row) {
        if (this.listaItemsAux.length <= 1) {
          this.listaItemsAux = [];
        } else {
          this.listaItemsAux.splice(contador, 1);
        }
      }
      contador++;
    });
  }
  masterToggle() {
    this.listaItemsAux = [];
    this.isAllSelected()
      ? this.selection.clear()
      : this.listaItems.forEach((row) => {
          this.listaItemsAux = [...this.listaItemsAux, row];
          this.selection.select(row);
        });
  }

  public getValue = (): string => {
      return this.search.value;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }
  filtar ($event:any): void{
    // console.log('filtrar',$event);
    this.itemSelected.emit(this.listaItemsAux);
  }

  public clear() {
    this.listaItemsAux = [];
    this.selection.clear()
    // console.log('clear', this.listaItemsAux);
    this.itemSelected.emit(this.listaItemsAux);
  }
}
