import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-firma',
  templateUrl: './firma.component.html',
  styleUrls: ['./firma.component.scss']
})

export class FirmaComponent implements OnInit {
  public data = this.formBuilder.group({
    sucursal: '',
    nombre: ['', [Validators.required]],
    departamento: ['', [Validators.required]],
    telefonoOficina: '',
    telefonoLlamadas: '',
    extencion: '',
    email: ['', [Validators.required]],
    calle: ['', [Validators.required]],
    numero: ['', [Validators.required]],
    colonia: ['', [Validators.required]],
    codigo_postal: ['', [Validators.required]],
    ciudad: ['', [Validators.required]],
    estado: ['', [Validators.required]],
    whatsapp: ''
  });

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.data.get('sucursal').setValue('Kabik Norte');
    this.data.get('telefonoOficina').setValue('8334543560');
    this.data.get('telefonoLlamadas').setValue('');
    this.data.get('whatsapp').setValue('8338503550');
    this.data.get('calle').setValue('Francisco Villa')
    this.data.get('numero').setValue('209');
    this.data.get('colonia').setValue('Nuevo Progreso');
    this.data.get('codigo_postal').setValue('89318');
    this.data.get('ciudad').setValue('Tampico');
    this.data.get('estado').setValue('Tamaulipas');
  }

  validate(event) {
    if (event.value === 'Kabik Norte') {
      this.data.get('telefonoOficina').setValue('8334543560');
      this.data.get('telefonoLlamadas').setValue('');
      this.data.get('whatsapp').setValue('8338503550')
      this.data.get('calle').setValue('Francisco Villa')
      this.data.get('numero').setValue('209');
      this.data.get('colonia').setValue('Nuevo Progreso');
      this.data.get('codigo_postal').setValue('89318');
      this.data.get('ciudad').setValue('Tampico');
      this.data.get('estado').setValue('Tamaulipas');
    }
    // if (event.value === 'Kabik Lomas') {
    //   this.data.get('telefono').setValue('');
    //   this.data.get('calle').setValue('Loma Rosa');
    //   this.data.get('numero').setValue('200');
    //   this.data.get('colonia').setValue('Lomas de Rosales');
    //   this.data.get('codigo_postal').setValue('89100');
    //   this.data.get('ciudad').setValue('Tampico');
    //   this.data.get('estado').setValue('Tamaulipas');
    // }
  }

  public submit() {
    if (this.data.invalid) {
      Swal.fire({
        type: 'warning',
        title: 'Advertencia',
        text: 'Faltan campos por completar'
      });
      return;
    }
    let name = this.name(this.data.value.nombre) + '_firma_kabik.html';
    let firm = document.querySelector('#firma').outerHTML;
    this.download(name, firm);
  }

  public download(name: string, firm: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/html;charset=utf-8,' + firm);
    element.setAttribute('download', name);
    element.click();
  }

  public numeric_input(event) {
    if(event.charCode >= 48 && event.charCode <= 57) {
      return true;
    }
    return false;
  }

  public  phone_mask(number: string) {
    return number.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '($1) $2 $3 $4');
  }

  public whatsapp_mask(number: string) {
    return number.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');
  }

  public name(name: string) {
    return name.split(' ').join('_').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  public email(email:string) {
    return email.toLowerCase();
  }
}
