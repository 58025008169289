import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { LoginService } from '../login/login.service';
import { map, retry, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { EmpresaService } from '../Empresa/Empresa.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
Injectable({
  providedIn: 'root',
})

export interface Respuesta {
  message: string;
}

export interface Images {
  images?: {};
  message: string;
}

export interface ResponceOfertas {
  respuesta: boolean | string | Array<any>;
  error: string;
}

export class ImagesService {
  private imageSource = new BehaviorSubject<Respuesta>({ message: '' });

  currentImage = this.imageSource.asObservable();
  empresaId =  String(environment.empresaId);
  private destroy$ = new Subject<void>();
  constructor(private http: HttpClient,
    private auth: LoginService,
    private empresaService: EmpresaService
  ) {
    this.empresaService.empresaId$
    .pipe(takeUntil(this.destroy$))
    .subscribe(id => {
      this.empresaId = id;
    });
  }

  updateImageObservable(image: Respuesta) {
    this.imageSource.next(image);
  }

  subirImagen(data: FormData) {
    return this.http
      .post<Respuesta>(
        environment.apiUrl + 'imagenes/subirImagenesPagina.php',
        data
      )
      .pipe(map((data1) => data1))
      .toPromise();
  }

  verificarArticulo(clave) {
    const data = {
      claveArticulo: clave,
      clientID: 1549,
      email: 'contacto@letech.mx',
      empresa:this.empresaId,
      showprice: true,
    };
    return this.http
      .post<any>(environment.apiUrl + 'productos/get_single_product.php', data)
      .pipe(map((data1) => data1))
      .toPromise();
  }

  eliminarImagen(data: FormData) {
    return this.http
      .post<Respuesta>(
        environment.apiUrl + 'imagenes/delete_image_admin.php',
        data
      )
      .pipe(map((data1) => data1));
  }


  eliminarBanners(list: any) {
    const data = {
      option: 'BannerDelete',
      list,
      empresa: this.empresaId
    };

    return this.http.post<any>(environment.apiUrl + 'imagenes/flyerPromocionales.php', data).pipe(retry(3),
    catchError(this.handleError), map(response => {
      return response;
    }));
  }

  editarImagen(data: FormData) {
    return this.http
      .post<Respuesta>(
        environment.apiUrl + 'imagenes/actualizarImagenPagina.php',
        data
      )
      .pipe(map((data1) => data1));
  }

  getImages(elemento) {
    return this.http
      .get<Images>(
        environment.apiUrl +
          'imagenes/get_images_admin.php?empresa=' +
          this.empresaId +'&elemento='+ elemento
      )
      .pipe(map((data) => data))
      .toPromise();
  }

  getImagesFaltantes() {
    return this.http
      .get<Images>(
        environment.apiUrl +
          'imagenes/getImagenesFaltantes.php?empresa=' +
          this.empresaId
      )
      .pipe(map((data) => data));
  }

  getImagenEnEdicion(clave: string) {
    return this.http
      .get(
        environment.apiUrl +
          'imagenes/getImagenEnEdicion.php?empresa=' +
          this.empresaId +
          '&clave=' +
          clave
      )
      .pipe(map((data) => data));
  }

  setImagenEnEdicion(clave: string, edicion: string) {
    return this.http
      .get(
        environment.apiUrl +
          'imagenes/setImagenEnEdicion.php?empresa=' +
          this.empresaId +
          '&clave=' +
          clave +
          '&edicion=' +
          edicion
      )
      .pipe(map((data) => data));
  }

  subirImagenesArticulo(data: FormData) {
    return this.http
      .post<Respuesta>(
        environment.apiUrl + 'imagenes/subirImagenesV3.php',
        data
      )
      .pipe(map((data1) => data1))
      .toPromise();
  }

  eliminarClaveConImagen(claveArticuloEditar: string) {
    const data = {
      empresa: this.empresaId,
      clave: claveArticuloEditar,
    };
    return this.http
      .post<Respuesta>(
        environment.apiUrl + 'imagenes/eliminarClaveImagenFaltante.php',
        data
      )
      .pipe(map((data1) => data1))
      .toPromise();
  }

  getImagesFaltantesFiltradas(
    fechaInicial: string,
    fechaFin: string,
    filtrarPorImagen: string = 'NO',
    filtrarPorDisponible: string = 'SI',
    filtrarPorExistencia: string = 'desc',
    filtrarPorClave: string = ''
  ) {
    const data = {
      empresa: this.empresaId,
      filtrarPorDisponible,
      filtrarPorImagen,
      filtrarPorExistencia,
      filtrarPorClave,
      fechaInicial,
      fechaFin,
    };
    return this.http
      .post<Images>(
        environment.apiUrl + 'imagenes/getImagenesFaltantesFiltradas.php',
        data
      )
      .pipe(map((data1) => data1))
      .toPromise();
  }

  updateDataImage(claveArticuloEditar: string) {
    const userData = this.auth.getUserData();
    const data = {
      user: userData.correo,
      empresa: this.empresaId,
      clave: claveArticuloEditar,
    };

    return this.http
      .post<Images>(
        environment.apiUrl + 'imagenes/actualizarImagenArticuloDatos.php',
        data
      )
      .pipe(map((data1) => data1))
      .toPromise();
  }

  cambiarVideo(video: any) {
    const data = {
      empresa: this.empresaId,
      video,
    };

    return this.http
      .post<Images>(environment.apiUrl + 'imagenes/subirVideo.php', data)
      .pipe(map((data1) => data1))
      .toPromise();
  }

  subirImagenesPromociones(data: FormData) {
    return this.http
      .post<Respuesta>(
        environment.apiUrl + 'imagenes/subirImagenesPromociones.php',
        data
      )
      .pipe(map((data1) => data1))
      .toPromise();
  }

  getImagesPromociones(): Promise<Images> {
    return this.http
      .get<Images>(
        environment.apiUrl +
          'imagenes/getImagenesPromociones.php?empresa=' +
          this.empresaId +
          '&sitio=admin'
      )
      .pipe(map((data) => data))
      .toPromise();
  }

  editarImagenPromociones(data: FormData) {
    return this.http
      .post<Respuesta>(
        environment.apiUrl + 'imagenes/editarImagenPromociones.php',
        data
      )
      .pipe(map((data1) => data1))
      .toPromise();
  }


  eliminarFlyerPromociones(list: any) {
    const data = {
      option: 'flayerPromocionesDelete',
      list,
      empresa: this.empresaId
    };

    return this.http.post<any>(environment.apiUrl + 'imagenes/flyerPromocionales.php', data).pipe(retry(3),
    catchError(this.handleError), map(response => {
      return response;
    }));
  }

  eliminarImagenFecha(images: any){
    let expiraronBanners = [];
    let actualBanners = [];

     for (let item of images) {
      const dateInicio = new Date(item.fechaInicial);
      const dateFinal = new Date(item.fechaFinal);
      const dateNow = new Date();
      if (dateInicio.getFullYear() < dateNow.getFullYear()) {
        item.status = 'default';
        actualBanners = [...actualBanners,item];
      } else {
        if (dateInicio <= dateNow && dateFinal >= dateNow) {
          item.status = 'actual';
          actualBanners = [...actualBanners,item];
        } else if (dateInicio < dateNow && dateFinal < dateNow) {
          expiraronBanners = [...expiraronBanners, item];
        } else if (dateInicio > dateNow && dateFinal > dateNow) {
          item.status = 'futuro';
          actualBanners = [...actualBanners,item];
        }
      }
     }
     return {expiraronBanners, actualBanners};
  }

  eliminarFlyers(images: any){
    let expiraronBanners = [];
    let actualBanners = [];

     for (let item of images) {
      const dateInicio = new Date(item.FechaInicio);
      const dateFinal = new Date(item.FechaFinal);

      const dateNow = new Date();
      if (dateInicio.getFullYear() < dateNow.getFullYear()) {
        item.status = 'default';
        expiraronBanners = [...expiraronBanners, item];
      } else {
        if (dateInicio <= dateNow && dateFinal >= dateNow) {
          item.status = 'actual';
          actualBanners = [...actualBanners,item];
        } else if (dateInicio < dateNow && dateFinal < dateNow) {
          expiraronBanners = [...expiraronBanners, item];
        } else if (dateInicio > dateNow && dateFinal > dateNow) {
          item.status = 'futuro';
          actualBanners = [...actualBanners,item];
        }
      }
     }
     return {expiraronBanners, actualBanners};
  }

  eliminarImagenPromociones(data: FormData) {
    return this.http
      .post<Respuesta>(
        environment.apiUrl + 'imagenes/eliminarImagenPromociones.php',
        data
      )
      .pipe(map((data1) => data1))
      .toPromise();
  }

  checkAndValidateImages(images: any, formdata: FormData) {
    let imgs = new Promise((resolve, reject) => {
      for (let item of images) {
        let dateInicio = new Date(item.FechaInicio);
        let dateFinal = new Date(item.FechaFinal);
        let dateNow = new Date();

        if (dateInicio.getFullYear() < dateNow.getFullYear()) {
          item.status = 'default';
        } else {
          if (dateInicio <= dateNow && dateFinal >= dateNow) {
            item.status = 'actual';
          } else if (dateInicio < dateNow && dateFinal < dateNow) {
            formdata.append('empresa', '1');
            formdata.append('id', item.ID);

            this.eliminarImagenPromociones(formdata).then((respuesta) => {
              if (respuesta.message === 'true') {
                this.updateImageObservable(respuesta);
              }
            });
          } else if (dateInicio > dateNow && dateFinal > dateNow) {
            item.status = 'futuro';
          }
        }
      }
      resolve(images);
    });
    return imgs;
  }

  agregarItemOferta(datalist): Observable<ResponceOfertas> {
    return this.http.post<ResponceOfertas>(
      environment.apiUrl + 'imagenes/Ofertas.php',
      datalist
    );
  }

  getListOferta(nombreOferta: string) {
    const rawData = {
      option: 'getOferta',
      nombreOferta,
      empresaId: this.empresaId,
    };

    return this.http
      .post<ResponceOfertas>(
        environment.apiUrl + 'imagenes/Ofertas.php',
        rawData
      )
      .pipe(map((info) => info))
      .toPromise();
  }

  deleteAllOferta(nombreOferta: string) {
    const dataDelete = {
      option: 'eliminarOferta',
      clienteId: this.empresaId,
      nombreOferta: nombreOferta,
    };
    return this.http
      .post<ResponceOfertas>(
        environment.apiUrl + 'imagenes/Ofertas.php',
        dataDelete
      )
      .pipe(map((info) => info));
  }
  private handleError = (error: HttpErrorResponse | any) => {
    Swal.fire({
      type: 'error',
      title: 'Error',
      text:
        'Lo sentimos, Ocurrio un error de comunicación, Favor de contactarse con el administrador del sitio Incidencias@kabik.com.mx' + error.message
    });
    return throwError(error);
  }
}
