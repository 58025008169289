import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listCheck'
})
export class ListCheckPipe implements PipeTransform {

  transform(selection: boolean, listaDeElemntos: any, abc2: boolean): boolean {
    // console.log(selection);
    // console.log(listaDeElemntos);
    // console.log(abc2);

    if (abc2) {
      if (!selection && (listaDeElemntos.length > 0 && listaDeElemntos.length < 2)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

}
